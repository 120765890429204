import React from 'react';
import { Card, Row, Col, Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { celularMask, cepMask, cpfMask } from '../../../../components/InputTextMask';
import "./informacoesCadastrais.scss";
//
// const location = {
//   address: 'Belo Horizonte, MG',
//   lat: -19.897496328764273,
//   lng: -43.94277516976184,
// }

export default function InformacoesCadastrais({ clientes, produto }) {
  return <>
    <Card.Body>
      <Tab.Container id="left-tabs" defaultActiveKey={1}>
        <Col>
          <Nav className="navsProponente" style={clientes.length === 1 ? { display: "none" } : {}} variant="pills">
            {clientes.map((cliente, index) =>
              <Nav.Item key={index}>
                <Nav.Link eventKey={index + 1}>{" "}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>

        </Col>
        <Col>
          <Tab.Content >
            {clientes.map((cliente, index) =>
              <Tab.Pane eventKey={index + 1} key={index}>
                <div className="dadosPessoaiscliente">
                  <div className="dadosIniciais">
                    <Row>
                      <Col xs={3}>
                        <div className="foto">
                          <div className="placeholder">
                            <div className=" fotoEnvolvido">
                              <FontAwesomeIcon
                                icon={faUserCircle} color="#444444" size="sm" />
                              {/* <Image src={Perfil} roundedCircle /> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={9}>
                        <div className="nomecliente">
                          <Card.Text>
                            <b>
                              {cliente.nome}
                            </b>
                          </Card.Text>
                        </div>
                        <div className="telefonecliente">
                          <Card.Text>
                            {celularMask(cliente.telefone)}
                          </Card.Text>
                        </div>
                        <div className="emailcliente">
                          <Card.Text>
                            {cliente.email}
                          </Card.Text>
                        </div>
                        <div className="empreendimentoCliente">
                          <Card.Text>
                            {produto.empreendimento_nome}
                          </Card.Text>
                        </div>
                        <div className="unidadeEmpreendimento">
                          <Card.Text>
                            {produto?.unidade_descricao}
                          </Card.Text>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="cpfcliente">
                    <strong>CPF: </strong> {cpfMask(cliente.cpf)}
                  </div>
                  <div className="rgcliente">
                    <strong>RG: </strong> {cliente.rg}
                  </div>
                  <div className="dataNasciemntocliente">
                    <strong>Data de Nascimento: </strong> {cliente.data_nascimento}
                  </div>
                  <div className="idadecliente">
                    <strong>Idade: </strong> {cliente.idade} Anos
                  </div>
                  <div className="generocliente">
                    <strong>Gênero: </strong> {cliente.genero}
                  </div>
                  <hr />
                  <div className="enderecocliente">
                    <div className="logradourocliente">
                      <strong>Logradouro: </strong> {cliente.logradouro}
                    </div>
                    <div className="complementocliente">
                      <strong>Complemento: </strong> {cliente.complemento == 'null' ? '' : cliente.complemento}
                    </div>
                    <div className="bairrocliente">
                      <strong>Bairro: </strong> {cliente.bairro}
                    </div>
                    <div className="cidadecliente">
                      <strong>Cidade/UF: </strong> {cliente.cidade} / {cliente.uf}
                    </div>
                    <div className="cepcliente">
                      <strong>CEP: </strong> {cepMask(cliente.cep)}
                    </div>
                  </div>
                </div>

              </Tab.Pane>

            )}
          </Tab.Content>
        </Col>

      </Tab.Container>

      {/* <div>
                                <Mapa location={location} zoomLevel={13} /> {/* include it here */}
      {/* </div> */}
    </Card.Body>


  </>
}
