import { faLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MidiaSocialIcone from '../../../../components/MidiaSocialIcone';
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, ModalFooter, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { celularMask, cpfMask } from "../../../../components/InputTextMask";
import ModalDommus from "../../../../components/Modal";
import './index.scss';

export function ModalDadosVinculacao({
  openModal,
  handleModal,
  processoSelecionado,
  handleVincularProcesso,
}) {

  const [referencia, setReferencia] = useState({});
  
  useEffect(()=>{
    if(processoSelecionado?.oportunidade != null){
      setReferencia({
        valor: processoSelecionado?.oportunidade,
        tipo: 'oportunidade'
      })
    }else if(processoSelecionado?.processo != null){
      setReferencia({
        valor: processoSelecionado?.processo,
        tipo: 'processo'
      })
    }
  },[processoSelecionado])

  return (
    <ModalDommus
      open={openModal}
      size={"lg"}
      close={() => {
        handleModal(false);
      }}
      titulo={"Dados da Vinculação"}
    >
      <Container fluid className="dadosVinculacao">
        <div className="mensagem">
        <Alert variant={'warning'}>
          <strong>Você está prestes a vincular este atendimento ao seguinte {referencia.tipo}: {referencia.valor}</strong>
        </Alert>          
        </div>
        <br />
        {processoSelecionado.proponentes?.map((proponente, index)=>{
            return (              
            <div key={index}>
                <Row>
                <Col>
                    <label>
                    <strong>Nome Proponente: </strong>
                    </label>
                    <p>{proponente?.nome === null ? "---" : proponente?.nome}</p>
                </Col>
                <Col>
                    <label>
                    <strong>CPF: </strong>
                    </label>
                    <p>{proponente?.cpf === null ? "---" : cpfMask(proponente?.cpf)}</p>
                </Col>
                <Col>
                    <label>
                    <strong>Telefone: </strong>
                    </label>
                    <p>{proponente?.telefone === null ? "---" : celularMask(proponente?.telefone)}</p>
                </Col>
                </Row>
                <Row>
                <Col>
                    <label>
                    <strong>E-mail: </strong>
                    </label>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="Email">
                                {proponente?.email === null ? "---" : proponente.email}
                            </Tooltip>
                        }
                    >
                    <p className="email-section">{proponente?.email === null ? "---" : proponente.email}</p>
                    </OverlayTrigger>
                </Col>            
                <Col>
                  <label>
                    <strong>Empreendimento: </strong>
                  </label>
                  <p>{processoSelecionado.empreendimento?.nome === null ? "---" : processoSelecionado.empreendimento?.nome}</p>
                </Col>
                <Col>
                  <label>
                    <strong>Unidade: </strong>
                  </label>
                  <p>{processoSelecionado.empreendimento?.unidade === null ? "---" : processoSelecionado.empreendimento?.unidade}</p>
                </Col>
                </Row>
                <hr/>
              </div>  
            )})}  
        <Row>
          <Col sm={1}>
            <div className="midia-section">
              <MidiaSocialIcone midia={(processoSelecionado.atendimento?.meioComunicacao)|| null} />
            </div>
          </Col>
          <Col>
            <label>
              <strong>Atendente: </strong>
            </label>
            <p>{processoSelecionado.atendimento?.nome === null ? "---" : processoSelecionado.atendimento?.nome}</p>
          </Col>
          <Col>
            <label>
              <strong>Contato: </strong>
            </label>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="contato">
                        {processoSelecionado.atendimento?.contato === null ? "---" : processoSelecionado.atendimento?.contato}
                    </Tooltip>
                }
            >
            <p className="email-section">{processoSelecionado.atendimento?.contato === null ? "---" : processoSelecionado.atendimento?.contato}</p>
            </OverlayTrigger>
          </Col>
          <Col>
            <label>
              <strong>Situação: </strong>
            </label>
            <p>{processoSelecionado.atendimento?.situacao === null ? "---" : processoSelecionado.atendimento?.situacao}</p>
          </Col>
        </Row>
        <Row>
        </Row>
        <ModalFooter className="d-flex justify-content-between">
          <strong style={{fontSize:'1.2rem'}}>Deseja continuar?</strong>
          <div>
            <Button 
              variant="success" 
              type="button" 
              className="mx-2"
              onClick={()=>{handleVincularProcesso(referencia.valor, referencia.tipo)}}
            >
              <FontAwesomeIcon icon={faLink} /> Sim, Vincular
            </Button>
            <Button
              variant="secondary"
              type="button"
              className="mx-2"
              onClick={() => handleModal(false)}
            >
              <FontAwesomeIcon icon={faTimes}/> Não, Cancelar
            </Button>
          </div>
        </ModalFooter>
      </Container>
    </ModalDommus>
  );
}
