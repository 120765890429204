import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import "./index.css";
import { MeioComunicacaoContext } from "../../../../contexts/MeioComunicacaoContext";
import { TipoAtendimentoContext } from "../../../../contexts/TipoAtendimentoContext";
import { SituacaoAtendimentoContext } from "../../../../contexts/SituacaoAtendimentoContext";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { ExecucaoDisparoItem } from "./ExecucaoDisparoItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { DisparoMassaContext } from "../../../../contexts/DisparoMassaContext";

export function AgendamentoDisparoVisualizacao({ agendamentoSemExecucao }) {
    const [agendamento, setAgendamento] = useState(null)
    const { meiosComunicacao, resolverNomeMeioComunicacao } = useContext(
        MeioComunicacaoContext
    );
    const { resolverNomeTipoAtendimento } = useContext(TipoAtendimentoContext);
    const { resolverNomeSituacaoAtendimento } = useContext(
        SituacaoAtendimentoContext
    );
    const { resolverNomeTemplate } = useContext(TemplateContext);
    const { buscarAgendamentoPorId } = useContext(DisparoMassaContext)
    const resolveNomeContatoOrigem = useMemo(() => {
        if (meiosComunicacao && agendamento) {
            let meioComunicacao = meiosComunicacao.find(
                (meio) => meio.id === agendamento.id_meio_comunicacao
            );
            if (meioComunicacao) {
                let contatoOrigem = meioComunicacao.contatos_origem.find(
                    (contato) =>
                        contato.id_contato_origem ===
                        agendamento.id_contato_origem
                );
                if (contatoOrigem) {
                    return contatoOrigem?.descricao;
                }
            }
        }
        return "Não Atribuido";
    }, [agendamento]);

    useEffect(() => {
        buscarAgendamento()
    }, [])
    
    async function buscarAgendamento() {
        const agendamentoComExecucoes = await buscarAgendamentoPorId(agendamentoSemExecucao.id)
        setAgendamento(agendamentoComExecucoes.data)
    }

    return agendamento && (
        <div className="agendamento-visualizacao-section">
            <fieldset className="dados-agendamento-section">
                <legend>Dados Agendamento</legend>
                <Row>
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Nome:</span>
                        <span className="info">{agendamento.nome}</span>
                    </Col>
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Tipo Atendimento:</span>
                        <span className="info">
                            {resolverNomeTipoAtendimento(
                                agendamento.id_tipo_atendimento
                            )}
                        </span>
                    </Col>
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Situação do Atendimento:</span>
                        <span className="info">
                            {resolverNomeSituacaoAtendimento(
                                agendamento.id_situacao
                            )}
                        </span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Meio de Comunicação:</span>
                        <span className="info">
                            {resolverNomeMeioComunicacao(
                                agendamento.id_meio_comunicacao
                            )}
                        </span>
                    </Col>
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Template:</span>
                        <span className="info">
                            {resolverNomeTemplate(agendamento.id_template)}
                        </span>
                    </Col>
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Contato Origem:</span>
                        <span className="info">{resolveNomeContatoOrigem}</span>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4} className="agendamento-dados-section">
                        <span className="label">Assunto:</span>
                        <span className="info">
                            {agendamento.assunto ?? "Não informado"}
                        </span>
                    </Col>
                    {agendamento?.anexos?.length ? (
                        <Col md={8} className="agendamento-dados-section">
                            <span className="label">Anexos:</span>
                            <div className="agendamento-anexos-section">
                                {agendamento.anexos.map((anexo, index) => {
                                    return (
                                        <a key={index} href={anexo.url} target="__blank" className="badge-anexo">
                                            <FontAwesomeIcon icon={faPaperclip}/>
                                            <span>
                                                {anexo.nome}
                                            </span>
                                        </a>
                                    );
                                })}
                            </div>
                        </Col>
                    ) : <></>}
                </Row>
            </fieldset>
            <Row className="mt-3">
                {agendamento.execucao_disparos.map((execucao, index) => {
                    return (
                        <ExecucaoDisparoItem
                            key={index}
                            execucaoDisparo={execucao}
                            meioComunicacao={agendamento.id_meio_comunicacao}
                        />
                    );
                })}
            </Row>
        </div>
    ) || <Alert variant="warning" className="text-center"><FontAwesomeIcon icon={faExclamationTriangle}/> Agendamento não encontrado.</Alert>;
}
