import api from './api';

const LeadsService = {
	getEmpreendimentos:()=>{
		return api.get('/leads/empreendimentos');
	},
	getCampanhas:(id)=>{
		return api.get('/leads/campanhas');
	},
};

export default LeadsService;
