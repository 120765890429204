import React, {useState, useContext, useEffect} from 'react';
import caAPI from "../../../services/caAPI";
import { WebSocketContext } from "../../../contexts/WebSocketContext";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import { VoipContext } from "../../../contexts/VoipContext";
import ListaBacia from "../ListaBacia";
import { errorToast, successToast } from "../../../components/DommusToast";
import DommusLoading from "../../../components/DommusLoading/DommusLoading";
import DommusSidenavSection from "../../../components/DommusSidenavSection";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemBarraTitulo from "../../../components/DommusBarraTitulo/ItemBarraTitulo";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import {
  faCouch
} from "@fortawesome/free-solid-svg-icons";
import NotificationDommus from "../../../helpers/NotificationsHelper";
import { DommusToastWarmingOptions } from '../../../components/DommusToastWarmingOptions';
import {isMobile, MobileView} from 'react-device-detect';
export default function BaciaAtendimentoWrapper(){
  const { channel } = useContext(WebSocketContext);
  const { inCallStatus } = useContext(VoipContext);
  const [bacia, setBacia] = useState([]);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [openBacia, setOpenBacia] = useState(false);
  const [openToastSalaEspera, setOpenToastSalaEspera] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    listaAtendimentos,
    setListaAtendimentos,
    setAtendimentoAtivo,
    atendimentoAtivo
  } = useContext(AtendimentoContext);

  const atualizarBacia = (notificar = true)=>{
    caAPI.bacia.buscar().then((res) => {
      if((res.data.length > Number(count)) && notificar){
        NotificationDommus.showNotification('Existem pessoas aguardando na sala de espera.', 'Sala de Espera')
        successToast.fire(
          '<span style="color:#FFFF"> Novo atendimento em espera. </span>"'
          );
        setOpenToastSalaEspera(true);
      }
      setBacia(res.data);
      setCount(res.data.length);
    });
  };

  const handleLembreteToastSala = ()=>{
    setOpenToastSalaEspera(false);
    setTimeout(() => {
      if(count > 0){
        setOpenToastSalaEspera(true);
      }
    }, 300000);
  }

  useEffect(() => {
    atualizarBacia(false);
  }, []);

  useEffect(() => {
    if(inCallStatus){
      setLoading(false);
    }
  }, [inCallStatus]);

  useEffect(() => {
    channel.unbind("atualizar-contagem-bacia");
    channel.bind("atualizar-contagem-bacia", function (data) {
      atualizarBacia();
    });

    return () => {
      channel.unbind("atualizar-contagem-bacia");
    }
  }, [channel, count, atualizarBacia])

  const handleOpenToastSala = ()=>{
    setOpenToastSalaEspera(!openToastSalaEspera);
  }

  const handleBacia = ()=>{
    handleOpenToastSala();
    setOpenBacia(!openBacia);
  }

  const fecharBacia = () => setOpenBacia(false);
  const headerBacia =
    <div className="w-100 d-flex justify-content-between">
      <div style={{marginTop: "0.5rem"}}>Sala de Espera</div>
      <div>
        {bacia.length > 0 ? (
          <>
          {caAPI.getLocalState('limpaBacia') &&
          <button
            className="botaoLimparBacia"
            onClick={() => {
                limparBacia();
            }}>Esvaziar</button>}
          <button
            className="botaoResgate"
            onClick={() => {
              if(!inCallStatus){
                recuperarBacia();
                setOpenBacia(false);
              }else{
                errorToast.fire({text: "Em ligação."})
              }
          }}
          >
            Resgatar
          </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>;
  const recuperarBacia = () => {
    Swal.fire({
      titleText: `Recuperar atendimento?`,
      text: "Tem certeza que deseja recuperar o último atendimento da sala de espera?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      showDenyButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      denyButtonText: "Não.",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
        denyButton: "btn btn-danger mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
          caAPI.bacia.recuperar().then(
            (res) => {
              if(res && res.data){
                if(!(res.data.meio_comunicacao.slug === "voip" && res.data.chamada_voip_ativa)){
                  const novaLista = listaAtendimentos.slice();
                  novaLista.unshift(res.data);
                  setAtendimentoAtivo(res.data);
                  setListaAtendimentos(novaLista);
                  Swal.fire({
                    titleText: "Sucesso!",
                    text: `Atendimento recuperado.`,
                    icon: "success",
                  });
                }else{
                  successToast.fire({text: "Atendimento recuperado."})
                  setLoading(false);
                }
              }else{
                Swal.fire({
                  titleText: "Nenhum atendimento a recuperar.",
                  icon: "info",
                });
                setLoading(false);
              }
            },
            (error) => {
              setLoading(false);

              Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao recuperar o atendimento: " + error,
                icon: "error",
              });
            }
          );
      } else {
        return;
      }
    });
  }

  const limparBacia = () => {
    Swal.fire({
      titleText: `Esvaziar sala?`,
      text: "Tem certeza que deseja remover todos os atendimentos da sala de espera?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      showDenyButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim!",
      denyButtonText: "Não.",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
        denyButton: "btn btn-danger mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise(
          caAPI.bacia.limpar().then(
            (res) => {
              Swal.fire({
                titleText: "Sucesso!",
                text: `A sala agora está vazia.`,
                icon: "success",
              });
              setOpenBacia(false);
            },
            (error) => {
              Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao esvaziar a sala: " + error,
                icon: "error",
              });
            }
          )
        );
      } else {
        return;
      }
    });
  }

  return(
    <>
    { !isMobile &&
      <ItemBarraTitulo
        classe="btn"
        onClick={() => {
          setOpenBacia(true);
        }}
      >
        <OverlayTrigger
          key="buttonSalaEspera"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-SalaEspera`}>
              Sala de espera
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faCouch} color="#265d9c" />
        </OverlayTrigger>
        {count >= 0 ? <div className="indicativoFiltro">{count}</div> : <></>}
      </ItemBarraTitulo>
    }
    <MobileView>
      <a
        onClick={() => {
          setOpenBacia(true);
        }}
      >
        <OverlayTrigger
          key="buttonSalaEspera"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-SalaEspera`}>
              Sala de espera
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faCouch} />
        </OverlayTrigger>
        {count >= 0 ? <div className="indicativoFiltro">{count}</div> : <></>}
      </a>
    </MobileView>
    <DommusSidenavSection openSection={openBacia} sectionHeader={headerBacia} closePaneHandler={fecharBacia} >
      <ListaBacia atendimentos={bacia} />
    </DommusSidenavSection>
    <DommusToastWarmingOptions
      open={openToastSalaEspera}
      handleOpen={handleOpenToastSala}
      handleLembrete={handleLembreteToastSala}
      handleVisualizarSala={handleBacia}
    />
    {
      loading && <DommusLoading />
    }
    </>
  );
}
