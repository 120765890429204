import api from "./api";

const FilaService = {
  index: () => {
    return api.get("/fila-atendentes");
  },
  mudarPosicao: (novaLista) => {
    return api.put("/fila-atendentes", { fila: novaLista });
  },
  excluirAtendente: (id) => {
    return api.delete(`/fila-atendentes/${id}`);
  },
  adicionarFila: (idUsuario) => {
    return api.post("/fila-atendentes", idUsuario);
  },
  atendentes: () => {
    return api.get("/atendentes");
  },
  qualificacao: () => {
    return api.get("/qualificacao-atendente");
  },
  comunicacao: () => {
    return api.get("/meio-comunicacao");
  },
  qualificarAtendente: (id, contato) => {
    return api.post(`/qualificacao-atendente/${id}`, contato);
  },
  deletarQualificacaoAtendente: (id, contato) => {
    return api.delete(`/qualificacao-atendente/${id}`, { data: contato });
  },
};

export default FilaService;
