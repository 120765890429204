import api from './api';

const TicketPrioridade = {
	index:()=>{
		return api.get('/ticket/prioridade');
	},
	show:(id)=>{
		return api.get('/ticket/prioridade/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket/prioridade/' + id;
        return api['put'].call(api, uri, {prioridade: dados});
    },
	store: (dados) => {
        let uri = '/ticket/prioridade';
        return api['post'].call(api, uri, {prioridade: dados});
    },
};

export default TicketPrioridade;
