import { Editor } from '@tinymce/tinymce-react';
import React, { useContext } from 'react'
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import DommusBarraTitulo from '../../../components/DommusBarraTitulo';
import ItemBarraTitulo from '../../../components/DommusBarraTitulo/ItemBarraTitulo';
import DommusToggle from '../../../components/DommusToggle';
import { AtendimentoContext } from '../../../contexts/AtendimentoContext';
import caAPI from '../../../services/caAPI';
import { PerfilUsuarioService } from '../../../services/PerfilUsuarioService';
import "./index.css";

export default function GerenciarAssinatura() {
    const { assinaturaUsuario, setAssinaturaUsuario } = useContext(AtendimentoContext);

    const handleEditorChange = (content, editor) => {
        setAssinaturaUsuario(content);
    }

    const salvarAssinatura = () => {
        let dados = { assinatura: assinaturaUsuario }

        trackPromise(PerfilUsuarioService.update(caAPI.getLocalState('idUsuario'), dados))
            .then(() => {
                Swal.fire({
                    titleText: "Sucesso!",
                    text: `Assinatura salva com sucesso!`,
                    icon: 'success'
                })
            })
            .catch(error => Swal.fire({
                titleText: "Ooops...",
                text: `Houve um problema ao salvar a sua assinatura. ${error}`,
                icon: 'error'
            }))
    }

    return (
        <Container fluid>

        <div>
            <DommusBarraTitulo>
                <ItemBarraTitulo>
                    Gerenciar Assinatura
                </ItemBarraTitulo>
            </DommusBarraTitulo>

            <DommusToggle title='Assinatura' toggle={true} className="assinatura">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <div>
                                    <Editor
                                        apiKey="25mzf46lh9blkhbpnxuvuz2etjw5cqrt3r82xl0geig8fhj5"
                                        value={assinaturaUsuario}
                                        onEditorChange={handleEditorChange}
                                        init={{
                                            height: 500,
                                            entity_encoding: "raw",
                                            selector: "textarea#templateTitle",
                                            menubar: false,
                                            plugins: ['link','image','media'],
                                            toolbar: ['casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | insertfile image template link anchor codesample '],
                                            content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                                        <br></br>
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <ButtonGroup  size="lg">
                                        <Button
                                            onClick={(e) => { salvarAssinatura() }} >
                                            Salvar
                                        </Button>
                                    </ButtonGroup>

                                </Col>
                        </Row>
                    </Col>
                </Row>
            </DommusToggle>
        </div>
        </Container>
    )
}

