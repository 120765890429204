import api from './api';

export const PerfilUsuarioService = {
	show: (id) => {
		return api.get(`/perfil-usuario/${id}`);
	},
	update: (id, data) => {
		return api.put(`/perfil-usuario/${id}`, data)
	},
	buscarUsuarioGu: (idUsuario) => {
		return api.get(`/perfil-usuario/usuario/${idUsuario}`)
	},
	atualizarUsuarioGU: (idUsuario, usuario) => {
		return api.put(`/perfil-usuario/usuario/${idUsuario}`, usuario)
	},
	atualizarFotoPerfilUsuario: (idUsuario, dados, multipart=false) => {
		let parametrosRequisicao = {}
		if(multipart) {
			parametrosRequisicao.headers = { 'Content-Type': 'multipart/form-data' }
		}
		return api.post(`/perfil-usuario/${idUsuario}`, dados, parametrosRequisicao)
	}
};