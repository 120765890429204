import React from 'react';

export default function CabecalhoTabelaHistorico(){
  return <tr>
    <th className="canalTabelaAtendimentoArquivados">
      Canal:
    </th>
    <th >
      Data / Hora:
    </th>
    <th>
      Atendimento:
    </th>
    <th >
      Interação:
    </th>
    <th>
      Agente:
    </th>
  </tr>;
};
