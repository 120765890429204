import React from 'react-bootstrap';
import { Alert, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./financeiro.scss";

export default function Financeiro({ financeiro }) {
    function colocaIcon(status){
        if(status === 'quitada'){
            return(
                <FontAwesomeIcon
                    title={status}
                    icon={faCheckCircle}
                    color="green"
                />
            )
        }else if(status === 'avencer'){
            return (
                <FontAwesomeIcon
                    title={status}
                    icon={faExclamationCircle}
                    color="orange"
                />
            )
        }else{
            return (
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="red"
                    title={status}
                />
            )
        }
    }

    return financeiro?.controle_financeiro?.mensagem && 
        <Alert>{`${financeiro.controle_financeiro.mensagem} ${financeiro.controle_financeiro.motivo && `Motivo: ${financeiro.controle_financeiro.motivo}` || ''}`}</Alert> || 
        <>
            <Card.Body className="cardTabela" >
                <div className="conteudoFianceiro">
                    <Table className="tebelaFinanceiro" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tipo da Parcela</th>
                                <th>Vencimento</th>
                                <th>Valor(R$)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {financeiro.parcelas ? financeiro.parcelas.map((financeiro, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{financeiro.tipo}</td>
                                        <td>{financeiro.vencimento}</td>
                                        <td>{financeiro.valor}</td>
                                        <td>{colocaIcon(financeiro.status)}</td>
                                    </tr>
                                )
                            }) : <></>}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </>
}
