import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import {
    Button,
    ButtonGroup,
    Container,
    Dropdown,
    OverlayTrigger,
    ToggleButton,
    Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEye,
    faPen,
    faPlusCircle,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../components/Modal";
import { ContatosNotificacaoHistoricoForm } from "./ContatoNotificacaoHistoricoForm";
import { ContatoNotificacaoHistoricoContext } from "../../contexts/ContatoNotificacaoHistoricoContext";
import { celularMask } from "../../components/InputTextMask";
import MidiaSocialIcone from "../../components/MidiaSocialIcone";
import DommusTabelaPaginada from "../../components/DommusTabelaPaginada";
import { trackPromise } from "react-promise-tracker";
import { ContatoNotificacaoHistoricoService } from "../../services/ContatoNotificacaoHistoricoService";
import Swal from "sweetalert2";

export function ContatosNotificacaoHistorico() {
    const [openModalContato, setOpenModalContato] = useState(false);
    const [contatoSelecionado, setContatoSelecionado] = useState(null);
    const { contatosNotificacaoLista, recarregarContatosNotificacao, atualizarContatoNotificacao, removerContatoNotificao } =
        useContext(ContatoNotificacaoHistoricoContext);

    useEffect(() => {
        recarregarContatosNotificacao();
    }, []);

    const handleModalContato = () => {
        if(openModalContato){
            setContatoSelecionado(null);
        }
        setOpenModalContato((current) => !current);
    };

    const thead = useMemo(() => {
        return (
            <tr>
                <th>Descrição</th>
                <th>Contato</th>
                <th style={{ width: "20%" }}>Tipo Histórico</th>
                <th style={{ width: "12%" }}>Meio Comunicação</th>
                <th className="text-center" style={{ width: "12%" }}>Status</th>
                <th className="text-center" style={{ width: "5%" }}>Ações</th>
            </tr>
        );
    }, []);

    const handleAlterarOculto = (contatoNotificacaoId, value)=>{
        let data = {
            "oculto": value
        }
        trackPromise(ContatoNotificacaoHistoricoService.update(contatoNotificacaoId, data)).then((response)=>{
            atualizarContatoNotificacao(response.data);
        }).catch((error)=>{
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao alterar o status contato " + error,
                icon: 'error'
            });
        })
    }

    const handleRemoverContato = (idContato) => {
        Swal.fire({
            titleText: "Deseja excluir o contato?",
            text: "Confime a realização da ação.",
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não, mantenha.",
            customClass: {
              confirmButton: "btn btn-primary mr-3",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (!result.isConfirmed) {
                return
            }
            trackPromise(ContatoNotificacaoHistoricoService.delete(idContato)).then((response)=>{
                removerContatoNotificao(idContato);
            }).catch((error)=>{
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao excluir contato " + error,
                    icon: 'error'
                });
            })
        })
    }

    const trows = useMemo(() => {
        return contatosNotificacaoLista.map((contato, index) => {
            return (
                <tr key={index}>
                    <th>{contato?.descricao ? contato?.descricao : "---"}</th>
                    <th>
                        {contato?.meio_comunicacao?.slug !== "email"
                            ? celularMask(contato?.contato)
                            : contato?.contato}
                    </th>
                    <th>{contato?.tipo_historico_atendimento?.descricao}</th>
                    <th className="text-center td-meio-comunicacao">
                        <MidiaSocialIcone
                            midia={contato?.meio_comunicacao?.slug}
                        />
                    </th>
                    <th className="text-center">
                    <ButtonGroup toggle className="btn-group-ativo-inativo">
                        <ToggleButton
                            type="radio"
                            variant={contato.oculto === "N" ? "success" : "secondary"}
                            name="radio"
                            value={"N"}
                            checked={contato.oculto === "N"}
                            disabled={contato.oculto === "N"}
                            onChange={(e) => handleAlterarOculto(contato.id_contato_notificacao_historico, e.target.value)}
                        >
                            Ativo
                        </ToggleButton>
                        <ToggleButton
                            type="radio"
                            variant={contato.oculto === "S" ? "danger" : "secondary"}
                            name="radio"
                            value={"S"}
                            checked={contato.oculto === "S"}
                            disabled={contato.oculto === "S"}
                            onChange={(e) => handleAlterarOculto(contato.id_contato_notificacao_historico, e.target.value)}
                        >
                            Inativo
                        </ToggleButton>
                    </ButtonGroup>
                    </th>
                    <th className="text-center">
                        <Dropdown className="acoes-tabela">
                            <Dropdown.Toggle>
                                <FontAwesomeIcon icon={faPen} color="#fff" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    setContatoSelecionado(contato)
                                    handleModalContato();
                                }}>
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        color="#B5DEFF"
                                        style={{ marginRight: 10 }}
                                    />
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={
                                        () => {handleRemoverContato(contato.id_contato_notificacao_historico)}
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        color="#E05D5D"
                                        style={{ marginRight: 10 }}
                                    />
                                    Remover
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </th>
                </tr>
            );
        });
    }, [contatosNotificacaoLista]);

    return (
        <div className="px-3">
            <DommusBarraTitulo>
                <ItemBarraTitulo>
                    Contatos para Notificação de Historico
                </ItemBarraTitulo>
                <OverlayTrigger
                    key="overlay-disparo-massa"
                    overlay={
                        <Tooltip id="tooltip-disparo-massa">
                            Adicionar novo contato
                        </Tooltip>
                    }
                    placement="top"
                >
                    <ItemBarraTitulo
                        classe="btn py-3"
                        onClick={() => handleModalContato()}
                    >
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            color="#4caf50"
                            size="lg"
                        />
                    </ItemBarraTitulo>
                </OverlayTrigger>
            </DommusBarraTitulo>
            <Container
                fluid
                className="bg-white border rounded formCadastro pt-3"
            >
                <DommusTabelaPaginada
                    linhasCabecalho={thead}
                    optionsTabela={{}}
                    linhasCorpo={trows}
                    customClasses={"overflow-auto"}
                />
            </Container>

            <ModalDommus
                titulo="Nova Base de Disparo"
                open={openModalContato}
                close={() => handleModalContato()}
            >
                <ContatosNotificacaoHistoricoForm
                    contato={contatoSelecionado}
                    closeModal={handleModalContato}
                />
            </ModalDommus>
        </div>
    );
}
