import React, { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";

export function AtalhoSection({ open, handleAtalhoSection, listaItens, refInput }) {
  const refAtalhoSection = useRef(null);

    useEffect(() => {        
    const handleClickOutside = (event) => {      
        if (refAtalhoSection.current && !refAtalhoSection.current.contains(event.target) 
            && refInput && !refInput.current.contains(event.target)) {
            handleAtalhoSection(false);
        }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
        document.removeEventListener("click", handleClickOutside, true);
    };
    }, [refAtalhoSection, refInput]);

  return (
    <Card className="section-atalho" hidden={!open} ref={refAtalhoSection}>
      {listaItens}
    </Card>
  );
}
