import React from "react";
import { trackPromise } from "react-promise-tracker";
import { successToast } from "../../../components/DommusToast";
import FilaService from "../../../services/FilaService";
import Lista from "./Lista";
import Swal from "sweetalert2";

export default function ListaFila({
  atendentes,
  infosAtendentes,
  setTrocaFeita,
  trocaFeita,
  setRefresh,
  refresh,
}) {
  function dropEndHandler(inicio, fim) {
    let ocorrenciaOrigem = Object.assign({}, infosAtendentes[inicio]);
    let ocorrenciaDestino = Object.assign({}, infosAtendentes[fim]);

    let auxDestino = {
      id_usuario: ocorrenciaDestino.id_usuario,
      id_fila_atendentes: ocorrenciaDestino.id_fila_atendentes,
      qualificacao_atendente: ocorrenciaDestino.qualificacao_atendente,
    };
    // ocorrenciaOrigem.id_usuario = ocorrenciaOrigem.id_usuario;
    ocorrenciaDestino.id_fila_atendentes = ocorrenciaOrigem.id_fila_atendentes;
    ocorrenciaDestino.qualificacao_atendente = ocorrenciaOrigem.qualificacao_atendente;

    ocorrenciaDestino.id_usuario = auxDestino.id_usuario;
    ocorrenciaOrigem.id_fila_atendentes = auxDestino.id_fila_atendentes;
    ocorrenciaOrigem.qualificacao_atendente = auxDestino.qualificacao_atendente;

    let ocorrenciasPersistir = [ocorrenciaDestino, ocorrenciaOrigem];

    trackPromise(
      FilaService.mudarPosicao(ocorrenciasPersistir)
        .then(
          (res) => {
            setTrocaFeita(!trocaFeita);
            successToast.fire({ text: "Posição trocada com sucesso." });
          },
          (error) => {
            throw error;
          }
        )
        .catch((error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao trocar as posições na fila: " + error,
            icon: "error",
          });
        })
    );
  }

  function excluirOcorrenciaFilaAtendimento(idFilaAtendente, posicao) {
    Swal.fire({
      titleText: "Deseja excluir o(a) atendente?",
      text:
        "Tem certeza que deseja excluir o(a) atendente na posição " +
        (posicao + 1) +
        " da fila?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não, mantenha.",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        trackPromise(
          FilaService.excluirAtendente(idFilaAtendente)
            .then(
              (response) => {
                setRefresh(!refresh);
                successToast.fire({
                  text: "Atendente removido(a) com sucesso.",
                });
              },
              (error) => {
                throw error;
              }
            )
            .catch((error) => {
              Swal.fire({
                titleText: "Ooops...",
                text:
                  "Ocorreu um erro ao remover o(a) atendente da fila: " + error,
                icon: "error",
              });
            })
        );
      }
    });
  }

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Lista
        dropEndHandler={dropEndHandler}
        atendentes={atendentes}
        infosAtendentes={infosAtendentes}
        excluirOcorrenciaFilaAtendimento={excluirOcorrenciaFilaAtendimento}
      />
    </div>
  );
}
