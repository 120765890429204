import React, { useContext, useEffect, useState, useRef } from 'react';
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ocultaTelefone } from '../../../../components/InputTextMask';
import Anotacoes from '../../../../components/Anotacoes';
import { Icon } from '@iconify/react';
import phoneOutgoing from '@iconify-icons/carbon/phone-outgoing';
import phoneBlock from '@iconify-icons/carbon/phone-block';
import bxMicrophone from '@iconify-icons/bx/bx-microphone';
import bxMicrophoneOff from '@iconify-icons/bx/bx-microphone-off';
import bxTransfer from '@iconify-icons/bx/bx-transfer';
import ModalDommus from "../../../../components/Modal";
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import caAPI from "../../../../services/caAPI";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { VoipContext } from "../../../../contexts/VoipContext";
import { AtendimentoContext } from "../../../../contexts/AtendimentoContext";
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { AtendentesContext } from "../../../../contexts/AtendentesContext";
import { errorToast, infoToast, successToast } from '../../../../components/DommusToast';
import  DommusLoading  from '../../../../components/DommusLoading/DommusLoading';

export default function VoipComponent() {
    const { handleRemoveLista, atendimentoAtivo, limparAtendimentoAtivo } = useContext(AtendimentoContext);
    const { handleCall, hangUp, inCallStatus, isMuted, handleMuteCall, connection, seconds, minutes } = useContext(VoipContext);
    const {listaAtendentes} = useContext(AtendentesContext);
    const { channel } = useContext(WebSocketContext);
    const selectAtendenteRef = useRef(null)
    const [openModalTransfer, setOpenModalTransfer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cacheCallSidTransferencia, setCacheCallSidTransferencia] = useState(null);
    const [idTimeoutTransferenciaPerdida, setIdTimeoutTransferenciaPerdida] = useState(null);

    const callbackTransferenciaPerdida = (data) =>{
      if(cacheCallSidTransferencia && (Number(data.id_atendimento) === atendimentoAtivo.id) ){
        if(idTimeoutTransferenciaPerdida){
          clearTimeout(idTimeoutTransferenciaPerdida);
          setIdTimeoutTransferenciaPerdida(null)
        }
        setOpenModalTransfer(false);
        setLoading(false);
        setCacheCallSidTransferencia(null)
        infoToast.fire({text: 'Chamada não atendida no destino'});
      }
    };

    useEffect(() => {
      if(channel){
        channel.unbind('chamada-transferida');
        channel.unbind('transferencia-nao-atendida');
      }
      if(channel && atendimentoAtivo){
        channel.bind('chamada-transferida', function(data) {
          let idUsuario = Number(caAPI.getLocalState("idUsuario"));
          if((data.data.id_atendente === idUsuario) && (Number(data.data.id_atendimento) === atendimentoAtivo.id)){
            if(idTimeoutTransferenciaPerdida){
              clearTimeout(idTimeoutTransferenciaPerdida);
              setIdTimeoutTransferenciaPerdida(null);
            }
            handleRemoveLista(atendimentoAtivo)
            hangUp();
            limparAtendimentoAtivo();
            setCacheCallSidTransferencia(null)
            Swal.fire({
                titleText: "Sucesso!",
                text: "Chamada transferida com sucesso.",
                icon: 'success'
            });
          }
        });

        channel.bind('transferencia-nao-atendida', function(data) {
          callbackTransferenciaPerdida(data.data);
        });
      }
      return () => {
        if(channel){
           channel.unbind('chamada-transferida');
           channel.unbind('transferencia-nao-atendida');
        }
      };
    }, [channel, atendimentoAtivo, cacheCallSidTransferencia, idTimeoutTransferenciaPerdida]);

    function handleTransfer() {
      if(selectAtendenteRef.current?.value){
        const data = {
            atendente: selectAtendenteRef.current?.value,
        }
        let conteudoAtual = Array.isArray(atendimentoAtivo.conteudos) && [...atendimentoAtivo.conteudos].pop();
        let detalhes = conteudoAtual && JSON.parse(conteudoAtual?.detalhes);
        setLoading(true);
        setCacheCallSidTransferencia(detalhes?.CallSid);
        caAPI.atendimento.update(data, atendimentoAtivo.id).then((res) => {
          const timeoutTransferenciaPerdida = setTimeout(() => {
            caAPI.atendimento.transferenciaNaoAtendida(atendimentoAtivo.id, Number(caAPI.getLocalState("idUsuario")))
              .then(() => {
                callbackTransferenciaPerdida({id_atendimento: atendimentoAtivo.id});
              })
              .catch(error => console.log(error));
          }, ((Number(caAPI.getLocalState('tempoLimiteVoipSegundos') || 30) * 1000) + 10000));
          setIdTimeoutTransferenciaPerdida(timeoutTransferenciaPerdida);
          infoToast.fire({text: 'Transferindo...'});
        }).catch((err) => {
          setLoading(false);
          Swal.fire({
              titleText: "Erro",
              text: "Erro ao editar atendimento",
              icon: 'error'
          });
        });
      }else{
        errorToast.fire({text: 'Nenhum atendente selecionado.'})
      }

    }

    useEffect(() => {
      if(channel){
        channel.unbind('chamada-encerrada-transferencia');
        channel.bind('chamada-encerrada-transferencia', (data) => {
            if((data.CallSid === cacheCallSidTransferencia) && loading){
              setOpenModalTransfer(false);
              setLoading(false);
              errorToast.fire({text: 'Ligação perdida.'})
            }
        });
      }

      return () => {
        channel && channel.unbind('chamada-encerrada-transferencia');
      };
    }, [channel, loading, cacheCallSidTransferencia]);
    

    return (
        (loading && <DommusLoading />) ||  <div className="voip">
            <div className="voip-control">
                <div className="info">
                    <div className="fotoEnvolvido">
                        <FontAwesomeIcon icon={faUserCircle} color="#444444" size="sm" />
                    </div>
                    <div className="nomeEnvolvido">{atendimentoAtivo.nome}</div>
                    <div className="telefoneEnvolvido">
                        {ocultaTelefone(atendimentoAtivo.contato_destino)}
                    </div>
                </div>
                <div className="timer">
                    {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                </div>
                <br />
                <div className="controls">
                    {(inCallStatus && !connection) ? <h3> Iniciando chamada... </h3> : <></>}
                    {connection._status === 'open'
                        &&
                        <>
                            {isMuted
                                ?
                                <OverlayTrigger
                                    key="buttonDesmutar"
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-desmutar`}> Desmutar </Tooltip>}
                                >
                                    <button className="botaoMutar" style={inCallStatus ? {} : { display: "none" }} disabled={!inCallStatus} onClick={() => handleMuteCall()}>
                                        <Icon icon={bxMicrophoneOff} width="1em" />
                                    </button>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    key="buttonMutar"
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-mutar`}> Mutar </Tooltip>}
                                >
                                    <button className="botaoMutar" style={inCallStatus ? {} : { display: "none" }} disabled={!inCallStatus} onClick={() => handleMuteCall()}>
                                        <Icon icon={bxMicrophone} width="1em" />
                                    </button>
                                </OverlayTrigger>
                            }
                          
                             
                              <OverlayTrigger
                                  key="buttonTransferir"
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-transferir`}> Transferir </Tooltip>}
                              >
                                  <button className="botaoTransferir" style={inCallStatus ? {} : { display: "none" }} disabled={!inCallStatus} onClick={() => { setOpenModalTransfer(!openModalTransfer) }}>
                                      <Icon icon={bxTransfer} width="1em" />
                                  </button>
                              </OverlayTrigger>
                            
                        </>
                    }
                    {
                      inCallStatus && <OverlayTrigger
                          key="buttonDesligar"
                          placement="top"
                          overlay={<Tooltip id={`tooltip-desligar`}> Desligar </Tooltip>}
                      >
                          <button className="botaoDesligar" style={inCallStatus ? {} : { display: "none" }} disabled={!inCallStatus} onClick={() => { hangUp(); }}>
                              <Icon icon={phoneBlock} width="1em" />
                          </button>
                      </OverlayTrigger>
                    }
                    {
                      (!(connection._status === 'open')) && (Number(atendimentoAtivo.atendente) === Number(caAPI.getLocalState("idUsuario")))
                        &&
                        <button className="botaoLigar" style={inCallStatus ? { display: "none" } : {}} onClick={() => { handleCall(); }}>
                            <Icon icon={phoneOutgoing} width="1em" />
                        </button>
                    }
                </div>
                <Anotacoes atendimento={atendimentoAtivo} showAtendimentosDoDia={true} />
                <ModalDommus
                    titulo="Editar"
                    open={openModalTransfer}
                    close={() => setOpenModalTransfer(false)}
                >
                    <div className="novoAtendimento">
                        <Row>
                            <Col>
                                <Form.Group as={Col} controlId="formAtendente">
                                    <Form.Label>Atendente: </Form.Label>
                                    <Form.Control as="select" custom ref={selectAtendenteRef}>
                                        <option>Selecione o/a Atendente:</option>
                                        {listaAtendentes && listaAtendentes.map((atendente) => {
                                            return <option key={atendente.id} value={atendente.id}>{atendente.nome}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <button className="btn btn-block btn-primary btnNovoEditar" onClick={handleTransfer}>Transferir</button>
                    </div>
                </ModalDommus>
            </div>

        </div >
    );
}
