import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import Select from "react-select";
import FilaService from "../../../services/FilaService";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2/src/sweetalert2";

const ListaAtendentes = ({
  atendentes,
  contatoSelecionado,
  infosAtendentes,
  checkHandler,
  addOcorrencia,
  children,
  refresh,
  check,
  setCheck,
}) => {
  const [itensCorretores, setItensCorretores] = useState();
  function atendenteNome(atendentes, id) {
    const index = atendentes.findIndex((atendente) => Number(atendente.id) === Number(id));
    return atendentes[index];
  }
  useEffect(() => {
    setItensCorretores(
      infosAtendentes.map((atendente) => {
        return (
          <ListGroup.Item key={atendente.id_usuario}>
            <div className={"d-flex justify-content-between"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Form.Check
                    inline
                    type="checkbox"
                    // label={corretor.nome}
                    value={atendente.id_usuario}
                    checked={
                      atendente.qualificacao_atendente.ids_contatos_origem
                        ? Object.values(
                            atendente.qualificacao_atendente.ids_contatos_origem
                          ).includes(contatoSelecionado.id)
                        : ''
                    }
                    onChange={(e) => {
                      checkHandler(e.target.value, e.target.checked);
                    }}
                    id={`check-usuario-${atendente.id_usuario}`}
                  />
                  <span style={{ fontSize: 12 }}>
                    {atendenteNome(
                      atendentes,
                      atendente.id_usuario
                    ).nome.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        );
      })
    );
  }, [contatoSelecionado, infosAtendentes, atendentes, check, checkHandler]);
  return (
    <ListGroup>
      {children}
      {itensCorretores}
    </ListGroup>
  );
};

export default function AtendentesForm({
  meiosComunicacao,
  qualificacaoAtendentes,
  atendentes,
  infosAtendentes,
  setRefresh,
  refresh,
}) {
  const [meioComunicacaoSelecionado, setMeioCoumicacaoSelecionado] = useState();
  const [contatoSelecionado, setContatoSelecionado] = useState();
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setContatoSelecionado(null);
  }, [meioComunicacaoSelecionado]);

  const meiosComunicacaoOptions = meiosComunicacao.map((meio, index) => ({
    label: meio.nome,
    value: meio.id,
    id: meio.id,
    contatos_origem: meio.contatos_origem,
  }));
  const contatosOptions = meioComunicacaoSelecionado ? (
    Object.values(meioComunicacaoSelecionado.contatos_origem).map(
      (contato) => ({
        label: contato.descricao,
        value: contato.id_contato_origem,
        id: contato.id_contato_origem,
      })
    )
  ) : (
    <></>
  );
  const checkHandler = (idUsuario, checked) => {
    let contato = {
      id_contato_origem: Number(contatoSelecionado.id),
    };
    let promise = checked
      ? FilaService.qualificarAtendente
      : FilaService.deletarQualificacaoAtendente;
    trackPromise(
      promise
        .call(FilaService, idUsuario, contato)
        .then(
          (response) => {
            if (Number(response.status) === 202 || Number(response.status) === 201) {
              setCheck(true);
              setRefresh(!refresh);
            }
            if (Number(response.status) === 200) {
              setCheck(false);
              Swal.fire({
                titleText: "Usuário inválido",
                text: response.data,
                icon: "warning",
              });
            }
          },
          (error) => {
            throw error;
          }
        )
        .catch((error) => {
          Swal.fire({
            titleText: "Ooops...",
            text:
              "Ocorreu um erro ao atualizar a qualificação do usuário: " +
              error,
            icon: "error",
          });
        })
    );
  };
  return (
    <Container fluid style={{ marginTop: 10 }}>
      <Row>
        <Col lg={12}>
          <Form.Group>
            <Form.Label
              style={{ fontSize: 13, color: "#868aa8", marginBottom: 0 }}
            >
              Meio de comunicação
            </Form.Label>
            <Select
              placeholder="Selecionar"
              value={meioComunicacaoSelecionado}
              name="meioComunicacao"
              onChange={(e) => {
                setMeioCoumicacaoSelecionado(e);
              }}
              options={meiosComunicacaoOptions}
            />
          </Form.Group>
        </Col>
      </Row>
      {meioComunicacaoSelecionado && (
        <Row>
          <Col lg={12}>
            <Form.Group>
              <Form.Label
                style={{ fontSize: 13, color: "#868aa8", marginBottom: 0 }}
              >
                Contatos
              </Form.Label>
              <Select
                placeholder="Selecionar"
                value={contatoSelecionado}
                name="contato"
                onChange={(e) => {
                  setContatoSelecionado(e);
                }}
                options={contatosOptions}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {contatoSelecionado && (
        <Row style={{ overflow: "auto", height: "60vh" }}>
          <Col lg={12}>
            <Form.Group>
              <Form.Label>Atendentes</Form.Label>
              <ListaAtendentes
                atendentes={atendentes}
                checkHandler={checkHandler}
                infosAtendentes={infosAtendentes}
                contatoSelecionado={contatoSelecionado}
                refresh={refresh}
                check={check}
                setCheck={setCheck}
              ></ListaAtendentes>
            </Form.Group>
          </Col>
        </Row>
      )}
    </Container>
  );
}
