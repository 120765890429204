import React, {createContext, useEffect, useState} from "react";
import Pusher from "pusher-js";
import caAPI from "../services/caAPI";

const pusher = new Pusher('ZWmsypUbT9C', {
  wsHost: 'wsdommus.dommus2.com.br',
  wssPort: 6001,
  forceTLS: true,
  enabledTransports: ['ws', 'wss']
})
export const WebSocketContext = createContext({});
export function WebSocketProvider ({children}) {

  const [channel, setChannel] = useState(pusher);

  useEffect(() => {
    const idCliente = caAPI.getLocalState('idCliente') ?? '';
    let channel_name = 'inquilino-'+idCliente.toString().padStart(4, '0');
    if(process.env.REACT_APP_ENV === 'local') channel_name = 'local';
    setChannel(pusher.subscribe(channel_name));
  }, [ setChannel])

  return (
    <WebSocketContext.Provider value={{channel}}>
      {children}
    </WebSocketContext.Provider>
  )
}
