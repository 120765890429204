import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import { ContatoNotificacaoHistoricoService } from "../services/ContatoNotificacaoHistoricoService";
import { trackPromise } from "react-promise-tracker";

export const ContatoNotificacaoHistoricoContext = createContext({});

export function ContatoNotificacaoHistoricoProvider({ children }) {
  const [contatosNotificacaoLista, setContatosNotificacaoLista] = useState([]);
  const [mapaContatoNotificacao, setMapaContatoNotificacao] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();
    contatosNotificacaoLista.forEach((tag) => {
      novoMapa.set(Number(tag.id), tag);
    });
    setMapaContatoNotificacao(novoMapa);
  }, [contatosNotificacaoLista]);

  const recarregarContatosNotificacao = ()=>{
    trackPromise(ContatoNotificacaoHistoricoService.seach()).then(res => {
        setContatosNotificacaoLista(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os contatos  " + err,
        icon: 'error'
    }))
  };

  const adicionarContatoNotificacao = (novoContato)=>{
    setContatosNotificacaoLista((current)=>{      
      return [novoContato, ...current];
    })
  }

  const atualizarContatoNotificacao = (novoContato) => {
    setContatosNotificacaoLista((current)=>{
      return current.map((item)=>{
        if(item.id_contato_notificacao_historico === novoContato.id_contato_notificacao_historico){
          return novoContato;
        }
        return item;
      })
    })
  }

  const removerContatoNotificao = (idContato) => {
    setContatosNotificacaoLista((current)=>{
      return current.filter((item) => item.id_contato_notificacao_historico !== idContato);
    })
  }

  const resolverNomeContato = (idContatoNotificacao, padrao = "Não Atribuído") => {
    let tag = mapaContatoNotificacao.get(Number(idContatoNotificacao));
    return (tag && tag.nome) || padrao;
  };

  return (
    <ContatoNotificacaoHistoricoContext.Provider value={{
      contatosNotificacaoLista,
      recarregarContatosNotificacao,
      resolverNomeContato,
      adicionarContatoNotificacao,
      atualizarContatoNotificacao,
      removerContatoNotificao
    }}>
      {children}
    </ContatoNotificacaoHistoricoContext.Provider>
  )
}
