import React, {createContext} from "react";
import {WebSocketProvider} from './WebSocketContext';
import {AtendentesProvider} from "./AtendentesContext";
import {AtendimentoProvider} from "./AtendimentoContext";
import {VoipProvider} from "./VoipContext";
import {ModulosProvider} from "./ModulosContext";
import {MeioComunicacaoProvider} from "./MeioComunicacaoContext";
import {SituacaoAtendimentoProvider} from "./SituacaoAtendimentoContext";
import {TipoAtendimentoProvider} from "./TipoAtendimentoContext";
import {TipoHistoricoAtendimentoProvider} from "./TipoHistoricoAtendimentoContext";
import { RelatorioProvider } from "./RelatorioContext";
import { TagsProvider } from "./TagsContext";
import { ItensAtalhoProvider } from "./ItensAtalhosContext";
import { EmpreendimentoProvider } from "./EmpreendimentosContext";
import { CampanhaProvider } from "./CampanhasContext";
import { TiposAnexosTemplateProvider } from "./TiposAnexosTemplateContext";
import { MapeamentoErrosTwillioProvider } from "./MapeamentoErrosTwillioContext";
import { DisparoMassaProvider } from "./DisparoMassaContext";
import { TemplateProvider } from "./TemplateContext";
import { ContatoNotificacaoHistoricoProvider } from "./ContatoNotificacaoHistoricoContext";

export const AppContext = createContext({});

export function AppContextProvider ({children}) {

  return (
    <AppContext.Provider value={{}}>
      <ModulosProvider>
        <WebSocketProvider>
          <MeioComunicacaoProvider>
            <SituacaoAtendimentoProvider>
              <TipoAtendimentoProvider>
                <TipoHistoricoAtendimentoProvider>                  
                  <AtendentesProvider>
                    <AtendimentoProvider>
                      <VoipProvider>
                        <RelatorioProvider>
                          <TagsProvider>
                            <ItensAtalhoProvider>
                              <EmpreendimentoProvider>
                                <CampanhaProvider>
                                  <TiposAnexosTemplateProvider>
                                    <MapeamentoErrosTwillioProvider>
                                      <DisparoMassaProvider>
                                        <TemplateProvider>
                                          <ContatoNotificacaoHistoricoProvider>
                                            {children}
                                          </ContatoNotificacaoHistoricoProvider>
                                        </TemplateProvider>
                                      </DisparoMassaProvider>
                                    </MapeamentoErrosTwillioProvider>
                                  </TiposAnexosTemplateProvider>
                                </CampanhaProvider>
                              </EmpreendimentoProvider>
                            </ItensAtalhoProvider>
                          </TagsProvider>
                        </RelatorioProvider>
                      </VoipProvider>
                    </AtendimentoProvider>
                  </AtendentesProvider>
                </TipoHistoricoAtendimentoProvider>
              </TipoAtendimentoProvider>
            </SituacaoAtendimentoProvider>
          </MeioComunicacaoProvider>
        </WebSocketProvider>
      </ModulosProvider>
    </AppContext.Provider>
  )
}
