const ArquivoService = {
    converterBlobParaBase64: async (blob) => {
        const file = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
          const base64 = file.replace(/^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+;base64,/, '');
          return base64;
    }
};

export default ArquivoService;
