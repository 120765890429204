import React, { useContext, useEffect, useState } from "react";
import DommusToggle from "../../../components/DommusToggle";
import Registro from "./Registro";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import {
  Nav,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  Form,
  Alert,
} from "react-bootstrap";
import "./style.scss";
import { Icon } from "@iconify/react";
import NotificationSolidBadged from '@iconify-icons/clarity/notification-solid-badged';
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import boxAdd from "@iconify/icons-icomoon-free/box-add";
import arrowDownAlt2 from "@iconify/icons-dashicons/arrow-down-alt2";
import DommusLoadingArea from '../../../components/DommusLoading/DommusLoadingArea';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from "sweetalert2";
import caAPI from "../../../services/caAPI";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RegistroAtendimento({
  abrirAtendimento,
  atendimentoAtivoId
}) {

  const [titleToggle] = useState();
  const { listaAtendimentos, filtro, setListaAtendimentos, totalAtendimentos, naoAtendidos, pagina, setPagina, 
            fetchingLista, setFetchingLista, atualizarAtendimentoLista, filtroSomenteNaoLidas, setFiltroSomenteNaoLidas } = useContext(AtendimentoContext);
  const [multiSelection, setMultiSelection] = useState(false);
  const [atendimentosSelecionados, setAtendimentosSelecionados] = useState([]);  

  const fetchScroll = () => {
    setFetchingLista(true);
    const paginaBusca = pagina + 1;
    trackPromise(caAPI.atendimento.filtro.filtrar(filtro, paginaBusca).then(res => {
      if(Array.isArray(res.data) && res.data.length){
        const novaLista = listaAtendimentos.concat(res.data);
        setListaAtendimentos(novaLista);
        setPagina(paginaBusca);
        setTimeout(function () {
          setFetchingLista(false);

        }, 10);
      }
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Não foi possivel buscar os dados filtrados",
        icon: 'error'
      });
    }), "filtroArea")
  };

  // const handleArquivamentoEmMassa = () => {
  //   const arquivamento = { arquivado: "1" }
  //   console.log("arquivamento", atendimentosSelecionados)
  //   trackPromise(
  //     caAPI.atendimento.update(arquivamento, atendimentosSelecionados).then(res => {
  //       setAtendimentoAtivo(null)

  //     }).catch(err => {
  //       Swal.fire({
  //         titleText: "Erro",
  //         text: "Erro ao arquivar atendimentos",
  //         icon: 'error'
  //       });
  //     })
  //   )
  // }

  const handleSelectMulti = () => {
    setMultiSelection(!multiSelection);
    setAtendimentosSelecionados([]);
  };

  const handleSelecionaAtendimento = (novaSelecao) => {
    let value = [...atendimentosSelecionados];
    if (
      atendimentosSelecionados.filter(
        (atendimento) => atendimento === novaSelecao
      ).length
    ) {
      const index = atendimentosSelecionados.findIndex(
        (atendimento) => atendimento === novaSelecao
      );
      value.splice(index, 1);
    } else {
      value.push(novaSelecao);
    }
    if (multiSelection) {
      setAtendimentosSelecionados(value);
    } else {
      setAtendimentosSelecionados();
    }
  };

  function menu() {
    return (
      <>
        <div className="menuAtendimento">
          <OverlayTrigger
            key="buttonMenuAtendimento"
            placement="top"
            overlay={<Tooltip id={`tooltip-editar`}> Ações em massa </Tooltip>}
          >
            <DropdownButton
              id="DropAtendimento"
              size="sm"
              title={<Icon icon={arrowDownAlt2} width="3rem" />}
            >
              <Dropdown.Item className="opcaoMenu" onClick={() => {
                // handleArquivamentoEmMassa()
              }}>
                <div className="opcaoArquivar">
                  <Icon icon={boxAdd} />
                  <span className="tituloArquivar"> Arquivar </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="opcaoMenu" onClick={() => { }}>
                <div className="opcaoExcluir">
                  <FontAwesomeIcon icon={faTrash} />
                  <span className="tituloExcluir"> Excluir </span>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </OverlayTrigger>
        </div>
      </>
    );
  }

  const titulo = () => {
    return (
      <>
        <Form.Check
          id="toggleCheckMultiSelect"
          checked={multiSelection}
          value={multiSelection}
          onChange={() => handleSelectMulti()}
        ></Form.Check>
        {multiSelection ? <div className="multiSelect"> {menu()} </div> : <></>}
        <div className="titleToggleAtendimentoContent">
          <div className="totalAtendimentos">
            Atendimentos: {totalAtendimentos} {naoAtendidos ? `(Não lidos: ${naoAtendidos})` : ''}
          </div>
          <OverlayTrigger
            key="btnFiltroVisualizadas"
            placement="top"
            overlay={<Tooltip id={`tooltip-filtro-visualizadas`}> {filtroSomenteNaoLidas ? "Exibir todas" : "Exibir somente não lidas"} </Tooltip>}
          >
            <button 
              className={`btn-filtro-visualizadas ${filtroSomenteNaoLidas ? "ativo" : ""}`} 
              onClick={() => {
                setFiltroSomenteNaoLidas((current) => !current);
              }}
            >
              <FontAwesomeIcon icon={faEye}/>
            </button>
          </OverlayTrigger>
        </div>
        <div className="dataToggle">
          {/* {Object.keys(filtro).length ?
            <OverlayTrigger
              key="buttonMenuRegisto"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-editar`}>
                  Limpar Filtros
                </Tooltip>
              }
            >
              <div className="limparFiltros" onClick={() => handleClearFiltro()}>
                <Icon icon="ci:search-small-minus" width="20" />
              </div>
            </OverlayTrigger>
            :
            <></>
          } */}

        </div>
      </>
    );
  };

  return (
    <>
      <DommusToggle
        toggle={true}
        headerClass={'dommus-toggle-sem-margem-bottom'}
        bodyClass="dommus-toggle-height-fixed-atendimento"
        title={titulo()}
        containerId="scrollContainer"
      >
        <DommusLoadingArea area="listaAtendimentosArea" fundo={"#fff"} />
          <Nav fill variant="pills">
            <div className="registros-container w-100">
            {
              (( listaAtendimentos.length || 0 ) &&

              <InfiniteScroll
                dataLength={listaAtendimentos.length || 0} //This is important field to render the next data
                next={fetchScroll}
                hasMore={!(fetchingLista) && ( listaAtendimentos.length < totalAtendimentos )}
                loader={<h3 className="scroll-carregando-msg">Carregando</h3>}
                style={{ minHeight: '350px'}}
                endMessage={((fetchingLista) && 
                  <Alert variant={'info'} className="alert-section">
                    <strong>Carregando ...</strong>
                  </Alert> 
                  ) 
                  || 
                  <Alert variant={'warning'} className="alert-section">
                    <strong>Não há mais atendimentos a carregar!</strong>
                  </Alert> 
                }
                scrollThreshold={0.5}
                scrollableTarget="scrollContainer">
                  {listaAtendimentos &&
                    listaAtendimentos.map((registro, index) => (
                      <div key={index} className="registroSection">
                        {multiSelection ? (
                          <div className="checkboxAtendimentosArea">
                            <Form.Check
                              id="checkboxRegistro"
                              type="checkbox"
                              value={registro.id}
                              onChange={(e) =>
                                handleSelecionaAtendimento(registro.id)
                              }
                            ></Form.Check>
                          </div>
                        ) : (
                          <></>
                        )}
                          <Registro
                          posicaoListaAtentimentos={index}
                          registro={registro}
                          abrirAtendimento={abrirAtendimento}
                          atendimentoAtivoId={atendimentoAtivoId}
                          key={registro.id}
                          atualizarAtendimentoLista={atualizarAtendimentoLista}
                          />
                      </div>
                    ))}
              </InfiniteScroll>) || 
              <Alert variant={'warning'} className="alert-section">
                <strong>Nenhum atendimento à ser exibido</strong>
              </Alert> 
              }
            </div>
          </Nav>
      </DommusToggle>
    </>
  );
}
