import React, { useContext, useState, useEffect } from "react";
import calendarOutlineBadged from '@iconify-icons/clarity/calendar-outline-badged';
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import DatePicker from "react-date-picker/dist/entry";
import "./DatePicker.scss";
import { Icon } from "@iconify/react";
import caAPI from '../../../services/caAPI';

function AtendimentoIntervaloDatasPicker({tipoBusca = null}){
  const { handleAtualizaLista, filtro } = useContext(AtendimentoContext);

  const [dataInicio, setDataInicio] = useState((filtro && filtro.data_inicio && new Date(filtro.data_inicio)) || new Date());
  const [dataFim, setDataFim] = useState((filtro && filtro.data_fim && new Date(filtro.data_fim)) || new Date())

  useEffect(() => {
    if(filtro && filtro.data_inicio){
    setDataInicio(new Date(filtro.data_inicio.length == 10 ? `${filtro.data_inicio} 00:00:00` : filtro.data_inicio))
    }else{
      setDataInicio(new Date())
    }

    if(filtro && filtro.data_fim){
      setDataFim(new Date(filtro.data_fim.length == 10 ? `${filtro.data_fim} 00:00:00` : filtro.data_fim))
      }else{
        setDataFim(new Date())
      }
  },[filtro])

  const handleChangeDate = (dateCalendar) => {
    setDataInicio(dateCalendar);
    if (!(filtro && filtro.data_inicio) || (dateCalendar.getTime() !== new Date(filtro.data_inicio).getTime())) {
      let novosFiltros = null;
      if(tipoBusca == 2){
        novosFiltros = Object.assign({}, filtro, {data_criacao_inicio: dateCalendar})
        delete novosFiltros.data_inicio;
      }else{
        novosFiltros = Object.assign({}, filtro, {data_inicio: dateCalendar})
        delete novosFiltros.data_criacao_inicio;
      }

      caAPI.setLocalState('filtros', JSON.stringify(Object.assign(JSON.parse(caAPI.getLocalState('filtros') || "{}"), {data_inicio: dateCalendar})));
      handleAtualizaLista(novosFiltros);
    }
  };

  const handleChangeDateFim = (dateCalendar) => {
    setDataFim(dateCalendar);
    if (!(filtro && filtro.data_fim) || (dateCalendar.getTime() !== new Date(filtro.data_fim).getTime())) {
      let novosFiltros = null;
      if(tipoBusca == 2){
        novosFiltros = Object.assign({}, filtro, {data_criacao_fim: dateCalendar})
        delete novosFiltros.data_fim;
      }else{
        novosFiltros = Object.assign({}, filtro, {data_fim: dateCalendar})
        delete novosFiltros.data_criacao_fim;
      }

      caAPI.setLocalState('filtros', JSON.stringify(Object.assign(JSON.parse(caAPI.getLocalState('filtros') || "{}"), {data_inicio: dateCalendar})));
      handleAtualizaLista(novosFiltros);
    }
  };
  return <>
  <DatePicker
    onChange={handleChangeDate}
    value={dataInicio}
    format="dd/MM/yyyy"
    calendarIcon={<Icon icon={calendarOutlineBadged} width="20" style={{ color: '#f58442' }}/>}
    clearIcon={null}
  /> <DatePicker
    onChange={handleChangeDateFim}
    value={dataFim}
    format="dd/MM/yyyy"
    calendarIcon={<Icon icon={calendarOutlineBadged} width="20" style={{ color: '#f58442' }}/>}
    clearIcon={null}
  />
  </>
}

export default AtendimentoIntervaloDatasPicker;
