import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Anexos from "../../../../components/Anexos";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { errorToast } from "../../../../components/DommusToast";
import { useForm } from "react-hook-form";
import DisparoMassaService from "../../../../services/DisparoMassaService";
import Swal from "sweetalert2/src/sweetalert2";
import { DisparoMassaContext } from "../../../../contexts/DisparoMassaContext";
import ArquivoService from "../../../../services/ArquivosService";

export function BaseDisparoFormularioCadastro() {
    const [arquivoDisparo, setArquivoDisparo] = useState([]);
    const {
        adicionarBaseDisparo,
        abrirFecharModalCadastro,
        atualizarBaseDisparo,
        baseDisparoSelecionada,
    } = useContext(DisparoMassaContext);
    const { register, handleSubmit } = useForm({
        defaultValues: baseDisparoSelecionada,
    });

    useEffect(() => {
        if (baseDisparoSelecionada?.nome_arquivo_base_disparo) {
            setArquivoDisparo([
                {
                    conteudo: null,
                    nome: baseDisparoSelecionada?.nome_arquivo_base_disparo,
                    tipo:
                        "." +
                        baseDisparoSelecionada?.nome_arquivo_base_disparo.split(
                            "."
                        )[1],
                },
            ]);
        }
    }, [baseDisparoSelecionada]);

    const salvarEditarBaseDisparo = (dados) => {
        if (!arquivoDisparo.length) {
            errorToast.fire("Nenhum arquivo anexado!!");
            return;
        }
        let arquivo = arquivoDisparo[0];
 
        if (Object.prototype.toString.call(arquivo) === "[object Blob]") {
            (async () => {
                try {
                    dados.base_disparo = {};
                    dados.base_disparo.conteudo = await ArquivoService.converterBlobParaBase64(arquivo);
                    dados.base_disparo.nome = arquivo.name;
                    let metodo = baseDisparoSelecionada?.id
                    ? DisparoMassaService.atualizarBaseDisparo(baseDisparoSelecionada?.id, dados)
                    : DisparoMassaService.salvarBaseDisparo(dados);
                    enviarChamada(metodo)
                } catch (error) {
                    console.error("Erro ao converter o arquivo:", error);
                }
            })();
        }else{
            let metodo = baseDisparoSelecionada?.id
            ? DisparoMassaService.atualizarBaseDisparo(baseDisparoSelecionada?.id, dados)
            : DisparoMassaService.salvarBaseDisparo(dados);
            enviarChamada(metodo)
        }

    };

    const enviarChamada = (metodo) => {
        trackPromise(metodo)
            .then((response) => {
                if (baseDisparoSelecionada?.id) {
                    atualizarBaseDisparo(
                        baseDisparoSelecionada?.id,
                        response.data
                    );
                } else {
                    adicionarBaseDisparo(response.data);
                }
                Swal.fire('Sucesso!', 'Base de disparo salvo com sucesso!', 'success').then(() => abrirFecharModalCadastro())
            })
            .catch((error) => {
                let erroMessage =
                    error?.response?.data?.nome ??
                    error?.response?.data?.base_referencia ??
                    error?.response?.data?.base_disparo ??
                    error;
                Swal.fire({
                    titleText: "Erro",
                    text:
                        "Houve um problema ao salvar base de disparo! " +
                        erroMessage,
                    icon: "error",
                });
            });
    }
    return (
        <Form onSubmit={handleSubmit(salvarEditarBaseDisparo)}>
            <Row>
                <Form.Group as={Col} md="8">
                    <Form.Label className="inputLabel">Nome: </Form.Label>
                    <DommusRequiredSpan />
                    <Form.Control
                        type="text"
                        name="nome"
                        {...register("nome", { required: true })}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className="inputLabel">
                        Tipo da Base:{" "}
                    </Form.Label>
                    <DommusRequiredSpan />
                    <Form.Control
                        as="select"
                        name="base_referencia"
                        {...register("base_referencia", { required: true })}
                    >
                        <option value="" hidden>
                            SELECIONE UMA OPÇÃO
                        </option>
                        <option value="processo">Processos</option>
                        <option value="oportunidade">Oportunidades</option>
                        <option value="contato">Contatos</option>
                    </Form.Control>
                </Form.Group>
            </Row>
            <Form.Group as={Row} className="px-3">
                <Form.Label className="inputLabel">
                    Arquivo Base Disparo (.csv){" "}
                </Form.Label>
                <DommusRequiredSpan />
                <OverlayTrigger
                    key="arquivo"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-arquivo`}>
                            {"São permitidos somentes separadores dos tipos vírgula(,) e ponto e vírgula(;)"}
                        </Tooltip>
                    }
                >
                    <FontAwesomeIcon className="ml-1" icon={faQuestionCircle} />
                </OverlayTrigger>
                <Anexos
                    listaAnexos={arquivoDisparo}
                    setListaAnexos={setArquivoDisparo}
                    filesTypes={[".csv"]}
                    multiplos={false}
                />
                <div className="area_btn_anexar"></div>
            </Form.Group>
            <Row className="px-3 justify-content-end pt-3 border-top">
                <Button variant="success" type="submit">
                    <FontAwesomeIcon icon={faCloudUpload} /> Salvar
                </Button>
            </Row>
        </Form>
    );
}
