import React, { useState} from 'react';
import { Form, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';

import "./styles.scss";
import Anexos from '../../Anexos';


export default function Upload({setMensagemInput, recebeArquivo, enviarImagem, setOpenModalUpload} ) {
    const [listaAnexos] = useState([]);
    const [filesTypes] = useState("*")
    return <>
        <Form>
            <Anexos listaAnexos={listaAnexos} setListaAnexos={recebeArquivo} filesTypes={filesTypes} />
            <div className="area_btn_anexar">
                <Button className="anexarImagem" onClick={() => { enviarImagem(); setOpenModalUpload(false)}}>
                    Enviar <Icon icon={sendOutlined} color="white" />
                </Button>
            </div>
        </Form>
    </>
}
