import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../../services/caAPI";
import Swal from "sweetalert2";
import DommusBarraTitulo from "../../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../../components/DommusBarraTitulo/ItemBarraTitulo";
import DommusToggle from "../../../components/DommusToggle";
import DommusTabelaPaginada from "../../../components/DommusTabelaPaginada";
import { successToast } from "../../../components/DommusToast";
import { celularMask } from "../../../components/InputTextMask";
import "./style.scss"

export default function Label() {

  const [listaTelefones, setListaTelefones] = useState([]);

  const handleChangeLabelName = useCallback(function (value, id) {
    const tels = [...listaTelefones];
    const index = tels.findIndex(tel => tel.id === id);
    tels[index].label = value;
    setListaTelefones(tels);
  }, [listaTelefones])

  const handleAtualizarListaLabels = useCallback(function() {
    trackPromise(
      caAPI.labels.list().then(res => {
        setListaTelefones(res.data);
      }).catch(err => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar os template  " + err,
          icon: 'error'
        });
      }))
  }, [setListaTelefones]);

  const handleUpdateLabel = useCallback(function (id, value) {
    caAPI.labels.update(id, value).then(res => {
      handleAtualizarListaLabels();
      successToast.fire('Label Atualizada');
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao atualizar o label do número " + err,
        icon: 'error'
      });
    })
  }, [handleAtualizarListaLabels])

  const thead = (
    <tr >
      <th className="cabecalhoTabelaTelefone" >
        Telefone:
      </th>
      <th className="cabecalhoTabelaLabel">
        Label:
      </th>
      <th className="cabecalhoTabelaOpcoes">
        Opções:
      </th>
    </tr>
  );


  const linhas = useMemo(() => {
    return listaTelefones.map((telefone, index) => {
      return (
        <tr>
          <td>{celularMask(telefone.telefone)}</td>
          <td>
            <Form.Control type="text" value={telefone.label} onChange={e => handleChangeLabelName(e.target.value, telefone.id)} />
          </td>
          <td className="areaOpcoes">
            <Button variant="success" onClick={() => handleUpdateLabel(telefone.id, telefone.label)}>
              Salvar
            </Button>
          </td>
        </tr>
      );
    })
  }, [listaTelefones, handleChangeLabelName, handleUpdateLabel]);

  useEffect(() => {
    handleAtualizarListaLabels();
  }, [handleAtualizarListaLabels]);

  return (<>
    <DommusBarraTitulo>
      <ItemBarraTitulo>
        Configuração Label
      </ItemBarraTitulo>
      {/* <ItemBarraTitulo classe="btn" onClick={() => console.log(true)}>
        <FontAwesomeIcon icon={faPlusCircle} color="#4caf50" size="lg" />
      </ItemBarraTitulo> */}
    </DommusBarraTitulo>

    <DommusToggle title='Label' toggle={true} className="labels" bodyClass="dommus-toggle-height-fixed ">
      <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} />
    </DommusToggle>



  </>);
}
