import React, { createContext, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const TipoHistoricoAtendimentoContext = createContext({});

export function TipoHistoricoAtendimentoProvider({ children }) {
  const [tiposHistoricoAtendimento, setTiposHistoricoAtendimento] = useState([]);

  const recarregarTiposHistoricoAtendimento = ()=>{
    caAPI.tipo_historico_atendimento.list().then(res => {
        setTiposHistoricoAtendimento(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os tipos de histórico de atendimento  " + err,
        icon: 'error'
    }))
  };

  return (
    <TipoHistoricoAtendimentoContext.Provider value={{
      tiposHistoricoAtendimento,
      recarregarTiposHistoricoAtendimento
    }}>
      {children}
    </TipoHistoricoAtendimentoContext.Provider>
  )
}
