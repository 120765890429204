import moment from 'moment'
import React from 'react'
import { celularMask } from '../../../../../components/InputTextMask'

import "./styles.scss"

export default function InformacoesAtendimento({ atendimento, criacao }) {

    return (
        <div className="dados">
            <div className="atendimentoContato">
                <strong>Contato: </strong> {celularMask(atendimento.contato)}
            </div>
            <div className="atendimentoCriadoPor">
                <strong>Criado por: </strong> {((atendimento.nome_criado_por && atendimento.nome_criado_por.length) ? atendimento.nome_criado_por[0] : '***')}
            </div>
            <div className="atendimentoCriadoEm">
                <strong>Criado em: </strong> {moment(criacao).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="atendimentoObservacao">
                <strong>Observação: </strong> {atendimento.observacao}
            </div>
            <div className="atendimentoTipo">
                <strong>Tipo: </strong> {atendimento.tipo}
            </div>
            {
              atendimento.tipo_atendimento && <div className="atendimentoTipoAtendimento">
                  <strong>Tipo de atendimento </strong> {atendimento.tipo_atendimento.nome}
              </div>
            }

        </div>
    )
}
