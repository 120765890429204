import React, { useContext } from "react";
import ModalDommus from "../Modal";
import ToggleDommus from "../DommusToggle";
import { MeioComunicacaoContext } from "../../contexts/MeioComunicacaoContext";

export function ModalQualificacoes({
  atendente,
  openModal,
  closeModal
}) {
  const {resolverNomeMeioComunicacao} = useContext(MeioComunicacaoContext);
  // var listQualificacoes = [];
  var listQualificacoes = [];
  if (openModal) {
    listQualificacoes =
      atendente.qualificacao_atendente.contatos_origem.reduce(
        (lista, contatoOrigem) => {          
          if (!lista[contatoOrigem.id_meio_comunicacao]) {
            lista[contatoOrigem.id_meio_comunicacao] = {
              contatosOrigemDescricao: [] 
            };
          }
          lista[contatoOrigem.id_meio_comunicacao].contatosOrigemDescricao.push(contatoOrigem.descricao);
          return lista;
        },
        {}
      );
  }
  return (
    <ModalDommus
      titulo="Qualificações"
      open={openModal}
      close={closeModal}
      size="md"
    >
      {Object.entries(listQualificacoes).map(([meioComunicacaoId, listaContatosOrigem], index) => {
        return (
          <ToggleDommus title={resolverNomeMeioComunicacao(meioComunicacaoId)} key={index}>
            <ul className="list-group">
              {listaContatosOrigem.contatosOrigemDescricao.map((contatoOrigem, index) => {
                return (
                  <li className="list-group-item" key={index}>
                    {contatoOrigem}
                  </li>
                );
              })}              
            </ul>
          </ToggleDommus>
        );
      })}
    </ModalDommus>
  );
}
