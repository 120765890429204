import React, {useContext} from 'react';
import { Card, CardGroup, Form } from 'react-bootstrap';
import MidiaSocialIcone from '../../components/MidiaSocialIcone';
import CommonService from "../../services/CommonService";
import {AtendentesContext} from '../../contexts/AtendentesContext';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch } from "@fortawesome/free-solid-svg-icons";

const mapaRotulosAtributos = {
  nome: "Nome do Destinatário",
  oportunidade: "Oportunidade",
  processo: "Processo",
  tipo_atendimento: "Tipo de Atendimento"
}
const montarViewAlteracao = (atributo, alteracao) => {
  if(atributo === 'tipo_atendimento'){
    return mapaRotulosAtributos[atributo] + " alterado de " +
    ( (alteracao.valor_anterior && alteracao.valor_anterior.descricao) || "nenhum") +
      " para " + ((alteracao.valor_posterior && alteracao.valor_posterior.descricao)|| "nenhum") + ".";
  }

  return mapaRotulosAtributos[atributo] + " alterado de " +
  (alteracao.valor_anterior || "nenhum") + " para " +
  (alteracao.valor_posterior || "nenhum") + ".";
}

export const resolverAlteracoesAtendimento = (alteracoes) => {
  const itensLista = [];
  for(let atributo in alteracoes){
    if(mapaRotulosAtributos[atributo]){
      const item = montarViewAlteracao(atributo, alteracoes[atributo]);
      itensLista.push(item);
    }
  }
  const lista = <ul>{itensLista.map((item, indice) => <li key={indice}>{item}</li>)}</ul>;
  return <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>
            {lista}
          </Tooltip>}
        >
          <FontAwesomeIcon icon={faSearch}/>
        </OverlayTrigger>;
};

export default function LinhaTabelaHistorico({historico}){
  const {resolverNomeAtendente} = useContext(AtendentesContext);
  const dataHoraCadastro = CommonService.formatarParaDataHoraBr(new Date(historico.criado_em));
  const servicoComum = Object.assign({}, CommonService);
  const interacao = historico.tipo_historico_atendimento &&  eval(historico.tipo_historico_atendimento.template);
  const contatoDestino = ((historico.dados.atendimento.meio_comunicacao.slug === 'voip'
   || historico.dados.atendimento.meio_comunicacao.slug === 'whatsapp')
   && servicoComum.formatarParaMascaraPhone(historico.dados.atendimento.contato_destino)) || historico.dados.atendimento.contato_destino;
   const contatoDestinoAbreviado = ((historico.dados.atendimento.meio_comunicacao.slug === 'voip'
    || historico.dados.atendimento.meio_comunicacao.slug === 'whatsapp')
    && servicoComum.formatarParaMascaraPhone(historico.dados.atendimento.contato_destino)) || historico.dados.atendimento.contato_destino.substr(0,40);
  const dadosAtendimento = historico.dados.atendimento && (
    <ul>
      <li>Destinatário: {historico.dados.atendimento.nome || '-'}</li>
      <li>Contato:
      {contatoDestino}</li>
    </ul>
  );

  return <tr>
    <td>
      <CardGroup>
        <div className="midiaAtendimentoArquivado" key={historico.dados && historico.dados.atendimento.meio_comunicacao.id}>
          <Card className="midiaCard">
            <Card.Body className="midiaCorpo">
              <Form.Check
                className="midiaListaTemplate"
                value={(historico.dados && historico.dados.atendimento.meio_comunicacao.slug) || null}
              >
                <Form.Check.Input />
                <Form.Check.Label>
                  <MidiaSocialIcone midia={(historico.dados && historico.dados.atendimento.meio_comunicacao.slug )|| null} />
                </Form.Check.Label>
              </Form.Check>
            </Card.Body>
          </Card>
        </div>
      </CardGroup>
    </td>
    <td>
      {dataHoraCadastro}
    </td>
    <td>
      <OverlayTrigger
        key={historico.id}
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={dadosAtendimento && <Tooltip>
          {dadosAtendimento}
        </Tooltip>}>
          <div>#{historico.id_atendimento} - {contatoDestinoAbreviado}</div>
        </OverlayTrigger>
    </td>
    <td>
      {interacao} {historico.tipo_historico_atendimento.id_tipo_historico_atendimento === 4 && historico.dados.alteracoes && resolverAlteracoesAtendimento(historico.dados.alteracoes)}
    </td>
    <td>
      {resolverNomeAtendente(historico.criado_por, "AÇÃO EXTERNA")}
    </td>
  </tr>
};
