import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Card, CardGroup, Col } from 'react-bootstrap';
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import caAPI from "../../../services/caAPI";
import Swal from "sweetalert2";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import "./style.scss";
import { trackPromise } from "react-promise-tracker";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import { AtendentesContext } from "../../../contexts/AtendentesContext";
import AtendimentoService from "../../../services/AtendimentoService";
import { celularMask } from "../../../components/InputTextMask";
import useTelefoneInternacionalInput from '../../../hooks/useTelefoneInternacionalInput';

export default function NovoAtendimentoWhatsapp({ posicaoListaAtentimentos, setOpenModalAtendimentoWhatsapp, idAtendimento, atendimento }) {

    const [atendimentoEditar, setAtendimentoEditar] = useState();
    const [meioComunicacaoInput, setMeioComunicacaoInput] = useState("whatsapp");
    const [situacaoInput, setSituacaoInput] = useState();
    const [atendenteInput, setAtendenteInput] = useState();
    const [tipoAtendimentoInput, setTipoAtendimentoInput] = useState();
    const [nomeDestinatarioInput, setNomeDestinatarioInput] = useState();
    const [contatoDestinatarioInput, setContatoDestinatarioInput] = useState("");
    const [contatoOrigemInput, setContatoOrigemInput] = useState();
    const [listaMeioComunicacao, setListaMeioComunicacao] = useState();
    const [listaSituacao, setListaSituacao] = useState();
    const [listaTipoAtendimento, setListaTipoAtendimento] = useState();
    const [maskDestinatarioType, setMaskDestinatarioType] = useState();
    const [textButton, setTextButton] = useState('Iniciar Atendimento');
    const [listaContatoOrigem, setListaContatoOrigem] = useState([]);
    const [meioComunicacaoWhatsapp, setMeioComunicacaoWhatsapp] = useState([]);
    const [refInputTelefone, objetoInputTelefone, getNumeroInternacional] = useTelefoneInternacionalInput(atendimentoEditar?.contato_destino);
    const { handleAdiconaLista,  listaAtendimentos,  handleReloadAtendimento, setListaAtendimentos } = useContext(AtendimentoContext);
    const {listaAtendentes, recarregarListaAtendentes, resolverNomeAtendente, testarAtendenteGerenciado} = useContext(AtendentesContext);
    const [podeIniciar, setPodeIniciar] = useState(true);
    useEffect(() => {
        trackPromise(
            caAPI.meio_comunicacao.list().then(res => {
                setListaMeioComunicacao(res.data);
                let comunicacaoWhatsapp = res.data.find(val => val.slug === 'whatsapp');
                setMeioComunicacaoWhatsapp(comunicacaoWhatsapp);
                setMeioComunicacaoInput(comunicacaoWhatsapp);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os meios de comunicação  " + err,
                    icon: 'error'
                });
            }),

            caAPI.situacao.list().then(res => {
                setListaSituacao(res.data);
            }).catch(err => Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar a lista de situações  " + err,
                icon: 'error'
            })),

            caAPI.tipo_atendimento.list().then(res => {
                setListaTipoAtendimento(res.data);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os tipos de atendimento  " + err,
                    icon: 'error'
                });
            })
        )
    }, [])

    useEffect(() => {
        if (idAtendimento) {
            trackPromise(
                caAPI.atendimento.find(idAtendimento).then(res => {
                    setAtendimentoEditar(res.data);
                    setNomeDestinatarioInput(res.data.nome);
                    setContatoDestinatarioInput(celularMask(res.data.contato_destino, true));
                    setContatoOrigemInput(res.data.contato_origem);
                    setTipoAtendimentoInput(res.data.tipo_atendimento.id);
                    setSituacaoInput(res.data.situacao);
                }).catch(err => {
                    Swal.fire({
                        titleText: "Erro",
                        text: "Houve um problema ao buscar o Atendimento",
                        icon: 'error'
                    });
                })
            )
        }
    }, [idAtendimento]);

    function buscarListaContatosOrigem(slug) {
        trackPromise(caAPI.meio_comunicacao.buscarContatoOrigem(slug)).then(
            (res) => {
                setListaContatoOrigem(res.data);
            }
        );
    }

    function formatarWhatsapp(numero) {
        numero = numero.replace(" ","").replace("(", "").replace(")", "").replace("-", "");
        return "+55" + numero;
    }

    const handleSubmitForm = function () {
      setPodeIniciar(false);
        let contatoDestino = contatoDestinatarioInput;
        if (objetoInputTelefone) {
          contatoDestino = getNumeroInternacional();
        } else {
            contatoDestino = formatarWhatsapp(contatoDestino);
        }

        if (!situacaoInput ||
            // !atendenteInput ||
            !tipoAtendimentoInput ||
            !meioComunicacaoInput ||
            !contatoDestinatarioInput ||
            (meioComunicacaoInput === 'voip' && !contatoOrigemInput) ||
            (meioComunicacaoInput === 'whatsapp' && !contatoOrigemInput)
        ) {
            Swal.fire({
                titleText: "Atenção",
                text: "Preecha todos os campos obrigatórios",
                icon: 'info'
            });
            return;
        }
        var data = {
            situacao: situacaoInput,
            atendente: atendenteInput,
            tipo_atendimento: tipoAtendimentoInput,
            meio_comunicacao: meioComunicacaoWhatsapp.slug,
            contato_origem: contatoOrigemInput,
            contato_destino: contatoDestino,
            nome: nomeDestinatarioInput,
        }

        trackPromise(caAPI.atendimento.create(data).then(res => {
            handleAdiconaLista(res.data)
            setOpenModalAtendimentoWhatsapp(false);
            setPodeIniciar(true);
            Swal.fire({
                title: 'Sucesso!',
                text: 'Atendimento foi criado com sucesso!',
                icon: 'success'
            })
        }).catch(err => {
          setPodeIniciar(true);

            Swal.fire({
                titleText: "Erro",
                text: "Erro ao criar/editar atendimento",
                icon: 'error'
            });
        }))
    };

    return <div className="novoAtendimentoWhatsapp">
    <Row>
        <Col xl={3}>
            <Form>
                <Form.Group >
                    <Form.Label>
                        Meio de Comunicação: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                        as="radio"
                        custom
                        onChange={(event) => {
                            setMeioComunicacaoInput(event.target.id);
                            buscarListaContatosOrigem(event.target.id);
                        }}
                    >
                        <CardGroup>
                            {listaMeioComunicacao && !atendimentoEditar && listaMeioComunicacao.map((midia) => {
                                return (
                                    midia.criavel === 1 && (
                                        <div key={midia.id}>
                                            <Card className="midiaCard">
                                                <Card.Body className="midiaCorpo">
                                                    <Form.Check
                                                        className="midia"
                                                        type="radio"
                                                        id={midia.slug}
                                                        value={midia.slug}
                                                    >
                                                        <Form.Check.Input type='radio' name="selecionaMidia" isValid />
                                                        <Form.Check.Label >
                                                            <MidiaSocialIcone midia={midia.slug} />
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )
                                )
                            }
                            )}

                            {!!atendimentoEditar &&
                                <Card className="midiaCard">
                                    <Card.Body className="midiaCorpo">
                                        <Form.Check
                                            className="midia"
                                            type="radio"
                                            id={meioComunicacaoWhatsapp.slug}
                                            value={meioComunicacaoWhatsapp.slug}
                                        >
                                            <Form.Check.Input checked={true} type='radio' name="selecionaMidia" isValid />
                                            <Form.Check.Label >
                                                <MidiaSocialIcone midia={meioComunicacaoWhatsapp.slug} />
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Card.Body>
                                </Card>
                            }

                        </CardGroup>
                    </Form.Control>
                </Form.Group>
            </Form>
        </Col>
        <Col>
            <Row>
                <Col>
                    <Form.Group as={Col} controlId="formDestinatario">
                        <Form.Label>Nome Destinatário: <DommusRequiredSpan /></Form.Label>
                        <Form.Control type="text" value={nomeDestinatarioInput}
                            onChange={(event) => setNomeDestinatarioInput(event.target.value)} disabled={"disabled"}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group as={Col} controlId="formContatoDestinatario">
                        <Form.Label>Contato Destinatário: <DommusRequiredSpan /></Form.Label>
                        <Form.Control type="text" value={contatoDestinatarioInput} disabled={"disabled"} ref={(meioComunicacaoInput == 'whatsapp' && refInputTelefone) || null}
                            onChange={(event) => setContatoDestinatarioInput(event.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col >
                    <Form.Group as={Col} controlId="formSituacao">
                        <Form.Label>Status do Atendimento: <DommusRequiredSpan /></Form.Label>
                        <Form.Control as="select" custom onChange={(event) => setSituacaoInput(event.target.value)} >
                            <option>Selecione o status:</option>
                            {listaSituacao && listaSituacao.map((situacao) => {
                                return <option key={situacao.id} value={situacao.id}>{situacao.nome}</option>
                            }
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group as={Col} controlId="formAtendimento">
                        <Form.Label>Tipo do Atendimento: <DommusRequiredSpan /></Form.Label>
                        <Form.Control as="select" custom onChange={(event) => setTipoAtendimentoInput(event.target.value)} disabled={"disabled"}>
                            <option>Selecione o tipo do atendimento:</option>
                            {listaTipoAtendimento && listaTipoAtendimento.map((tipo) => {
                                const selected = atendimentoEditar && atendimentoEditar.tipo_atendimento.id === tipo.id;
                                return <option key={tipo.id} selected={selected}
                                    value={tipo.id}>{tipo.descricao}</option>;
                            }
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col
                    style={listaContatoOrigem && listaContatoOrigem.length > 0 ? {} : { display: "none" }}
                >
                    <Form.Group as={Col} controlId="formNome">
                        <Form.Label>Contato Origem: <DommusRequiredSpan /></Form.Label>
                        <Form.Control
                            disabled={((listaContatoOrigem && !listaContatoOrigem.length > 0) || !!atendimentoEditar) ?? "disabled"}
                            as="select" custom onChange={(event) => setContatoOrigemInput(event.target.value)}>
                            <option>Selecione o Meio de Comunicação</option>
                            {listaContatoOrigem && listaContatoOrigem.map((numero, index) => {

                                const selected = listaContatoOrigem && listaContatoOrigem.contato_origem === numero.valor;
                                return <option key={index} selected={selected} value={numero.valor}>{numero.descricao ?? numero.valor}</option>;
                            })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                {atendimentoEditar ?
                    <Col>
                        <Form.Group as={Col} controlId="formAtendente">
                            <Form.Label>Atendente: <DommusRequiredSpan /></Form.Label>
                            <Form.Control as="select" custom onChange={(event) => setAtendenteInput(event.target.value)} disabled={"disabled"}>
                                <option>Selecione o/a Atendente:</option>
                                {listaAtendentes && listaAtendentes.map((atendente) => {
                                    const selected = atendimentoEditar && atendimentoEditar.atendente === parseInt(atendente.id);
                                    return <option key={atendente.id} selected={selected} value={atendente.id}>{atendente.nome}</option>
                                }
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    :
                    <></>
                }

            </Row>
        </Col>
    </Row>
    {
      podeIniciar && <button className="btn btn-block btn-primary btnNovoEditar" onClick={handleSubmitForm}>{textButton}</button>
    }
</div>
}
