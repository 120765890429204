import React, { useEffect, useState, useCallback, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PortalCliente from "./pages/PortalCliente/";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Auth from "./pages/Auth";
import Base from "./base.js";
import { trackPromise } from "react-promise-tracker";
import GuLogin from "./pages/Login/GuLogin";
import caAPI from "./services/caAPI";
import RecebeLigacaoVoipModal from "./pages/Voip/RecebeLigacaoVoipModal";
import Template from "./pages/Configuracao/Template";
import Label from "./pages/Configuracao/Label";
import AtendimentosArquivados from "./pages/PortalCliente/RegistroAtendimento/AtendimentosArquivados";
import FilasAtendimento from "./pages/FilasAtendimento";
import {AtendentesContext} from "./contexts/AtendentesContext";
import {AtendimentoContext} from "./contexts/AtendimentoContext";
import {ModulosContext} from "./contexts/ModulosContext";
import {MeioComunicacaoContext} from "./contexts/MeioComunicacaoContext";
import {TipoAtendimentoContext} from "./contexts/TipoAtendimentoContext";
import {TipoHistoricoAtendimentoContext} from "./contexts/TipoHistoricoAtendimentoContext";
import {SituacaoAtendimentoContext} from "./contexts/SituacaoAtendimentoContext";
import QuadroHistoricoAtendimento from "./pages/QuadroHistoricoAtendimento";
import Integracoes from "./pages/Integracoes";
// import { FiltroInteracoes } from "./pages/Relatorios/FiltroInteracoes";
// import { FiltroNotificacoes } from "./pages/Relatorios/FiltroNotificacoes";
// import { RelatoriosDiversos } from "./pages/Relatorios/Diversos";
import { RelatorioContext } from "./contexts/RelatorioContext";
import { TagsContext } from "./contexts/TagsContext";
import { EmpreendimentoContext } from "./contexts/EmpreendimentosContext";
import { CampanhaContext } from "./contexts/CampanhasContext";
import TempoOnline from "./pages/Configuracao/TempoOnline";
import GerenciarAssinatura from "./pages/Usuario/GerenciarAssinatura";
import { TiposAnexosTemplateContext } from "./contexts/TiposAnexosTemplateContext";
import { Dashboard } from "./pages/Dashboard";
import { MeuPerfil } from "./pages/Perfil";
import { DisparoEmMassa } from "./pages/DisparoEmMassa";
import { TemplateContext } from "./contexts/TemplateContext.js";
import { MapeamentoErrosTwillioContext } from "./contexts/MapeamentoErrosTwillioContext.js";
import { ContatosNotificacaoHistorico } from "./pages/ContatosNotificacaoHistorico/index.js";

export default function Routes() {
  const [tokenValido, setTokenValido] = useState(false);
  const {recarregarListaAtendentes, recarregarListaAtendentesGerenciados} = useContext(AtendentesContext);
  const {filtrarListaAtendimentos, carregaConfiguracoesDoUsuario, setFiltrosFixados} = useContext(AtendimentoContext);
  const {recarregarModulos} = useContext(ModulosContext);
  const {recarregarMeiosComunicacao} = useContext(MeioComunicacaoContext);
  const {recarregarRelatorios} = useContext(RelatorioContext);
  const {recarregarTiposAtendimento} = useContext(TipoAtendimentoContext);
  const {recarregarTiposHistoricoAtendimento} = useContext(TipoHistoricoAtendimentoContext);
  const {recarregarSituacoesAtendimento} = useContext(SituacaoAtendimentoContext);
  const {recarregarTags} = useContext(TagsContext);
  const {recarregarEmpreendimentos} = useContext(EmpreendimentoContext)
  const {recarregarCampanhas} = useContext(CampanhaContext)
  const {recarregarTiposAnexosTemplate} = useContext(TiposAnexosTemplateContext);
  const {recarregarTemplates} = useContext(TemplateContext);
  const {buscarMapeamentosErrosTwillio} = useContext(MapeamentoErrosTwillioContext);
  const telas = caAPI.getLocalState("telas");
  const history = useHistory();
  const valida = useCallback(async function() {
    let tokenAtual = caAPI.getLocalState("token");
    if (
      caAPI.getLocalState("token") !== null &&
      caAPI.getLocalState("idUsuario") !== null
    ) {
      caAPI
        .validaToken(tokenAtual)
        .then(async (res) => {
          caAPI.setLocalState("permissoes", res.data.permissoes);
          setTokenValido(res.data.valido);
          recarregarListaAtendentes();
          recarregarListaAtendentesGerenciados();
          recarregarModulos();
          recarregarMeiosComunicacao();
          recarregarTemplates();
          recarregarRelatorios();
          recarregarTiposAtendimento();
          recarregarTiposHistoricoAtendimento();
          recarregarSituacoesAtendimento();
          recarregarTags();
          recarregarEmpreendimentos();
          recarregarCampanhas();
          recarregarTiposAnexosTemplate();
          buscarMapeamentosErrosTwillio();
          let filtrosFixados = await carregaConfiguracoesDoUsuario()
          const filtrosInicial = {data_inicio: new Date(), data_fim: new Date()};
          caAPI.setLocalState('filtros', filtrosFixados ? filtrosFixados : JSON.stringify(filtrosInicial));
          setFiltrosFixados(filtrosFixados ? true : false)
          if(filtrosFixados) {
            filtrosFixados = JSON.parse(filtrosFixados)
            for(let campo in filtrosFixados) {
              if(Array.isArray(filtrosFixados[campo]) && filtrosFixados[campo][0]?.label) {
                filtrosFixados[campo] = filtrosFixados[campo].map(option => option.value)
              }
            }
          }
          filtrarListaAtendimentos(filtrosFixados ? filtrosFixados : filtrosInicial)
        })
        .catch((err) => {
          console.log(err);
          caAPI.logout();
        });
    } else {
      caAPI.logout();
    }
  }, []);

  useEffect(() => {
    if (!history.location.pathname.includes("gu-login")) {
      trackPromise(valida());
    }
  }, [history, valida]);

  return (
    <>
      <RecebeLigacaoVoipModal />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/sair" component={Logout} />
        <Route path="/auth" component={Auth} />
        <Route path="/gu-login/:token" component={GuLogin} />
        {tokenValido && (
          <Base
            history={history}
            conteudo={
              <div style={{ height: "100%" }}>
                {telas.includes("portal_cliente") && (
                  <Route path="/" exact component={PortalCliente} />
                )}
                <Route path="/configuracao/perfil" exact component={MeuPerfil} />
                {telas.includes("template") && (
                  <Route
                    path="/configuracao/template"
                    exact
                    component={Template}
                  />
                )}
                {telas.includes("label") && (
                  <Route path="/configuracao/label" exact component={Label} />
                )}
                {/* TODO habilitar as rotas para aparecer os filtros */}
                {/* {telas.includes("filtro_interacoes") && (
                  <Route path="/relatorios/filtro_interacoes" exact component={FiltroInteracoes} />
                )}
                {telas.includes("filtro_notificacoes") && (
                  <Route path="/relatorios/filtro_notificacoes" exact component={FiltroNotificacoes} />
                )}
                {telas.includes("relatorios_diversos") && (
                  <Route path="/relatorios/relatorios_diversos" exact component={RelatoriosDiversos} />
                )} */}
                {telas.includes("arquivo") && (
                  <Route
                    path="/arquivo"
                    exact
                    component={AtendimentosArquivados}
                  />
                )}
                {telas.includes("dashboard") && (
                  <Route
                    path="/dashboard"
                    exact
                    component={Dashboard}
                  />
                )}
                {telas.includes("portal_cliente") && (
                  <Route
                    path="/filas-atendimento"
                    exact
                    component={FilasAtendimento}
                  />
                )}
                {telas.includes("quadro_historico") && (
                  <Route
                    path="/quadro-historico"
                    exact
                    component={QuadroHistoricoAtendimento} />)}
                {telas.includes("integracoes") && (
                  <Route
                    path="/integracoes"
                    exact
                    component={Integracoes}
                  />
                )}
                {telas.includes("contatos_notificacao_historico") && (
                  <Route
                    path="/configuracao/contatos_notificacao_historico"
                    exact
                    component={ContatosNotificacaoHistorico}
                  />
                )}
                <Route
                    path="/tempo-online"
                    exact
                    component={TempoOnline}
                  />
                  <Route path="/usuario/gerenciar-assinatura" exact component={GerenciarAssinatura} />
                <Route path="/disparo-massa" exact component={DisparoEmMassa}/>
              </div>
            }
          ></Base>
        )}
        <Route path="*">
          <div>Carregando...</div>
        </Route>
      </Switch>
    </>
  );
}
