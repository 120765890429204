import moment from "moment";
import { Fragment, useCallback, useContext } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { InputMensagens } from "../../../../components/InputMensage";
import caAPI from "../../../../services/caAPI";
import RecebeText from "../WhatsappComponent/Mensagem/Text";
import Zoom from 'react-medium-image-zoom';
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';

import "./style.scss";
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { AtendentesContext } from "../../../../contexts/AtendentesContext";
const templateMensagens = 'chatbot';

export default function ChatBotComponent() {

    const {atendimentoAtivo} = useContext(AtendimentoContext);
    const [mensagemInput, setMensagemInput] = useState('');
    const [listaMensagens, setListaMensagens] = useState(atendimentoAtivo.conteudos);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [desativaInputMessage, setDesativaInputMessage] = useState(true);

    const {resolverNomeAtendente} = useContext(AtendentesContext);

    const messageContainerRef = useRef();

    useEffect(() => {
        let novaLista = (typeof atendimentoAtivo.detalhes === 'object')
            ? atendimentoAtivo.detalhes?.ticket
            : JSON.parse(atendimentoAtivo.detalhes)?.ticket;
        if (atendimentoAtivo.detalhes && novaLista) {
            setDesativaInputMessage(false)
        } else {
            setDesativaInputMessage(true)
        }
        setCurrentPage(1);
    }, [atendimentoAtivo]);

    const verificaJson = (json) => {
        try {
            let conteudo = JSON.parse(json);
            if (typeof conteudo === 'object') {
                return true;
            }
            return false;
        }
        catch (e) {
            return false;
        }
    }

    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }

    useEffect(() => {
        setListaMensagens(atendimentoAtivo.conteudos);
        setHasMore(true)
    }, [atendimentoAtivo]);

    const { channel } = useContext(WebSocketContext);
    useEffect(() => {
        channel.unbind('chatbot.' + atendimentoAtivo?.id);
        channel.bind('chatbot.' + atendimentoAtivo?.id, function (data) {
            const newMessage = JSON.parse(data.mensagem);
            setListaMensagens(messages => [...messages, newMessage]);
            let objmensagem = JSON.parse(data.mensagem);
            if (verificaJson(objmensagem.conteudo)) {
                let tipoConteudo = JSON.parse(objmensagem.conteudo);
                if (tipoConteudo.id) {
                    setDesativaInputMessage(false)
                }

            }
        });

    }, [channel, atendimentoAtivo])

    const enviarMensagem = useCallback(() => {
        if (!mensagemInput) {
            Swal.fire({
                titleText: "Atenção",
                text: "Escreva a mensagem antes de enviar",
                icon: 'warning'
            });
            return;
        }
        trackPromise(
            caAPI.atendimento.chatbot.enviar({ id_atendimento: atendimentoAtivo.id, body: mensagemInput }).then(res => {
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
        setMensagemInput('');
    }, [atendimentoAtivo, mensagemInput]);


    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    const scrollDown = function () {
        messageContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const mensagens = useMemo(() => {
        if (listaMensagens) {
            let listIterar = listaMensagens.filter(msg => msg && msg.detalhes).reverse();
            let date = null;
            let separator = null;
            let isLast = true;
            return listIterar.map(msg => {
              let cadastrado_por = (msg.direcao === 'enviado' && resolverNomeAtendente(msg.criado_por)) || (atendimentoAtivo.nome || "Destinatário");
                let conteudo = "";
                let imagens = [];
                let options = [];
                if (verificaJson(msg.conteudo)) {
                    imagens = JSON.parse(msg.conteudo).items;
                    conteudo = JSON.parse(msg.conteudo).text;
                    options = JSON.parse(msg.conteudo).options;
                } else {
                    conteudo = msg.conteudo
                }

                let msgContent = <RecebeText conteudo={conteudo} />
                let msg_date = moment(msg.criado_em).format('DD/MM/YYYY');
                if (!date || date !== msg_date) {
                    separator = <div className="separador">{date}</div>;
                    date = msg_date;
                } else {
                    separator = '';
                    isLast = false;
                }

                return <Fragment key={msg.id}>
                    {!isLast && separator}
                    <div className={'data ' + (msg.direcao === 'recebido' ? 'recebida' : 'enviada')}>
                        {moment(msg.criado_em).format('HH:mm')} - {cadastrado_por}
                    </div>
                    <div className={'mensagem-item ' + (msg.direcao === 'recebido' ? 'recebida' : 'enviada')}>
                        {msgContent}
                        {options?.map((option, index) => {
                            return (
                                <button key={index} disabled className="optionsChatBlip">
                                    {option.text}
                                </button>
                            )
                        })}
                        {imagens?.map((imagem, index) => {
                            return (
                                <div key={index}>
                                    <p />
                                    <Zoom image={{
                                        width: 'auto',
                                        src: imagem.value.uri,
                                        className: "mensagemImagem",
                                    }} />
                                    {/* <img key={index} src={imagem.uri} alt="" /> */}
                                </div>
                            )
                        })}
                    </div>
                </Fragment>
            });
        }
        scrollDown()
    }, [listaMensagens]);

    const handleLoadMoreMessages = () => {
        const messages = [...listaMensagens];
        caAPI.atendimento.getMessages(atendimentoAtivo.id, Number(currentPage) + 1).then(res => {
            {(res.data.conteudos.length > 0) ?  setHasMore(true) : setHasMore(false)}
            console.log("mais mensagens",res.data)
            messages.push(res.data);
            setCurrentPage(res.data.currentPage)
            setListaMensagens([...res.data.conteudos, ...listaMensagens]);
        }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar mais mensagens",
                icon: 'error'
            });
            return;
        });

    };


    return (
        <>
            <div className="chatbot-container" id="scrollableDiv" >
                <InfiniteScroll
                    className="chatbot-container"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    dataLength={mensagens.length || "0"}
                    // dataLength={mensagens.length || "0"}
                    next={handleLoadMoreMessages}
                    hasMore={hasMore}
                    inverse={true}
                    loader={<Spinner animation="border" style={{ color: '#265d9c' }} />}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Fim das Mensagens</b>
                        </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={() => { }}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    scrollableTarget="scrollableDiv"
                >
                    {mensagens}
                </InfiniteScroll>
            </div>
            <div ref={messageContainerRef} />
            <div>
                <InputMensagens
                    disabledInputMessage={desativaInputMessage}
                    templateMensagens={templateMensagens}
                    mensagemInput={mensagemInput}
                    setLegendaInput={setMensagemInput}
                    enviarMensagem={enviarMensagem}
                    receberMensagemInicial={enviarMensagem}
                    atendimento={atendimentoAtivo}
                    recebeMensagemInput={recebeMensagemInput}
                    changeSubmitKey={changeSubmitKey} />
            </div>
        </>
    )
}
