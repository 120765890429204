import React, { createContext, useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import AtendimentoService from "../services/AtendimentoService";
import caAPI from "../services/caAPI";

export const AtendentesContext = createContext({});

export function AtendentesProvider({ children }) {
  const [listaAtendentes, setListaAtendentes] = useState([]);
  const [listaAtendentesGerenciados, setListaAtendentesGerenciados] = useState([]);
  const [mapaAtendentes, setMapaAtendentes] = useState(new Map());
  const [mapaAtendentesGerenciados, setMapaAtendentesGerenciados] = useState(new Map());
  
  useEffect(() => {
    const novoMapa = new Map();
    listaAtendentes.forEach((atendente) => {
      novoMapa.set(Number(atendente.id), atendente);
    });
    setMapaAtendentes(novoMapa);
  }, [listaAtendentes]);

  useEffect(() => {
    const novoMapa = new Map();
    listaAtendentesGerenciados.forEach((atendente) => {
      novoMapa.set(Number(atendente.id), atendente);
    });
    setMapaAtendentesGerenciados(novoMapa);
  }, [listaAtendentesGerenciados]);

  const recarregarListaAtendentes = () => {
    caAPI.atendimento.atendentes
      .listaAtendentesTransferencia()
      .then((res) => {
        let arrayAtendentes = res.data.sort((a, b) => {
          return (a.nome && b.nome && a.nome < b.nome && -1) || 0;
        });
        setListaAtendentes(arrayAtendentes);
      })
      .catch((err) =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar a lista de atendentes  " + err,
          icon: "error",
        })
      );
  };

  const recarregarListaAtendentesGerenciados = () => {
    caAPI.atendimento.atendentes
      .listaAtendentes()
      .then((res) => {
        let arrayAtendentes = res.data.sort((a, b) => {
          return (a.nome && b.nome && a.nome < b.nome && -1) || 0;
        });
        setListaAtendentesGerenciados(arrayAtendentes);
      })
      .catch((err) =>
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar a lista de atendentes  " + err,
          icon: "error",
        })
      );
  };

  const resolverNomeAtendente = (idAtendente, padrao = "Não Atribuído") => {
    let atendente = mapaAtendentes.get(Number(idAtendente));
    return (atendente && atendente.nome) || padrao;
  };

  const testarAtendenteGerenciado = (idAtendente) => {
    return mapaAtendentesGerenciados.has(Number(idAtendente));
  };

  const testarUsuarioAtendeInputMensagem = (atendimento)=>{
    return testarAtendenteGerenciado(atendimento?.atendente) || AtendimentoService.testarUsuarioAtualAtende(atendimento);
  }


  return (
    <AtendentesContext.Provider
      value={{
        listaAtendentes,
        setListaAtendentes,
        listaAtendentesGerenciados,
        setListaAtendentesGerenciados,
        recarregarListaAtendentes,
        recarregarListaAtendentesGerenciados,
        resolverNomeAtendente,
        testarAtendenteGerenciado,
        testarUsuarioAtendeInputMensagem,
      }}
    >
      {children}
    </AtendentesContext.Provider>
  );
}
