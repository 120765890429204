import Alert from 'react-bootstrap/Alert';
import "./style.scss";
export function DommusAlert({title,message}) {
  return (
    <>
      {[
        'primary'
      ].map((variant) => (
        <Alert  key={variant} variant={variant}>
            <h4 id='title'>{title}</h4>

            <p id='message'>{message}</p>
        </Alert>
      ))}
    </>
  );
}

