import React, { createContext, useState, useEffect, useContext} from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const TagsContext = createContext({});

export function TagsProvider({ children }) {
  const [tagsLista, setTagsLista] = useState([]);
  const [mapaTags, setMapaTags] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();
    tagsLista.forEach((tag) => {
      novoMapa.set(Number(tag.id), tag);
    });
    setMapaTags(novoMapa);
  }, [tagsLista]);

  const recarregarTags = ()=>{
    caAPI.tags.list().then(res => {
        setTagsLista(res.data.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar as tags  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeTags = (idTag, padrao = "Não Atribuído") => {
    let tag = mapaTags.get(Number(idTag));
    return (tag && tag.nome) || padrao;
  };

  return (
    <TagsContext.Provider value={{
      tagsLista,
      recarregarTags,
      resolverNomeTags
    }}>
      {children}
    </TagsContext.Provider>
  )
}
