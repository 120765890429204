import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const TiposAnexosTemplateContext = createContext({});

export function TiposAnexosTemplateProvider({ children }) {
  const [documentosCarregadoAnexos, setDocumentosAnexoSelecionados] = useState([]);
  const [mapaDocumentosCarregadosAnexos, setMapaDocumentosCarregadosAnexos] = useState(new Map());
  const [modelosImpressaoAnexos, setModelosImpressaoSelecionados] = useState([]);
  const [mapaModelosImpressaoAnexos, setMapaModelosImpressaoAnexos] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();    
      documentosCarregadoAnexos.forEach((documentosCarregados) => {
        novoMapa.set(Number(documentosCarregados.id), documentosCarregados);
      });
      setMapaDocumentosCarregadosAnexos(novoMapa);
  }, [documentosCarregadoAnexos]);

  useEffect(()=>{
    const novoMapa = new Map();
      modelosImpressaoAnexos.forEach((modeloImpressao) => {
        novoMapa.set(
          Number(modeloImpressao.id_modelo_contratual),
          modeloImpressao
        );
      });
      setMapaModelosImpressaoAnexos(novoMapa);
  }, [modelosImpressaoAnexos]);

  const recarregarTiposAnexosTemplate = ()=>{
    caAPI.template.buscarTiposAnexos().then(res => {
        setDocumentosAnexoSelecionados(res.data.tipoDocumentoImpressao || []);
        setModelosImpressaoSelecionados(res.data.tipoModeloImpressao || []);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os tipos de anexos do template  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeModeloImpressao = (idModeloImpressao, padrao = "Não encotrado!") => {
    let modeloImpressao = mapaModelosImpressaoAnexos.get(Number(idModeloImpressao));
    return (modeloImpressao && modeloImpressao.nome) || padrao;
  };

  const resolverNomeDocumentoCarregado = (idModeloCarregado, padrao = "Não encotrado!") => {
    let documentoCarregado = mapaDocumentosCarregadosAnexos.get(Number(idModeloCarregado));
    return (documentoCarregado && documentoCarregado.descricao) || padrao;
  };

  return (
    <TiposAnexosTemplateContext.Provider value={{
      recarregarTiposAnexosTemplate,
      documentosCarregadoAnexos,
      modelosImpressaoAnexos,
      resolverNomeModeloImpressao,
      resolverNomeDocumentoCarregado,
    }}>
      {children}
    </TiposAnexosTemplateContext.Provider>
  )
}
