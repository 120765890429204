import api from "./api";

const DisparoMassaService = {
  buscarListaBasesDisparos: () => {
    return api.get(`/disparo-em-massa/base`);
  },
  salvarBaseDisparo: (payload) => {
    return api.post("/disparo-em-massa/base", payload);
  },
  atualizarBaseDisparo: (id, payload) => {
    return api.put(`/disparo-em-massa/base/${id}`, payload);
  },
  removeBaseDisparo: (id) => {
    return api.delete(`/disparo-em-massa/base/${id}`);
  },
  buscarIdsBase: (idBase) => {
    return api.get(`/disparo-em-massa/base/${idBase}/lista-ids`);
  },
  buscarDadosBase: (idBase, ids=[]) => {
    return api.get(`/disparo-em-massa/base/${idBase}/dados`, {params: { ids }});
  },
  buscarListaAgendamentoDisparo: (limit, page) => {
    return api.get(`/disparo-em-massa/agendamento?limit=${limit}&page=${page}`);
  },
  buscarLogsDisparos: (idExecucao, limit, page) => {
    return api.get(`/disparo-em-massa/agendamento/execucao/${idExecucao}/log-disparo?limit=${limit}&page=${page}`);
  },
  buscarAgendamento: (idAgendamento) => {
    return api.get(`/disparo-em-massa/agendamento/${idAgendamento}`);
  },
  gerarRelatorio: (id_execucao_disparo) => {
    return api.get(`/disparo-em-massa/relatorio/execucao-disparo/${id_execucao_disparo}`);
  },
  salvarAgendamentoDisparo: (payload) => {
    return api.post("/disparo-em-massa/agendamento", payload);
  },
  atualizarAgendamentoDisparo: (id, payload) => {
    return api.put(`/disparo-em-massa/agendamento/${id}`, payload);
  },
  removeAgendamentoDisparo: (id) => {
    return api.delete(`/disparo-em-massa/agendamento/${id}`);
  },
  removerDisparoPorId: (idAgendamento, idDisparo) => {
    return api.delete(`/disparo-em-massa/agendamento/${idAgendamento}/disparo/${idDisparo}`);
  },
  cancelarDisparosPendentes: (idDisparo) => {
    return api.delete(`/disparo-em-massa/agendamento/cancelamento/disparo/${idDisparo}`);
  },

};

export default DisparoMassaService;
