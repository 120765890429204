import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Tab, Tabs, Col, Image } from "react-bootstrap";
import DommusOnlineFlag from "../../components/DommusOnlineFlag";
import stringHelper from "../../helpers/StringHelper";
import Swal from "sweetalert2";
import useTelefoneInternacionalInput from "../../hooks/useTelefoneInternacionalInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import { PerfilUsuarioService } from "../../services/PerfilUsuarioService";
import { trackPromise } from "react-promise-tracker";
import './index.css'
import caAPI from "../../services/caAPI";
import ReactInputMask from "react-input-mask";
import { apenasNumeros, validaCPF } from "../../components/InputTextMask";
import formatDommus from "../../helpers/format";
import ReactTooltip from "react-tooltip";

export function MeuPerfil() {
    const [refInputCelular, objCelular, getCelular] = useTelefoneInternacionalInput('');
    const [refInputTelefone, objTelefone, getTelefone] = useTelefoneInternacionalInput('');
    const { handleSubmit, register, setValue, control } = useForm();
    const [usuario, setUsuario] = useState(null)
    const fotoUsuario = useRef(null)
    const idUsuario = caAPI.getLocalState('idUsuario')
    const atualizarDadosUsuario = (dados)  => {
        if(dados.nome.length == 0) {
            Swal.fire('Nome inválido', 'Preencha um nome válido prosseguir', 'warning');

            return
        }
        if(!stringHelper.isEmail(dados.email) || dados.email.length == 0) {
            Swal.fire('E-mail inválido', 'Preencha um e-mail válido prosseguir', 'warning');

            return
        }
        if(dados.cpf_cnpj?.length > 0 && !validaCPF(dados.cpf_cnpj)) {
            Swal.fire('CPF inválido', 'Preencha um CPF válido prosseguir', 'warning');

            return
        }
        dados.cpf_cnpj = apenasNumeros(dados.cpf_cnpj)
        dados.celular = apenasNumeros(getCelular())
        dados.telefone = apenasNumeros(getTelefone())
        dados.celular = Number(dados.celular) || null
        dados.telefone = Number(dados.telefone) || null
        dados.cpf_cnpj = Number(dados.cpf_cnpj) || null
        PerfilUsuarioService.atualizarUsuarioGU(idUsuario, dados)
            .then(() => {
                setValue('celular', dados.celular? `+${dados.celular}` : '')
                setValue('telefone', dados.telefone ? `+${dados.telefone}` : '')
                Swal.fire('Sucesso!', 'Usuário atualizado com sucesso.', 'success')
            })
            .catch(error => Swal.fire('Ooops...', `Houve um erro ao atualizar os dados do usuário. Erro: ${error} \nResposta: ${error.response?.data?.message}`, 'error'))
    }
    
    useEffect(() => {
        trackPromise(PerfilUsuarioService.buscarUsuarioGu(idUsuario))
            .then(response => {
                setUsuario(response.data)
            })
            .catch(error => Swal.fire('Ooops...', `Houve um erro ao buscar os dados do usuário. Erro: ${error} \nResposta: ${error.response?.data?.message}`, 'error'))
    }, [])

    function uploadFotoUsuario() {
        if(!fotoUsuario?.current?.files[0]) {
            return
        }
        const formData = new FormData();
        formData.append('foto_perfil', fotoUsuario.current.files[0]);
        trackPromise(PerfilUsuarioService.atualizarFotoPerfilUsuario(idUsuario, formData, true))
            .then((response) => {
                const usuarioAtualizado = {
                    ...usuario,
                    foto_perfil: null
                }
                setUsuario(usuarioAtualizado)
                Swal.fire('Sucesso.', `Foto de perfil atualizada com sucesso.`, 'success').then(() => {
                    const usuarioAtualizadoComFoto = {
                        ...usuario,
                        foto_perfil: response.data.foto_perfil
                    }
                    setUsuario(usuarioAtualizadoComFoto)
                })
            }).catch(error => Swal.fire('Erro.', `Houve um erro ao tentar atualizar a foto de perfil do usuário. Erro: ${error} \nResposta: ${error.response?.data?.message}`, 'error'))
    }

    return <div id="pag-perfil">
        <ReactTooltip/>
        {usuario && <>
            <Row className="justify-content-center bg-perfil pt-5">
                <div id="img-perfil" onClick={() => fotoUsuario.current.click()} data-tip={'Você está online.'}>
                    <div id="alterar-foto" className="pt-5">
                        <FontAwesomeIcon icon={faCameraAlt} size="xl" color="#fff"/>
                        <br/>
                        <span>Alterar Foto</span>
                    </div>
                    {usuario.foto_perfil ? (<Image 
                            id="img-usuario" 
                            className={`rounded-circle align-self-center border-success bg-success`} 
                            width={150} 
                            height={150}
                            src={usuario.foto_perfil}
                        />) : <DommusOnlineFlag/>}
                </div>
            </Row>
            <Row className="justify-content-center bg-perfil pt-3 pb-5">
                <Form.Label className="text-light font-weight-bold font-size-16">{usuario.nome}</Form.Label>
            </Row>
            <Row className="justify-content-center">
                <div className='navegation bg-perfil'>
                    <Tabs defaultActiveKey="informacoespessoais" className="tab-perfil">
                        <Tab className="tabstyle" eventKey="informacoespessoais" title="Informações Pessoais" id="tab-informacoes">
                            <Form className="form-perfil bg-light" onSubmit={handleSubmit(atualizarDadosUsuario)}>
                                <Form.Control ref={fotoUsuario} type="file" className="d-none" onChange={() => uploadFotoUsuario()} accept="image/*"/>
                                <div id="conteudo-informacoes" className="container d-block border mt-0 p-3 rounded pt-5">
                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nome: </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o nome"
                                                {...register("nome", { required: false })}
                                                defaultValue={usuario.nome}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email: </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Digite o e-mail"
                                                {...register("email", { required: false })}
                                                defaultValue={usuario.email}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="4" controlId="formNome">
                                            <Form.Label>
                                                Celular:
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                ref={refInputCelular}
                                                defaultValue={usuario.celular ? `+${usuario.celular}` : ''}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formNome">
                                            <Form.Label>
                                                Telefone:
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                ref={refInputTelefone}
                                                defaultValue={usuario.telefone ? `+${usuario.telefone}` : ''}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formNome">
                                            <Form.Label>CPF:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="cpf_cnpj"
                                                rules={{ required: false }}
                                                render={({ field }) => (
                                                    <ReactInputMask 
                                                        className="form-control" 
                                                        defaultValue={usuario.cpf_cnpj ? formatDommus.zeroPad(usuario.cpf_cnpj, 11) : ''} 
                                                        {...field} 
                                                        mask="999.999.999-99" 
                                                        maskChar={""}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col}>
                                            <button type="submit" className="btn btn-success float-right mt-3">
                                                <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }}/>Alterar Informações
                                            </button>
                                        </Form.Group>
                                    </Row>
                                </div>
                            </Form>
                        </Tab>
                    </Tabs>
                </div>
            </Row>
        </>}
    </div>
}