import React from "react";
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const ListaAtendentesAdicionar = ({
  atendentes,
  addOcorrencia,
  children,
  addOcorrenciaFila,
  refresh,
}) => {
  const ordenados = atendentes.sort(function (a, b) {
    if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
    if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
    return 0;
  });
  const itensCorretores = ordenados.map((atendente) => {
    return (
      <ListGroup.Item key={atendente.id}>
        <div className={"d-flex justify-content-between"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <span style={{ fontSize: 12 }}>
                {atendente.nome.toUpperCase()}
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faPlusCircle}
                onClick={() => {
                  addOcorrenciaFila(atendente.id);
                }}
                style={{ cursor: "pointer" }}
                title={"Adicionar à fila"}
              />
            </div>
          </div>
        </div>
      </ListGroup.Item>
    );
  });
  return (
    <ListGroup>
      {children}
      {itensCorretores}
    </ListGroup>
  );
};

export default function AdicionarFila({
  atendentes,
  setRefresh,
  refresh,
  addOcorrenciaFila,
}) {
  return (
    <Container fluid style={{ marginTop: 10 }}>
      <Row style={{ overflow: "auto", height: "65vh" }}>
        <Col lg={12}>
          <Form.Group>
            <Form.Label>Atendentes</Form.Label>
            <ListaAtendentesAdicionar
              atendentes={atendentes}
              refresh={refresh}
              addOcorrenciaFila={addOcorrenciaFila}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}
