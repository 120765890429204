import React, { Component } from 'react';
import { Button, Form, Row, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import './anotacao.scss';

class Anotacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInEditMode: false,
        }
        this.deletarAnotacao = false;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            value: event.target,
        });
        this.changeEditMode();
    }

    changeEditMode = (e) => {
        const edimode = !(this.state.isInEditMode);
        this.setState({
            isInEditMode: edimode
        });
    }

    updateComponentValue = (e) => {
        e.preventDefault();
        const updateAnotacao = (this.refs.entradaTexto.value);
        this.handleChange(updateAnotacao)
        // console.log("ref", this.props.mensagem.id);
        this.setState({
            value: updateAnotacao,
        });
        this.setState({
            isInEditMode: false,
        })
        this.props.atualizaAnotacao({ mensagem: this.refs.entradaTexto.value, id: this.props.mensagem.id });
    }

    renderEditView = () => {

        return <div className="areaEditarAnotacao">
            {/* {console.log(this.props.mensagem.id)} */}
            <Form.Group className="inputAnotacao" controlId="ControlTextarea1">
                <Form.Control
                    as="textarea"
                    defaultValue={this.state.value === undefined ? this.props.mensagem.anotacao : this.state.value}
                    ref="entradaTexto"
                />
            </Form.Group>
            <div className="areaBtn">
                <Button variant="danger" style={{ backgroundColor: "#F44336" }} onClick={this.changeEditMode}>Cancelar</Button>{' '}
                <Button variant="success" style={{ backgroundColor: "#4CAF50" }} onClick={this.updateComponentValue}>Salvar</Button>
            </div>
        </div>
    }


    renderDefaultView = () => {
        return <>

            <div className="areaAnotacao">
                <Col>
                    <div >
                        {/* {console.log("inicial", this.state.value)} */}
                        {this.state.value === undefined ? this.props.mensagem.anotacao : this.state.value}
                    </div>
                </Col>
                <Row className="linhaBotaoEditarAnotacao">
                    <OverlayTrigger
                        key="buttonEditarAnotacao"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-editar`}>
                                Editar
                            </Tooltip>
                        }
                    >
                        <div className="buttonEditarAnotacao"
                            onClick={this.changeEditMode}
                            ata-tip="Editar Inntrodução"><FontAwesomeIcon icon={faEdit}
                            />
                        </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                        key="buttonExcluirAnotacao"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-excluir`}>
                                Excluir
                            </Tooltip>
                        }
                    >
                        <div className="buttonExcluirAnotacao"
                            onClick={() => this.props.removeLista(this.props.mensagem.id)}
                            ata-tip="Editar Inntrodução">
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    </OverlayTrigger>
                </Row>

            </div>

        </>
    }

    render() {
        return this.state.isInEditMode ?
            this.renderEditView() :
            this.renderDefaultView()
    }
}

export default Anotacao;
