import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import { Form, Row, Col, Button } from "react-bootstrap";
import Anexos from "../../../../components/Anexos";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import caAPI from "../../../../services/caAPI";
import { trackPromise } from "react-promise-tracker";
import { MeioComunicacaoContext } from "../../../../contexts/MeioComunicacaoContext";
import { TipoAtendimentoContext } from "../../../../contexts/TipoAtendimentoContext";
import { SituacaoAtendimentoContext } from "../../../../contexts/SituacaoAtendimentoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import {
    FormProvider,
    useFieldArray,
    useForm,
    useWatch,
} from "react-hook-form";
import { AgendamentoDisparoItem } from "./AgendamentoDisparoItem";
import DisparoMassaService from "../../../../services/DisparoMassaService";
import Swal from "sweetalert2/src/sweetalert2";
import { DisparoMassaContext } from "../../../../contexts/DisparoMassaContext";
import { TemplateContext } from "../../../../contexts/TemplateContext";

export function AgendamentoDisparoFormularioCadastro() {
    const [listaAnexos, setListaAnexos] = useState([]);
    const [contatosOrigem, setContatosOrigem] = useState([]);
    const { meiosComunicacao } = useContext(MeioComunicacaoContext);
    const { tiposAtendimento } = useContext(TipoAtendimentoContext);
    const { situacoesAtendimento } = useContext(SituacaoAtendimentoContext);
    const { templates } = useContext(TemplateContext);
    const {
        adicionarAgendamento,
        atualizarAgendamento,
        abrirFecharModalCadastro,
        agendamentoSelecionado,
    } = useContext(DisparoMassaContext);
    const [desabilitarCamposAssunto, setDesabilitaCamposAssunto] =
        useState(true);
    const [desabilitaCamposAnexo, setDesabilitaCamposAnexo] = useState(true);
    const [listaTemplates, setListaTemplates] = useState([]);

    const atendimentoFinalizado = useMemo(()=>{
        return situacoesAtendimento.find((situacao)=>situacao.tipo === "F");
    },[])

    const defaultValue = useMemo(() => {
        if (agendamentoSelecionado) {
            if (
                agendamentoSelecionado?.anexos &&
                agendamentoSelecionado?.anexos.length
            ) {
                setListaAnexos(agendamentoSelecionado?.anexos);
            }
            return {
                nome: agendamentoSelecionado.nome,
                disparos: agendamentoSelecionado?.execucao_disparos.map(
                    (disparo) => {
                        return {
                            id_disparo: disparo?.id,
                            id_base_disparo: disparo?.id_base_disparo,
                            data_hora_disparo: disparo?.data_hora_disparo,
                            recorrencia: disparo?.recorrencia,
                            intervalo_recorrencia:
                                disparo?.intervalo_recorrencia,
                            tipo_intervalo_recorrencia:
                                disparo?.tipo_intervalo_recorrencia,
                            disparo_imediato: disparo?.disparo_imediato,
                            recorrencia_habilitado: disparo?.recorrencia > 0,
                        };
                    }
                ),
                assunto: agendamentoSelecionado?.assunto,
                id_contato_origem: agendamentoSelecionado?.id_contato_origem,
                id_meio_comunicacao:
                    agendamentoSelecionado?.id_meio_comunicacao,
                id_situacao: agendamentoSelecionado?.id_situacao,
                id_template: agendamentoSelecionado?.id_template,
                id_tipo_atendimento:
                    agendamentoSelecionado?.id_tipo_atendimento,
            };
        } else {
            return {
                nome: null,
                disparos: [
                    {
                        id_disparo: null,
                        id_base_disparo: null,
                        data_hora_disparo: null,
                        recorrencia: null,
                        intervalo_recorrencia: null,
                        tipo_intervalo_recorrencia: null,
                        disparo_imediato: null,
                        recorrencia_habilitado: null,
                    },
                ],
                assunto: null,
                id_contato_origem: null,
                id_meio_comunicacao: null,
                id_situacao: null,
                id_template: null,
                id_tipo_atendimento: null,
            };
        }
    }, [agendamentoSelecionado]);

    const methods = useForm({
        defaultValues: defaultValue,
    });
    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: "disparos",
    });
    const meioComunicacaoWatch = useWatch({
        control: methods.control,
        name: "id_meio_comunicacao",
    });

    const salvarEditarAgendamentoDisparo = (dados) => {
        let payload = dados;
        payload.anexos = listaAnexos ? listaAnexos : [];
        let metodo = agendamentoSelecionado?.id
            ? DisparoMassaService.atualizarAgendamentoDisparo(
                agendamentoSelecionado?.id,
                payload
            )
            : DisparoMassaService.salvarAgendamentoDisparo(payload);

        trackPromise(metodo)
            .then((response) => {
                if (agendamentoSelecionado?.id) {
                    atualizarAgendamento(
                        agendamentoSelecionado?.id,
                        response.data
                    );
                } else {
                    adicionarAgendamento(response.data);
                }
                Swal.fire(
                    "Sucesso!",
                    "Agendamento salvo com sucesso!",
                    "success"
                ).then(() => abrirFecharModalCadastro());
            })
            .catch((error) => {
                let erroMessage =
                    error?.response?.data?.nome ??
                    error?.response?.data?.base_referencia ??
                    error?.response?.data?.base_disparo ??
                    error;
                Swal.fire({
                    titleText: "Erro",
                    text:
                        "Houve um problema ao salvar Agendamento de Disparo! " +
                        erroMessage,
                    icon: "error",
                });
            });
    };

    useEffect(() => {
        let meioComunicacao = meiosComunicacao.find(
            (meio) => meio.id === Number(meioComunicacaoWatch)
        );
        if (meioComunicacao) {
            setContatosOrigem(meioComunicacao.contatos_origem);
            let temp = templates
                .filter((template) => {
                    return template?.meios.find(
                        (meio) => meio.id === meioComunicacao.id
                    );
                })
                .filter((template) => {
                    if (meioComunicacao.slug === "whatsapp") {
                        return template.aprovado === "S";
                    } else {
                        return template;
                    }
                })
                .map((template) => (
                    <option key={`template-${template.id}`} value={template.id}>
                        {template.nome}
                    </option>
                ));
            setListaTemplates(temp);
            if (
                meioComunicacao.slug === "email" ||
                meioComunicacao.slug === "whatsapp"
            ) {
                if (meioComunicacao.slug === "email") {
                    setDesabilitaCamposAssunto(false);
                } else {
                    setDesabilitaCamposAssunto(true);
                    methods.setValue("assunto", "");
                }
                setDesabilitaCamposAnexo(false);
            } else {
                setDesabilitaCamposAssunto(true);
                setDesabilitaCamposAnexo(true);
                setListaAnexos([]);
                methods.setValue("assunto", "");
            }
        } else {
            setContatosOrigem([]);
            setDesabilitaCamposAssunto(true);
            setDesabilitaCamposAnexo(true);
            setListaAnexos([]);
            methods.setValue("assunto", "");
        }
    }, [meioComunicacaoWatch, templates]);

    useEffect(() => {
        methods.setValue("id_template", agendamentoSelecionado?.id_template);
        methods.setValue(
            "id_contato_origem",
            agendamentoSelecionado?.id_contato_origem
        );
    }, [listaTemplates, contatosOrigem]);

    function handleAddItem() {
        append({
            id_disparo: null,
            id_base_disparo: null,
            data_hora_disparo: null,
            recorrencia: null,
            intervalo_recorrencia: null,
            tipo_intervalo_recorrencia: null,
            disparo_imediato: null,
            recorrencia_habilitado: null,
        });
    }

    function handleRemover(index) {
        remove(index);
    }

    const disparosRealizados = useMemo(() => {
        let realizado = false;
        if (agendamentoSelecionado) {
            agendamentoSelecionado.execucao_disparos.forEach((execucao) => {
                execucao.logs_disparos.forEach((disparo) => {
                    if (disparo.status_execucao === "concluido") {
                        realizado = true;
                    }
                });
            });
        }
        return realizado;
    }, [agendamentoSelecionado]);

    useEffect(() => {
        if (!agendamentoSelecionado) {
            let situacao = situacoesAtendimento.find(
                (situacao) => situacao.tipo === "F"
            );
            if (situacao) {
                methods.setValue("id_situacao", situacao.id);
            }
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <Form
                className="container-agendamento-form"
                onSubmit={methods.handleSubmit(salvarEditarAgendamentoDisparo)}
            >
                <Row>
                    <Col md={7} style={{ overflowX: "auto" }}>
                        <fieldset>
                            <legend>Dados do Agendamento</legend>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Nome:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            type="text"
                                            {...methods.register("nome", {
                                                required: true,
                                            })}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Situação do Atendimento:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            as="select"
                                            {...methods.register(
                                                "id_situacao",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={true}
                                        >
                                            <option
                                                selected={true}
                                                value={atendimentoFinalizado.id}
                                            >
                                                {atendimentoFinalizado.nome}
                                            </option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Template:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            as="select"
                                            {...methods.register(
                                                "id_template",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={disparosRealizados}
                                        >
                                            <option value="" hidden>
                                                SELECIONE UM TEMPLATE
                                            </option>
                                            {listaTemplates?.length ? (
                                                listaTemplates
                                            ) : (
                                                <option value="" disabled>
                                                    Não possui nenhum Template
                                                </option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Tipo de Atendimento:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            as="select"
                                            {...methods.register(
                                                "id_tipo_atendimento",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={disparosRealizados}
                                        >
                                            <option value="" hidden>
                                                SELECIONE UM TIPO
                                            </option>
                                            {tiposAtendimento.map(
                                                (tipoAtendimento) => (
                                                    <option
                                                        key={`tipo-atendimento-${tipoAtendimento.id}`}
                                                        value={
                                                            tipoAtendimento.id
                                                        }
                                                    >
                                                        {
                                                            tipoAtendimento.descricao
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Meio de Comunicação:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            as="select"
                                            {...methods.register(
                                                "id_meio_comunicacao",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={disparosRealizados}
                                        >
                                            <option value="" hidden>
                                                SELECIONE UM MEIO
                                            </option>
                                            {meiosComunicacao.filter((meio)=>{
                                                switch(meio.slug){
                                                    case 'whatsapp':
                                                    case 'sms':
                                                    case 'email':
                                                        return 1;
                                                    default:
                                                        return 0
                                                }
                                            }).map(
                                                (meioComunicacao) => (
                                                    <option
                                                        key={`meio-comunicacao-${meioComunicacao.id}`}
                                                        value={
                                                            meioComunicacao.id
                                                        }
                                                    >
                                                        {meioComunicacao.nome}
                                                    </option>
                                                )
                                            )}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Contato Origem:{" "}
                                        </Form.Label>
                                        <DommusRequiredSpan />
                                        <Form.Control
                                            as="select"
                                            {...methods.register(
                                                "id_contato_origem",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            disabled={disparosRealizados}
                                        >
                                            <option value="" hidden>
                                                SELECIONE UM CONTATO
                                            </option>
                                            {contatosOrigem.length ? (
                                                contatosOrigem.map(
                                                    (contatoOrigem, index) => (
                                                        <option
                                                            key={`contato-origem-${index}`}
                                                            value={
                                                                contatoOrigem.id_contato_origem
                                                            }
                                                        >
                                                            {
                                                                contatoOrigem.descricao
                                                            }
                                                        </option>
                                                    )
                                                )
                                            ) : (
                                                <option value="" disabled>
                                                    Não possui nenhum Contato de
                                                    Origem
                                                </option>
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="inputLabel">
                                            Assunto:{" "}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...methods.register("assunto")}
                                            disabled={desabilitarCamposAssunto}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group as={Row} className="px-3">
                                        <Form.Label className="inputLabel">
                                            Anexos:{" "}
                                        </Form.Label>
                                        <Anexos
                                            listaAnexos={listaAnexos}
                                            setListaAnexos={setListaAnexos}
                                            disabled={desabilitaCamposAnexo}
                                            clean={desabilitaCamposAnexo}
                                            filesTypes=""
                                        />
                                        <div className="area_btn_anexar"></div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                    <Col style={{ overflowX: "auto" }}>
                        <fieldset>
                            <legend>Disparos</legend>
                            <div className="agendamentos-disparos-section">
                                {fields.map((field, index) => (
                                    <AgendamentoDisparoItem
                                        key={field.id}
                                        index={index}
                                        name={`disparos.${index}`}
                                        handleRemover={handleRemover}
                                    />
                                ))}
                            </div>
                        </fieldset>
                    </Col>
                </Row>
                <Row className="px-3 justify-content-end pt-3 border-top">
                    <Button
                        variant="info"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            handleAddItem();
                        }}
                    >
                        <FontAwesomeIcon icon={faCirclePlus} /> Adicionar
                        Disparo
                    </Button>
                    <Button variant="success" type="submit">
                        <FontAwesomeIcon icon={faCloudUpload} /> Salvar
                    </Button>
                </Row>
            </Form>
        </FormProvider>
    );
}
