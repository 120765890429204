import React, { useContext, useEffect, useState } from "react";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import {
    Container,
    OverlayTrigger,
    Tooltip,
    Tabs,
    Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ModalDommus from "../../components/Modal";
import { DisparoMassaContext } from "../../contexts/DisparoMassaContext";
import { BaseDisparoFormularioCadastro } from "./BaseDisparoEmMassa/BaseDisparoFormularioCadastro";
import "./index.css";
import { BaseDisparoEmMassa } from "./BaseDisparoEmMassa";
import { AgendamentoDisparoEmMassa } from "./AgendamentoDisparoEmMassa";
import { AgendamentoDisparoFormularioCadastro } from "./AgendamentoDisparoEmMassa/AgendamentoDisparoFormularioCadastro";
import { DommusModalFullScreen } from "../../components/DommusModalFullScreen";

export function DisparoEmMassa() {
    const [tabSelecionada, setTabSelecionada] = useState("agendamentos");
    const {
        abrirFecharModalCadastro,
        modalCadastroBaseDisparoAberto,
        recarrecarListaBaseDisparo,
    } = useContext(DisparoMassaContext);

    useEffect(() => {
        recarrecarListaBaseDisparo();
    }, []);

    return (
        <div className="px-3">
            <DommusBarraTitulo>
                <ItemBarraTitulo>Disparo em Massa</ItemBarraTitulo>
                <OverlayTrigger
                    key="overlay-disparo-massa"
                    overlay={
                        <Tooltip id="tooltip-disparo-massa">
                            Criar Nova Base de Disparo
                        </Tooltip>
                    }
                    placement="top"
                >
                    <ItemBarraTitulo
                        classe="btn py-3"
                        onClick={() => abrirFecharModalCadastro()}
                    >
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            color="#4caf50"
                            size="lg"
                        />
                    </ItemBarraTitulo>
                </OverlayTrigger>
            </DommusBarraTitulo>
            <Container
                fluid
                className="bg-white border rounded formCadastro pt-3"
            >
                <Tabs
                    id="tabs-disparo-massa"
                    activeKey={tabSelecionada}
                    onSelect={(tab) => setTabSelecionada(tab)}
                    className="tabs-dommus"
                >
                    <Tab eventKey="agendamentos" title="Agendamentos" className="tab-dommus">
                        <AgendamentoDisparoEmMassa />
                    </Tab>
                    <Tab eventKey="bases" title="Bases de Disparos" className="tab-dommus">
                        <BaseDisparoEmMassa />
                    </Tab>
                </Tabs>
            </Container>
            {tabSelecionada === "agendamentos" ? (
                <DommusModalFullScreen
                    titulo="Novo Agendamento de Disparo"
                    open={modalCadastroBaseDisparoAberto}
                    close={() => abrirFecharModalCadastro()}
                >
                    <AgendamentoDisparoFormularioCadastro />
                </DommusModalFullScreen>
            ) : (
                <ModalDommus
                    titulo="Nova Base de Disparo"
                    open={modalCadastroBaseDisparoAberto}
                    close={() => abrirFecharModalCadastro()}
                >
                    <BaseDisparoFormularioCadastro />
                </ModalDommus>
            )}
        </div>
    );
}
