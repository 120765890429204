const stringHelper = {
	isBlank: (str) => {
		return (!str || /^\s*$/.test(str));
	},
	isEmpty: (str) => {
		return (!str || 0 === str.length);
	},
	isEmail: (email) => {
		var emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
		return emailPattern.test(email);
	}
};
export default stringHelper;