import React, { createContext, useCallback, useEffect, useState, useContext } from "react";
import caAPI from "../services/caAPI";
import moment from "moment";
import Swal from "sweetalert2";
import { trackPromise } from 'react-promise-tracker';
import {AtendentesContext} from "./AtendentesContext";
import { PerfilUsuarioService } from "../services/PerfilUsuarioService";

const atualizaLista = 1;

export const AtendimentoContext = createContext({});

export function AtendimentoProvider({ children }) {

  const [atendimentoAtivo, setAtendimentoAtivo] = useState();
  const [listaAtendimentos, setListaAtendimentos] = useState([]);
  const [openFiltros, setOpenFiltros] = useState(false);
  const [filtro, setFiltro] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [totalAtendimentos, setTotalAtendimentos] = useState(0);
  const [naoAtendidos, setNaoAtendidos] = useState(0);
  const { resolverNomeAtendente } = useContext(AtendentesContext);
  const [fetchingLista, setFetchingLista] = useState(false);
  const [loadingMensagens, setLoadingMensagens] = useState(false);
  const [filtrosFixados, setFiltrosFixados] = useState(null)
  const [assinaturaUsuario, setAssinaturaUsuario] = useState(null)
  const [mensagensAtualizadasWhatsapp, setMensagensAtualizadasWhatsapp] = useState(null)
  const [filtroSomenteNaoLidas, setFiltroSomenteNaoLidas] = useState(false);


  // useEffect(() => {
  //   if(!(listaAtendimentos) || (listaAtendimentos.length === 0)){
  //     console.log("Entra aqui");
  //     console.trace();
  //   }
  // }, [listaAtendimentos]);

  useEffect(() => {
    atualizarTotaisAtendimentos()
  }, [filtro]);

  useEffect(()=>{
    atualizarTotaisAtendimentos()
    let filtros = Object.assign(JSON.parse(caAPI.getLocalState('filtros') || "{}"), {somente_nao_lidas: filtroSomenteNaoLidas});
    filtros = Object.assign(filtros, {data_inicio: filtros.data_inicio ?? moment().format("YYYY-MM-DD")});

    filtrarListaAtendimentos(filtros);
  },[filtroSomenteNaoLidas])

  const atualizarTotaisAtendimentos = () => {
    if(caAPI.getLocalState('token') && filtro){
      const novaPagina = 1;
      setPagina(novaPagina);      
      caAPI.atendimento.filtro.total(filtro).then(res => {
        const novoTotal = ( res.data && Number(res.data.total) ) || 0;
        const novoNaoAtendidos = ( res.data && Number(res.data.naoLidas) ) || 0;
        if(filtroSomenteNaoLidas){
          setTotalAtendimentos(novoNaoAtendidos);
          setNaoAtendidos(novoNaoAtendidos)
        }else{
          setTotalAtendimentos(novoTotal);
          setNaoAtendidos(novoNaoAtendidos)
        }
      }).catch(() => {
        Swal.fire({
          titleText: "Erro",
          text: "Não foi possivel buscar os dados filtrados",
          icon: 'error'
        });
      })
    }
  }

  const filtrarListaAtendimentos = (filtrosEntrada) => {
    setListaAtendimentos([])
    setTotalAtendimentos(0);
    setNaoAtendidos(0);
    let dataAtual = moment().format("YYYY-MM-DD");
    let filtros = filtrosEntrada;    
    if (!filtrosEntrada?.data_inicio) {
      filtros = Object.assign({}, { data_inicio: dataAtual, data_fim: dataAtual }, filtrosEntrada)
    }
    const novoFiltros = Object.assign({}, filtros);
    setOpenFiltros(false);    
    trackPromise(caAPI.atendimento.filtro.filtrar(filtros).then(res => {
        setFiltro(novoFiltros);
        setListaAtendimentos(res.data)
        // setTotal(res.data.count)

    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Não foi possivel buscar os dados filtrados",
        icon: 'error'
      });
    }), "listaAtendimentosArea")
  };

  const setExportarFiltros = (filtrosEntrada, exportar = false) => {
    setListaAtendimentos([])
    setTotalAtendimentos(0);
    setNaoAtendidos(0);
    let dataAtual = moment().format("YYYY-MM-DD");
    let filtros = filtrosEntrada;
    if (!filtrosEntrada.data_inicio) {
      filtros = Object.assign({}, { data_inicio: dataAtual, data_fim: dataAtual }, filtrosEntrada)
    }
    const novoFiltros = Object.assign({}, filtros);
    trackPromise(caAPI.atendimento.filtro.exportar(filtros, exportar).then(res => {
      if(res.headers['content-type'].replace(/;.+/, "") == 'text/csv'){
        const csvData = res.data;
        const blob = new Blob([csvData], { type: "text/csv" });
        const csvURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = csvURL;
        link.setAttribute('download', 'Filtro-pesquisa-conecta-'+ (new Date().toISOString()) +'.csv');

        document.body.appendChild(link);
        link.click();
      }
      filtrarListaAtendimentos(filtrosEntrada)
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Não foi possivel buscar os dados filtrados",
        icon: 'error'
      });
    }), "listaAtendimentosArea")
  };

  const handleAdiconaLista = (atendimento) => {
    const novaLista = listaAtendimentos.slice();
    if(atendimento && atendimento.id){
      let indiceAtendimento = novaLista.findIndex(atendimentoLista => atendimentoLista.id && (Number(atendimentoLista.id) === Number(atendimento.id)));
      if(indiceAtendimento > -1){
          novaLista.splice(indiceAtendimento, 1);
      }
      if(!(atendimento.nome_atendente)){
        atendimento.nome_atendente = resolverNomeAtendente(atendimento.atendente);
      }
      novaLista.unshift(atendimento);
      setListaAtendimentos(novaLista);
      atualizarTotaisAtendimentos()
    }
  }

  const handleRemoveLista = (atendimento) => {
    const novaLista = listaAtendimentos.slice();
    if(atendimento && atendimento.id){
      let indiceAtendimento = listaAtendimentos.findIndex(atendimentoLista => atendimentoLista.id && (Number(atendimentoLista.id) === Number(atendimento.id)))
      if(indiceAtendimento >= 0){
          novaLista.splice(indiceAtendimento, 1);
          atualizarTotaisAtendimentos()
          setListaAtendimentos(novaLista);
      }
    }
  }

  const handleAtualizaLista = useCallback((novosFiltros = null) => {
    if(!novosFiltros){
      novosFiltros = Object.assign({}, filtro);
    }
    filtrarListaAtendimentos(novosFiltros)
  }, [filtro]);

  const handleClearFiltro = function () {
    let dataAtual = new Date();
    localStorage.removeItem("@DommusCA-filtros");
    localStorage.removeItem("@DommusCA-filtrosCount");
    localStorage.removeItem("@DommusCA-modificadorCodigo");
    localStorage.removeItem("@DommusCA-modificadorContato");
    localStorage.removeItem("@DommusCA-modificadorProponenteEnvolvido");
    caAPI.setLocalState('filtros', JSON.stringify({ data_inicio: dataAtual, data_fim: dataAtual }));
    caAPI.setLocalState('filtrosCount', JSON.stringify({ data_inicio: dataAtual, data_fim: dataAtual }));
    setFiltro({ data_inicio: dataAtual, data_fim: dataAtual });
    setOpenFiltros(false);
  }

  const handleReloadAtendimento = function () {
    if(atendimentoAtivo && atendimentoAtivo.id){
      caAPI.atendimento.find(atendimentoAtivo.id).then(res => {
          setAtendimentoAtivo(res.data);
        }).catch(err => {
      })
    }

  }

  const atualizarAtendimentoLista = (indice, atendimento) => {
    const novaLista = listaAtendimentos.slice();
    novaLista.splice(indice, 1, atendimento);
    setListaAtendimentos(novaLista);
  };

  async function carregaConfiguracoesDoUsuario() {
    const filtros = await trackPromise(PerfilUsuarioService.show(caAPI.getLocalState('idUsuario') || 0))
      .then(res => {
        setAssinaturaUsuario(JSON.parse(res.data.assinatura))
        if(res.data?.filtros_fixados) {
          return res.data.filtros_fixados
        }
      })
      .catch(error => {
        Swal.fire({
          titleText: "Ooops...",
          text: `Houve um problema ao buscar as configurações do usuário. ${error}`,
          icon: 'error'
        })
      })
    return filtros
  }

  const checkExisteIdEmLista = (idAtendimento) => {
    let indiceAtendimento = listaAtendimentos.findIndex(atendimentoLista => atendimentoLista.id && (Number(atendimentoLista.id) === Number(idAtendimento)));
    return (indiceAtendimento > -1);
  }

  const limparAtendimentoAtivo = () => {
    setAtendimentoAtivo(null);
  }

  const atualizarMensagensMesWhatsapp =useCallback( () => {
    if(atendimentoAtivo && atendimentoAtivo.id){
      trackPromise(caAPI.atendimento.whatsapp.atualizarMensagensMes(atendimentoAtivo.id).then(res => {
          const novoMensagens = (Array.isArray(res.data) && res.data.slice()) || null;
          setMensagensAtualizadasWhatsapp(novoMensagens);
      }).catch(err => {
        Swal.fire({
          titleText: "Erro",
          text: "Não foi possivel buscar as mensagens do último mês",
          icon: 'error'
        });
      }))
    }
  }, [atendimentoAtivo]);

  return (
    <AtendimentoContext.Provider value={{
      handleAdiconaLista,
      handleAtualizaLista,
      handleClearFiltro,
      handleReloadAtendimento,
      handleRemoveLista,
      atualizaLista,
      atendimentoAtivo,
      setAtendimentoAtivo,
      listaAtendimentos,
      setListaAtendimentos,
      filtrarListaAtendimentos,
      openFiltros,
      setOpenFiltros,
      filtro,
      totalAtendimentos,
      naoAtendidos,
      pagina,
      setPagina,
      fetchingLista,
      setFetchingLista,
      atualizarAtendimentoLista,
      loadingMensagens,
      setLoadingMensagens,
      filtrosFixados,
      setFiltrosFixados,
      carregaConfiguracoesDoUsuario,
      assinaturaUsuario,
      setAssinaturaUsuario,
      checkExisteIdEmLista,
      limparAtendimentoAtivo,
      setExportarFiltros,
      atualizarMensagensMesWhatsapp,
      mensagensAtualizadasWhatsapp,
      setMensagensAtualizadasWhatsapp,
      filtroSomenteNaoLidas, 
      setFiltroSomenteNaoLidas,
      atualizarTotaisAtendimentos
    }}>
      {children}
    </AtendimentoContext.Provider>
  )
}
