import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import Swal from 'sweetalert2'
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import caAPI from "../../../../services/caAPI";
import './style.scss';
import moment from "moment";
import { InputMensagens } from '../../../../components/InputMensage';
import Templates from './templates';
import RecebeImages from './Mensagem/Images';
import RecebeVideo from './Mensagem/Video';
import RecebeAudio from './Mensagem/Audio';
import RecebeText from './Mensagem/Text';
import InfiniteScroll from "react-infinite-scroll-component";
import FileDownload from "./Mensagem/FileDownload";
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
const templateMensagens = 'facebook-messenger';

export default function FacebookMessengerComponent() {

    const [mensagemInput, setMensagemInput] = useState('');
    const [iniciado, setIniciado] = useState(false);
    const [enviarTemplate, setEnviarTemplate] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const messageContainerRef = useRef();
    const [arquivos, setArquivos] = useState({});
    const [disabledInputMessage, setDisabledInputMessage] = useState(false);
    const {atendimentoAtivo, setAtendimentoAtivo, setLoadingMensagens} = useContext(AtendimentoContext);
    const [listaMensagens, setListaMensagens] = useState([]);

    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }

    function recebeArquivo(e) {
        setArquivos(e);
    }

    function verificaJanelaAtendimento() {
        if(atendimentoAtivo.conteudos.length > 0){
          setIniciado(true)
          setEnviarTemplate(false);
          setDisabledInputMessage(false);
        }else{
          setIniciado(false);
          setDisabledInputMessage(true);
        }
    }

    useEffect(() => {
      setListaMensagens([]);
        setDisabledInputMessage(true)
        setIniciado(false)
        setListaMensagens(atendimentoAtivo.conteudos);
        setHasMore(atendimentoAtivo.hasMore);
        verificaJanelaAtendimento()
    }, [atendimentoAtivo]);

    const { channel } = useContext(WebSocketContext);

    useEffect(() => {
        channel.unbind('facebook-messenger.' + atendimentoAtivo.id);
        channel.bind('facebook-messenger.' + atendimentoAtivo.id, function (data) {
            const newMessage = data.mensagem;
            setListaMensagens(messages => [...messages, newMessage]);
            disabledInputMessage && setDisabledInputMessage(false)
        });

    }, [channel, atendimentoAtivo, disabledInputMessage ])

    const enviarMensagem = useCallback(() => {
        if (!mensagemInput) {
            Swal.fire({
                titleText: "Atenção",
                text: "Escreva a mensagem antes de enviar",
                icon: 'warning'
            });
            return;
        }
        trackPromise(
            caAPI.atendimento.facebookMessenger.send({ id_atendimento: atendimentoAtivo.id, body: mensagemInput }).then(res => {
                setListaMensagens(messages => [...messages, (res.data.conteudo)]);
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
        setMensagemInput('');
    }, [atendimentoAtivo, mensagemInput]);

    const enviarImagem = useCallback(() => {
        if (!arquivos) {
            Swal.fire({
                titleText: "Atenção",
                text: "Nenhuma imagem selecionada",
                icon: 'warning'
            });
            return;
        }
        arquivos.forEach((arquivo) => {
            trackPromise(
                caAPI.atendimento.facebookMessenger.sendImage({
                    id_atendimento: atendimentoAtivo.id,
                    arquivo: arquivo.conteudo,
                    body: mensagemInput,
                    tipo: arquivo.tipo,
                    contato_destino: atendimentoAtivo.contato_destino
                }).then(res => {
                    setListaMensagens(messages => [(res.data.conteudos), ...messages]);
                    setMensagemInput('');
                }).catch(e => {
                    Swal.fire({
                        titleText: "Erro",
                        text: "Houve um problema ao enviar a mensagem " + e,
                        icon: 'error'
                    });
                })

            )
        })
        setArquivos("");
    }, [atendimentoAtivo, arquivos]);

    function recebeMensagemInicial(mensagemInicial) {
        trackPromise(
            caAPI.atendimento.whatsapp.enviar({ id_atendimento: atendimentoAtivo.id, body: mensagemInicial, template: true }).then(res => {
              const novoConteudos = atendimentoAtivo.conteudos.slice();
              novoConteudos.push(res.data.conteudos[0]);
              const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
              novoAtendimentoAtivo.conteudos = novoConteudos;
              setAtendimentoAtivo(novoAtendimentoAtivo);
            }).catch(err => {
                console.log(err)
                console.log(err.response)
                if(err.response?.status == 422) {
                    Swal.fire('Ooops...', err.response?.data?.message, 'warning')
                    
                    return
                }
                Swal.fire({
                    titleText: "Erro",
                    text: "Ocorreu um problema ao iniciar o atendimento.",
                    icon: 'error'
                });
            })
        )
    }

    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    const scrollDown = function () {
        messageContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const mensagens = useMemo(() => {
        // listaMensagens.length !== 0 ? setIniciado(true) : setIniciado(false);

        if (listaMensagens) {
            let listIterar = listaMensagens.filter(msg => msg && msg.detalhes).reverse();
            let date = null;
            let separator = null;
            let isLast = true;
            setLoadingMensagens(false);

            return listIterar.map(msg => {
                const detalhes = JSON.parse(msg.detalhes) || {type: 'text'};
                let msgContent;

                switch (detalhes.type) {
                    case 'img':
                        msgContent =
                            <RecebeImages conteudo={msg.conteudo} url={detalhes.url} />
                        break;
                    case 'video':
                        msgContent =
                            <RecebeVideo conteudo={msg.conteudo} url={detalhes.url} />
                        break;
                    case 'audio':
                        msgContent =
                            <RecebeAudio conteudo={msg.conteudo} url={detalhes.url} />
                        break;
                    case 'text':
                        msgContent =
                            <RecebeText conteudo={msg.conteudo} />
                        break;

                    case 'file':
                    case 'msword':
                    case 'pdf':
                        detalhes.url ?
                            msgContent = <FileDownload conteudo={detalhes} url={detalhes.url} />
                            :
                            msgContent = <RecebeText conteudo={msg.conteudo} />
                        break;

                    default:
                        msgContent = <RecebeText conteudo={msg.conteudo} />
                        break;
                }

                let msg_date = moment(msg.criado_em).format('DD/MM/YYYY');
                if (!date || date !== msg_date) {
                    separator = <div className="separador">{date}</div>;
                    date = msg_date;
                } else {
                    separator = '';
                    isLast = false;
                }

                return <Fragment key={msg.id}>
                    {!isLast && separator}
                    <div className={'data ' + (msg.direcao === 'recebido' ? 'recebida' : 'enviada')}>
                        {moment(msg.criado_em).format('HH:mm')}
                    </div>
                    <div className={'mensagem-item ' + (msg.direcao === 'recebido' ? 'recebida' : 'enviada')}>
                        {msgContent}
                    </div>
                </Fragment>
            });
        }

        scrollDown()
    }, [listaMensagens]);

    const handleLoadMoreMessages = () => {
        const messages = [...listaMensagens];
        setLoadingMensagens(true);
        caAPI.atendimento.getMessages(atendimentoAtivo.id, Number(currentPage) + 1).then(res => {
            messages.push(res.data);
            setCurrentPage(res.data.currentPage)
            setListaMensagens([...res.data.conteudos, ...listaMensagens]);
            setHasMore(res.data.hasMore);

        }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar mais mensagens",
                icon: 'error'
            });
            return;
        });

    };

    return (
        <>

            <div className="facebook-container" id="scrollableDiv" >
            { enviarTemplate &&
              <>
                  <Templates recebeMensagemInicial={recebeMensagemInicial} />
              </>
            }
                {iniciado &&
                    <>
                        <InfiniteScroll
                            className="whatsapp-container"
                            style={{ display: 'flex', flexDirection: 'column-reverse' }}
                            dataLength={mensagens.length}
                            next={handleLoadMoreMessages}
                            hasMore={hasMore}
                            inverse={true}
                            loader={<Spinner animation="border" style={{ color: '#265d9c' }} />}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Fim das Mensagens</b>
                                </p>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={() => { }}
                            pullDownToRefresh
                            pullDownToRefreshThreshold={50}
                            scrollableTarget="scrollableDiv"

                        >
                            {mensagens}
                        </InfiniteScroll>
                    </>
                  }

            </div>
            <div ref={messageContainerRef} />
            <div>
                {(iniciado && !(enviarTemplate) && (atendimentoAtivo.situacao_atendimento && atendimentoAtivo.situacao_atendimento.tipo !== 'F')) &&
                    <InputMensagens
                        disabledInputMessage={disabledInputMessage}
                        disabledEmoticons={disabledInputMessage}
                        disabledAnexos={disabledInputMessage}
                        disabledAnotacoes={disabledInputMessage}
                        disabledMenu={false}
                        templateMensagens={templateMensagens}
                        mensagemInput={mensagemInput}
                        setLegendaInput={setMensagemInput}
                        recebeArquivo={recebeArquivo}
                        enviarImagem={enviarImagem}
                        enviarMensagem={enviarMensagem}
                        receberMensagemInicial={enviarMensagem}
                        atendimento={atendimentoAtivo}
                        recebeMensagemInput={recebeMensagemInput}
                        changeSubmitKey={changeSubmitKey} />
                }
            </div>
        </>
    );
}
