import api from './api';

const TicketMotivo = {
	index:()=>{
		return api.get('/ticket/motivo');
	},
	show:(id)=>{
		return api.get('/ticket/motivo/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket/motivo/' + id;
        return api['put'].call(api, uri, {motivo: dados});
    },
	store: (dados) => {
        let uri = '/ticket/motivo';
        return api['post'].call(api, uri, {motivo: dados});
    },
};

export default TicketMotivo;
