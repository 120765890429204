const CommonService =  function(){

    this.arredondarDecimal = function(num){
        return +(Math.round(Number(num) + "e+2")  + "e-2");
    }

    this.limparMascaraCep = function (cep) {
        if (cep) {
            return cep.replace('.', '').replace('-', '');
        }
    };

    this.lerDataPb = function (dataEn) {
        let date = new Date(dataEn);
        return date.getUTCDate() + "/" + (date.getUTCMonth() + 1).toString() + "/" + date.getUTCFullYear();
    };

    this.formatarDeMascaraMoeda = function (strValue) {
      if (strValue !== undefined) {
          return Number(strValue.toString().replace(/\./g, '').replace(',', '.'));
      }

      return null;
    };

    this.formatarParaMascaraMoeda = function (value) {
      if (value === undefined) {
          value = '0';
      }
      return Number(value).toFixed(2).toString().replace('.', ',');
    };

    this.formatarParaMascaraMoedaComSeparadorDeMilhares = function (value) {
      if (value === undefined) {
          value = '0';
      }

      let valor = Number(value);
      let valorAbsoluto = Math.abs(valor);
      if (valorAbsoluto < 1000) {
          return this.formatarParaMascaraMoeda(value);
      }
      let strComDecimais = valorAbsoluto.toFixed(2).toString();
      let arrInteiroSeparadoDosDecimais = strComDecimais.split('.');
      arrInteiroSeparadoDosDecimais[0] = arrInteiroSeparadoDosDecimais[0].split('').reverse().join('').match(/.{1,3}/g).join('.').split('').reverse().join('');
      let sinal = valor < 0 ? '-' : ''
      return sinal + arrInteiroSeparadoDosDecimais.join(',');
    };

    this.formatarDeMascaraPercent = function (strValue) {
      if(!(strValue)){
        strValue = 0;
      }
        return Number(strValue.toString().replace(',', '.'));
    };

    this.formatarParaMascaraPercent = function (value) {
      if (value === undefined) {
          value = '0';
      }
      return Number(value).toFixed(2).toString().replace('.', ',');
    };

    this.formatarParaMascaraCep = function (value) {
      if(!value){
        return '';
      }
      let strCep = value.toString();
      let partesCep = [];
      partesCep.push(strCep.substr(0, 2));
      partesCep.push(strCep.substr(2, 3));
      let sufixoCep = strCep.substr(5, 3);
      return partesCep.join('.') + '-' + sufixoCep;
    };

    this.formatarParaMascaraCpf = function (value) {
      if(value){
          let strCpf = value.toString();
          let partesCpf = [];
          partesCpf.push(strCpf.substr(0, 3));
          partesCpf.push(strCpf.substr(3, 3));
          partesCpf.push(strCpf.substr(6, 3));
          let sufixoCpf = strCpf.substr(9, 2);
          return partesCpf.join('.') + '-' + sufixoCpf;
      }
      return null;
    };

    this.formatarDeMascaraCpf = function (value) {
        return ( value && value.toString().replace(/\./g, '').replace(/-/g, '') ) || null;
    };

    this.formatarParaMascaraCNPJ = function (value) {
      if(!value){
        return '';
      }
      let strCnpj = value.toString();
      let partesCnpj = [];
      partesCnpj.push(strCnpj.substr(0, 2));
      partesCnpj.push(strCnpj.substr(2, 3));
      partesCnpj.push(strCnpj.substr(5, 3));
      let milInvertidoCnpj = strCnpj.substr(8, 4);
      let sufixoCnpj = strCnpj.substr(12, 2);
      return partesCnpj.join('.') + "/" + milInvertidoCnpj + '-' + sufixoCnpj;
    };

    this.formatarDeMascaraCNPJ = function (value) {
        return  value.toString().replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
    };

    this.formatarParaMascaraPhone = function (value) {
      if(value && value.charAt(0) === '+'){
        if(value.substring(0,3) === '+55'){
          value = value.substring(3);
        }else{
          return value;
        }
      }
      if(value){
        let strPhone = value.toString();
        let partesPhone = [];
        let ddd = (strPhone.substr(0, 2));
        partesPhone.push(strPhone.substr(2, strPhone.length - 6));
        partesPhone.push(strPhone.substr(-4));

        return "(" + ddd + ") " + partesPhone.join("-");
      }
      return '';
    };


    this.formatarDeMascaraPhone = function (value) {
        return value.toString().replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/\s+/g, '');
    };

    this.formatarDeDataBr = function (dataBr) {
        if ((dataBr || null) === null) {
            return null;
        }
        let arrData = dataBr.split('/');
        return new Date(arrData[2], (arrData[1] - 1), arrData[0]);
    };

    this.formatarDeDataHoraBr = function (dataBr) {

        if ((dataBr || null) === null) {
            return null;
        }

        if (dataBr._isAMomentObject || false) {
            if(dataBr.year() || false){
                return dataBr.year().toString() +
                    '-' + (dataBr.month() + 1).toString().padStart(2, '0') +
                    '-' + (dataBr.date()).toString().padStart(2, '0') +
                    'T' + (dataBr.hours()).toString().padStart(2, '0') +
                    ':' + (dataBr.minutes()).toString().padStart(2, '0') +
                    ':' + (dataBr.seconds()).toString().padStart(2, '0') +
                    '.' + (dataBr.milliseconds() / 1000).toFixed(3).slice(2, 5) +
                    'Z';
            }
            return null;
        }

        let arrDataHora = dataBr.split(' ');
        let arrData = arrDataHora[0].split('/');
        let arrHora = arrDataHora[1].split(':');
        return new Date(arrData[2], (arrData[1] - 1), arrData[0], arrHora[0], arrHora[1], arrHora[2]);
    };

    this.formatarParaDataBr = function (objData) {
        if (((objData || null) !== null) && objData !== "0000-00-00" && objData !== "0000-00-00 00:00:00" && objData !== "0000-00-00T00:00:00Z") {
            if (!Date.isPrototypeOf(objData)) {
                objData = new Date(objData);
            }
            return objData.getUTCDate().toString().padStart(2, '0') + "/" + (objData.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + objData.getUTCFullYear().toString();
        }

        return null;
    };

    this.formatarParaDataHoraBr = function (objData) {
        if (objData !== undefined && objData !== null && objData !== "0000-00-00 00:00:00" && objData !== "0000-00-00T00:00:00Z") {
            if (!Date.isPrototypeOf(objData)) {
                objData = new Date(objData);
            }
            return objData.getUTCDate().toString().padStart(2, '0') + "/" + (objData.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + objData.getUTCFullYear().toString() + " " + objData.getHours().toString().padStart(2, '0') + ":" + objData.getMinutes().toString().padStart(2, '0') + ":" + objData.getSeconds().toString().padStart(2, '0');
        }

        return null;
    };

    this.formatarParaMascaraMoedaComSeparadorRomano = function (value) {
        let valorNumerico = Number(value || 0);
        let valorComVirgula = this.formatarParaMascaraMoedaComSeparadorDeMilhares(valorNumerico);
        let valorExplodido = valorComVirgula.split('.');
        if (valorNumerico === 0) {
            return valorComVirgula;
        }
        if (valorNumerico >= 1000000000) {
            valorExplodido.pop();
            valorExplodido.pop();
            valorExplodido.pop();

            return valorExplodido.join('.').toString() + 'BI';

        }
        if (valorNumerico >= 1000000) {
            valorExplodido.pop();
            valorExplodido.pop();

            return valorExplodido.join('.').toString() + 'MI';
        }
        if (Math.abs(valorNumerico) > 1000) {
            valorExplodido.pop();
            return valorExplodido.join('.').toString() + 'K';
        }

        return valorComVirgula;
    };

    // ORDENA UM OBJETO ESPECIFICO DE FORMA ASCEDENTE.
    this.orderByAsc = function(objeto, params){

        let referencia = params.referencia;

        if(params.conversion){

            switch(params.conversion){

                case 'number':
                    objeto.sort((a, b) => Number(a[referencia]) - Number(b[referencia]));
                break;

                case 'float':
                    objeto.sort((a, b) => parseFloat(a[referencia]) - parseFloat(b[referencia]));
                break;

                default:
                    objeto.sort((a, b) => a[referencia] - b[referencia]);
                break;
            }
        }
        else{
            objeto.sort((a, b) => a[referencia] - b[referencia]);
        }

        return objeto;
    }

    // ORDENA UM OBJETO ESPECIFICO DE FORMA ASCEDENTE.
    this.orderByDesc = function(objeto, params){

        let referencia = params.referencia;

        if(params.conversion){

            switch(params.conversion){

                case 'number':
                    objeto.sort((a, b) => Number(b[referencia]) - Number(a[referencia]));
                break;

                case 'float':
                    objeto.sort((a, b) => parseFloat(b[referencia]) - parseFloat(a[referencia]));
                break;

                default:
                    objeto.sort((a, b) => b[referencia] - a[referencia]);
                break;
            }
        }
        else{
            objeto.sort((a, b) => b[referencia] - a[referencia]);
        }

        return objeto;
    }
};

export default new CommonService();
