import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./index.scss";
import {Alert, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { AtendimentoContext } from "../../../../contexts/AtendimentoContext";
import caAPI from "../../../../services/caAPI";
import MensagemConteudoAtendimentoWhatsapp from "../../../MensagemConteudoAtendimentoWhatsapp";
import { MensagemConteudoEmail } from "./MensagemConteudoEmail";

export function MostrarConteudoAtendimentos({ atendimento }) {
  const [listaMensagens, setListaMensagens] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { setLoadingMensagens } = useContext(AtendimentoContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    trackPromise(caAPI.atendimento.getMessages(atendimento.id, 1)).then(
      (response) => {
        setListaMensagens(response.data.conteudos);
        setCurrentPage(response.data.currentPage);
        setHasMore(response.data.hasMore);
      }
    );
  }, [atendimento]);

  const handleLoadMoreMessages = () => {
    const messages = [...listaMensagens];
    setLoadingMensagens(true);
    caAPI.atendimento
      .getMessages(atendimento.id, Number(currentPage) + 1)
      .then((res) => {
        messages.push(res.data.conteudos);
        setCurrentPage(res.data.currentPage);
        setListaMensagens([...res.data.conteudos, ...listaMensagens]);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar mais mensagens",
          icon: "error",
        });
        return;
      });
  };

  const resolveTipoMensagem = (mensagem) => {
    switch (atendimento?.meio_comunicacao?.slug) {
      case "whatsapp":
      case "sms":
        return <MensagemConteudoAtendimentoWhatsapp conteudo={mensagem} />;
      case "email":
        return <MensagemConteudoEmail mensagem={mensagem} />
      default:
        return <Alert variant="warning">Não possui mensagem</Alert>;
    }
  };

  const mensagens = useMemo(() => {
    if (listaMensagens) {
      let listIterar = Array.from(listaMensagens.values())
        .filter((mensagem) => mensagem && mensagem?.detalhes)
        .reverse();
      let date = null;
      setLoadingMensagens(false);
      return listIterar.map((mensagem, indice) => {
        let isLast = indice === listIterar.length - 1;
        let separator = false;
        let mensagem_date = moment(mensagem.criado_em).format("DD/MM/YYYY");
        if (!date) {
          date = mensagem_date;
        }
        if (date !== mensagem_date || isLast) {
          separator = <div className="separador">{date}</div>;
          date = mensagem_date;
        }
        return (
          <Fragment key={mensagem.id}>
            {!isLast && separator}
            {resolveTipoMensagem(mensagem)}
            {isLast && separator}
          </Fragment>
        );
      });
    }
  }, [listaMensagens, resolveTipoMensagem]);
  return (
    <div className="mensagens-container">
      <div className="whatsapp-container" id="scrollableDiv">
        <InfiniteScroll
          className="whatsapp-container"
          style={{ display: "flex", flexDirection: "column-reverse" }}
          dataLength={mensagens.length}
          next={handleLoadMoreMessages}
          hasMore={hasMore}
          inverse={true}
          loader={<Spinner animation="border" style={{ color: "#265d9c" }} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Fim das Mensagens</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={() => {}}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        >
          {mensagens}
        </InfiniteScroll>
      </div>
    </div>
  );
}
