import React from 'react';
import {Modal} from 'react-bootstrap';
import './index.css';

export function DommusModalFullScreen({children, ...props}) {

	return <Modal className="fullscreen-modal" show={props.open} onHide={props.close}>
		<Modal.Header closeButton>
			<Modal.Title><h2>{props.titulo}</h2></Modal.Title>
		</Modal.Header>
		<Modal.Body className='conteudo'>
				{children}
		</Modal.Body>
	</Modal>;
}
