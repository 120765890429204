import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorToast } from "../../../../components/DommusToast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, ListGroup } from "react-bootstrap/esm/index";
import { AtendentesContext } from "../../../../contexts/AtendentesContext";
import {
  faSearch,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ModalQualificacoes } from "../../../../components/ModalQualificacoes";

export default function Lista({
  atendentes,
  infosAtendentes,
  dropEndHandler,
  excluirOcorrenciaFilaAtendimento,
}) {
  const [openModalQualificacoes, setModalQualificacoes] = useState(false);
  const [atentendeOpenQualificacao, setAtendenteQualificacao] = useState();
  const { resolverNomeAtendente } = useContext(AtendentesContext);
  const onDragEnd = ({ source, destination }) => {
    if (source && destination && source.index !== destination.index) {
      if (
        (atendentes[source.index].id || null) !==
        (atendentes[destination.index].id || null)
        ) {
          dropEndHandler(source.index, destination.index);
        } else {
          errorToast.fire({ text: "Você não pode trocar esses operadores." });
        }
      }
    };
    
  const verificaStatus = (item) => {
    if (item.perfil_usuario){
      const agora = new Date();
      const visto_ultimo = new Date(item.perfil_usuario.visto_ultimo);
      const vistoUltimoComOffset = new Date(visto_ultimo.getTime() + ( (item.tempo_configuracao || 15) * 60 * 1000 ));
      if (vistoUltimoComOffset > agora) {
        return "#4caf50";
      } else{
        return "#f44336";
      }
    }
    return "black";
  }

  return (
    <>
      <ModalQualificacoes
        openModal={openModalQualificacoes}
        closeModal={() => setModalQualificacoes(false)}
        atendente={atentendeOpenQualificacao}
      />
      <Card>
        {atendentes.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"dropFila"}>
              {(provided) => (
                <ListGroup ref={provided.innerRef} {...provided.droppableProps}>
                  {infosAtendentes.map((item, index) => {
                    const bgStyleDiv = {
                      backgroundColor: index % 2 === 0 ? "snow" : "whitesmoke",
                    };

                    return (
                      <div style={bgStyleDiv} key={item.id_usuario}>
                        <Draggable
                          draggableId={item.id_usuario.toString()}
                          index={index}
                        >
                          {(provided) => {
                            const bgStyle = {
                              ...provided.draggableProps.style,
                              backgroundColor:
                                index % 2 === 0 ? "snow" : "whitesmoke",
                            };

                            return (
                              <ListGroup.Item
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={bgStyle}
                                ref={provided.innerRef}
                              >
                                <div
                                  className={"d-flex justify-content-between"}
                                >
                                  <span>
                                  {/* <DommusOnlineFlag/> */}
                                    <FontAwesomeIcon
                                      icon={faUserCircle}
                                      style={{
                                        marginRight: "15px",
                                        color: verificaStatus(item),
                                        backgroundColor: "#fff",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    #{String(index + 1).padStart(4, "0")} -{" "}
                                    {resolverNomeAtendente(
                                      item.id_usuario
                                    ).toUpperCase()}
                                  </span>
                                  <div
                                    className={
                                      "d-sm-flex justify-content-around"
                                    }
                                    style={{ width: !item.id_usuario && "2%" }}
                                  >
                                      {
                                        item.qualificacao_atendente.contatos_origem.length?
                                          <div
                                            style={{
                                              width: "25%",
                                              marginRight: item.id_usuario && "15px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faSearch}
                                              style={{ color: "blue" }}
                                              cursor={"pointer"}
                                              onClick={() => {
                                                setAtendenteQualificacao(item);
                                                setModalQualificacoes(true);
                                              }}
                                              title="Qualificações"
                                            />
                                          </div>                                      
                                      :
                                      <div  className="text-right"
                                            style={{
                                              width: "10rem",
                                            }}
                                          >
                                      <span style={{fontSize: "12px"}} className="font-weight-bold">Sem qualificações</span>
                                      </div>
                                      }                                      
                                    <div className="text-right" style={{ width: "25%" }}>
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ color: "red" }}
                                        cursor={"pointer"}
                                        onClick={() => {
                                          excluirOcorrenciaFilaAtendimento(
                                            item.id_fila_atendentes,
                                            index
                                          );
                                        }}
                                        title="Excluir"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </ListGroup>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Card>
    </>
  );
}
