import { Icon } from "@iconify/react";
import mailSendLine from "@iconify/icons-ri/mail-send-line";
import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { ContextAwareToggle } from "../../../../../pages/PortalCliente/DetalheAtendimento/EmailComponent";
import caAPI from "../../../../../services/caAPI";
import moment from "moment";
import { Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";
import "./index.scss"

export function MensagemConteudoEmail({mensagem}) {
  const detalhes = JSON.parse(mensagem.detalhes).data;
  const transformarAnchor = (node: HTMLElement, children: Node[]) => {
    if (node.tagName === "a" || node.tagName === "A") {
      return (
        <a href={"https://" + node.getAttribute("href")} target="_blank">
          {children}
        </a>
      );
    }
  };

  const handleDownloadAttachment = (attachment) => {
    caAPI.atendimento.email.getAnexo(attachment.file_name).then((res) => {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = res.data.url;
      a.click();
    });
  };
  return (
    <div className="email" key={mensagem.id}>
      <div className="header email-header">
        <Card>
          <Accordion>
            <div className="accordion_iten">
              <ContextAwareToggle
                as={Card.Header}
                cardName={mensagem.id}
                title={detalhes.assunto}
                eventKey={mensagem.id}
              >
                <Row>
                  <Col>
                    <div className="dadosAssunto">
                      {mensagem.direcao === "enviado" ? (
                        <Icon
                          className="iconeEnviadoRecebido"
                          icon={mailSendLine}
                        />
                      ) : (
                        <Icon
                          className="iconeEnviadoRecebido"
                          icon={mailSendLine}
                          hFlip={true}
                        />
                      )}
                      {detalhes.assunto}
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="data">
                      {moment(mensagem.criado_em).format("DD/MM/YYYY HH:mm")}
                    </div>
                  </Col>
                </Row>
              </ContextAwareToggle>
            </div>
            <Accordion.Collapse eventKey={mensagem.id}>
              <div className="dadosEnvio">
                {mensagem.direcao === "enviado" ? (
                  <div className="dadosPara">Para: {detalhes.to} </div>
                ) : (
                  <div className="dadosDe">De: {detalhes.from}</div>
                )}
                {detalhes.cc ? (
                  <div className="dadosCopiaCortesia">
                    CC:{" "}
                    <span className="emailCopiaCortesia"> {detalhes.cc} </span>{" "}
                  </div>
                ) : (
                  <></>
                )}
                {detalhes.bcc ? (
                  <div className="dadosCopiaCortesiaOculta">
                    CCO:{" "}
                    <span className="emailCopiaCortesiaOculta">
                      {detalhes.bcc}{" "}
                    </span>{" "}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </Card>
      </div>
      <div className="body" style={{ overflow: "hidden" }}>
        <Interweave
          content={mensagem.conteudo.substring(0, 500)}
          transform={transformarAnchor}
          matchers={[new UrlMatcher("url", { validateTLD: false })]}
        />
        {detalhes.attachments && (
          <div style={{ backgroundColor: "#ebebeb" }}>
            {detalhes.attachments.map(function (attachment, indice) {
              return (
                <span
                  key={indice}
                  onClick={() => handleDownloadAttachment(attachment)}
                  style={{
                    padding: "15px",
                    cursor: "pointer",
                    fontSize: "0.8em",
                  }}
                >
                  {attachment.file_name}
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
