import React from 'react';
import { Icon } from '@iconify/react';

export default function AnexoIcone({ anexo, ...rest }) {
    if (anexo === undefined) return <></>;
    const anexoMap = {
        pdf: <Icon icon="vscode-icons:file-type-pdf2" {...rest} />,
        doc: <Icon icon="vscode-icons:file-type-word2" {...rest} />,
        docx: <Icon icon="vscode-icons:file-type-word" {...rest} />,
        xlt: <Icon icon="vscode-icons:file-type-excel2" {...rest} />,
        xls: <Icon icon="vscode-icons:file-type-excel2" {...rest} />,
        xlsx: <Icon icon="vscode-icons:file-type-excel" {...rest} />,
        ppt: <Icon icon="vscode-icons:file-type-powerpoint2" {...rest} />,
        pptx: <Icon icon="vscode-icons:file-type-powerpoint" {...rest} />,
        pps: <Icon icon="vscode-icons:file-type-powerpoint2" {...rest} />,
        ppx: <Icon icon="vscode-icons:file-type-powerpoint" {...rest} />,
        default: <Icon icon="vscode-icons:default-file" {...rest}/>,
    }

    let selecionado = anexoMap[anexo];

    if (!selecionado) return anexoMap["default"];

    return selecionado;
}
