import React from "react";
import { Card, CardDeck, Col } from "react-bootstrap";
import Facebook from "./Facebook";
import "./index.css";


export default function Integracoes() {

  return (
    <div className="integracao_pagina">
      <div className="row">
        <div className="col-12">
          <div className="tituloPagina">Integrações</div>
        </div>
      </div>
      <CardDeck>
        <Card className="p-4 cardIntegracao">
            <Col md="3" className="border p-0 pb-3">
                <Card.Header>Facebook</Card.Header>
                <Facebook />
            </Col>
        </Card>
      </CardDeck>
    </div>
  );
}

