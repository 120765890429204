import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const SituacaoAtendimentoContext = createContext({});

export function SituacaoAtendimentoProvider({ children }) {
  const [situacoesAtendimento, setSituacoesAtendimento] = useState([]);

  const [mapaSituacoesAtendimento, setMapaSituacoesAtendimento] = useState([]);
  
  useEffect(()=>{
    const novoMapa = new Map();
    situacoesAtendimento.forEach((meioComunicacao) => {
      novoMapa.set(Number(meioComunicacao.id), meioComunicacao);
    });
    setMapaSituacoesAtendimento(novoMapa);
  }, [situacoesAtendimento]);

  const recarregarSituacoesAtendimento = ()=>{
    caAPI.situacao.list().then(res => {
        setSituacoesAtendimento(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar as situações de atendimento  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeSituacaoAtendimento = (idSituacaoAtendimento, padrao = "Não Atribuído") => {
    let situacaoAtendimento = mapaSituacoesAtendimento.get(Number(idSituacaoAtendimento));
    return (situacaoAtendimento && situacaoAtendimento.nome) || padrao;
  };

  return (
    <SituacaoAtendimentoContext.Provider value={{
      situacoesAtendimento,
      recarregarSituacoesAtendimento,
      resolverNomeSituacaoAtendimento
    }}>
      {children}
    </SituacaoAtendimentoContext.Provider>
  )
}
