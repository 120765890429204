import React, { createContext, useState, useEffect, useContext} from "react";
import { SituacaoAtendimentoContext } from "./SituacaoAtendimentoContext";
import { TagsContext } from "./TagsContext";
import { TipoAtendimentoContext } from "./TipoAtendimentoContext";

export const ItensAtalhoContext = createContext({});

export function ItensAtalhoProvider({ children }) {
  const [ItensAtalhoLista, setItensAtalhoLista] = useState([]);
  const {tagsLista} = useContext(TagsContext);
  const {situacoesAtendimento} = useContext(SituacaoAtendimentoContext);
  const {tiposAtendimento} = useContext(TipoAtendimentoContext);

  useEffect(()=>{
    recarregarItensAtalho();
  },[tagsLista, situacoesAtendimento, tiposAtendimento]);

  const recarregarItensAtalho = ()=>{
    let tempLista = [];
    situacoesAtendimento.forEach((situacao)=>{
        tempLista.push({
            id: situacao.id,
            nome: situacao.nome,
            categoria: "statusDoAtendimento",
        });
    });    
    tiposAtendimento.forEach((tipo)=>{
        tempLista.push({
            id: tipo.id,
            nome: tipo.descricao,
            categoria: "tipoAtendimento"
        });
    });
    tagsLista.forEach((tag)=>{
        tempLista.push({
            id: tag.id_tag,
            nome: tag.nome,
            categoria: "tags",
        });
    });            

    setItensAtalhoLista(tempLista);
  };
  
  return (
    <ItensAtalhoContext.Provider value={{
      ItensAtalhoLista,
      recarregarItensAtalho,
    }}>
      {children}
    </ItensAtalhoContext.Provider>
  )
}
