import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import { trackPromise } from "react-promise-tracker";
import { Dropdown, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEye,
    faPen,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DisparoMassaService from "../../../services/DisparoMassaService";
import DommusTabelaPaginadaControlada from "../../../components/DommusTabelaPaginadaControlada";
import ModalDommus from "../../../components/Modal";
import { DisparoMassaContext } from "../../../contexts/DisparoMassaContext";
import Swal from "sweetalert2/src/sweetalert2";
import { successToast } from "../../../components/DommusToast";
import moment from "moment";
import { AgendamentoDisparoVisualizacao } from "./AgendamentoDisparoVisualizacao";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import { MeioComunicacaoContext } from "../../../contexts/MeioComunicacaoContext";
import { DommusModalFullScreen } from "../../../components/DommusModalFullScreen";

export function AgendamentoDisparoEmMassa() {
    const [openVisualizarAgendamento, setOpenVisualizarAgendamento] =
        useState(false);
    const [agendamentoSelecionado, setAgendamentoSelecionado] =
        useState(null);
    const [tamanhoPagina, setTamanhoPagina] = useState(8);
    const [total, setTotal] = useState(0);
    const [pagina, setPagina] = useState(1);    
    const {
        listaAgendamentos, 
        setListaAgendamentos,
        abrirFecharModalCadastro,
        removerAgendamento
    } = useContext(DisparoMassaContext);

    useEffect(() => {
        if(tamanhoPagina){
            recarrecarListaAgendamentos();
        }
    }, [tamanhoPagina, pagina]);

    function recarrecarListaAgendamentos() {
        trackPromise(
            DisparoMassaService.buscarListaAgendamentoDisparo(
                tamanhoPagina,
                pagina
            )
        )
            .then((response) => {
                setListaAgendamentos(response?.data?.dados);
                setTotal(response?.data?.total);
            })
            .catch((error) => {
                Swal.fire({
                    titleText: "Erro",
                    text:
                        "Houve um problema ao carregar as bases de disparo! " +
                        error,
                    icon: "error",
                });
            });
    }

    const handleRemoverAgendamento = (id) => {
        Swal.fire({
            titleText: "Deseja excluir o agendamento disparo?",
            text: "Tem certeza que deseja excluir o agendamento? Todos os disparos pendentes serão excluídos!",
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não, mantenha.",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                trackPromise(
                    DisparoMassaService.removeAgendamentoDisparo(id)
                        .then(
                            (response) => {
                                removerAgendamento(id);
                                successToast.fire({
                                    text: "Agendamento removido com sucesso!",
                                });
                            },
                            (error) => {
                                throw error;
                            }
                        )
                        .catch((error) => {
                            Swal.fire({
                                titleText: "Ooops...",
                                text:
                                    "Ocorreu um erro ao remover o agendamento: " +
                                    error,
                                icon: "error",
                            });
                        })
                );
            }
        });
    };

    const handleVisualizarAgendamento = (agendamento = null) => {
        trackPromise(DisparoMassaService.buscarAgendamento(agendamento.id)).then((response=>{
            setAgendamentoSelecionado(response.data);
            setOpenVisualizarAgendamento((current) => !current);
        })).catch((error)=>{
            Swal.fire({
                titleText: "Ooops...",
                text:
                    "Ocorreu um erro ao buscar o agendamento: " +
                    error,
                icon: "error",
            });
        })        
    };

    const cabecalhoTabela = (
        <tr>
            <th className="text-left" style={{ verticalAlign: "middle" }}>
                Nome Agendamento
            </th>
            <th className="text-center" style={{ width: "12%" }}>
                Meio de Comunicação
            </th>
            <th className="text-center" style={{ width: "10%" }}>
                Último Disparo
            </th>
            <th
                className="text-center"
                style={{ verticalAlign: "middle", width: "8%" }}
            >
                Ações
            </th>
        </tr>
    );

    const linhasTabela = useMemo(() => {
        return listaAgendamentos?.map((linha, index) => {
            let ultimosDisparos = [];
            linha?.execucao_disparos?.forEach((execucaoDisparo) => {
                let temp = execucaoDisparo?.logs_disparos.filter((log)=>log.status_execucao === 'concluido');
                if(temp.length){
                    ultimosDisparos = [...ultimosDisparos, ...temp];
                }
            });

            if(ultimosDisparos.length){
                ultimosDisparos = ultimosDisparos.sort((a,b)=>{
                    var c = new Date(a.atualizado_em);
                    var d = new Date(b.atualizado_em);
                    return d-c;
                })
            }
            return (
                <tr
                    key={`linha-${index}-${linha.dataCadastro}`}
                    className="text-center"
                >
                    <td className="text-left">{linha?.nome}</td>
                    <td className="td-meio-comunicacao">
                        <MidiaSocialIcone midia={linha?.meio_comunicacao_without_contato?.slug} />
                    </td>
                    <td className="text-center">{linha?.data_ultimo_disparo ?  moment(linha?.data_ultimo_disparo).format("DD/MM/YYYY HH:mm") : "Aguardando"}</td>
                    <td>
                        <Dropdown className="acoes-tabela">
                            <Dropdown.Toggle>
                                <FontAwesomeIcon icon={faPen} color="#fff" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleVisualizarAgendamento(linha)}
                                >
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        color="#969696"
                                        style={{ marginRight: 10 }}
                                    />
                                    Visualizar
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                    onClick={() => abrirFecharModalCadastro(null,linha)}
                                >
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        color="#B5DEFF"
                                        style={{ marginRight: 10 }}
                                    />
                                    Editar
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={() =>handleRemoverAgendamento(linha?.id)}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        color="#E05D5D"
                                        style={{ marginRight: 10 }}
                                    />
                                    Remover
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            )
        });
    }, [listaAgendamentos]);

    return (
        <>
            {listaAgendamentos.length ? (
                <DommusTabelaPaginadaControlada
                    linhasCabecalho={cabecalhoTabela}
                    optionsTabela={{}}
                    linhasCorpo={linhasTabela}
                    pagina={pagina}
                    setPagina={setPagina}
                    setTamanhoPagina={setTamanhoPagina}
                    tamanhoPagina={tamanhoPagina}
                    tamanhoTotal={total}
                    customClasses="tabela-disparo"
                />
            ) : (
                <Alert variant="warning">
                    Nenhuma Base de disparo encontrada !
                </Alert>
            )}
            <DommusModalFullScreen
                size="xl"
                titulo="Visualizar Base de Disparo"
                open={openVisualizarAgendamento}
                close={() => setOpenVisualizarAgendamento(false)}
            >
                <AgendamentoDisparoVisualizacao agendamentoSemExecucao={agendamentoSelecionado}/>
            </DommusModalFullScreen>
        </>
    );
}
