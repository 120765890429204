import React from 'react';
import { Card } from 'react-bootstrap';
import { cepMask, realMoeda} from '../../../../components/InputTextMask';

export default function Produto({ produto }) {
    return <>
        <Card.Body>
            <div className="empreendimentoProduto">
                <strong>Empreendimento: </strong>
                <br/>{produto.empreendimento_nome}
            </div>
            <div className="unidadeProduto">
                <strong>Unidade: </strong>
                <br/>{produto.unidade_descricao}
            </div>
            <div className="tipologiaProduto">
                <strong>Tipologia: </strong>
                <br/>{produto.tipo_unidade_descricao}
            </div>
            <hr/>
            <div className="areaPrivativaTotalProduto">
                <strong>Área Privativa Total: </strong>
                <br/>{produto.unidade_area_privativa_total}
            </div>
            <div className="areaExclusivaTotalProduto">
                <strong>Área Exclusiva Total: </strong>
                <br/>{produto.unidade_area_exclusiva_total}
            </div>
            <div className="vagaGaragemProduto">
                <strong>Vaga de Garagem: </strong>
                <br/>{produto.unidade_vaga_garagem}
            </div>
            <div className="previsaoEntregaProduto">
                <strong>Previsão de Entrega: </strong>
                <br/>{produto.empreendimento_banco_previsao_entrega}
            </div>
            <hr />
            <div className="valorVendaProduto">
                <strong>Valor de Venda: </strong>
                <br/>{realMoeda(produto.unidade_valor)}
            </div>
            <div className="valorAvaliacaoProduto">
                <strong>Valor de Avaliação: </strong>
                <br/>{realMoeda(produto.unidade_valor_avaliacao_banco)}
            </div>
            <hr />
            <div className="enderecoEmpreendimentoProduto">
                <strong>Endereço do Empreendimento: </strong>
                <br/>{produto.empreendimento_endereco}
            </div>
            <div className="bairroProduto">
                <strong>Bairro: </strong>
                <br/>{produto.empreendimento_bairro}
            </div>
            <div className="cidadeUFProduto">
                <strong>Cidade/UF: </strong>
                <br/>{produto.empreendimento_cidade} / {produto.empreendimento_uf}
            </div>
            <div className="cepProduto">
                <strong>CEP: </strong>
                <br/>{cepMask(produto.empreendimento_cep)}
            </div>

        </Card.Body>
    </>

}
