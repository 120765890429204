import React, { useState, useContext, useMemo } from "react";
import {
  Badge,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  Row,
  Button,
} from "react-bootstrap";
import moment from "moment";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import { Nav, Col } from "react-bootstrap";
import { celularMask, ocultaTelefone } from "../../../components/InputTextMask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faArrowLeft, faArrowRight, faUsers, faSync } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@iconify/react";
import { MdUpdate } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { FaHeadset } from "react-icons/fa";
// npm install --save-dev @iconify/react @iconify-icons/vaadin
import boxAdd from "@iconify/icons-icomoon-free/box-add";
import overflowMenuVertical from "@iconify-icons/carbon/overflow-menu-vertical";
import ModalDommus from "../../../components/Modal";
import ModalNovoAtendimento from "../ModalNovoAtendimento";
import "./style.scss";
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../../services/caAPI";
import Swal from "sweetalert2";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import { AtendentesContext } from "../../../contexts/AtendentesContext";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import NovoAtendimentoWhatsapp from "../NovoAtendimentoWhatsapp";
import formatDommus from "../../../helpers/format";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
export default function Registro({
  posicaoListaAtentimentos,
  registro,
  abrirAtendimento,
  atendimentoAtivoId,
  noAction,
  atualizarAtendimentoLista
}) {
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const { setAtendimentoAtivo, handleRemoveLista, handleAdiconaLista, handleReloadAtendimento, atualizarMensagensMesWhatsapp } = useContext(AtendimentoContext);
  const { resolverNomeAtendente } = useContext(AtendentesContext);
  const [ openModalAtendimentoWhatsapp, setOpenModalAtendimentoWhatsapp] = useState(false);

  let nomeAtendente = useMemo(() => {
    return  (registro.nome_atendente ? registro.nome_atendente.length > 20 ? registro.nome_atendente.substr(0, 20) + '...' : registro.nome_atendente : resolverNomeAtendente(registro.atendente));
  },[registro])


  const handleArquivar = (idAtendimento = null) => {
    const arquivamento = { arquivado: "1" };
    trackPromise(
      caAPI.atendimento.update(arquivamento, idAtendimento || registro.id).then(res => {
        setAtendimentoAtivo(null)
        handleRemoveLista(registro)
      }).catch(err => {
        Swal.fire({
          titleText: "Erro",
          text: "Erro ao arquivar atendimento",
          icon: 'error'
        });
      })
    )
  }

  const handleAtribuir = () => {
      trackPromise(
        caAPI.atendimento.update({atendente: caAPI.getLocalState('idUsuario')}, registro.id).then(res => {
          setAtendimentoAtivo(res.data)
          handleAdiconaLista(res.data)
          atualizarAtendimentoLista(posicaoListaAtentimentos, res.data);
        }).catch(err => {
          Swal.fire({
            titleText: "Erro",
            text: "Erro ao arquivar atendimento" + err,
            icon: 'error'
          });
        })
      )
  }


  const handleFinalizar = () => {
    const idAtendimento = registro.id;
    const finalizado = { situacao: "4" };
    Swal.fire({
      position: 'center',
      icon: "success",
      title: 'Deseja arquivar o atendimento?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: 'Quero Arquivar o Atendimento',
      cancelButtonText: 'Quero manter o Atendimento',
      width: '400px',
      customClass: {
        confirmButton: 'btn btn-primary mb-1',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false,
    }).then((result) => {
        let arquivarAtendimento = false;
        if ( result.isConfirmed) {
          arquivarAtendimento = true;
        }
        trackPromise(
          caAPI.atendimento.update(finalizado, idAtendimento).then(res => {
            atualizarAtendimentoLista(posicaoListaAtentimentos, res.data);
            setAtendimentoAtivo(null)
            if(arquivarAtendimento){
              handleArquivar(idAtendimento);
              Swal.fire({
                title: 'Sucesso!',
                text: 'Atendimento foi arquivado com sucesso!',
                icon: 'success'
              })
            }
          }).catch(err => {
            Swal.fire({
              titleText: "Erro",
              text: "Erro ao finalizar atendimento",
              icon: 'error'
            });
          })
        )
    })

  }

  function menu() {
    return <>
      <div className="menuRegisto">
        <OverlayTrigger
          key="buttonMenuRegisto"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-editar`}>
              Opções
            </Tooltip>
          }
        >
          <DropdownButton
            id="DropRegistro"
            size="sm"
            title={
              <Icon
                icon={overflowMenuVertical}
                width="1.5rem"
                color="#fff"
              />
            }>
            {registro.atendente == null &&
              <>
                <Dropdown.Item className="opcaoMenu" onClick={() => handleAtribuir(true)}>
                  <div className="opcaoArquivar">
                    <FontAwesomeIcon icon={faUsers} color="#009688" /><span className="tituloArquivar">Atribuir à mim</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            }
            <Dropdown.Item className="opcaoMenu" onClick={() => { setOpenModalEditar(true) }}>
              <div className="opcaoEditar">
                <FontAwesomeIcon
                  icon={faEdit}
                  size="1x"
                />
                <span className="tituloEditar">Editar</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item className="opcaoMenu" onClick={() => { handleFinalizar() }}>
              <div className="opcaoFinalizar">
              <Icon icon="ep:finished" width="1.2rem" />
                <span className="tituloFinalizar">Finalizar</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item className="opcaoMenu" onClick={() => {
              handleArquivar()
            }}>
              <div className="opcaoArquivar">
                <Icon icon="formkit:trash" />
                <span className="tituloArquivar">Lixeira</span>
              </div>
            </Dropdown.Item>
            {registro.meio_comunicacao.slug === 'voip' &&
              <>
                <Dropdown.Divider />
                <Dropdown.Item className="opcaoMenu" onClick={() => setOpenModalAtendimentoWhatsapp(true)}>
                  <div className="opcaoArquivar">
                    <FontAwesomeIcon icon={faWhatsapp} color="#009688" /><span className="tituloArquivar">Atendimento Whatsapp</span>
                  </div>
                </Dropdown.Item>
              </>
            }

          </DropdownButton>
        </OverlayTrigger>
      </div>
    </>
  }

  function atribuirBotao(registro) {
    return <>
      <div>
        <div onClick={() => { handleAtribuir(registro) }} classname="tag-nome" ><FontAwesomeIcon icon={faUsers} color="#009688" />
        </div>
      </div>
    </>
  }

  const verificaContatoDestino = () => {
    switch (registro.meio_comunicacao.slug) {
      case "whatsapp":
      case "voip":
      case "sms":
        return celularMask(registro.contato_destino)
      case ("email"):
        return (registro?.contato_destino ?? "---")
      case ("chatbot"):
        return ("Chatbot")
      default:
        return (registro.contato_destino)
    }
  }

  const colocaTooltip = () => {
    switch (registro.meio_comunicacao.slug) {
      case ("email"):
        return ((registro?.contato_destino && registro?.contato_destino?.length > 20) ? registro?.contato_destino : '')
      default:
        return
    }
  }

  function exibeProcesso(processo){
    if(!Number(processo) && !registro.oportunidade){
      return 'OPV/Processo'
    }else{
      return registro.processo   ? `Processo: ${registro.processo.toString().padStart(5, '0')}` : ''
    }
  }

  return (<>

    <Nav.Item className="navIntemRegistro" >
      <Nav.Link eventKey={registro.id} className={"registro"} active={registro.id === atendimentoAtivoId} onClick={() => !(noAction) && abrirAtendimento(registro)}>
        <Col className="colunaIcone" >
          {((registro.msg_nao_lidas || 0) > 0 && registro.id !== atendimentoAtivoId)
            ?
            <OverlayTrigger
              key="baggeNaoLidas"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-nao-lidas`}>
                  Não Lidas/Atendidas
                </Tooltip>
              }
            >
              <Badge bsPrefix="naoLidas" variant="danger">
                {(registro.msg_nao_lidas || 0)}
              </Badge>
            </OverlayTrigger>
            :
            <></>
          }
          <OverlayTrigger
            key={`badgeDirecaoRegistro_${registro.id}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-direcao-registro-${registro.id}`}>
                Atendimento {((registro.criado_por && "Ativo") || "Receptivo")}
              </Tooltip>
            }
          >
            <Badge pill bsPrefix={"direcaoRegistro " + ((registro.criado_por && 'enviado') || 'recebido')} text={"primary"}>
              <FontAwesomeIcon icon={((registro.criado_por && faArrowRight) || faArrowLeft)} />
            </Badge>
          </OverlayTrigger>

          <MidiaSocialIcone midia={registro.meio_comunicacao?.slug} />
        </Col>
        <Col className="col-atendimento-info">
          <Row>
            <Col md={6} xs={6} className="registroContatoNome">
                {/* NOME */}
              <OverlayTrigger
                key="nomeCliente"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-nome-atendente`}>
                    {registro?.nome ? registro?.nome : "Nome não informado!"}
                  </Tooltip>
                }
              >
                <div className="nome">
                  {registro?.nome ? registro?.nome : " ----------------- "}
                </div>
              </OverlayTrigger>
            </Col>
            <Col md={6} xs={6} className="oportunidadeProcesso">
                 {/* DATA DA ULTIMA ATUALIZAÇÃO */}
              <div className="data-ultima-atualizacao">
                {registro.ultima_movimentacao ? <OverlayTrigger
                  key="dataAtualizacao"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-data-atualizacao`}>
                      Data da atualização do atendimento
                    </Tooltip>
                  }
                >
                  <MdUpdate className="icon-processo-info" />
                </OverlayTrigger> : <></>}
                <span>{registro.ultima_movimentacao && moment(registro.ultima_movimentacao).format('DD/MM/YYYY HH:mm')}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={6} className="registroContatoNome">
              {/* Contato */}
              <div className="contato" title={colocaTooltip()}>
                {verificaContatoDestino()}
              </div>
            </Col>
            <Col md={6} xs={6} className="oportunidadeProcesso">
              {/* DATA DA CRIAÇÃO DO ATENDIMENTO */}
              <div className="data data-inicio">
                {registro.criado_em ? <OverlayTrigger
                  key="dataCriacao"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-data-criacao`}>
                      Data da criação do atendimento em: {moment(registro.criado_em).format('DD/MM/YYYY HH:mm')}
                    </Tooltip>
                  }
                >
                  <BiEditAlt className="icon-processo-info" />
                </OverlayTrigger> : <></>}
                {/* {registro.criado_em && moment(registro.criado_em).format('DD/MM/YYYY HH:mm')} */}
                Iniciado {registro.criado_em && formatDommus.formatDateProcessInfo(registro.criado_em)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={7} className="registroContatoNome">
              {/* OPV */}
              <div className="referencia">
                {exibeProcesso(registro.processo)}
                {registro.processo && registro.oportunidade ? <div className="dot"></div> : <></>}
                <div className="opv">{registro.oportunidade ? `OPV-${registro.oportunidade.toString().padStart(5, '0')}` : ''}</div>
              </div>
            </Col>
            <Col md={5} xs={5} className="oportunidadeProcesso">
              {/* TIPO DO ATENDIMENTO */}
              <OverlayTrigger
                key="IconTipoAtendimento"
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-editar`}>
                        Tipo do Atendimento
                    </Tooltip>
                }
                >
                <div className="tipo-atendimento">
                  {registro.tipo_atendimento?.descricao}
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={7} className="registroContatoNome">
             <OverlayTrigger
                  key="nomeAtendente"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-nome-atendente`}>
                      Originado por
                    </Tooltip>
                  }
                >
              <div className="contato-origem">
                <div className="descricao-contato-origem">
                  <BsFillTelephoneOutboundFill className="icon-registro-contato"/>
                  {registro.origem?.descricao || "---"}
                </div>
              </div>
              </OverlayTrigger>
            </Col>
            <Col md={5} xs={5} className="oportunidadeProcesso">
              {/* NOME DO ATENDENTE */}
              <div className="atendenteNome" title={registro.nome_atendente ? registro.nome_atendente.length > 20 ? registro.nome_atendente : '' : ''}>
                <OverlayTrigger
                  key="nomeAtendente"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-nome-atendente`}>
                      Nome do atendente
                    </Tooltip>
                  }
                >
                <FaHeadset className="icon-processo-info" />
                </OverlayTrigger>
                {nomeAtendente}
              </div>
            </Col>
          </Row>
          <Row className="row-atendimento-info-footer">
            <Col md={5} xs={5} className="column-atendimento-info-footer">
              {/* STATUD DO ATENDIMENTO */}
              <OverlayTrigger
                key="status atendimento"
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-editar`}>
                        Status do Atendimento
                    </Tooltip>
                }
                >
                <div className="statusAtendimento">
                  <span className="situacaoAtendimentoBadge" style={registro.situacao_atendimento?.cor ? { backgroundColor: `${registro.situacao_atendimento?.cor}` } : { backgroundColor: `#CA965C` }}>{registro.situacao_atendimento?.nome}</span>
                </div>
              </OverlayTrigger>
            </Col>
            <Col md={7} xs={7} className="column-atendimento-info-footer">
              {/* TAGS */}
              <div className="tag-section">
                  {registro?.tags && registro?.tags.map((tag, index)=>{
                    if(index <= 1){
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          overlay={
                            <Tooltip>
                              {tag.nome}
                            </Tooltip>
                          }
                        >
                          <div className="tag-nome">
                              {tag.nome}
                          </div>
                        </OverlayTrigger>

                      );
                    }
                  })
                  }
                  {registro?.tags && registro?.tags.length > 2 ?
                  <OverlayTrigger
                    key="TagsAgrupadas"
                    placement="top"
                    overlay={
                      <Tooltip>
                        {registro?.tags && registro?.tags.map((tag,index)=>{
                          if(index >=2){
                            return tag?.nome + (index !== registro?.tags.length -1 ? " \u{2022} " : "");
                          }
                        })}
                      </Tooltip>
                    }
                  >
                    <div className="tag-agrupada">
                      + {registro?.tags.length - 2 }
                    </div>
                  </OverlayTrigger>
                  : <></>
                  }
                </div>
            </Col>
          </Row>
        </Col>
      </Nav.Link>
      {registro.id === atendimentoAtivoId ?
        <div className="editarAtendimento">
          {menu()}
        </div>
        :
        <></>
      }
    </Nav.Item>
    <ModalDommus
      titulo="Editar"
      open={openModalEditar}
      close={() => setOpenModalEditar(false)}
    >
      <ModalNovoAtendimento
        setOpenModalNovoAtendimento={setOpenModalEditar}
        idAtendimento={registro.id}
        posicaoListaAtentimentos={posicaoListaAtentimentos}
      />
    </ModalDommus>
    <ModalDommus
      titulo="Criar Atendimento Whatsapp"
      open={openModalAtendimentoWhatsapp}
      close={() => setOpenModalAtendimentoWhatsapp(false)}
    >
      <NovoAtendimentoWhatsapp
        setOpenModalAtendimentoWhatsapp={setOpenModalAtendimentoWhatsapp}
        idAtendimento={registro.id}
        atendimento={registro}
      />
    </ModalDommus>
  </>
  );
}
