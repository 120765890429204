import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, ProgressBar, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-date-picker/dist/DatePicker'
import DommusBarraTitulo from '../../../components/DommusBarraTitulo'
import ItemBarraTitulo from '../../../components/DommusBarraTitulo/ItemBarraTitulo'
import DommusToggle from '../../../components/DommusToggle';
import { trackPromise } from 'react-promise-tracker';
import caAPI from '../../../services/caAPI';
import Swal from 'sweetalert2';
import './index.css'

export default function TempoOnline() {

    const [data, setData] = useState(new Date());
    const [usuario, setUsuario] = useState({
        id: caAPI.getLocalState('idUsuario'),
        nome: caAPI.getLocalState('nomeUsuario'),
    });
    const [dados, setDados] = useState([])
    const [progress, setProgress] = useState([])

    let tempoOnline = progress?.progress;

    useEffect(() => {
        let dataHoje = tempoOnlineFiltroData(new Date());
        buscadados(usuario, dataHoje);
    }, [])

    async function buscadados(user, filtro) {
        await trackPromise(
            caAPI.perfil.tempoOnline(user.id, filtro).then((response) => {
                if (response.data !== undefined) {
                    let arrayProgresso = []
                    let result = response.data
                    result = result.reduce(function (r, a) {
                        r[a.usuario] = r[a.usuario] || [];
                        r[a.usuario].push(a);
                        return r;
                    }, Object.create(null));
                    setDados(response.data)
                    let userProgress = []

                    if (!result[user.id]) {
                        userProgress = {
                            "usuario": user.nome,
                            "progress": "Usuário não atuante neste dia"
                        }
                    }
                    else {
                        let currentUserProgress = result[user.id]
                        let soma = 0
                        currentUserProgress.length > 0 && currentUserProgress.map((item, i) => {
                            let horaInicial = i == 0 ? new Date(filtro + 'T00:00:00.000-0300') : response.data[i - 1].online_ate;
                            let diferenca = calculaIntervalo(horaInicial, item.entrou_em)
                            soma += diferenca

                            arrayProgresso.push({
                                "porcentagem": diferenca,
                                "entrou_em": new Date(item.entrou_em).getHours() + ':' + new Date(item.entrou_em).getMinutes() + 'h',
                                "online_ate": new Date(item.online_ate).getHours() + ':' + new Date(item.online_ate).getMinutes() + 'h'
                            })

                            let porcentagem = calculaIntervalo(item.entrou_em, item.online_ate)

                            soma += porcentagem

                            arrayProgresso.push({
                                "porcentagem": porcentagem,
                                "entrou_em": new Date(item.entrou_em).toTimeString().slice(0, 5) + ' h',
                                "online_ate": new Date(item.online_ate).toTimeString().slice(0, 5) + ' h'
                            })
                        })
                        userProgress = { "usuario": user.nome, "progress": arrayProgresso }

                    }
                    setProgress(userProgress)
                }
            }
            ).catch(error => Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao buscar as dependencias: " + error,
                icon: "error"
            }))
        )
    }

    function formatDate(date) {
        return (
            [
                date.getFullYear(),
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate()),
            ].join('-')
        );
    }

    function calculaIntervalo(entrada, onlineAte) {
        entrada = new Date(entrada)
        onlineAte = new Date(onlineAte)

        let milisegEntrada = entrada.getTime()
        let milisegOnlineAte = onlineAte.getTime()

        let minutesOnline = (milisegOnlineAte - milisegEntrada) / 60000;

        let porcentagem = minutesOnline * ((100 / 24) / 60);
        return porcentagem
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function tempoOnlineFiltroData(date) {
        setData(date)
        let dataSelecionada = formatDate(date)
        buscadados(usuario, dataSelecionada)
    }

    return (
        <Container fluid>
            <DommusBarraTitulo>
                <ItemBarraTitulo>Tempo Online</ItemBarraTitulo>
            </DommusBarraTitulo>

            <DommusToggle
                title={"Acompanhamento de Tempo Online"}
                toggle={true}
                className="labels"
                bodyClass="dommus-toggle-height-fixed overflow-auto"
            >
                <Row>
                    <Form.Group style={{marginLeft: '38%'}} as={Col} md="3" controlId="formNome">
                        <p className='text-center '>
                      <Form.Label>Selecione uma data:</Form.Label>
                        </p>
                        <DatePicker
                            className='form-control m-1'
                            locale="pt"
                            selected={data}
                            value={data}
                            onChange={(date) => tempoOnlineFiltroData(date)}
                            dateFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            placeholderText=""
                            maxDate={new Date()}
                        />
                        </Form.Group>
                </Row>
                {progress?.progress?.length &&
                    <>
                        <h3 className='text-center'>{progress.usuario}</h3>
                        {typeof (progress.progress) == 'string' && <p className='text-center'>{progress.progress}</p>}
                    </>
                }
                {
                    typeof (progress?.progress) != 'string' && tempoOnline?.length > 0 && <>

                        <ProgressBar>

                            {tempoOnline.map((item, i) => {
                                return (
                                    <ProgressBar key={i} striped variant={i % 2 != 0 ? "success" : ""} now={item.porcentagem}
                                        data-toggle="tooltip" title={i % 2 != 0 ? `Entrou em: ${item.entrou_em} | Online até: ${item.online_ate}` : "Offline"} />
                                )
                            })
                            }
                        </ProgressBar>
                        <div className='horas-bar'>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                            <div className='div-ponteiro'></div>
                        </div>
                        <div className='horas-bar'>
                            <div className="div-horas">00h</div>
                            <div className="div-horas">01h</div>
                            <div className="div-horas">02h</div>
                            <div className="div-horas">03h</div>
                            <div className="div-horas">04h</div>
                            <div className="div-horas">05h</div>
                            <div className="div-horas">06h</div>
                            <div className="div-horas">07h</div>
                            <div className="div-horas">08h</div>
                            <div className="div-horas">09h</div>
                            <div className="div-horas">10h</div>
                            <div className="div-horas">11h</div>
                            <div className="div-horas">12h</div>
                            <div className="div-horas">13h</div>
                            <div className="div-horas">14h</div>
                            <div className="div-horas">15h</div>
                            <div className="div-horas">16h</div>
                            <div className="div-horas">17h</div>
                            <div className="div-horas">18h</div>
                            <div className="div-horas">19h</div>
                            <div className="div-horas">20h</div>
                            <div className="div-horas">21h</div>
                            <div className="div-horas">22h</div>
                            <div className="div-horas">23h</div>
                        </div>
                    </>}
            </DommusToggle>
        </Container>
    )
}
