import React from "react";
import {  Container } from "react-bootstrap";
import "./style.scss";
import Registro from "../RegistroAtendimento/Registro";
import {
  Nav
} from "react-bootstrap";

export default function ListaBacia({ atendimentos }) {
  return (
    <Container className="registrosAtendimento listaBacia" fluid style={{ overflow: "auto" }}>
      <Nav fill variant="pills">
        <div className="registros-container">
          {atendimentos.map((item, indice) => {
            return (
              item.atendimento && <div className="registroSection" key={item.id_bacia_atendimento}>
                <Registro
                  posicaoListaAtentimentos={indice}
                  registro={item.atendimento}
                  key={item.id_bacia_atendimento}
                  noAction={true}/>
              </div>
            );
          })}
        </div>
      </Nav>
    </Container>
  );
}
