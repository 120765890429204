import React, { useEffect, useState } from "react";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import {
  faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import FilaService from "../../services/FilaService";
import { successToast } from "../../components/DommusToast";
import Swal from "sweetalert2";
import ListaFila from "./ListaFila";
import AtendentesWrapper from "./AtendentesWrapper";

export default function FilasAtendimento() {
  const [openPane, setOpenPane] = useState(false);
  const [qualificacaoAtendentes, setQualificacaoAtendentes] = useState([]);
  const [meiosComunicacao, setMeiosComunicacao] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const [infosAtendentes, setInfosAtendentes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [trocaFeita, setTrocaFeita] = useState(false);

  useEffect(() => {
    trackPromise(FilaService.qualificacao()).then((res) => {
      setQualificacaoAtendentes(res.data);
    });
    trackPromise(FilaService.comunicacao()).then((res) => {
      setMeiosComunicacao(res.data);
    });
  }, [refresh]);

  useEffect(() => {
    trackPromise(
      Promise.all([FilaService.index(), FilaService.atendentes()]).then(
        (res) => {
          setInfosAtendentes(res[0].data);
          setAtendentes(res[1].data);
        }
      )
    );
  }, [refresh, trocaFeita]);

  function addOcorrenciaFila(idUsuario) {
    let objetoInserir = {
      id_usuario: idUsuario,
    };
    //verifica se já está na lista
    const index = infosAtendentes.findIndex(
      (atendente) => Number(atendente.id_usuario) === Number(idUsuario)
    );
    if (infosAtendentes[index]) {
      Swal.fire({
        titleText: "Ooops...",
        text: "Parece que esse atendente já está na lista, tente outro.",
        icon: "error",
      });
    } else {
      trackPromise(
        FilaService.adicionarFila(objetoInserir)
          .then(
            (response) => {
              if (Number(response.status) === 201) {
                setRefresh(!refresh);
                setOpenPane(false);
                successToast.fire({
                  text: "Atendente adicionado(a) com sucesso.",
                });
              }
              if (Number(response.status) === 200) {
                Swal.fire({
                  titleText: "Atenção",
                  text: response.data,
                  icon: "warning",
                });
              }
            },
            (error) => {
              throw error;
            }
          )
          .catch((error) => {
            Swal.fire({
              titleText: "Ooops...",
              text:
                "Ocorreu um erro ao adicinar o(a) atendente na fila: " + error,
              icon: "error",
            });
          })
      );
    }
  }

  return (
    <>
      <DommusBarraTitulo>
        <ItemBarraTitulo>Fila de Atendimento</ItemBarraTitulo>
        {/* <ItemBarraTitulo classe="btn" onClick={() => {}}>
          <FontAwesomeIcon icon={faCog} color="#444" />
        </ItemBarraTitulo>
        <ItemBarraTitulo classe="btn" onClick={() => {}}>
          <FontAwesomeIcon icon={faSort} color="#444" />
        </ItemBarraTitulo>
        <ItemBarraTitulo classe="btn" onClick={() => {}}>
          <FontAwesomeIcon icon={faExchangeAlt} color="#444" />
        </ItemBarraTitulo> */}
        <ItemBarraTitulo
          classe="btn"
          onClick={() => {
            setOpenPane(true);
          }}
        >
          <FontAwesomeIcon icon={faUsersCog} size={"1x"} color="#444" />
        </ItemBarraTitulo>
      </DommusBarraTitulo>

      <ListaFila
        atendentes={atendentes}
        infosAtendentes={infosAtendentes}
        setInfosAtendentes={setInfosAtendentes}
        setTrocaFeita={setTrocaFeita}
        trocaFeita={trocaFeita}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <AtendentesWrapper
        addOcorrenciaFila={addOcorrenciaFila}
        setOpenPane={setOpenPane}
        openPane={openPane}
        qualificacaoAtendentes={qualificacaoAtendentes}
        meiosComunicacao={meiosComunicacao}
        atendentes={atendentes}
        infosAtendentes={infosAtendentes}
        setRefresh={setRefresh}
        refresh={refresh}
      />      
    </>
  );
}
