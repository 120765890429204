import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_CA_API,
    headers: {
        'Authorization': JSON.parse(localStorage.getItem('@DommusCA-token'))
    }
});

export default api;
