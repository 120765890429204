import React, { useContext, useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './historico.scss';
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import caAPI from '../../../../services/caAPI';
import { errorToast } from '../../../../components/DommusToast'
import CommonService from '../../../../services/CommonService';
import { resolverAlteracoesAtendimento } from '../../../../components/LinhaTabelaHistorico'
import { AtendentesContext } from '../../../../contexts/AtendentesContext';

export default function Historico() {
    const { atendimentoAtivo } = useContext(AtendimentoContext)
    const { resolverNomeAtendente } = useContext(AtendentesContext);
    const [historicos, setHistoricos] = useState([])
    const servicoComum = Object.assign({}, CommonService);
    
    useEffect(() => {
        caAPI.atendimento.arquivado.historico(atendimentoAtivo.id)
            .then(response => setHistoricos(response.data))
            .catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao tentar buscar os históricos dos atendimentos.' })
            })
    }, [atendimentoAtivo])

    function ItemTimeLine({ historico, index }) {
        return (
            <TimelineItem className="item">
                <TimelineSeparator>
                    <TimelineDot className="dotHistorico" />
                    {historicos.length !== (index + 1) && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                    <Paper className="paper" >
                        <h6>{historico.tipo_historico_atendimento.descricao || 'Não Encontrado'}</h6>
                        {eval(historico.tipo_historico_atendimento.template)} {
                            historico.tipo_historico_atendimento.id_tipo_historico_atendimento === 4 && 
                            historico.dados.alteracoes && 
                            resolverAlteracoesAtendimento(historico.dados.alteracoes)
                        }
                        <Typography className="labelHora">
                            {historico.criado_em ? servicoComum.formatarParaDataHoraBr(historico.criado_em) : ''}
                        </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        );
    };

    return (
        <div className="historico">
            <Timeline align="right">
                {historicos.map((historico, index) =>
                    <div key={index}>
                        <ItemTimeLine 
                            historico={historico} 
                            index={index}
                        />
                    </div>
                )}
            </Timeline>
        </div>
    );
}
