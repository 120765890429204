import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '@material-ui/core'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { celularMask } from '../../../../../components/InputTextMask'

import "./styles.scss"

export default function InformacoesLead({ lead, status, envolvidos, empreendimento, substatus }) {
    const nomeCliente = envolvidos && envolvidos[0] && envolvidos[0].nome 
    || lead && lead.nome || null;
    return (
        <div>
            <div className="dadosPessoaisLead">
                <div className="dadosIniciais">
                    <Row>
                        <Col xs={3}>
                            <div className="foto">
                                <div className="placeholder">
                                    <div className=" fotoEnvolvido">
                                        <FontAwesomeIcon
                                            icon={faUserCircle} color="#444444" size="sm" />
                                        {/* <Image src={Perfil} roundedCircle /> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <div className="nomecliente">
                                <Card.Text>
                                    <b>
                                        {nomeCliente || 'Nome Não Informado'}
                                    </b>
                                </Card.Text>
                            </div>
                            <div className="telefonecliente">
                                <Card.Text>
                                    {celularMask(lead.telefone)}
                                </Card.Text>
                            </div>
                            <div className="emailcliente">
                                <Card.Text>
                                    {lead.email}
                                </Card.Text>
                            </div>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div className="outrosDados">
                  {
                    empreendimento && <div className="empreendimento">
                        <strong>Empreendimento: </strong>{empreendimento?.nome}
                    </div>
                  }
                  {
                    lead && lead.campanha && <div className="cpfcliente">
                        <strong>Campanha: </strong> {lead?.campanha?.nome || 'Não Informado'}
                    </div>
                  }
                  {
                    lead && lead.midia && <div className="rgcliente">
                        <strong>Midia: </strong> {lead?.midia?.descricao || 'Não Informado'}
                    </div>
                  }
                  {
                    status && <div className="dataNasciemntocliente">
                        <strong>Etapa Funil: </strong> {status?.nome}
                    </div>
                  }
                  {
                    substatus && <div className="substatus">
                        <strong>Substatus: </strong> {substatus?.nome || 'Não Informado'}
                    </div>
                  }
                </div>
            </div>
        </div>

    )
}
