import {useState, useRef, useCallback} from 'react';
import intlTelInput from 'intl-tel-input';

export default function useTelefoneInternacionalInput(){
  const inputRef = useRef(null);
  const [objetoIntlTelInput, setObjetoIntlTelInput] = useState(null);

  const setInputRef = useCallback((no) => {
    if(inputRef?.current && objetoIntlTelInput){
      objetoIntlTelInput.destroy();
    }

    if(no){
      const novoIntl = intlTelInput(no, {
        utilsScript: './helpers/intl-tel-input/utils.js',
        initialCountry: 'br',
        separateDialCode: true
      });
      setObjetoIntlTelInput(novoIntl);
    }else{
      setObjetoIntlTelInput(null);
    }

    inputRef.current = no;
  }, [intlTelInput]);

  const getNumero = useCallback(() => {
    let retorno = "";
    if(objetoIntlTelInput){
      if(objetoIntlTelInput.isValidNumber()){
        return objetoIntlTelInput.getNumber();
      }
      let valorInput = objetoIntlTelInput.telInput?.value || "";
      if(valorInput.length > 0){
        valorInput = valorInput.toString().replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/\s+/g, '');
        if(valorInput.substring(0,1) !== '+' && objetoIntlTelInput.selectedCountryData?.dialCode){
          valorInput = '+' + objetoIntlTelInput.selectedCountryData?.dialCode + valorInput;
        }
        return valorInput;
      }
    }
    return retorno;
  }, [objetoIntlTelInput])

  return [setInputRef, objetoIntlTelInput, getNumero];
}
