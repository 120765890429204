import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import './index.css';
import {BrowserView, MobileView} from "react-device-detect";
import { Button } from "react-bootstrap";

export default function DommusSidenavSection({openSection, closePaneHandler, children, sectionHeader}) {
    return (
        <>
            <div className={"sidenav-overlay " + (openSection && "open")}></div>
            <section className={"sidenav-section " + (openSection && "open")}>
                <header>
                  <div className="d-flex justify-content-between w-100">
                    <div className="sidenav-section-title">{sectionHeader}</div>
                    <BrowserView className={"close-pane"}><FontAwesomeIcon icon={faArrowRight} onClick={closePaneHandler}/></BrowserView>
                    <MobileView className={"close-pane"}>
                      <Button onClick={closePaneHandler} variant="light">
                        <FontAwesomeIcon icon={faArrowRight}/>
                      </Button>
                    </MobileView>
                  </div>
                </header>
                <div>
                    {
                        children
                    }
                </div>
            </section>
        </>
    )
};
