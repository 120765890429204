const NotificationDommus = {
  showNotification: (body, tittle) => {
    // create a new notification
    const notification = new Notification(`Dommus: ${tittle}`, {
        body: `${body}`,
        icon: 'https://i.imgur.com/2jnmgZr.jpg'
    });

    // close the notification after 10 seconds
    setTimeout(() => {
        notification.close();
    }, 10 * 1000);

    // navigate to a URL when clicked
    notification.addEventListener('click', () => {
      
    });
  }
}

export default NotificationDommus;