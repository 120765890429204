import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import CommonService from '../../../../services/CommonService';
export default function Alertas({ alertas }) {
   function formataData(data){
     return CommonService.formatarParaDataHoraBr(data);
   }
  return <>
        <Card.Body>
            {alertas && alertas.map((alerta, index) => {
              return (<Fragment key={index}>
                    <p className="card-text">
                        <strong>Assunto: </strong> {alerta.assunto} <br></br>
                        <strong>Descricao: </strong> {alerta.descricao} <br></br>
                        <strong>Data: </strong> {formataData(alerta.data)} <br></br>
                        <strong>Solicitante: </strong> {alerta.solicitante} <br></br>
                    </p>
                    <hr />
                </Fragment>)
            })}
        </Card.Body>
    </>
}
