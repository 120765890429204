import api from './api';

const TicketCategoria = {
	index:()=>{
		return api.get('/ticket/categoria');
	},
	show:(id)=>{
		return api.get('/ticket/categoria/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket/categoria/' + id;
        return api['put'].call(api, uri, {categoria: dados});
    },
	store: (dados) => {
        let uri = '/ticket/categoria';
        return api['post'].call(api, uri, {categoria: dados});
    },
};

export default TicketCategoria;
