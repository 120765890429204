import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";

export function DommusAlertaBootstrap({ mensagem, classe="", variante="warning", icone=null }) {
    return (
        <Alert variant={variante} className={classe}>
            <FontAwesomeIcon icon={icone ?? faExclamationTriangle}/> {mensagem}
        </Alert>
    )
}