import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";
import { errorToast } from "../components/DommusToast";

export const TemplateContext = createContext({});

export function TemplateProvider({ children }) {
    const [templates, setTemplates] = useState([]);
    const [mapaTemplates, setMapaTemplates] = useState([]);

    useEffect(() => {
        const novoMapa = new Map();
        templates.forEach((template) => {
            novoMapa.set(Number(template.id), template);
        });
        setMapaTemplates(novoMapa);
    }, [templates]);

    const recarregarTemplates = () => {
        caAPI.template
            .list()
            .then((response) => setTemplates(response.data))
            .catch((error) => {
                errorToast.fire({
                    text: "Houve um erro ao buscar os templates.",
                });
            });
    };

    const resolverNomeTemplate = (idTemplate, padrao = "Não Atribuído") => {
        let template = mapaTemplates.get(Number(idTemplate));
        return (template && template.nome) || padrao;
    };

    return (
        <TemplateContext.Provider
            value={{
                templates,
                recarregarTemplates,
                resolverNomeTemplate,
            }}
        >
            {children}
        </TemplateContext.Provider>
    );
}
