import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, Col, Row, Tab, Nav } from 'react-bootstrap'
import { celularMask, cepMask } from '../../../../../components/InputTextMask'

import "./styles.scss"

export default function InformacesEnvolvidos({ envolvidos }) {
    return (
        <Tab.Container id="left-tabs" defaultActiveKey={1}>
            <Col>
                <Nav className="navsProponente"
                    style={envolvidos.length === 1 ? { display: "none" } : {}}
                    variant="pills">
                    {envolvidos.map((envolvido, index) => {envolvido && (
                        <Nav.Item key={index}>
                            <Nav.Link eventKey={index + 1}>{" "}</Nav.Link>
                        </Nav.Item>
                    ) || <></>})}
                </Nav>
            </Col>
            <Col>
                <div className="dados">
                    <Tab.Content >
                        {envolvidos.map((envolvido, index) => envolvido?.envolvido && (
                            <Tab.Pane eventKey={index + 1} key={index}>
                                <div className="dadosPessoaiscliente">
                                    <div className="dadosIniciais">
                                        <Row>
                                            <Col xs={3}>
                                                <div className="foto">
                                                    <div className="placeholder">
                                                        <div className=" fotoEnvolvido">
                                                            <FontAwesomeIcon
                                                                icon={faUserCircle} color="#444444" size="sm" />
                                                            {/* <Image src={Perfil} roundedCircle /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={9}>
                                                <div className="nomecliente">
                                                    <Card.Text>
                                                        <b>
                                                            {envolvido.envolvido.nome}
                                                        </b>
                                                    </Card.Text>
                                                </div>
                                                <div className="telefonecliente">
                                                    <Card.Text>
                                                        {celularMask(envolvido.envolvido.telefone)}
                                                    </Card.Text>
                                                </div>
                                                <div className="emailcliente">
                                                    <Card.Text>
                                                        {envolvido.envolvido.email}
                                                    </Card.Text>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />

                                    <div className="rgcliente">
                                        <strong>RG: </strong> {envolvido.envolvido.rg}
                                    </div>
                                    <div className="dataNasciemntocliente">
                                        <strong>Data de Nascimento: </strong> {envolvido.envolvido.data_nascimento}
                                    </div>
                                    <div className="generocliente">
                                        <strong>Gênero: </strong> {envolvido.envolvido.genero}
                                    </div>
                                    <hr />
                                    <div className="enderecocliente">
                                        <div className="logradourocliente">
                                            <strong>Logradouro: </strong> {envolvido.envolvido.endereco}
                                        </div>
                                        <div className="complementocliente">
                                            <strong>Complemento: </strong> {envolvido.envolvido.complemento == 'null' ? '' : envolvido.envolvido.complemento}
                                        </div>
                                        <div className="bairrocliente">
                                            <strong>Bairro: </strong> {envolvido.envolvido.bairro}
                                        </div>
                                        <div className="cidadecliente">
                                            <strong>Cidade/UF: </strong> {envolvido.envolvido.cidade} / {envolvido.envolvido.uf}
                                        </div>
                                        <div className="cepcliente">
                                            <strong>CEP: </strong> {cepMask(envolvido.envolvido.cep)}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane> 
                        )|| <></>)}
                    </Tab.Content>
                </div>
            </Col>
        </Tab.Container>
    )
}
