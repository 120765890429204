import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import "./style.scss";

export default function RecebeImages({ conteudo, url }) {
  return <>
    {url &&
      <div>
        <div>
          <Zoom image={{
            width: 'auto',
            src: url,
            className: "mensagemImagem",
          }} />
        </div>
        <div className="textoImagem">
          {conteudo}
        </div>
      </div>
    }
  </>
}
