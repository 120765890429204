import React, { useCallback, useEffect, useState, } from 'react';
import { Form, Card, OverlayTrigger, Tooltip, CardGroup } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import './style.scss';
import ModalNovoTemplate from "./ModalNovoTamplate";
import MidiaSocialIcone from "../../../components/MidiaSocialIcone";
import caAPI from "../../../services/caAPI";
import DommusBarraTitulo from "../../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../../components/DommusBarraTitulo/ItemBarraTitulo";
import DommusToggle from "../../../components/DommusToggle";
import DommusTabelaPaginada from "../../../components/DommusTabelaPaginada";
import ModalDommus from "../../../components/Modal";
import { Interweave } from 'interweave';
import FormDisparoEmMassa from './FormDisparoEmMassa';
import { DommusModalFullScreen } from '../../../components/DommusModalFullScreen';

export default function Template() {
  const [listaTemplates, setListaTemplates] = useState([]);
  const [openModalNovoTemplate, setOpenModalNovoTemplate] = useState(false);
  const [openModalEditarTemplate, setOpenModalEditarTemplate] = useState(false);
  const [idTemplate, setIdTemplate] = useState();

  const thead = (
    <tr >
      <th style={{ width: '1%' }}>
        ID:
      </th>
      <th style={{ width: '7%' }}>
        Disponível para:
      </th>
      <th>
        Nome:
      </th>
      <th>
        Atendimento:
      </th>
      <th>
        Mensagem:
      </th>
      <th style={{ width: '5%' }}>
        Opções:
      </th>
    </tr>
  );


  const linhas =
    listaTemplates.reverse().map(template => (
      <tr key={template.id}>
        <th >
          {template.id}
        </th>
        <th>
          <CardGroup>
            {template.meios && template.meios.map((meio) =>
              <div className="tabelaMidias" key={meio.id}>
                <Card className="midiaCard">
                  <Card.Body className="midiaCorpo">
                    <Form.Check
                      className="midiaListaTemplate"
                      value={meio.slug}
                    >
                      <Form.Check.Input />
                      <Form.Check.Label>
                        <MidiaSocialIcone midia={meio.slug} />
                      </Form.Check.Label>
                    </Form.Check>
                  </Card.Body>
                </Card>
              </div>
            )}
          </CardGroup>
        </th>
        <th>
          {template.nome}
        </th>
        <th>
          {template.tipos && template.tipos.map((tipo) => <p key={tipo.id}>{tipo.descricao}</p>)}
        </th>
        <th>
          <Interweave content={template.mensagem} />
        </th>
        <th>
          <div className="opcoesTemplate">
          {
            template.meios && template.meios.some(meio => meio.slug == 'sms') && <OverlayTrigger
              key="buttonDispararEmMassa"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-disparo`}>
                  Disparar envio em massa.
                </Tooltip>
              }
            >
              <div className="buttonDispararEmMassa"
                   ata-tip="Enviar em Massa">
                   <FormDisparoEmMassa idTemplate={template.id}/>
              </div>
            </OverlayTrigger>
          }
            <OverlayTrigger
              key="buttonEditarAnotacao"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-editar`}>
                  Editar
                </Tooltip>
              }
            >
              <div className="buttonEditarAnotacao"
                   onClick={()=>
                     {
                       setIdTemplate(template.id);
                       setOpenModalEditarTemplate(true);
                     }
                   }
                   ata-tip="Editar Introdução">
                <FontAwesomeIcon icon={faEdit} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key="buttonExcluirAnotacao"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-excluir`}>
                  Excluir
                </Tooltip>
              }
            >
              <>
                {template.padrao === 'N' &&
                <div className="buttonExcluirAnotacao"
                     onClick={() => removeTemplate(template.id)}
                     ata-tip="Editar Introdução">
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                }
              </>
            </OverlayTrigger>
          </div>
        </th>
      </tr>
    ));

  function handleAtualizaListaTemplates () {

    trackPromise(
      caAPI.template.list().then(res => {
        setListaTemplates(res.data);
      }).catch(err => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar os template  " + err,
          icon: 'error'
        });
      }))
  }
  useEffect(() => {
    handleAtualizaListaTemplates();

  }, []);


  const updateListaTemplates = useCallback(novoTemplate => {
    setListaTemplates(lista => [novoTemplate, ...lista]);

  }, [])


  const removeTemplate = (id_template) => {
    Swal.fire({
      titleText: "Apagar o template?",
      text: "Tem certeza que deseja apagar o Template?",
      icon: 'question',
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Sim, Apagar!',
      cancelButtonText: 'Não!',
      customClass: {
        confirmButton: 'btn btn-primary mr-3',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    }).then(
      (result) => {
        if (result.value) {
          trackPromise(
            caAPI.template.deletar(id_template).then(res => {
              const templateIndex = listaTemplates.findIndex(template => template.id === id_template);
              let novaLista = [...listaTemplates];
              novaLista.splice(templateIndex, 1)
              setListaTemplates(novaLista);
            }).catch(e => {
              Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao excluir o Template " + e,
                icon: 'error'
              });
            })
          )
        }
      }
    )

  };


  return (<>
    <DommusBarraTitulo>
      <ItemBarraTitulo>
        Criação de Templates
      </ItemBarraTitulo>
      <ItemBarraTitulo classe="btn" onClick={() => setOpenModalNovoTemplate(true)}>
        <FontAwesomeIcon icon={faPlusCircle} color="#4caf50" size="lg" />
      </ItemBarraTitulo>
    </DommusBarraTitulo>

    <DommusToggle title='Templates' toggle={true} className="tampletes" bodyClass="dommus-toggle-height-fixed-atendimento">
      <DommusTabelaPaginada linhasCabecalho={thead} optionsTabela={{}} linhasCorpo={linhas} customClasses={'overflow-auto'}/>
    </DommusToggle>

    <DommusModalFullScreen titulo="Novo Template" open={openModalNovoTemplate} close={() => setOpenModalNovoTemplate(false)}>
      <ModalNovoTemplate handleAtualizaListaTemplates={handleAtualizaListaTemplates}
                         updateListaTemplates={()=>updateListaTemplates}
                         setOpenModalNovoTemplate={setOpenModalNovoTemplate} />
    </DommusModalFullScreen>

    <DommusModalFullScreen titulo="Editar Template"
                 open={openModalEditarTemplate}
                 close={() => setOpenModalEditarTemplate(false)}>
      <ModalNovoTemplate handleAtualizaListaTemplates={handleAtualizaListaTemplates}
                         updateListaTemplates={()=>updateListaTemplates}
                         setOpenModalNovoTemplate={setOpenModalEditarTemplate}
                         idTemplate={idTemplate}/>
    </DommusModalFullScreen>
  </>);
}
