import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import DommusTabelaPaginadaControlada from "../../../components/DommusTabelaPaginadaControlada";
import DommusTabelaPaginada from "../../../components/DommusTabelaPaginada";
import { Dropdown, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEye,
    faPen,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DisparoMassaContext } from "../../../contexts/DisparoMassaContext";
import { trackPromise } from "react-promise-tracker";
import DisparoMassaService from "../../../services/DisparoMassaService";
import Swal from "sweetalert2/src/sweetalert2";
import { successToast } from "../../../components/DommusToast";
import ModalDommus from "../../../components/Modal";
import { VisualizacaoBaseDisparo } from "./VisualizacaoBaseDisparo";

export function BaseDisparoEmMassa() {
    const [openVisualizarBaseDisparo, setOpenVisualizarBaseDisparo] =
        useState(false);
    const {
        listaBaseDisparo,
        setListaBaseDisparo,
        removerBaseDisparo,
        abrirFecharModalCadastro,
        setBaseDisparoSelecionada,
    } = useContext(DisparoMassaContext);

    const handleRemoverBaseDisparo = (id) => {
        Swal.fire({
            titleText: "Deseja excluir a base de disparo?",
            text: "Tem certeza que deseja excluir a base de disparo?",
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não, mantenha.",
            customClass: {
                confirmButton: "btn btn-primary mr-3",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                trackPromise(
                    DisparoMassaService.removeBaseDisparo(id)
                        .then(
                            (response) => {
                                removerBaseDisparo(id);
                                successToast.fire({
                                    text: "Base de disparo removido com sucesso.",
                                });
                            },
                            (error) => {
                                throw error;
                            }
                        )
                        .catch((error) => {
                            Swal.fire({
                                titleText: "Ooops...",
                                text:
                                    "Ocorreu um erro ao remover a base de disparo: " +
                                    error,
                                icon: "error",
                            });
                        })
                );
            }
        });
    };

    const cabecalhoTabela = (
        <tr>
            <th className="text-left" style={{ verticalAlign: "middle" }}>
                Nome da Base de Disparo
            </th>
            <th className="text-center" style={{ width: "20%" }}>
                Referência de Base
            </th>
            <th
                className="text-center"
                style={{ verticalAlign: "middle", width: "8%" }}
            >
                Ações
            </th>
        </tr>
    );

    const handleVisualizarBaseDisparo = (baseDisparo = null) => {
        setOpenVisualizarBaseDisparo((current) => !current);
        setBaseDisparoSelecionada(baseDisparo);
    };

    const linhasTabela = useMemo(() => {
        return listaBaseDisparo.map((linha, index) => (
            <tr
                key={`linha-${index}-${linha.dataCadastro}`}
                className="text-center"
            >
                <td className="text-left">{linha.nome}</td>
                <td style={{ textTransform: "capitalize" }}>
                    {linha?.base_referencia}
                </td>
                <td>
                    <Dropdown className="acoes-tabela">
                        <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faPen} color="#fff" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() =>
                                    handleVisualizarBaseDisparo(linha)
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faEye}
                                    color="#969696"
                                    style={{ marginRight: 10 }}
                                />
                                Visualizar
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => abrirFecharModalCadastro(linha)}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    color="#B5DEFF"
                                    style={{ marginRight: 10 }}
                                />
                                Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    handleRemoverBaseDisparo(linha?.id)
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color="#E05D5D"
                                    style={{ marginRight: 10 }}
                                />
                                Remover
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        ));
    }, [listaBaseDisparo]);

    return (
        <>
            {listaBaseDisparo.length ? (
                <DommusTabelaPaginada
                    linhasCabecalho={cabecalhoTabela}
                    optionsTabela={{}}
                    linhasCorpo={linhasTabela}
                    customClasses={"overflow-auto"}
                />
            ) : (
                <Alert variant="warning">
                    Nenhuma Base de disparo encontrada !
                </Alert>
            )}
            <ModalDommus
                titulo="Visualizar Base de Disparo"
                open={openVisualizarBaseDisparo}
                close={() => setOpenVisualizarBaseDisparo(false)}
            >
                <VisualizacaoBaseDisparo />
            </ModalDommus>
        </>
    );
}
