import React from 'react';
// import video from "../../../../../../assets/Video/video.mp4";
import "./style.scss";

export default function RecebeVideo({conteudo, url}) {
  return <>
    <video
      controls
      >
      <source src={url} type="video/mp4" />
    </video>

  </>;
}

