import React from 'react';
import { Card, Media } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import pageDoc from '@iconify-icons/foundation/page-doc';

export default function Documentacao({ documentacao }) {
    return <>
        <Card.Body>
            {documentacao.arquivos.map( documento =>
                <ul className="list-unstyled" key={documento.id_documento}>
                <Media as="li">
                    <Icon icon={pageDoc} color="#265d9c" width="1.5rem" height="1.5rem" />
                    <Media.Body>
                        <a href={documento.caminho}>
                            <Card.Text>{documento.descricao_documento}</Card.Text>
                        </a>
                    </Media.Body>
                </Media>
            </ul>
            )}
        </Card.Body>
    </>
}
