import React, { useContext, useEffect, useMemo, useState } from "react";
import { DisparoMassaContext } from "../../../../../contexts/DisparoMassaContext";
import { trackPromise } from "react-promise-tracker";
import { Alert, Nav, Table, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import DisparoMassaService from "../../../../../services/DisparoMassaService";
import DommusToggle from "../../../../../components/DommusToggle";
import { celularMask } from "../../../../../components/InputTextMask";
import Swal from "sweetalert2/src/sweetalert2";

export function VisualizacaoBaseProcessoOportunidade() {
    const { baseDisparoSelecionada } = useContext(DisparoMassaContext);
    const [listaIdsBase, setListaIdsBase] = useState([]);
    const [listaDadosBase, setListaDadosBase] = useState([]);
    const [fetchingLista, setFetchingLista] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const tamanhoPagina = 8;

    const fetchScroll = () => {
        if (hasMore) {
            setPagina((current) => current + 1);
        }
    };

    const buscarDadosId = () => {
        if (
            baseDisparoSelecionada.base_referencia !== "contato" &&
            !listaIdsBase.length
        ) {
            trackPromise(
                DisparoMassaService.buscarIdsBase(baseDisparoSelecionada.id)
            )
                .then((response) => {
                    setListaIdsBase(response?.data?.ids);
                })
                .catch((error) => {
                    Swal.fire('Ooops...', 'Houve um erro ao tentar buscar ids da base.' + error, 'error')
                });
        } else {
        }
    };

    useEffect(() => {
        if (listaIdsBase.length) {
            buscarDadosBase();
        }
    }, [listaIdsBase, pagina]);

    const buscarDadosBase = () => {
        let indexIncial = pagina * tamanhoPagina - tamanhoPagina;
        let indexFinal = pagina * tamanhoPagina;
        if (indexFinal > listaIdsBase.length - 1) {
            indexFinal = listaIdsBase.length;
            setHasMore(false);
        }
        let ids = listaIdsBase.slice(indexIncial, indexFinal);
        if (ids.length) {
            setFetchingLista(true);
            trackPromise(
                DisparoMassaService.buscarDadosBase(
                    baseDisparoSelecionada.id,
                    ids
                )
            )
                .then((response) => {
                    setListaDadosBase((current) => [
                        ...current,
                        ...response.data.envolvidos || [],
                    ]);
                    setFetchingLista(false);
                })
                .catch((error) => {
                    Swal.fire('Ooops...', 'Houve um erro ao tentar buscar dados da base.' + error, 'error')
                });
        }
    };

    const handleClickToggle = (toogle) => {
        buscarDadosId();
    };

    const opcoesTabela = useMemo(() => {
        return {
            responsive: true,
            bordered: true,
            hover: true,
        };
    }, []);

    return (
        <DommusToggle
            title="Base de dados"
            toggle={false}
            bodyClass="position-relative fixed-height"
            callback={handleClickToggle}
            containerId="scrollContainer"
        >
            {listaDadosBase.length ? (
                <Nav fill variant="pills">
                    <div className="w-100">
                        <InfiniteScroll
                            dataLength={listaDadosBase.length || 0} //This is important field to render the next data
                            next={fetchScroll}
                            hasMore={!fetchingLista && hasMore}
                            loader={
                                <Alert variant="info" className="alert-loading">
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size="sm"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </Spinner>
                                    <span className="scroll-carregando-msg">
                                        Carregando
                                    </span>
                                </Alert>
                            }
                            style={{ minHeight: "350px" }}
                            endMessage={
                                (fetchingLista && (
                                    <Alert
                                        variant="info"
                                        className="alert-loading"
                                    >
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                        <span className="scroll-carregando-msg">
                                            Carregando
                                        </span>
                                    </Alert>
                                )) || (
                                    <Alert
                                        variant={"warning"}
                                        className="alert-section"
                                    >
                                        <strong>
                                            Não há mais atendimentos a carregar!
                                        </strong>
                                    </Alert>
                                )
                            }
                            scrollThreshold={0.5}
                            scrollableTarget="scrollContainer"
                        >
                            <div className="card card-table">
                                <Table
                                    className={"tabela-dommus"}
                                    {...opcoesTabela}
                                >
                                    <thead className={"thead-dark"}>
                                        <tr>
                                            <th
                                                style={{
                                                    textTransform: "capitalize",
                                                    width: "10%",
                                                }}
                                            >
                                                {
                                                    baseDisparoSelecionada.base_referencia
                                                }
                                            </th>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Telefone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listaDadosBase.map((envolvido) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {envolvido?.id_oportunidade ??
                                                            envolvido?.id_processo ??
                                                            ""}
                                                    </td>
                                                    <td>{envolvido?.nome ?? "----"}</td>
                                                    <td>{envolvido?.email ?? "----"}</td>
                                                    <td>
                                                        {envolvido?.celular ? celularMask(envolvido?.celular) :  
                                                            envolvido?.telefone ? celularMask(envolvido?.telefone) : 
                                                            "----"
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </InfiniteScroll>
                    </div>
                </Nav>
            ) : (
                <Alert variant={"warning"} className="alert-section">
                    <strong>Nenhum dado encontrado!</strong>
                </Alert>
            )}
        </DommusToggle>
    );
}
