import React from 'react';
import { Card } from 'react-bootstrap';
import { cnpjMask, cepMask, realMoeda} from '../../../../components/InputTextMask';
import moment from 'moment';

export default function Contrato({ contrato }) {
    const aprovacaoContrato = {
        A: 'Aprovado',
        R: 'Reprovado',
        E: 'Em Análise',
        C: 'Carregado'
    }

    return <>
        <Card.Body>
            <div>
                <strong>Data da Venda:</strong>
                <br/>{moment(contrato.venda_data).format('DD/MM/YYYY')}
            </div>
            <div>
                <strong>Data da Contabilização:</strong>
                <br/>{contrato.venda_data_contabilizacao}
            </div>
            <div>
                <strong>Aprovação Contrato:</strong>
                <br/>{aprovacaoContrato[contrato.processo_aprovacao_contrato]} {/* (A - APROVADO / R - REPROVADO / E - EM ANÁLISE / C - CARREGADO) */}
            </div>
            <hr />
            <div>
                <strong>Valor da Venda:</strong>
                <br/>R${realMoeda(contrato.venda_total_contrato)}
            </div>
            <div>
                <strong>Recursos Próprios:</strong>
                <br/>R${realMoeda(contrato.venda_total_recursos_proprios)} {/* (Soma das parcelas da entrada) */}
            </div>
            <div>
                <strong>Recursos Bancários:</strong>
                <br/>R${realMoeda(contrato.analise_credito_total_recursos_bancarios)} {/* (Soma das parcelas de FGTS + Subsidio + Financiamento atreladas à venda) */}
            </div>
            <hr />
            <div>
                <strong>Vendedora:</strong>
                <br/>{contrato.contratada_razao_social}
            </div>
            <div>
                <strong>CNPJ:</strong>
                <br/>{cnpjMask(contrato.contratada_cnpj)}
            </div>
            <div>
                <strong>Endereço:</strong>
                <br/>{contrato.contratada_endereco}, {contrato.contratada_numero} - {contrato.contratada_complemento}
            </div>
            <div>
                <strong>Bairro:</strong>
                <br/>{contrato.contratada_bairro}
            </div>
            <div>
                <strong>Cidade/UF:</strong>
                <br/>{contrato.contratada_cidade} / {contrato.contratada_uf}
            </div>
            <div>
                <strong>CEP:</strong>
                <br/>{cepMask(contrato.contratada_cep)}
            </div>
        </Card.Body>
    </>
}
