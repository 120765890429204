import React, { useContext, useEffect, useMemo, useState } from "react";
import DommusToggle from "../../../../../components/DommusToggle";
import { DisparoMassaContext } from "../../../../../contexts/DisparoMassaContext";
import { Alert, Nav, Spinner, Table } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import DisparoMassaService from "../../../../../services/DisparoMassaService";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2/src/sweetalert2";
import { celularMask } from "../../../../../components/InputTextMask";

export function VisualizacaoBaseContato() {
    const { baseDisparoSelecionada } = useContext(DisparoMassaContext);
    const [fetchingLista, setFetchingLista] = useState(false);
    const [listaBaseTotal, setListaBaseTotal] = useState([]);
    const [listaDadosBase, setListaDadosBase] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const tamanhoPagina = 8;

    const fetchScroll = () => {
        if (hasMore) {
            setPagina((current) => current + 1);
        }
    };
    const handleClickToggle = (toggle) => {
        if(!toggle && !listaDadosBase.length){
            buscarDadosBase();
        }
    };

    const addItemLista = () => {
        setFetchingLista(true);
        let indexIncial = pagina * tamanhoPagina - tamanhoPagina;
        let indexFinal = pagina * tamanhoPagina;
        if (indexFinal > listaBaseTotal.length - 1) {
            indexFinal = listaBaseTotal.length;
            setHasMore(false);
        }
        let temp = listaBaseTotal.slice(indexIncial, indexFinal);
        if (temp.length) {
            setListaDadosBase((current) => [...current, ...temp]);
        }
        setFetchingLista(false);
    };

    useEffect(() => {
        if(listaBaseTotal.length){
            addItemLista();
        }
    }, [pagina, listaBaseTotal]);

    const buscarDadosBase = () => {
        trackPromise(
            DisparoMassaService.buscarDadosBase(baseDisparoSelecionada.id)
        )
            .then((response) => {
                let temp = response.data?.envolvidos;
                setListaBaseTotal(temp ?? []);
            })
            .catch((error) => {
                Swal.fire(
                    "Ooops...",
                    "Houve um erro ao tentar buscar dados da base." + error,
                    "error"
                );
            });
    };

    const opcoesTabela = useMemo(() => {
        return {
            responsive: true,
            bordered: true,
            hover: true,
        };
    }, []);

    return (
        <DommusToggle
            title="Base de dados"
            toggle={false}
            bodyClass="position-relative fixed-height"
            callback={handleClickToggle}
            containerId="scrollContainer"
        >
            {listaDadosBase.length ? (
                <Nav fill variant="pills">
                    <div className="w-100">
                        <InfiniteScroll
                            dataLength={listaDadosBase.length || 0} //This is important field to render the next data
                            next={fetchScroll}
                            hasMore={!fetchingLista && hasMore}
                            loader={
                                <Alert variant="info" className="alert-loading">
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size="sm"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </Spinner>
                                    <span className="scroll-carregando-msg">
                                        Carregando
                                    </span>
                                </Alert>
                            }
                            style={{ minHeight: "350px" }}
                            endMessage={
                                (fetchingLista && (
                                    <Alert
                                        variant="info"
                                        className="alert-loading"
                                    >
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                        <span className="scroll-carregando-msg">
                                            Carregando
                                        </span>
                                    </Alert>
                                )) || (
                                    <Alert
                                        variant={"warning"}
                                        className="alert-section"
                                    >
                                        <strong>
                                            Não há mais atendimentos a carregar!
                                        </strong>
                                    </Alert>
                                )
                            }
                            scrollThreshold={0.5}
                            scrollableTarget="scrollContainer"
                        >
                            <div className="card card-table">
                                <Table
                                    className={"tabela-dommus"}
                                    {...opcoesTabela}
                                >
                                    <thead className={"thead-dark"}>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Telefone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listaDadosBase.map((envolvido) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {envolvido?.nome ??
                                                            "----"}
                                                    </td>
                                                    <td>
                                                        {envolvido?.email ? envolvido?.email : "----"}
                                                    </td>
                                                    <td>
                                                        {envolvido?.telefone
                                                            ? celularMask(
                                                                  envolvido?.telefone
                                                              )
                                                            : "----"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </InfiniteScroll>
                    </div>
                </Nav>
            ) : (
                <Alert variant={"warning"} className="alert-section">
                    <strong>Nenhum dado encontrado!</strong>
                </Alert>
            )}
        </DommusToggle>
    );
}
