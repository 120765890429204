import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Alert } from "react-bootstrap";
import moment from "moment";
import './index.scss';
import { trackPromise } from "react-promise-tracker";
import { AtendimentoContext } from "../../../contexts/AtendimentoContext";
import formatDommus from "../../../helpers/format";
import caAPI from "../../../services/caAPI";
import DommusTabelaPaginadaControlada from "../../DommusTabelaPaginadaControlada";
import { VoipContext } from "../../../contexts/VoipContext";
import DommusToggle from "../../DommusToggle";

export default function RegistroChamadasPerdidas () {
  const [listaChamadasPerdidas, setListaChamadasPerdidas] = useState([]);
  const [tamanhoPagina, setTamanhoPagina] = useState(5);
  const [total, setTotal] = useState(0);
  const [pagina, setPagina] = useState(1);
  const {atendimentoAtivo} = useContext(AtendimentoContext);
  const {chamadasPerdidas} = useContext(VoipContext);

  const count = <div className="title-chamadas-perdidas"><span>{'Chamadas Perdidas '}</span><div className="contador-chamadas-perdidas">{chamadasPerdidas.length}</div></div>

  const linhas = useMemo(() => {
    return (
      chamadasPerdidas && chamadasPerdidas.map((item, index) => {
        return (
          <tr key={index}>
            <td className="items-rows text-center">
              <div className="items-rows text-center">
                {moment(item.criado_em).format('DD/MM/YYYY HH:mm')}
              </div>
            </td>
          </tr>
        );
      })
    );
  }, [chamadasPerdidas]);

  const thead = (
    <tr>
      <th className="text-center">Data e Hora da Chamada Perdida:</th>
    </tr>
  )


  return(
    <div className="chamadas-perdidas position-absolute d-none">
      <DommusToggle  title={count} toggle={false} bodyClass="dommus-toggle-chamadas-perdidas position-relative" angle={true}>
        <div className="registro-atendimentos-section">
          {linhas.length ?
            <DommusTabelaPaginadaControlada
              linhasCabecalho={thead}
              optionsTabela={{ bordered: false }}
              linhasCorpo={linhas}
              pagina={pagina}
              setPagina={setPagina}
              setTamanhoPagina={setTamanhoPagina}
              tamanhoPagina={tamanhoPagina}
              tamanhoTotal={total}
            />
            : <Alert className="alert-tabela-chamadas-perdidas" variant="warning" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              Sem chamadas perdidas!
            </Alert>
          }
        </div>
      </DommusToggle>
    </div>
  )
}