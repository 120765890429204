import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import DommusToggle from "../../../../../components/DommusToggle";
import { Alert, Col, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import moment from "moment";
import { DisparoMassaContext } from "../../../../../contexts/DisparoMassaContext";
import { celularMask } from "../../../../../components/InputTextMask";
import { MeioComunicacaoContext } from "../../../../../contexts/MeioComunicacaoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faEnvelopeOpen,
    faLink,
    faPaperPlane,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import DisparoMassaService from "../../../../../services/DisparoMassaService";
import Swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";

export function ExecucaoDisparoItem({ execucaoDisparo, meioComunicacao }) {
    const { listaBaseDisparo } = useContext(DisparoMassaContext);
    const [pagina, setPagina] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [fetchingLista, setFetchingLista] = useState(false);
    const [listaLogsDisparo, setListaLogsDisparo] = useState([]);
    const [rows, setRow] = useState([]);
    const { resolverSlugMeioComunicacao } = useContext(MeioComunicacaoContext);
    
    const tamanhoPagina = 8;

    const baseSelecionada = useMemo(() => {
        if (listaBaseDisparo.length) {
            return listaBaseDisparo.find(
                (base) => base.id === execucaoDisparo.id_base_disparo
            );
        }
        return {};
    }, [execucaoDisparo]);

    const resolverTipoIntevalo = useMemo(() => {
        switch (execucaoDisparo.tipo_intervalo_recorrencia) {
            case "M":
                return "Minutos";
            case "H":
                return "Horas";
            case "D":
                return "Dias";
            default:
                return "Não encontrado!";
        }
    }, [execucaoDisparo]);

    const resolverReferencia = (log) => {
        switch (log.referencia) {
            case "oportunidade":
                return `OPV- ${log.id_referencia}`;
            case "processo":
                return `Processo - ${log.id_referencia}`;
            case "contato":
                return `Contato`;
            default:
                return "Não encontrada!";
        }
    };

    const buscarLogsDisparos = ()=>{
        setFetchingLista(true);
        DisparoMassaService.buscarLogsDisparos(execucaoDisparo.id, tamanhoPagina, pagina).then((response)=>{
            let temp = [...listaLogsDisparo, ...response?.data?.dados];
            setListaLogsDisparo(temp);
            setHasMore(temp.length !== response?.data?.total)            
            setFetchingLista(false);
        }).catch((error)=>{
            Swal.fire({
                titleText: "Erro",
                text: "Houve ao carregar disparos" + error,
                icon: 'error'
            });
        })
    }

    const handleClickToggle = (toggle) => {
        if(!toggle && !listaLogsDisparo.length){
            buscarLogsDisparos();
        }
    };

    const fetchScroll = () => {
        if (hasMore) {
            setPagina((current) => current + 1);
        }
    };

    useEffect(()=>{
        if(pagina && pagina !== 1){
            buscarLogsDisparos()
        }
    },[pagina])

    const resolveBadgeStatus = ()=>{
        let label = "";
        switch (execucaoDisparo.status_execucao) {
            case 'concluido':
                label = "Concluído";
                break;        
            default:
                label = execucaoDisparo.status_execucao.toUpperCase();
                break;
        }
        return (
            <span
            className={`badge-status ${execucaoDisparo.status_execucao}`}
            >
                {label}
            </span>
        )
    }

    const handleGerarRelatorio = ()=>{
        Swal.fire({
                titleText: "Relatório de Execução?",
                text: "Deseja gerar relatório de execução de disparo?",
                icon: 'question',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sim, gerar!',
                cancelButtonText: 'Não!',
                customClass: {
                confirmButton: 'btn btn-primary mr-3',
                cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    trackPromise(DisparoMassaService.gerarRelatorio(execucaoDisparo.id)).then((response)=>{
                        const csvData = response.data;
                        const blob = new Blob([csvData], { type: "text/csv" });
                        const csvURL = URL.createObjectURL(blob);
            
                        const link = document.createElement('a');
                        link.href = csvURL;
                        link.setAttribute('download', 'relatório-disparo-conecta-'+ (new Date().toISOString()) +'.csv');
            
                        document.body.appendChild(link);
                        link.click();
                    }).catch((error)=>{
                        Swal.fire({
                            titleText: "Erro",
                            text: "Houve ao gerar relatório" + error,
                            icon: 'error'
                        });
                    })
                }
            }
        )
        
    }

    const title = useMemo(() => {
        return (
            <div className="title-disparo">
                <div>
                    <span className="data-disparo">
                        {`Disparo inicial em ${moment(
                            execucaoDisparo.data_hora_disparo
                        ).format("DD/MM/YYYY")} às ${moment(
                            execucaoDisparo.data_hora_disparo
                        ).format("HH:mm")}`}
                    </span>
                    <span className="recorrencia">
                        {execucaoDisparo?.recorrencia
                            ? ` | Com ${execucaoDisparo?.recorrencia} recorrências no intervalo de ${execucaoDisparo.intervalo_recorrencia} ${resolverTipoIntevalo}`
                            : ""}
                    </span>                    
                </div>
                <div className="section-badges">
                    {resolveBadgeStatus()}
                    <span 
                        className="btn-imprimir-relatorio"
                        onClick={()=>{
                            handleGerarRelatorio();
                        }}
                    >
                        <FontAwesomeIcon icon={faPrint}/>
                    </span>
                </div>
            </div>
        );
    }, [execucaoDisparo]);

    const header = useMemo(() => {
        let arrayDisparos = [];
        if (execucaoDisparo.recorrencia) {
            for (let index = 0; index < execucaoDisparo.recorrencia; index++) {
                arrayDisparos.push(index + 1);
            }
        }
        return (
            <tr>
                <th className="text-left" style={{ width: "8%" }}>
                    Referência
                </th>
                <th className="text-left" style={{ maxWidth: "15%" }}>
                    Nome
                </th>
                <th className="text-left" style={{ maxWidth: "20%" }}>
                    Contato Disparado
                </th>
                <th className="text-left" style={{ width: "20%" }}>
                    Base
                </th>
                {arrayDisparos.length ? (
                    arrayDisparos.map((disparo, index) => {
                        return (
                            <th
                                key={index}
                                className="text-center"
                                style={{ minWidth: "9%" }}
                            >
                                Disparo {disparo}
                            </th>
                        );
                    })
                ) : (
                    <th className="text-center" style={{ width: "12%" }}>
                        Disparo
                    </th>
                )}
            </tr>
        );
    }, [listaLogsDisparo]);

    const statusEmail = (dadosStatus) => {

        return (
            <div className="section-status-disparo">
                <OverlayTrigger
                    key="badgeEnviado"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-enviado`}>{dadosStatus?.enviado ? `Enviado em: ${moment(dadosStatus.enviado).format('DD/MM/YYYY HH:mm')}` : "Não enviado"}</Tooltip>}
                >
                    <span className={`badge-status-disparo ${dadosStatus?.enviado ? "enviado" : ""}`}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    key="badgeEntregue"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-entregue`}>{dadosStatus?.entregue ? `Entregue em: ${moment(dadosStatus.entregue).format('DD/MM/YYYY HH:mm')}` : "Não entregue"}</Tooltip>
                    }
                >
                    <span className={`badge-status-disparo ${dadosStatus?.entregue ? "entregue" : ""}`}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    key="badgeLido"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-Lido`}>{
                        dadosStatus?.lido?.length ? `Lido em: ${dadosStatus?.lido.map((data) => `\u25CF ${moment(data).format('DD/MM/YYYY HH:mm')}`)}` : "Não lido"}</Tooltip>}
                >
                    <span className={`badge-status-disparo ${dadosStatus?.lido.length ? "lido" : ""}`}>
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                        {dadosStatus?.lido?.length > 0 && 
                            <span className="qts-lido">{dadosStatus?.lido?.length}</span>
                        }
                    </span>
                </OverlayTrigger>
                {/* <OverlayTrigger
                    key="badgeAnexo"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-anexo`}>{dadosStatus.download_anexo ? `Download Anexo em: ${moment(dadosStatus.download_anexo).format('DD/MM/YYYY HH:mm')}` : "Não baixado"}</Tooltip>
                    }
                >
                    <span className={`badge-status-disparo ${dadosStatus.download_anexo ? "anexo" : ""}`}>
                        <FontAwesomeIcon icon={faDownload} />
                    </span>
                </OverlayTrigger> */}
                <OverlayTrigger
                    key="badgeLink"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-Link`}>{dadosStatus?.click_link ? `Clicado link em: ${moment(dadosStatus.click_link).format('DD/MM/YYYY HH:mm')}` : "Não clicado"}</Tooltip>
                    }
                >
                    <span className={`badge-status-disparo ${dadosStatus?.click_link ? "link" : ""}`}>
                        <FontAwesomeIcon icon={faLink} />
                    </span>
                </OverlayTrigger>
            </div>
        );
    };

    const statusWhatsapp = (dadosStatus) => {
        return (
            <div className="section-status-disparo">
                <OverlayTrigger
                    key="badgeEnviado"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-enviado`}>{dadosStatus.enviado ? `Enviado em: ${moment(dadosStatus.enviado).format('DD/MM/YYYY HH:mm')}` : "Não enviado"}</Tooltip>}
                >
                    <span className={`badge-status-disparo ${dadosStatus.enviado ? "enviado" : ""}`}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    key="badgeEntregue"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-entregue`}>{dadosStatus.entregue ? `Entregue em: ${moment(dadosStatus.entregue).format('DD/MM/YYYY HH:mm')}` : "Não entregue"}</Tooltip>
                    }
                >
                    <span className={`badge-status-disparo ${dadosStatus.entregue ? "entregue" : ""}`}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    key="badgeLido"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-Lido`}>{dadosStatus.lido ? `Lido em: ${moment(dadosStatus.lido).format('DD/MM/YYYY HH:mm')}` : "Não lido"}</Tooltip>}
                >
                    <span className={`badge-status-disparo ${dadosStatus.lido ? "lido" : ""}`}>
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                    </span>
                </OverlayTrigger>
            </div>
        );
    };

    const statusSms = (dadosStatus) => {
        return (
            <div className="section-status-disparo">
                <OverlayTrigger
                    key="badgeEnviado"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-enviado`}>{dadosStatus.enviado ? `Enviado em: ${moment(dadosStatus.enviado).format('DD/MM/YYYY HH:mm')}` : "Não enviado"}</Tooltip>}
                >
                    <span className={`badge-status-disparo ${dadosStatus.enviado ? "enviado" : ""}`}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    key="badgeEntregue"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-entregue`}>{dadosStatus.entregue ? `Entregue em: ${moment(dadosStatus.entregue).format('DD/MM/YYYY HH:mm')}` : "Não entregue"}</Tooltip>
                    }
                >
                    <span className={`badge-status-disparo ${dadosStatus.entregue ? "entregue" : ""}`}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </OverlayTrigger>               
            </div>
        );
    };

    const resolverStatusDisparo = (itemDisparo) => {
        let slugMeio = resolverSlugMeioComunicacao(meioComunicacao);
        let dadosStatus = {
            enviado: false,
            entregue: false,
            lido: false,
            download_anexo: false,
            click_link: false
        }
        if (
            itemDisparo.status_execucao === "pendente" ||
            itemDisparo.status_execucao === "erro"
        ) {
            return (
                <span
                    className={`badge-status-log ${
                        itemDisparo.status_execucao ?? ""
                    }`}
                >
                    {itemDisparo.status_execucao}
                </span>
            );
        } else {
            switch (slugMeio) {
                case "sms":
                    return statusSms(dadosStatus);
                case "email":
                    dadosStatus.lido = [];
                    itemDisparo?.monitoramento.forEach((monitoramento)=>{
                        switch(monitoramento.evento){
                            case "processed":
                                dadosStatus.enviado = monitoramento?.data_hora_evento;
                                break;
                            case "delivered":
                                dadosStatus.entregue = monitoramento?.data_hora_evento;
                                break;
                            case "open":
                                dadosStatus.lido.push(monitoramento?.data_hora_evento);
                                break;
                            case "click":
                                dadosStatus.click_link = monitoramento?.data_hora_evento;
                                break;
                            default:
                                break;
                        }
                    })
                    return statusEmail(dadosStatus);
                case "whatsapp":
                    itemDisparo?.monitoramento.forEach((monitoramento)=>{
                        switch(monitoramento.evento){
                            case "sent":
                                dadosStatus.enviado = monitoramento?.data_hora_evento;
                                break;
                            case "delivered":
                                dadosStatus.entregue = monitoramento?.data_hora_evento;
                                break;
                            case "read":
                                dadosStatus.lido = monitoramento?.data_hora_evento;
                                break;
                            default:
                                break;
                        }
                    })
                    return statusWhatsapp(dadosStatus);
                default:
                    return <></>;
            }
        }
    };

    useEffect(()=>{
        setRow(
            listaLogsDisparo.map((log, index) => {
                let isEmail = log.contato ? log.contato.includes("@") : null;
                return (
                    <tr key={`${log.id_log_disparo_massa}-${index}`} className="text-center">
                        <td className="text-left">{resolverReferencia(log)}</td>
                        <td className="text-left">{log?.nome_envolvido_proponente ?? "Não encontrado"}</td>
                        <td className="text-left">
                            {isEmail ? log.contato : celularMask(log?.contato)}
                        </td>
                        <td className="text-left">
                            {baseSelecionada
                                ? baseSelecionada?.nome
                                : "Não encontrada"}
                        </td>
                        {log?.disparos?.map((itemDisparo) => {
                            return (
                                <td className="text-center p-0 pt-2">
                                    <div className="data-disparo-item-section">
                                        <span>
                                            {`${moment(
                                                itemDisparo.data_hora_disparo
                                            ).format("DD/MM/YYYY")} às ${moment(
                                                itemDisparo.data_hora_disparo
                                            ).format("HH:mm")}`}
                                        </span>
                                        {resolverStatusDisparo(itemDisparo)}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                );
            })
        );
    },[listaLogsDisparo])
    
    return (
        <Col md={12}>
            <DommusToggle
                title={title} toggle={false}
                bodyClass="position-relative fixed-height"
                callback={handleClickToggle}
                containerId="scrollContainer"
            >
                {rows.length ? (                             
                            <InfiniteScroll
                                dataLength={rows.length || 0}
                                next={fetchScroll}
                                hasMore={!fetchingLista && hasMore}
                                loader={
                                    <Alert variant="info" className="alert-loading">
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                        <span className="scroll-carregando-msg">
                                            Carregando ...
                                        </span>
                                    </Alert>
                                }
                                style={{ minHeight: "350px" }}
                                endMessage={
                                    (fetchingLista && (
                                        <Alert
                                            variant="info"
                                            className="alert-loading"
                                        >
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </Spinner>
                                            <span className="scroll-carregando-msg">
                                                Carregando ...
                                            </span>
                                        </Alert>
                                    )) || (
                                        <Alert
                                            variant={"warning"}
                                            className="alert-section"
                                        >
                                            <strong>
                                                Não há mais disparos a carregar!
                                            </strong>
                                        </Alert>
                                    )
                                }
                                scrollThreshold={0.5}
                                scrollableTarget="scrollContainer"
                            >
                                <Table className={"tabela-dommus"}>
                                    <thead className={"thead-dark"}>
                                        {header}
                                    </thead>
                                    <tbody style={{height: "200px", overflowY: "auto"}}>
                                        {rows}
                                    </tbody>                        
                                </Table>                        
                            </InfiniteScroll>
                ):<Alert variant="info">Nenhum log disparo encontrado!</Alert>}
            </DommusToggle>
        </Col>
    );
}
