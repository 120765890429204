import React, { useContext } from "react";
import "./index.css";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DisparoMassaContext } from "../../../../contexts/DisparoMassaContext";
import { VisualizacaoBaseProcessoOportunidade } from "./VisualizacaoBaseProcessoOportunidade";
import { VisualizacaoBaseContato } from "./VisualizacaoBaseContato";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

export function VisualizacaoBaseDisparo() {
    const { baseDisparoSelecionada } = useContext(DisparoMassaContext);

    return (
        <Container fluid>
            <Row>
                <Col md={5}>
                    <div className="disparo-info-section">
                        <span className="label">Nome</span>
                        <span className="value">
                            {baseDisparoSelecionada.nome}
                        </span>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="disparo-info-section">
                        <span className="label">Referẽncia Base Disparo</span>
                        <span className="value">
                            {baseDisparoSelecionada?.base_referencia}
                        </span>
                    </div>
                </Col>
                {baseDisparoSelecionada.base_referencia === "contato" ? (
                    <Col md={4}>
                        <div className="disparo-info-section">
                            <span className="label">Arquivo Base</span>
                            <OverlayTrigger
                                key="arquivo"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-arquivo`}>
                                        Clique para baixar!
                                    </Tooltip>
                                }
                            >
                                <a
                                    className="badge-arquivo"
                                    href={
                                        baseDisparoSelecionada.url_arquivo_base_disparo
                                    }
                                    download
                                >
                                    <FontAwesomeIcon icon={faPaperclip} />
                                    <span>
                                        {
                                            baseDisparoSelecionada.nome_arquivo_base_disparo
                                        }
                                    </span>
                                </a>
                            </OverlayTrigger>
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            <Row className="disparo-dados-section mt-3">
                <Col>
                    {baseDisparoSelecionada.base_referencia === "contato" ? (
                        <VisualizacaoBaseContato />
                    ) : (
                        <VisualizacaoBaseProcessoOportunidade />
                    )}
                </Col>
            </Row>
        </Container>
    );
}
