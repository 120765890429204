import React, { createContext, useState, useEffect } from "react";
import { MapeamentosErrosTwillioService } from "../services/MapeamentosErrosTwillioService";

export const MapeamentoErrosTwillioContext = createContext({});

export function MapeamentoErrosTwillioProvider({ children }) {
  const [mapeamentosErrosTwillio, setMapeamentosErrosTwillio] = useState([]);

  function buscarMapeamentosErrosTwillio() {
    if(mapeamentosErrosTwillio.length) {
      return
    }
    MapeamentosErrosTwillioService.index()
      .then(response => setMapeamentosErrosTwillio(response.data))
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  function resolverMensagemErroTwillioPorCodigoTwillio(codigoErro) {
    if(!mapeamentosErrosTwillio.length) {
      return 'Erro. Mensagem não entregue.'
    }
    return mapeamentosErrosTwillio.find(mapeamento => mapeamento.codigo_erro_twillio == codigoErro)?.mensagem_erro_dommus || 'Erro. Mensagem não entregue.'
  }

  return (
    <MapeamentoErrosTwillioContext.Provider value={{ mapeamentosErrosTwillio, resolverMensagemErroTwillioPorCodigoTwillio, buscarMapeamentosErrosTwillio }}>
      {children}
    </MapeamentoErrosTwillioContext.Provider>
  )
}
