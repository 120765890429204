import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import moment from "moment";
import caAPI from "../../../../services/caAPI";
import { trackPromise } from "react-promise-tracker";
import './style.scss';
import { Interweave } from 'interweave';
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { InputMensagens } from '../../../../components/InputMensage';
import InfiniteScroll from "react-infinite-scroll-component";
import { AtendentesContext } from '../../../../contexts/AtendentesContext';

// const idMidiaSms = 1;

export default function SmsComponent({ atendimento }) {

    const [mensagemInput, setMensagemInput] = useState('');
    const [listaMensagens, setListaMensagens] = useState(atendimento.conteudos);
    // const [listaTemplates, setListaTemplates] = useState([]);
    const messageContainerRef = useRef();
    const [atendimentoAtivo, setAtendimentoAtivo] = useState(atendimento);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const {resolverNomeAtendente} = useContext(AtendentesContext);

    useEffect(() => {
        setAtendimentoAtivo(atendimento)
    }, [atendimento]);

    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }
    useEffect(() => {
        setListaMensagens(atendimentoAtivo.conteudos);
        setHasMore(atendimentoAtivo.hasMore);
    }, [atendimentoAtivo]);

    const { channel } = useContext(WebSocketContext);
    useEffect(() => {
        channel.bind('sms.' + atendimentoAtivo.id, function (data) {
            const newMessage = JSON.parse(data.mensagem);
            setListaMensagens(messages => [...messages, newMessage]);
        })

    }, [channel, atendimentoAtivo])

    //busca templates
    //
    // useEffect(() => {
    //     trackPromise(
    //         caAPI.meio_comunicacao.getTemplate(idMidiaSms).then(res => {
    //             setListaTemplates(res.data);
    //             console.log("lista", res.data)
    //         }).catch(err => {
    //             Swal.fire({
    //                 titleText: "Erro",
    //                 text: "Houve um problema ao buscar os templates " + err,
    //                 icon: 'error'
    //             });
    //         }),
    //     )
    // }, [idMidiaSms])

    const enviarMensagem = useCallback(() => {
        const data = {
            "mensagem": mensagemInput
        };
        trackPromise(
            caAPI.atendimento.sms.enviar(atendimentoAtivo.id, data).then(res => {
                setListaMensagens(messages => [res.data, ...messages]);
            }).catch(e => alert(e))
        )
        setMensagemInput('');
    }, [atendimentoAtivo.id, mensagemInput]);

    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    const mensagens = useMemo(() => {
        let date = null;
        let separator = null;
        let isFirst = true;
        if (listaMensagens) {
            return listaMensagens.map(msg => {
              let cadastrado_por = (msg.direcao === 'enviado' && resolverNomeAtendente(msg.criado_por)) || (atendimentoAtivo.nome || "Destinatário");
                let msg_date = moment(msg.criado_em).format('DD/MM/YYYY');
                if (!date || date !== msg_date) {
                    date = msg_date;
                    separator = <div className="separador">{msg_date}</div>;
                } else {
                    separator = '';
                    isFirst = false;
                }
                return <div key={msg.id} className="smsLista">
                    {!isFirst && separator}
                    <div key={msg.id} className={'mensagem-item ' + (msg.direcao === 'recebido' ? 'recebida' : 'enviada')}>
                        <Interweave content={msg.conteudo} />
                    </div>
                    <div className="dataSms">{moment(msg.criado_em).format('HH:mm')} - {cadastrado_por}</div>
                </div>

            });
        }
        messageContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [listaMensagens]);

    const handleLoadMoreMessages = () => {
        const messages = [...listaMensagens];

        caAPI.atendimento.getMessages(atendimento.id, Number(currentPage) + 1).then(res => {
            messages.push(res.data);
            setCurrentPage(res.data.currentPage)
            setListaMensagens([...listaMensagens, ...res.data.conteudos]);
            setHasMore(res.data.hasMore);
        }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar mais mensagens",
                icon: 'error'
            });
            return;
        });

    };

    return (
        <>
            <div className="sms-container" id="scrollableDiv" >
                <InfiniteScroll
                    className="sms-container"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    dataLength={mensagens.length}
                    next={handleLoadMoreMessages}
                    hasMore={hasMore}
                    inverse={true}
                    loader={<Spinner animation="border" style={{ color: '#265d9c' }} />}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Fim das Mensagens</b>
                        </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={() => { }}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    scrollableTarget="scrollableDiv"
                >
                    {mensagens}
                </InfiniteScroll>
            </div>
            <div ref={messageContainerRef} />
            <div>
                <InputMensagens
                    templateMensagens={'sms'}
                    disabledAnexos={true}
                    disabledInputMessage={false}
                    mensagemInput={mensagemInput}
                    enviarMensagem={enviarMensagem}
                    receberMensagemInicial={enviarMensagem}
                    atendimento={atendimento}
                    recebeMensagemInput={recebeMensagemInput}
                    changeSubmitKey={changeSubmitKey} />
            </div>
        </>
    );
}
