import React, {useRef, useEffect} from 'react';
import './styles.css'

 export default function DommusScrollAnchor(){
   const anchorRef = useRef(null);
   useEffect(() => {
     if(anchorRef.current){
       anchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
     }
   }, [anchorRef.current])
   return <div className="scrollAnchor" ref={anchorRef}></div>;
 }
