import "./index.css";
import React, { useState, useEffect } from "react";
import caAPI from "../../services/caAPI";
import ReactTooltip from "react-tooltip";
import user from "../../assets/usuario.svg";

export default function DommusOnlineFlag() {
  const [status, setStatus] = useState(true);
  const [iniciado, setIniciado] = useState(false);

  const statusClass = status ? "onlineFlag" : "offlineFlag";
  const statusText = status ? "online" : "offline";
  const atualizarVisto = () => {
    caAPI.perfil.buscarVistoUltimo().then((response) => {
      const agora = new Date();
      const dados = response.data;
      const visto_ultimo = new Date(dados.visto_ultimo);
      const vistoUltimoComOffset = new Date(visto_ultimo.getTime() + ( (dados.tempo_configuracao || 15) * 60 * 1000 ));
      if (vistoUltimoComOffset > agora) {
        setStatus(true);
      } else {
        setStatus(false);
      }

      setTimeout(() => {
        atualizarVisto();
      }, 60000);
    });
  };
  useEffect(() => {
    if(!iniciado){
      setIniciado(true);
      atualizarVisto();
    }

  }, [atualizarVisto]);

  return (
    <>
      <ReactTooltip />
      <picture className={"picture-topo"}>
        <img
          className={statusClass}
          src={user}
          data-tip={
            "Olá, " +
            caAPI.getLocalState("nomeUsuario") +
            ". Você está " +
            statusText +
            ". "
          }
        />
      </picture>
    </>
  );
};
