import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Row, Container, Col, InputGroup, Card, CardGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import MidiaSocialIcone from '../../../components/MidiaSocialIcone';
import caAPI from '../../../services/caAPI';
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilter,faEraser, faWrench, faMinusCircle, faDownload} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR'
import './style.scss';
import { celularMask } from '../../../components/InputTextMask';
import { useForm, Controller } from "react-hook-form";
import { AtendimentoContext } from '../../../contexts/AtendimentoContext';
import DommusLoadingArea from '../../../components/DommusLoading/DommusLoadingArea';
import { PerfilUsuarioService } from '../../../services/PerfilUsuarioService';
import { errorToast, successToast } from '../../../components/DommusToast';
import {isMobile, BrowserView, MobileView} from 'react-device-detect';
import DommusSwitch from '../../../components/DommusSwitch';

const startDate = new Date();

function FiltrarAtendimentos({setSalvarFiltros, setTipoDataFiltro, setDataInicio, setDataFim}) {
    const { setListaAtendimentos, filtrarListaAtendimentos, filtro, handleClearFiltro, fetchingLista, filtrosFixados, setFiltrosFixados, setExportarFiltros, filtroSomenteNaoLidas, setFiltroSomenteNaoLidas } = useContext(AtendimentoContext);
    const [listaMeioComunicacao, setListaMeioComunicacao] = useState();
    const [modificadorCodigo, setModificadorCodigo] = useState(caAPI.getLocalState('modificadorCodigo') || 1);
    const [modificadorContato, setModificadorContato] = useState(caAPI.getLocalState('modificadorContato') || 1);
    const [modificadorProponenteEnvolvido, setModificadorProponenteEnvolvido] = useState(caAPI.getLocalState('modificadorProponenteEnvolvido') || 1);
    const [tipoAtendimentoMultSelect, setTipoAtendimentoMultSelect] = useState([]);
    const [listaTipoAtendimento, setListaTipoAtendimento] = useState([]);
    const [telefoneDestinatarioInput, setTelefoneDestinatarioInput] = useState();
    const [selecionadosTemplate] = useState(filtro.meio_comunicacao ? filtro.meio_comunicacao : []);
    const [listaSituacao, setListaSituacao] = useState();
    const [listaAtendentes, setListaAtendentes] = useState();
    const [multSelectAtendentes, setMultSelectAtendentes] = useState([]);
    const [multSelectContatoOrigem, setMultSelectContatoOrigem] = useState([]);
    const [tipoSituacaoMultSelect, setTipoSituacaoMultSelect] = useState([]);
    const [modificadorDate, setModificadorDate] = useState(1)
    const [contatosOrigem, setContatosOrigem] = useState([])
    const [listaTags, setListaTags] = useState([]);    
    let fixaFiltro = false
    let filtrar = true
    let exportar = false



    // Form hook forms
    const {
        register,
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        watch
    } = useForm({ defaultValues: (caAPI.getLocalState('filtros') && JSON.parse(caAPI.getLocalState('filtros'))) || {data_inicio: new Date(), data_fim: new Date()}});
    const watchFiltroSomenteLidas = watch('somente_nao_lidas');

    const onSubmit = (data) => {
        if(data.data_criacao_inicio && data.data_criacao_fim && modificadorDate > 1) {
            setDataInicio(data.data_inicio)
            setDataFim(data.data_fim)
            delete data.data_fim
            delete data.data_inicio
        } else {
            setDataInicio(data.data_criacao_inicio)
            setDataFim(data.data_criacao_fim)
            delete data.data_criacao_fim
            delete data.data_criacao_inicio
        }
        if(filtrar) {
            setListaAtendimentos([]);
        }

        let parametrosFiltro = {};
        let parametrosFiltroCount = {};
        for (let atributo in data) {
            if (data[atributo]) {
                if (Array.isArray(data[atributo])) {
                    parametrosFiltroCount[atributo] = data[atributo].map(retorno => retorno)
                } else {
                    parametrosFiltroCount[atributo] = data[atributo]
                }
            }
        }
        if (selecionadosTemplate.length) {
            parametrosFiltroCount.meio_comunicacao = selecionadosTemplate.slice()
        }
        if (!parametrosFiltroCount.data_inicio && modificadorDate == 1) {
            parametrosFiltroCount.data_inicio = startDate;
        }
        if (!parametrosFiltroCount.data_fim && modificadorDate == 1) {
            parametrosFiltroCount.data_fim = startDate;
        }
        if(data.contato_destino) {
            parametrosFiltroCount.contato_destino_tipo = modificadorContato == 1 ? 'email' : 'telefone'
        }
        caAPI.setLocalState('filtros', JSON.stringify(parametrosFiltroCount))
        caAPI.setLocalState('modificadorContato', modificadorContato)
        caAPI.setLocalState('modificadorCodigo', modificadorCodigo)
        caAPI.setLocalState('modificadorProponenteEnvolvido', modificadorProponenteEnvolvido)

        caAPI.setLocalState('filtrosCount', JSON.stringify(parametrosFiltroCount))
        setSalvarFiltros(parametrosFiltroCount)
        for (let atributo in data) {
            if (data[atributo]) {
                if (Array.isArray(data[atributo]) && atributo != 'contato_origem_ramal') {
                    parametrosFiltro[atributo] = data[atributo].filter((retorno) => retorno).map(retorno => retorno.value)
                } else {
                    parametrosFiltro[atributo] = data[atributo]
                }
            }
        }
        if (selecionadosTemplate.length) {
            parametrosFiltro.meio_comunicacao = selecionadosTemplate.slice()
        }
        if (!parametrosFiltro.data_inicio && modificadorDate == 1) {
            parametrosFiltro.data_inicio = startDate;
        }
        if (!parametrosFiltro.data_fim && modificadorDate == 1) {
            parametrosFiltro.data_fim = startDate;
        }
        if(data.contato_destino) {
            parametrosFiltro.contato_destino_tipo = modificadorContato == 1 ? 'email' : 'telefone'
        }

        if(fixaFiltro && !filtrar) {
            fixaFiltros(parametrosFiltroCount)

            return 0
        } else if(!fixaFiltro && !filtrar) {
            desfixaFiltro()

            return 0
        }
        
        setFiltroSomenteNaoLidas(parametrosFiltro?.somente_nao_lidas ?? false);

        if(exportar){
            setExportarFiltros(parametrosFiltro, exportar)
        }else{
            filtrarListaAtendimentos(parametrosFiltro);
        }
    }

    useEffect(() => {
        setTipoDataFiltro(modificadorDate)
    }, [modificadorDate])


    function handleModificaInputCodigo() {
        if (modificadorCodigo < 3) {
            setModificadorCodigo(modificadorCodigo + 1);
            return;
        }
        setModificadorCodigo(1);
    }
    function handleModificaDate() {
        if(modificadorDate < 2) {
          setModificadorDate(modificadorDate + 1);
          return;
        }
        setModificadorDate(1);
      }
    function handleModificaInputContato() {
        if (modificadorContato < 2) {
            setModificadorContato(modificadorContato + 1);
            return;
        }
        setModificadorContato(1);
    }
    function handleModificaProponenteEnvolvido() {
        if (modificadorProponenteEnvolvido < 2) {
            setModificadorProponenteEnvolvido(modificadorProponenteEnvolvido + 1);
            return;
        }
        setModificadorProponenteEnvolvido(1);
    }

    const adicionaContatosOrigem = (contatosAdicionar) => {
        const novoContatosOrigem = contatosOrigem.concat(contatosAdicionar);
        setContatosOrigem(novoContatosOrigem);
      };

    const removeContatosOrigemMeio = (idMeio) => {
        idMeio = Number(idMeio);
        const novoContatosOrigem = contatosOrigem.filter((contato) => Number(contato.id_meio_comunicacao) !== idMeio);
        setContatosOrigem(novoContatosOrigem);
    }

    const handleSelectMeioComunicacao = (midia, indice) => {

        if(getValues("meio_comunicacao." + indice ) === midia.id){
            setValue("meio_comunicacao." + indice, null);
            removeContatosOrigemMeio(midia.id);
        }else{
            setValue("meio_comunicacao." + indice, midia.id);
            adicionaContatosOrigem(midia.contatos_origem);
        }
        if (selecionadosTemplate.filter(item => item === midia.id).length) {
            const index = selecionadosTemplate.findIndex(item => item === midia.id);
            selecionadosTemplate.splice(index, 1);
        } else {
            selecionadosTemplate.push(midia.id);
        }
    }

    useEffect(() => {
        setTelefoneDestinatarioInput(val => celularMask(val));
    }, [telefoneDestinatarioInput])

    const ordenados = (unordenedArr) => unordenedArr.sort(function (a, b) {
        if ((a.label && a.label.toLowerCase()) < (b.label && b.label.toLowerCase())) return -1;
        if ((a.label && a.label.toLowerCase()) > (b.label && b.label.toLowerCase())) return 1;
        return 0;
    });

    useEffect(() => {
        setListaAtendentes([]);
        setListaSituacao([]);
        setListaTipoAtendimento([]);
        trackPromise(
            caAPI.meio_comunicacao.list().then(res => {
                setListaMeioComunicacao(res.data);
                let meuSet = [];
                res.data.forEach(origem => {
                    if (origem.contatos_origem) {
                        origem.contatos_origem.forEach((contato, index) => {
                            if (!meuSet.some((el) => el.value === contato.valor)) {
                                meuSet.push({ value: contato.valor, label: (contato.descricao || contato.valor) })
                            }
                        })
                    }
                })
                setMultSelectContatoOrigem(ordenados(meuSet));
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os meios de comunicação  " + err,
                    icon: 'error'
                });
            }),
            caAPI.tipo_atendimento.list().then(res => {
                setListaTipoAtendimento(res.data);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar os tipos de atendimento  " + err,
                    icon: 'error'
                });
            }),
            caAPI.situacao.list().then(res => {
                setListaSituacao(res.data);
            }).catch(err => Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar a lista de situações  " + err,
                icon: 'error'
            })),
            caAPI.atendimento.atendentes.listaAtendentes().then(res => {
                setListaAtendentes(res.data);
            }).catch(err => Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar a lista de atendentes  " + err,
                icon: 'error'
            })),
            caAPI.tags.listTagsAtendimentoDistintos().then(res => {
                let formatado = res.data.data.map((value)=>{
                    return{
                        value: value.id_tag,
                        label: value.nome_tag,
                    }
                });
                setListaTags(formatado);
            }).catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar as tags  " + err,
                    icon: 'error'
                });
            }),
        )
        if(!filtrosFixados){
            setValue('somente_nao_lidas', filtroSomenteNaoLidas);
        }
    }, []);

    useEffect(() => {
        if (listaTipoAtendimento) {
            const novoTipoAtendimento = [];
            listaTipoAtendimento.forEach((tipo) => {
                novoTipoAtendimento.push({ value: tipo.id, label: tipo.descricao });
            });

            setTipoAtendimentoMultSelect(ordenados(novoTipoAtendimento));
        }
    }, [listaTipoAtendimento, setTipoAtendimentoMultSelect]);

    useEffect(() => {
        const novoTipoSituacao = [];

        listaSituacao && listaSituacao.forEach((situacao) => {
            novoTipoSituacao.push({ value: situacao.id, label: situacao.nome })
        });

        setTipoSituacaoMultSelect(ordenados(novoTipoSituacao));

    }, [listaSituacao, setTipoSituacaoMultSelect])

    useEffect(() => {
        const novoSelectAtendentes = [{value: null, label: "Não Atribuído"}];

        listaAtendentes && listaAtendentes.forEach((atendente) => {
            novoSelectAtendentes.push({ value: atendente.id, label: atendente.nome })
        });

        setMultSelectAtendentes(ordenados(novoSelectAtendentes))

    }, [listaAtendentes, setMultSelectAtendentes])

    function fixaFiltros(filtros) {
        delete filtros.data_criacao_inicio
        delete filtros.data_criacao_fim
        delete filtros.data_inicio
        delete filtros.data_fim
        trackPromise(PerfilUsuarioService.update(caAPI.getLocalState('idUsuario') || 0, {filtros_fixados: filtros}))
            .then(() => {
                successToast.fire({title: 'Filtros fixados com sucesso.'})
                setFiltrosFixados(true)
            })
            .catch(error => errorToast.fire({title: `<h5 style='color:#fff'>Houve um erro ao fixar os filtros. ${error}</h5>`}))
    }

    function desfixaFiltro() {
        trackPromise(PerfilUsuarioService.update(caAPI.getLocalState('idUsuario') || 0, {filtros_fixados: null}))
            .then(() => {
                successToast.fire({title: 'Filtros desfixados com sucesso.'})
                setFiltrosFixados(false)
            })
            .catch(error => errorToast.fire({title: `<h5 style='color:#fff'>Houve um erro ao fixar os filtros. ${error}</h5>`}))
    }


    return <>
    <DommusLoadingArea area="filtroArea" fundo={"#fff"} />
    { !(fetchingLista) && <Container fluid>
            <Form onSubmit={handleSubmit(onSubmit)}
                className="formCadastro"
            >
                <Row className="formBody">
                  <Col xl={12}>
                    <Row>
                        <Col xl={3} xs={12}>
                            <Form.Group>
                                <Form.Label>Meio de Comunicação:</Form.Label>
                                <Form.Control
                                    as="checkbox"
                                    custom
                                >
                                  <BrowserView>
                                    <CardGroup>
                                        {listaMeioComunicacao && listaMeioComunicacao.map((midia, indice) =>
                                            <div key={midia.id}>
                                                <Card className="midiaCard">
                                                    <Card.Body className="midiaCorpo">
                                                        <Form.Check
                                                            name={midia.id}
                                                            className="midia"
                                                            type="checkbox"
                                                            id={midia.id}
                                                            value={midia.id}
                                                        >
                                                            <Form.Check.Input
                                                                type='checkbox'
                                                                defaultChecked={filtro.meio_comunicacao && filtro.meio_comunicacao.includes(midia.id)}
                                                                disabled={filtrosFixados}
                                                            />
                                                            <Form.Check.Label onClick={() => { handleSelectMeioComunicacao(midia, indice) }} style={{opacity: filtrosFixados ? '0.5' : '1'}}>
                                                                <MidiaSocialIcone midia={midia.slug}/>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Card.Body>
                                                </Card>
                                            </div>)
                                        }
                                    </CardGroup>
                                  </BrowserView>
                                  <MobileView>
                                    <Row xs={3}>
                                    {listaMeioComunicacao && listaMeioComunicacao.map((midia, indice) =>
                                        <Col key={midia.id}>
                                            <Card className="midiaCard">
                                                <Card.Body className="midiaCorpo">
                                                    <Form.Check
                                                        name={midia.id}
                                                        className="midia"
                                                        type="checkbox"
                                                        id={midia.id}
                                                        value={midia.id}
                                                    >
                                                        <Form.Check.Input
                                                            type='checkbox'
                                                            defaultChecked={filtro.meio_comunicacao && filtro.meio_comunicacao.includes(midia.id)}
                                                            disabled={filtrosFixados}
                                                        />
                                                        <Form.Check.Label onClick={() => { handleSelectMeioComunicacao(midia, indice) }} style={{opacity: filtrosFixados ? '0.5' : '1'}}>
                                                            <MidiaSocialIcone midia={midia.slug}/>
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                    }
                                    </Row>
                                  </MobileView>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xl={9}>
                            <Row>
                            {modificadorDate == 1 && (
                                <Form.Group as={Col} controlId="formFiltro">
                                    <Form.Label>Última movimentação do atendimento:</Form.Label>
                                    <InputGroup style={{ flexWrap: "nowrap" }}>
                                        <Controller
                                            control={control}
                                            name="data_inicio"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    locale={pt_BR}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={(value && new Date(value)) ?? new Date()}
                                                />
                                            )}
                                        />
                                        <InputGroup.Append className='segundo-input' style={{ marginLeft: -3, width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="data_fim"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <DatePicker
                                                        className="form-control"
                                                        locale={pt_BR}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={(value && new Date(value)) ?? new Date()}
                                                    />
                                                )}
                                            />
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaProcesso"
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-editar`}>Alterar para Abertura do atendimento</Tooltip>}
                                            >
                                                <Button className="btnAlterar" onClick={() => handleModificaDate()} style={{ zIndex: 0 }}>
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            ) || (
                                <Form.Group as={Col} controlId="formFiltro">
                                    <Form.Label>Abertura do atendimento:</Form.Label>
                                    <InputGroup style={{ flexWrap: "nowrap" }}>
                                        <Controller
                                            control={control}
                                            name="data_criacao_inicio"
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    locale={pt_BR}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={(value && new Date(value)) ?? new Date()}
                                                />
                                            )}
                                        />
                                        <InputGroup.Append className='segundo-input' style={{ marginLeft: -3, width: '100%' }}>
                                            <Controller
                                                control={control}
                                                name="data_criacao_fim"
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <DatePicker
                                                        className="form-control"
                                                        locale={pt_BR}
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={(value && new Date(value)) ?? new Date()}
                                                    />
                                                )}
                                            />
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaProcesso"
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-editar`}>Alterar para Última movimentação do atendimento</Tooltip>}
                                            >
                                                <Button className="btnAlterar" onClick={() => handleModificaDate()} style={{ zIndex: 0 }}>
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true}/>
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            )}
                            </Row>
                            <Row xs={1} md={2}>
                                <Form.Group as={Col} controlId="formFiltro">
                                    <Form.Label>Tipo de Atendimento:</Form.Label>
                                    <Controller
                                        name="tipo_atendimento"
                                        control={control}
                                        render={({ field }) => <Select
                                            {...field}
                                            isMulti
                                            placeholder="Selecionar"
                                            name="tipoAtendimento"
                                            options={tipoAtendimentoMultSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={filtrosFixados}
                                            // defaultValue={buscaValorPadrao()}
                                        />}
                                        // defaultValue={buscaValorPadrao()}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formFiltro">
                                    <Form.Label>Status do Atendimento:</Form.Label>
                                    <Controller
                                        name="situacao"
                                        control={control}
                                        render={({ field }) => <Select
                                            {...field}
                                            isMulti
                                            placeholder="Selecionar"
                                            name="situacaoAtendimento"
                                            options={tipoSituacaoMultSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={filtrosFixados}
                                        />}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} sm={8} controlId="formFiltro">
                                    <Form.Label>Tags do Atendimento:</Form.Label>
                                    <Controller
                                        name="tag_atendimento"
                                        control={control}
                                        render={({ field }) => <Select
                                            {...field}
                                            isMulti
                                            placeholder="Selecionar"
                                            name="tagsAtendimento"
                                            options={listaTags}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={filtrosFixados}
                                            maxMenuHeight={200}
                                        />}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={4} controlId="formFiltro" className='align-self-end'>
                                    <Controller
                                        control={control}
                                        name={'somente_nao_lidas'}
                                        render={({ onChange, onBlur, value }) =>
                                            <DommusSwitch
                                                label="Exibir somente não lidas"
                                                checked={Boolean(Number(watchFiltroSomenteLidas))}
                                                clickEvent={(e) => {
                                                    if(!filtrosFixados){
                                                        let valor = !Boolean(Number(getValues("somente_nao_lidas")));
                                                        setValue("somente_nao_lidas", valor);
                                                    }
                                                }}
                                                tooltip={Boolean(Number(watchFiltroSomenteLidas)) ? 'Sim' : 'Não'}
                                                labelDesativado=""
                                                isDisabled={filtrosFixados}
                                            />
                                        }
                                        defaultValue={""}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row xs={1} md={3}>
                    <Form.Group as={Col} controlId="formFiltro">
                            {modificadorContato === 1 ? (
                                <>
                                    <Form.Label>E-mail destinatário:</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            disabled={filtrosFixados}
                                            type="text"
                                            placeholder="E-mail"
                                            {...register("contato_destino")}
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaTelefone"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-editar`}>Alterar para telefone</Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btnAlterar"
                                                    onClick={() => { handleModificaInputContato() }}
                                                    data-toggle="Alterar para telefone"
                                                    style={{ zIndex: 0 }}
                                                >
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </>
                            ) : (
                                <>
                                    <Form.Label>Telefone destinatário:</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            disabled={filtrosFixados}
                                            type="text"
                                            placeholder="Telefone"
                                            {...register("contato_destino")}
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaEmail"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-editar`}>Alterar para e-mail</Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btnAlterar"
                                                    onClick={() => { handleModificaInputContato() }}
                                                    data-toggle="Alterar para email"
                                                    style={{ zIndex: 0 }}
                                                >
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} controlId="formFiltro">
                            <Form.Label>Nome destinatário:</Form.Label>
                            <Form.Control
                                disabled={filtrosFixados}
                                type="text"
                                {...register("nome")}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formFiltro">
                            <Form.Label>Contato Origem:</Form.Label>
                            <Controller
                                name="contato_origem_ramal"
                                control={control}
                                render={({ field:{onChange, value} }) => <Select
                                isMulti
                                placeholder="Selecionar"
                                name="selecionaContatoOrigem"
                                options={contatosOrigem && contatosOrigem.map(contato =>{
                                    return {value: contato.valor + '#' + contato.ramal, label: contato.descricao};
                                })}
                                onChange={(e) => setValue('contato_origem_ramal', ( e && e.map(contato => contato.value) ) || [])}
                                value={contatosOrigem.reduce(
                                    (selecionados, contato) => {
                                        if(value && value.includes(contato.valor + '#' + contato.ramal)){
                                            selecionados.push({value: contato.valor + '#' + contato.ramal, label: contato.descricao})
                                        }
                                        return selecionados;
                                    }, [])
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                menuPlacement="top"
                                noOptionsMessage={() => "Nenhuma origem configurada."}
                                isDisabled={filtrosFixados}
                                />}
                            />
                        </Form.Group>
                    </Row>
                    <Row xs={1} md={3}>
                        <Form.Group as={Col} controlId="formFiltro">
                                {modificadorCodigo === 1 ? (
                                    <>
                                        <Form.Label>N° Processo:</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                disabled={filtrosFixados}
                                                type="text"
                                                placeholder="Processo"
                                                {...register("processo")}
                                            />
                                            <InputGroup.Append>
                                                <OverlayTrigger
                                                    key="buttonAlterarParaOPV"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-editar`}>Alterar para OPV</Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        className="btnAlterar"
                                                        onClick={() => { handleModificaInputCodigo() }}
                                                        data-toggle="Aletrar para OPV"
                                                        style={{ zIndex: 0 }}
                                                    >
                                                        <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </>
                                ) :
                                    modificadorCodigo === 2 ? (
                                        <>
                                            <Form.Label>N° OPV:</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    disabled={filtrosFixados}
                                                    type="text"
                                                    placeholder="OPV"
                                                    {...register("oportunidade")}
                                                />
                                                <InputGroup.Append>
                                                    <OverlayTrigger
                                                        key="buttonAlterarParaAtendimento"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-editar`}>Alterar para Atendimento</Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            className="btnAlterar"
                                                            onClick={() => { handleModificaInputCodigo() }}
                                                            data-toggle="Alterar para Atendimento"
                                                            title="Alterar para Atendimento"
                                                            style={{ zIndex: 0 }}
                                                        >
                                                            <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </>
                                    ) :
                                        <>
                                            <Form.Label>N° Atendimento:</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    disabled={filtrosFixados}
                                                    type="text"
                                                    placeholder="Atendimento"
                                                    {...register("id")}
                                                />
                                                <InputGroup.Append>
                                                    <OverlayTrigger
                                                        key="buttonAlterarParaProcesso"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-editar`}>Alterar para Processo</Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            className="btnAlterar"
                                                            onClick={() => { handleModificaInputCodigo() }}
                                                            data-toggle="Alterar para Processo"
                                                            style={{ zIndex: 0 }}
                                                        >
                                                            <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </>
                                }
                            </Form.Group>
                            <Form.Group as={Col} controlId="formFiltro">
                            {modificadorProponenteEnvolvido === 1 ? (
                                <>
                                    <Form.Label>Nome do Proponente:</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            disabled={filtrosFixados}
                                            type="text"
                                            placeholder="Proponente"
                                            {...register("nome_proponente")}
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaEnvolvido"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-editar`}>Alterar para Envolvido</Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btnAlterar"
                                                    onClick={() => { handleModificaProponenteEnvolvido() }}
                                                    data-toggle="Alterar para Envolvido"
                                                    style={{ zIndex: 0 }}
                                                >
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </>
                            ) : (
                                <>
                                    <Form.Label>Nome do Envolvido:</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            disabled={filtrosFixados}
                                            type="text"
                                            placeholder="Envolvido"
                                            {...register("nome_envolvido")}
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                key="buttonAlterarParaProponente"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-editar`}>Alterar para Proponente</Tooltip>
                                                }
                                            >
                                                <Button
                                                    className="btnAlterar"
                                                    onClick={() => { handleModificaProponenteEnvolvido() }}
                                                    data-toggle="Alterar para proponente"
                                                    style={{ zIndex: 0 }}
                                                >
                                                    <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} controlId="formFiltro">
                            <Form.Label>Atendentes:</Form.Label>
                            <Controller
                                name="atendente"
                                control={control}
                                render={({ field }) => <Select
                                    {...field}
                                    menuPlacement="top"
                                    isMulti
                                    placeholder="Selecionar"
                                    name="selecionaAtendente"
                                    options={multSelectAtendentes}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isDisabled={filtrosFixados}
                                />}
                            />
                        </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Row className="formFooter">
                    <Col>
                        <div style={{ marginRight: "10px" }}>
                            <Button variant={filtrosFixados ? "secondary" : "info"} type="button" onClick={() => {
                                filtrar = false
                                exportar = false
                                if(filtrosFixados) {
                                    fixaFiltro = false
                                } else {
                                    fixaFiltro = true                                    
                                }
                                handleSubmit(onSubmit)()
                            }}>
                                <FontAwesomeIcon
                                    icon={filtrosFixados? faMinusCircle : faWrench}
                                    style={!isMobile && { marginRight: "10px" }}
                                />
                                {(!isMobile && (filtrosFixados ? "Desfixar Filtros" : "Fixar Filtro")) || ""}
                            </Button>
                        </div>
                        {!filtrosFixados && (
                            <div style={{ marginRight: "10px" }}>
                                <Button
                                onClick={() => {
                                    exportar = false
                                    reset({
                                        data_fim: new Date(),
                                        data_inicio: new Date(),
                                        data_criacao_fim: new Date(),
                                        data_criacao_inicio: new Date(),
                                        atendente: null,
                                        contato_origem: null,
                                        situacao: null,
                                        tipo_atendimento: null,
                                        somente_nao_lidas: false
                                    });
                                    setFiltroSomenteNaoLidas(false)
                                }}
                                variant={"primary"}
                                type={"button"}
                                >
                                    <FontAwesomeIcon icon={faEraser} style={!isMobile && { marginRight: "15px" }}/>
                                    {isMobile ? "" : "Limpar"}
                                </Button>
                            </div>
                        )}
                    <div>
                        <Button style={{ marginRight: "10px" }} variant={"success"} type="submit"
                        onClick={() => { exportar = true
                            filtrar = true
                            fixaFiltro = false }}
                        >
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={!isMobile && { marginRight: "15px" }}
                            />
                                {isMobile ? "" : "Exportar"}
                            </Button>
                    </div>
                    <div>
                    <Button variant={"success"}  type="submit" onClick={() => {
                        filtrar = true
                        fixaFiltro = false
                        exportar = false
                    }}>
                        <FontAwesomeIcon
                            icon={faFilter}
                            style={!isMobile && { marginRight: "15px" }}
                        />
                        {isMobile ? "" : "Filtrar"}
                    </Button>
                    </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <Button className="btnFiltrar" onClick={() => {
                            setSalvarFiltros(null)
                            handleClearFiltro()
                            }}>Limpar</Button>
                    </Col>
                    <Col>
                        <Button className="btnFiltrar" type="submit">Filtrar</Button>
                    </Col>
                </Row> */}
            </Form>
        </Container>
        }
    </>;
}

export default FiltrarAtendimentos;
