import api from './api';

const ticketService = {
	index:()=>{
		return api.get('/ticket');
	},
	store: (dados) => {
        let uri = '/ticket';
        return api['post'].call(api, uri, dados);
    },
	update: (id, dados) => {
        let uri = '/ticket/'+id;
        return api['put'].call(api, uri, dados);
    },
	getMensagemByTicket:(id)=>{
		return api.get('/ticket/'+id+'/mensagens');
	},
	storeMensagem:(id, dados)=>{
		let uri = '/ticket/'+id+'/mensagem';
        return api['post'].call(api, uri, dados);
	},
	getNotaInternaByTicket:(id)=>{
		return api.get('/ticket/'+id+'/notas');
	},
	storeNotaInterna:(id, dados)=>{
		let uri = '/ticket/'+id+'/nota';
        return api['post'].call(api, uri, {nota: dados});
	},
};

export default ticketService;