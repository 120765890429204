import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const RelatorioContext = createContext({});

export function RelatorioProvider({ children }) {
  const [relatorios, setRelatorios] = useState([]);

  const recarregarRelatorios = ()=>{
    caAPI.relatorios.list().then(res => {      
        setRelatorios(res.data.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os relatorios " + err,
        icon: 'error'
    }))
  };

  return (
    <RelatorioContext.Provider value={{
      relatorios,
      recarregarRelatorios,      
    }}>
      {children}
    </RelatorioContext.Provider>
  )
}
