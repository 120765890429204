import caAPI from "./caAPI";

const AtendimentoService = {
  testarUsuarioAtualAtende: (atendimento) => {
    let idUsuario = Number(caAPI.getLocalState("idUsuario"));
    let contatosVeTudo = JSON.parse(caAPI.getLocalState("contatosVeTudo"));

    return (Number(atendimento?.atendente) === idUsuario) || ( !(atendimento?.atendente) && (
        (!(contatosVeTudo) && (caAPI.getLocalState("visualizaNaoAtendidos") )) ||
        (contatosVeTudo && atendimento?.origem && AtendimentoService.testarUsuarioAtualVeOrigem(atendimento?.origem?.id_contato_origem))
      )
    );
  },

  testarUsuarioAtualVeOrigem: (idOrigem) => {
    let contatosVeTudo = JSON.parse(caAPI.getLocalState("contatosVeTudo"));
    return Array.isArray(contatosVeTudo) && contatosVeTudo.includes(idOrigem);
  }
};

export default AtendimentoService;
