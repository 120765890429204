import React, {useContext, useState} from 'react';
import {Form, Row, Col, Card, CardGroup, InputGroup,  OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import {useForm, Controller} from 'react-hook-form';
import {MeioComunicacaoContext} from '../../../contexts/MeioComunicacaoContext';
import {TipoHistoricoAtendimentoContext} from '../../../contexts/TipoHistoricoAtendimentoContext';
import {TipoAtendimentoContext} from '../../../contexts/TipoAtendimentoContext';
import {SituacaoAtendimentoContext} from '../../../contexts/SituacaoAtendimentoContext';
import {AtendentesContext} from '../../../contexts/AtendentesContext';
import { Icon } from '@iconify/react';
import DommusRequiredSpan from '../../../components/DommusRequiredSpan';
import MidiaSocialIcone from '../../../components/MidiaSocialIcone';
import { celularMask } from "../../../components/InputTextMask";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR'
import "react-datepicker/dist/react-datepicker.css";
import '../../../pages/PortalCliente/FiltrarAtendimentos/style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFilter,faEraser} from "@fortawesome/free-solid-svg-icons";
import objectHelper from '../../../helpers/ObjectHelper';

export default function FiltroHistoricoAtendimento({filtros, filtrarPorEntrada, setFiltrosFiltrarPor,setOpenModalFiltro}){

  const { meiosComunicacao } = useContext(MeioComunicacaoContext);
  const {register, handleSubmit, control,  errors, getValues, setValue, reset} = useForm(
    {
      defaultValues: {
        meio_comunicacao: meiosComunicacao.map((meio) => filtros?.meio_comunicacao
          && filtros.meio_comunicacao.includes(meio.id))
        }
    });
  const { tiposHistoricoAtendimento } = useContext(TipoHistoricoAtendimentoContext);
  const { tiposAtendimento } = useContext(TipoAtendimentoContext);
  const {situacoesAtendimento} = useContext(SituacaoAtendimentoContext);
  const {listaAtendentesGerenciados} = useContext(AtendentesContext);
  const [modificadorContato, setModificadorContato] = useState(1)
  const [contatosOrigem, setContatosOrigem] = useState([]);
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorEntrada || 3);

  const submitHandler = (data) => {
    const novoFiltros = objectHelper.filterObject(data);
    if(novoFiltros.meio_comunicacao){
      novoFiltros.meio_comunicacao = novoFiltros.meio_comunicacao.filter((meio) => meio);
      if(!(novoFiltros.meio_comunicacao.length)){
        delete novoFiltros.meio_comunicacao;
      }
    }
    setFiltrosFiltrarPor({filtros: novoFiltros, filtrarPor: filtrarPor});
    setOpenModalFiltro(false);
  };

  const handleModificaInputContato = () => {
      if (modificadorContato < 2) {
          setModificadorContato(modificadorContato + 1);
          return;
      }
      setModificadorContato(1);
  };

  const adicionaContatosOrigem = (contatosAdicionar) => {
    const novoContatosOrigem = contatosOrigem.concat(contatosAdicionar);
    setContatosOrigem(novoContatosOrigem);
  };

  const removeContatosOrigemMeio = (idMeio) => {
    idMeio = Number(idMeio);
    const novoContatosOrigem = contatosOrigem.filter((contato) => Number(contato.id_meio_comunicacao) !== idMeio);
    setContatosOrigem(novoContatosOrigem);
  }
  return <Form className="formCadastro" onSubmit={handleSubmit(submitHandler)}>
    <Row>
      <Col xl={3}>
        <Form.Group as={Col}>
          <Form.Label>
            Meio de Comunicação:
          </Form.Label>
          <CardGroup>
            {meiosComunicacao &&
              meiosComunicacao.map((midia, indice) => {
                return (
                  <div key={midia.id}>
                    <Card className="midiaCard">
                      <Card.Body
                        className="midiaCorpo">
                        <Form.Check
                          className="midia"
                          type="checkbox"
                          is="x3d">
                          <Form.Check.Input
                            type="checkbox"
                            is="x3d"
                            id={"check-" + midia.id}
                            defaultValue={midia.id}
                            {...register("meio_comunicacao." + indice )}
                            checked={(getValues("meio_comunicacao." + indice ) === midia.id)}
                            />
                          <Form.Check.Label onClick={() => {
                              if(getValues("meio_comunicacao." + indice ) === midia.id){
                                setValue("meio_comunicacao." + indice, null);
                                removeContatosOrigemMeio(midia.id);
                              }else{
                                setValue("meio_comunicacao." + indice, midia.id);
                                adicionaContatosOrigem(midia.contatos_origem);
                              }
                            }}>
                            <MidiaSocialIcone midia={midia.slug} />
                          </Form.Check.Label>
                        </Form.Check>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
          </CardGroup>
        </Form.Group>
      </Col>
      <Col xl="9">
       <Row>
        <Col xl="12">
          <Form.Group>
            <Form.Label>Filtrar Por:</Form.Label>
            <Form.Control as="div">
              <Form.Check
                inline
                label="Estado Atual"
                name="filtrar-por"
                type={"radio"}
                value={1}
                onClick={(e) => setFiltrarPor(1)}
                defaultChecked={filtrarPor === 1}
                id={'filtrar-por-radio-1'}
              />
              <Form.Check
                inline
                label="Momento do Registro"
                name="filtrar-por"
                type={"radio"}
                value={2}
                onClick={(e) => setFiltrarPor(2)}
                defaultChecked={filtrarPor === 2}
                id={'filtrar-por-radio-2'}
              />
              <Form.Check
                inline
                label="Ambos"
                name="filtrar-por"
                type={"radio"}
                value={3}
                onClick={(e) => setFiltrarPor(3)}
                defaultChecked={filtrarPor === 3}
                id={'filtrar-por-radio-3'}
              />
            </Form.Control>
          </Form.Group>
        </Col>
       </Row>
       <Row>
        <Form.Group as={Col} controlId="formFiltro">
            <Form.Label>Período da Interação:</Form.Label>
            <InputGroup style={{ flexWrap: "nowrap" }}>
                <Controller
                  placeholder="De"
                  control={control}
                  defaultValue={filtros?.data_inicio}
                  name="data_inicio"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                          className="form-control"
                          locale={pt_BR}
                          dateFormat="dd/MM/yyyy"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={(value && new Date(value))}
                      />
                  )}
                />
                <InputGroup.Append className='segundo-input' style={{ marginLeft: -3, width: '100%' }}>
                    <Controller
                        control={control}
                        defaultValue={filtros?.data_fim}
                        name="data_fim"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker
                                className="form-control"
                                locale={pt_BR}
                                dateFormat="dd/MM/yyyy"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={(value && new Date(value))}
                            />
                        )}
                    />
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formFiltro">
            <Form.Label>Tipo de Interação:</Form.Label>
            <Controller
                name="id_tipo_historico_atendimento"
                control={control}
                defaultValue={filtros?.id_tipo_historico_atendimento}
                render={({ field: {onChange, value} }) => <Select
                    isMulti
                    placeholder="Selecionar"
                    name="tipoHistoricoAtendimento"
                    options={tiposHistoricoAtendimento && tiposHistoricoAtendimento.map(tipo => {
                      return {value: tipo.id_tipo_historico_atendimento, label: tipo.descricao};
                    })}
                    onChange={(e) => setValue('id_tipo_historico_atendimento', ( e && e.map(tipo => tipo.value) ) || [])}
                    value={tiposHistoricoAtendimento.reduce(
                      (selecionados, tipo) => {
                        if(value && value.includes(tipo.id_tipo_historico_atendimento)){
                          selecionados.push({value: tipo.id_tipo_historico_atendimento, label: tipo.descricao})
                        }
                        return selecionados;
                      }, [])}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />}
            />
        </Form.Group>
        <Form.Group as={Col} controlId="formFiltro">
            <Form.Label>Tipo de Atendimento:</Form.Label>
            <Controller
                name="tipo_atendimento"
                control={control}
                defaultValue={filtros?.tipo_atendimento}
                render={({ field:{onChange, value} }) => <Select
                    isMulti
                    placeholder="Selecionar"
                    name="tipo"
                    options={tiposAtendimento && tiposAtendimento.map(tipo => {
                      return {value: tipo.id, label:tipo.descricao};
                    })}
                    onChange={(e) => setValue('tipo_atendimento', ( e && e.map(tipo => tipo.value) ) || [])}
                    value={tiposAtendimento.reduce(
                      (selecionados, tipo) => {
                        if(value && value.includes(tipo.id)){
                          selecionados.push({value: tipo.id, label: tipo.descricao})
                        }
                        return selecionados;
                      }, [])}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />}
            />
        </Form.Group>
        <Form.Group as={Col} controlId="formFiltro">
            <Form.Label>Status do Atendimento:</Form.Label>
            <Controller
                name="situacao"
                control={control}
                defaultValue={filtros?.situacao}
                render={({ field:{onChange, value} }) => <Select
                    isMulti
                    placeholder="Selecionar"
                    name="situacaoAtendimento"
                    options={situacoesAtendimento && situacoesAtendimento.map(situacao =>{
                      return {value: situacao.id, label: situacao.nome}
                    })}
                    onChange={(e) => setValue('situacao', ( e && e.map(situacao => situacao.value) ) || [])}
                    value={situacoesAtendimento.reduce(
                      (selecionados, situacao) => {
                        if(value && value.includes(situacao.id)){
                          selecionados.push({value: situacao.id, label: situacao.nome})
                        }
                        return selecionados;
                      }, [])}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />}
            />
        </Form.Group>
      </Row>
    </Col>
    <Col xl={12}>
      <Row>
        <Form.Group as={Col} controlId="formFiltro">
                {modificadorContato === 1 ? (
                    <>
                        <Form.Label>E-mail destinatário:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="E-mail"
                                defaultValue={filtros?.contato_destino}
                                {...register("contato_destino")}
                            />
                            <InputGroup.Append>
                                <OverlayTrigger
                                    key="buttonAlterarParaTelefone"
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-editar`}>Alterar para telefone</Tooltip>
                                    }
                                >
                                    <Button
                                        className="btnAlterar"
                                        onClick={() => { handleModificaInputContato() }}
                                        data-toggle="Alterar para telefone"
                                        style={{ zIndex: 0 }}
                                    >
                                        <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                    </Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    </>
                ) : (
                    <>
                        <Form.Label>Telefone destinatário:</Form.Label>
                        <InputGroup>
                          <Form.Control
                              type="text"
                              defaultValue={filtros?.contato_destino}
                              {...register("contato_destino",
                                {
                                  pattern:/[0-9]/g,
                                  onChange:(e) => {
                                    const {value} = e.target;
                                    e.target.value = celularMask(e.target.value);
                                  },
                                  setValueAs: (value) => value.replace(/[^0-9]/g, "")
                              })}
                          />
                            <InputGroup.Append>
                                <OverlayTrigger
                                    key="buttonAlterarParaEmail"
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-editar`}>Alterar para e-mail</Tooltip>
                                    }
                                >
                                    <Button
                                        className="btnAlterar"
                                        onClick={() => { handleModificaInputContato() }}
                                        data-toggle="Alterar para email"
                                        style={{ zIndex: 0 }}
                                    >
                                        <Icon icon="akar-icons:arrow-right-left" color="white" inline={true} />
                                    </Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    </>
                )}
            </Form.Group>
            <Form.Group as={Col} controlId="formFiltro">
                <Form.Label>Nome destinatário:</Form.Label>
                <Form.Control
                    type="text"
                    defaultValue={filtros?.nome}
                    {...register("nome")}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formFiltro">
                <Form.Label>Contato Origem:</Form.Label>
                <Controller
                    name="contato_origem_ramal"
                    control={control}
                    defaultValue={filtros?.contato_origem_ramal}
                    render={({ field:{onChange, value} }) => <Select
                        isMulti
                        placeholder="Selecionar"
                        name="selecionaContatoOrigem"
                        options={contatosOrigem && contatosOrigem.map(contato =>{
                          return {value: contato.valor + '#' + contato.ramal, label: contato.descricao};
                        })}
                        onChange={(e) => setValue('contato_origem_ramal', ( e && e.map(contato => contato.value) ) || [])}
                        value={contatosOrigem.reduce(
                          (selecionados, contato) => {
                            if(value && value.includes(contato.valor + '#' + contato.ramal)){
                              selecionados.push({value: contato.valor + '#' + contato.ramal, label: contato.descricao})
                            }
                            return selecionados;
                          }, [])}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPlacement="top"
                        noOptionsMessage={() => "Nenhuma origem configurada."}
                    />}
                />
            </Form.Group>
            <Form.Group as={Col} controlId="formFiltro">
                <Form.Label>Agente:</Form.Label>
                <Controller
                    name="criado_por"
                    control={control}
                    defaultValue={filtros?.criado_por}
                    render={({ field:{onChange, value} }) => <Select
                        isMulti
                        placeholder="Selecionar"
                        name="selecionaCriadoPor"
                        options={listaAtendentesGerenciados && listaAtendentesGerenciados.map(atendente => {
                          return {value: atendente.id, label: atendente.nome};
                        })}
                        onChange={(e) => setValue('criado_por', ( e && e.map(atendente => atendente.value) ) || [])}
                        value={listaAtendentesGerenciados.reduce(
                          (selecionados, atendente) => {
                            if(value && value.includes(atendente.id)){
                              selecionados.push({value: atendente.id, label: atendente.nome})
                            }
                            return selecionados;
                          }, [])}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPlacement="top"
                    />}
                />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
                <Form.Label>Processo:</Form.Label>
                <Form.Control
                    type="text"
                    defaultValue={filtros?.processo}
                    {...register("processo",
                      {
                        pattern:/[0-9]/g,
                        onChange:(e) => {
                          const {value} = e.target;
                          e.target.value = value.replace(/[^0-9]/g, "");
                        }
                    })}
                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Oportunidade:</Form.Label>
                <Form.Control
                    type="text"
                    defaultValue={filtros?.oportunidade}
                    {...register("oportunidade",
                      {
                        pattern:/[0-9]/g,
                        onChange:(e) => {
                          const {value} = e.target;
                          e.target.value = value.replace(/[^0-9]/g, "");
                        }
                    })}                />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Atendimento:</Form.Label>
                <Form.Control
                    type="text"
                    defaultValue={filtros?.id_atendimento}
                    {...register("id_atendimento",
                      {
                        pattern:/[0-9]/g,
                        onChange:(e) => {
                          const {value} = e.target;
                          e.target.value = value.replace(/[^0-9]/g, "");
                        }
                    })}
                />
            </Form.Group>
          </Row>
      </Col>
    </Row>
    <Row className="formFooter">
      <Col xl="12" className="d-flex justify-content-between">
        <div>
          <Button
            onClick={() => {
                reset();
            }}
            variant={"primary"}
            type={"button"}
            >
            <FontAwesomeIcon
                icon={faEraser}
                style={{ marginRight: "15px" }}
            />
            Limpar
          </Button>
        </div>
        <div>
          <Button variant={"success"} type="submit">
              <FontAwesomeIcon
                  icon={faFilter}
                  style={{ marginRight: "15px" }}
              />
              Filtrar
          </Button>
        </div>
      </Col>
    </Row>
  </Form>

}
