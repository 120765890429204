import moment from 'moment';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Card, CardGroup, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import DommusBarraTitulo from '../../../../components/DommusBarraTitulo';
import ItemBarraTitulo from '../../../../components/DommusBarraTitulo/ItemBarraTitulo';
import DommusTabelaPaginadaControlada from '../../../../components/DommusTabelaPaginadaControlada';
import DommusToggle from '../../../../components/DommusToggle';
import MidiaSocialIcone from '../../../../components/MidiaSocialIcone';
import caAPI from '../../../../services/caAPI';
import { Icon } from '@iconify/react';
import { trackPromise } from "react-promise-tracker";
import DommusLoadingArea from '../../../../components/DommusLoading/DommusLoadingArea';
import { celularMask } from '../../../../components/InputTextMask';

import './styles.scss';

export default function AtendimentosArquivados() {
  const [tamanhoPagina, setTamanhoPagina] = useState(20);
  const [pagina, setPagina] = useState(1);
  const [totalAtendimentos, setTotalAtendimentos] = useState(null);
  const [listaAtendimentosArquivados, setListaAtendimentosArquivados] = useState([]);
  const [linhas, setLinhas] = useState([]);

  const atualizarLista = () => {
    trackPromise(caAPI.atendimento.arquivado.list(pagina, tamanhoPagina).then(res => {
      const novaLista = res.data || [];
      setListaAtendimentosArquivados(novaLista);
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar atendimentos arquivados  " + err,
        icon: 'error'
      });
    }), 'loadingArquivoArea');
  };
  useEffect(() => {
    if(pagina && tamanhoPagina && totalAtendimentos){
      atualizarLista();
    }
  }, [pagina, tamanhoPagina])

  useEffect(() => {
    trackPromise(caAPI.atendimento.arquivado.total().then(res => {
      setTotalAtendimentos(res.data.total);
      atualizarLista();
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar atendimentos arquivados  " + err,
        icon: 'error'
      });
    }), 'loadingArquivoArea');
  }, [])

  const desarquivarAtendimento = useCallback((id) => {
    caAPI.atendimento.arquivado.desarquivar(id).then(res => {
      const templateIndex = listaAtendimentosArquivados.findIndex(atendimento => atendimento.id === id);
      let novaLista = [...listaAtendimentosArquivados];
      novaLista.splice(templateIndex, 1)
      setListaAtendimentosArquivados(novaLista);
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema a desarquivar o atendimento " + err,
        icon: 'error'
      });
    })
  }, [listaAtendimentosArquivados]);

  const thead = (
    <tr >
      <th className="canalTabelaAtendimentoArquivados">
        Canal:
      </th>
      <th>
        Nome:
      </th>
      <th >
        Contato:
      </th>
      <th >
        Ultima atualização:
      </th>
      <th >
        Atendente:
      </th>
      <th >
        Situação:
      </th>
      <th className="recuperarTabelaAtendimentoArquivados" >
        Recuperar:
      </th>
    </tr>
  );

  useEffect(() => {
    const novasLinhas = listaAtendimentosArquivados.map((registro) => {
        return (
          <tr key={registro.id}>
            <td>
              <CardGroup>
                <div className="midiaAtendimentoArquivado" key={registro.meio_comunicacao.id}>
                  <Card className="midiaCard">
                    <Card.Body className="midiaCorpo">
                      <Form.Check
                        className="midiaListaTemplate"
                        value={registro.meio_comunicacao.slug}
                      >
                        <Form.Check.Input />
                        <Form.Check.Label>
                          <MidiaSocialIcone midia={registro.meio_comunicacao.slug} />
                        </Form.Check.Label>
                      </Form.Check>
                    </Card.Body>
                  </Card>
                </div>
              </CardGroup>
            </td>
            <td>
              {registro.nome || "Não Especificado"}
            </td>
            <td>
              {registro.meio_comunicacao.slug === 'whatsapp' || registro.meio_comunicacao.slug === 'voip' ? celularMask(registro.contato_destino) : registro.contato_destino}
            </td>
            <td>
              {moment(registro.atualizado_em).format('DD/MM/YYYY HH:mm')}
            </td>
            <td>
              {registro.nome_atendente}
            </td>
            <td>
              {registro.situacao_atendimento.nome}
            </td>
            <td>
              <div  className="recuperarCol">
              <Icon onClick={() => desarquivarAtendimento(registro.id)} className="desarquivarTabelaIcone" icon="fluent:send-copy-24-regular" color="#4CAF50" width="30" />
              </div>
            </td>
          </tr>
        );
      });

      setLinhas(novasLinhas);
  }, [listaAtendimentosArquivados])


  return (<>
    <DommusBarraTitulo>
      <ItemBarraTitulo>
        Atendimentos arquivados
      </ItemBarraTitulo>

    </DommusBarraTitulo>
    <DommusToggle title='Arquivo' toggle={true} className="labels" bodyClass="dommus-toggle-height-fixed-atendimento">
      <div>
        <DommusLoadingArea area="loadingArquivoArea" fundo={"#fff"} />
        {(totalAtendimentos && linhas && linhas.length && <DommusTabelaPaginadaControlada linhasCabecalho={thead} optionsTabela={{}}
        linhasCorpo={linhas} pagina={pagina} setPagina={setPagina} tamanhoPagina={tamanhoPagina}
        setTamanhoPagina={setTamanhoPagina} tamanhoTotal={totalAtendimentos} />) || "Nenhum atendimento arquivado"}
      </div>
    </DommusToggle>



  </>);
}
