import api from "./api";

export const ContatoNotificacaoHistoricoService = {
  seach: () => {
    return api.get(`notificacao-historico-atendimento`);
  },
  store: (dados) => {
    return api.post(`notificacao-historico-atendimento`, dados);
  },
  update: (id, dados) => {
    return api.put(`notificacao-historico-atendimento/${id}`, dados);
  },
  delete: (id) => {
		return api.delete(`notificacao-historico-atendimento/${id}`)
	},
};