import React, { createContext, useState, useEffect} from "react";
import Swal from "sweetalert2";
import LeadsService from "../services/LeadsService";

export const CampanhaContext = createContext({});

export function CampanhaProvider({ children }) {
  const [campanhaLista, setCampanhaLista] = useState([]);
  const [mapaCampanhas, setMapaCampanha] = useState(new Map());

  useEffect(()=>{
    const novoMapa = new Map();
    campanhaLista.forEach((tag) => {
      novoMapa.set(Number(tag.id), tag);
    });
    setMapaCampanha(novoMapa);
  }, [campanhaLista]);

  const recarregarCampanhas = ()=>{
    LeadsService.getCampanhas()
        .then(res => {
        setCampanhaLista(res.data ? res.data : []);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar as campanhas  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeCampanha = (idCampanha, padrao = "Não Atribuído") => {
    let campanha = mapaCampanhas.get(Number(idCampanha));
    return (campanha && campanha.nome) || padrao;
  };

  return (
    <CampanhaContext.Provider value={{
      campanhaLista,
      recarregarCampanhas,
      resolverNomeCampanha
    }}>
      {children}
    </CampanhaContext.Provider>
  )
}
