import React from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import './index.css'
import { PopoverAtendenteDashboard } from "../PopoverAtendenteDashboard";
import imgPadraoUsuario from '../../assets/usuario.svg'

export function CartaoDashboardAtendente({ usuario }) {
  return (<Card as={Col} className="px-0 shadow card-dashboard">
    <Image 
      id="img-usuario" 
      className={`rounded-circle align-self-center mt-3 ${usuario.estaOnline ? 'border-success bg-success' : 'border-danger bg-danger'}`} 
      width={150} 
      height={150}
      src={usuario.foto_perfil ?? imgPadraoUsuario}
      data-tip={`${usuario.nome} está ${usuario.estaOnline ? 'online' : 'offline'}`}
    />
    <h4 className="text-center pt-2 pb-1">{`${usuario.nome}`}</h4>
    <Card.Body className="pb-2 pt-0">
      <Row className="mb-2">
        <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${usuario.tempoOnline.split('h')[0]} horas e ${usuario.tempoOnline.split('h')[1].slice(0, -1)} minutos online`}>
          <Form.Text className="text-center font-16-px-hover"><strong>{usuario.tempoOnline}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Tempo Online</Form.Text>
        </Col>
        <Col className="cursor-help bg-secondary text-light rounded px-0 mx-1" data-tip={`${usuario.quantidadeAtendimentos} atendimentos recebidos`}>
          <Form.Text className="text-center font-16-px"><strong>{usuario.quantidadeAtendimentos}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Atendimentos</Form.Text>
        </Col>
        <Col 
          className="cursor-help px-0 mx-1 bg-coluna-3-dashboard rounded" 
          data-tip={`${usuario.quantidadeLigacoesNaoAtendidas} ligações não atendidas, ${Number(usuario.quantidadeLigacoes) - Number(usuario.quantidadeLigacoesNaoAtendidas)} atendidas e ${usuario.quantidadeLigacoes} no total`}
        >
          <Form.Text className="text-center font-16-px"><strong>{usuario.quantidadeLigacoes}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Voip</Form.Text>
        </Col>
        <Col  
          className="cursor-help px-0 mx-1 border rounded" 
          data-tip={`${usuario.quantidadeLigacoesRecebidas} ligações recebidas e ${usuario.quantidadeLigacoesRealizadas} ligações realizadas`}
        >
          <Form.Text className="text-center font-16-px-hover">
            <strong>{`${usuario.quantidadeLigacoesRecebidas} | ${usuario.quantidadeLigacoesRealizadas}`}</strong>
          </Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Ligações Recebidas | Realizadas</Form.Text>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col 
          className="cursor-help px-0 mx-1 border rounded" 
          data-tip={`${usuario.minutagemVoip.split('h')[0]} horas e ${usuario.minutagemVoip.split('h')[1].slice(0, -1)} minutos de ligação`}
        >
          <Form.Text className="text-center font-16-px-hover"><strong>{usuario.minutagemVoip}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Minutagem Voip</Form.Text>
        </Col>
        <Col 
          className={`cursor-help px-0 mx-1 border rounded ${Number(usuario.quantidadeProdutividadePorcentual.slice(0, -1)) > 50 ? 'bg-success' : 'bg-danger'}`} 
          data-tip={`${usuario.quantidadeProdutividade} atendimentos realizados (${usuario.quantidadeProdutividadePorcentual})`}
        >
          <Form.Text className="text-center font-16-px text-light"><strong>{`${usuario.quantidadeProdutividade} | ${usuario.quantidadeProdutividadePorcentual}`}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard text-light">Produtividade</Form.Text>
        </Col>
        <Col 
          className="cursor-help px-0 mx-1 border rounded bg-coluna-3-dashboard rounded" 
          data-tip={`${usuario.quantidadeAtendimentosWhatsappNaoLidos} conversas não lidas ou respondidas de um total de ${usuario.quantidadeAtendimentosWhatsapp} conversas`}
        >
          <Form.Text className="text-center font-16-px">
            <strong>{`${usuario.quantidadeAtendimentosWhatsappNaoLidos}* | ${usuario.quantidadeAtendimentosWhatsapp}`}</strong>
          </Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Whatsapp</Form.Text>
        </Col>
        <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${usuario.quantidadeAtendimentosResgate} atendimentos resgatados da sala de espera`}>
          <Form.Text className="text-center font-16-px-hover"><strong>{usuario.quantidadeAtendimentosResgate}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Resgates Sala Espera</Form.Text>
        </Col>
      </Row>
      <Row>
        <Col 
          className="cursor-help px-0 mx-1 border rounded" 
          data-tip={`${usuario.tempoMedioLigacao.split('m')[0]} horas e ${usuario.tempoMedioLigacao.split('s')[1].slice(0, -1)} minutos de ligação`}
        >
          <Form.Text className="text-center font-16-px-hover"><strong>{usuario.tempoMedioLigacao}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">TM Ligação</Form.Text>
        </Col>
        <Col 
          className="cursor-help px-0 mx-1 border rounded" 
          data-tip={`${usuario.quantidadeAtendimentosFinalizados} atendimentos concluídos (${usuario.quantidadeAtendimentosFinalizadosPorcentual})`}
        >
          <Form.Text className="text-center font-16-px-hover">
            <strong>{`${usuario.quantidadeAtendimentosFinalizados} | ${usuario.quantidadeAtendimentosFinalizadosPorcentual}`}</strong>
          </Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Concluídos</Form.Text>
        </Col>
        <Col 
          className="cursor-help px-0 mx-1 border rounded bg-coluna-3-dashboard rounded" 
          data-tip={`${usuario.quantidadeAtendimentosEmailNaoLidos} emails não lidos de um total de ${usuario.quantidadeAtendimentosEmail} emails`}>
          <Form.Text className="text-center font-16-px"><strong>{`${usuario.quantidadeAtendimentosEmailNaoLidos}* | ${usuario.quantidadeAtendimentosEmail}`}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">E-mails</Form.Text>
        </Col>
        <Col className="cursor-help px-0 mx-1 border rounded" data-tip={`${usuario.quantidadeTicketsAbertos} tickets abertos`}>
          <Form.Text className="text-center font-16-px-hover"><strong>{usuario.quantidadeTicketsAbertos}</strong></Form.Text>
          <Form.Text className="text-center label-descricao-campo-dashboard">Tickets abertos</Form.Text>
        </Col>
      </Row>
    </Card.Body>
    <Card.Footer className="py-2 px-2">
      <small className="text-muted">*<em>Ligações não atendidas ou Mensagens não lidas</em></small>
      <PopoverAtendenteDashboard idUsuario={usuario.id_usuario}/>
    </Card.Footer>
  </Card>)
}