import api from './api';

const TicketTipo = {
	index:()=>{
		return api.get('/ticket/tipo');
	},
	show:(id)=>{
		return api.get('/ticket/tipo/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket/tipo/' + id;
        return api['put'].call(api, uri, {tipo: dados});
    },
	store: (dados) => {
        let uri = '/ticket/tipo';
        return api['post'].call(api, uri, {tipo: dados});
    },
};

export default TicketTipo;
