const checkTemplateModulo = (moduloId, atendimentoAtivo) => {
    if(atendimentoAtivo.oportunidade){
        return [1, 6].includes(Number(moduloId));
    } else if(atendimentoAtivo.processo) {
        return [2, 6, 8, 9].includes(Number(moduloId));
    }
    return Number(moduloId) == 6;
}

const checkTemplateTipoAtendimento = (templateTipos, atendimentoAtivo) => {
    return templateTipos.some((element) => element.id === atendimentoAtivo.tipo_atendimento.id);
}

export const templatesFilter = (templates, atendimentoAtivo) =>{
    let templatesFiltered = [];
    templatesFiltered  = templates.filter((template)=>{        
        if(template.id_modulo_tags && template.tipos.length){
            if(checkTemplateModulo(template.id_modulo_tags, atendimentoAtivo) && checkTemplateTipoAtendimento(template.tipos, atendimentoAtivo)){
                return template;
            }
        }else if(template.id_modulo_tags){
            if(checkTemplateModulo(template.id_modulo_tags, atendimentoAtivo)){
                return template;
            }
        }else if(template.tipos.length){
            if(checkTemplateTipoAtendimento(template.tipos, atendimentoAtivo)){
                return template;
            }
        }         
        else{
            return template;
        }
    })
    return templatesFiltered;    
}

