
import React, { useContext, useState, useEffect } from 'react'
import { Accordion, AccordionContext, Alert, Card, Col, Nav,  Tab, useAccordionToggle } from 'react-bootstrap'
import DommusToggle from '../../../../components/DommusToggle'
import Historico from '../DadosDetalhes/historico'
import MenuDetalhes from '../MenuDetalhes'
import InformacoesAtendimento from './InformacoesAtendimento'
import InformacesEnvolvidos from './InformacoesEnvolvidos'
import InformacoesLead from './InformacoesLead/index'
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext'
import { CardOutroAtendimento } from '../../../../components/CardOutroAtendimento'
import { trackPromise } from 'react-promise-tracker'
import caAPI from '../../../../services/caAPI'
import Swal from 'sweetalert2'

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey ? '#265d9c' : 'rgba(0,0,0,.03)',
        color: isCurrentEventKey ? 'white' : 'black',
        padding: '.75rem 1.25rem',
        marginbottom: '0',
        borderbottom: '1px solid rgba(0,0,0,.125)'
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

function DetalhesOpv({ cliente, titleToggle, handleSelecionaProcesso, handleSetClienteDadosOpv }) {
  const envolvidos = cliente.envolvidos.filter(el => el.envolvido && el.envolvido != null)
  .map(el => el.envolvido)
  const { atendimentoAtivo } = useContext(AtendimentoContext);
  const [outrosAtendimentos, setOutrosAtendimentos] = useState([]);

  useEffect(()=>{
    trackPromise(caAPI.atendimento.buscarOutrosAtendimento(atendimentoAtivo.id)).then((response)=>{
      setOutrosAtendimentos(response.data);
    }).catch((erro)=>{
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao carregar outros atendimentos " + erro,
        icon: 'error'
    });
    })
},[atendimentoAtivo])


  return (
    <>
      <DommusToggle
        title={<MenuDetalhes
          titleToggle={titleToggle}
          handleSetClienteDadosOpv={handleSetClienteDadosOpv}
          handleSelecionaProcesso={handleSelecionaProcesso}
          link={cliente.link_opv}
        />}
        toggle={true}
        bodyClass="dommus-toggle-height-fixed-d"
      >
        <div id='dadosDetalhes'>
          <Tab.Container id="left-tabs" defaultActiveKey="dados_cliente">
            <div className='conteudoOpv'>
              <div className="menuClienteHistoricoTarefas">
                <Nav variant="pills" className="menu_direita">
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="dados_cliente">Cliente</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="historico">Histórico</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="tarefas">Tarefas</Nav.Link>
                    </Nav.Item>
                  </Col>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="dados_cliente">
                  <Accordion className="accordion" defaultActiveKey="info">
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="info">
                          Informações Lead
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="info">
                        <InformacoesLead lead={cliente.lead} status={cliente.status}
                            envolvidos={envolvidos}
                            empreendimento={cliente.empreendimento}
                            substatus={cliente.substatus_oportunidade}
                        />
                      </Accordion.Collapse>
                    </Card>
                    {
                      cliente.atendimento &&
                      <Card>
                        <div className="accordion_iten">
                          <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="Atendimento">
                            Atendimento
                          </ContextAwareToggle>
                        </div>
                          <Accordion.Collapse eventKey="Atendimento">
                            <InformacoesAtendimento atendimento={cliente.atendimento} criacao={cliente.criado_em}/>
                          </Accordion.Collapse>
                      </Card>
                    }

                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="Envolvidos">
                          Envolvidos
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Envolvidos">
                        <InformacesEnvolvidos  envolvidos={cliente.envolvidos} />
                      </Accordion.Collapse>
                    </Card>

                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="historico">
                  <Accordion defaultActiveKey="HistoricoCliente">
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="HistoricoCliente">
                          Histórico do Cliente
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="HistoricoCliente">
                        <Card.Body>
                          <Historico />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {outrosAtendimentos?.oportunidade?.length ?
                      <Card>
                        <div className="accordion_iten">
                          <ContextAwareToggle as={Card.Header} eventKey="OutroAtendimentos">
                            Outros Atendimentos Pela Oportunidade
                          </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="OutroAtendimentos">
                          <Card.Body style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                            {outrosAtendimentos?.oportunidade?.length ?
                              outrosAtendimentos?.oportunidade?.map((outroAtendimento, indice)=>{
                                return (
                                  <CardOutroAtendimento key={indice} atendimento={outroAtendimento}/>
                                )
                              })
                              :
                              <Alert variant='warning'>Não possui outros atendimentos!</Alert>
                            }

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :<></>
                    }
                    {/* <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="OutroChamados">
                          Outros Chamados
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="OutroChamados">
                        <Card.Body>
                          Outros Chamados
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card> */}
                  </Accordion>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </DommusToggle>
    </>
  )
}

export default DetalhesOpv
