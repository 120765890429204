import './index.css';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

function DommusToggle(props) {
	const [toggle, setToggle] = useState(props.toggle || false);
	const [angle, setAngle] = useState(props.angle || null);

    useEffect(() => {
        setToggle(props.toggle);
    }, [props.toggle])

	const headerClass = toggle ? 'dommus-toggle' : 'dommus-toggle arrow';
	return (
		<>
			<header className={`${headerClass} ${props.headerClass || ''}`}>{props.title}<FontAwesomeIcon icon={props.angle != null ? !angle ? faAngleDown : faAngleUp : faAngleDown}
			onClick={() => {
				setToggle(!toggle);
				setAngle(!angle);
				(props.callback && props.callback(toggle));
			}}/></header>
			<div style={{'display': (toggle) ? 'block' : 'none'}} id={props.containerId ?? ''} className={props.bodyClass ?? ''}>
				{toggle}
				{props.children}
			</div>
		</>
	)
}

export default DommusToggle;
