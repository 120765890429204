import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import "./index.css";

function AtendentesSection({ openSection, closePaneHandler, children }) {
  return (
    <>
      <div className={"sidenav-overlay " + (openSection && "open")}></div>
      <section className={"equipes " + (openSection && "open")}>
        <header>
          <div>Atendimento</div>
          <div className={"close-pane"}>
            <FontAwesomeIcon icon={faArrowRight} onClick={closePaneHandler} />
          </div>
        </header>
        <div>{children}</div>
      </section>
    </>
  );
}

export default AtendentesSection;
