import React, { useEffect,  useState, useContext } from 'react';
import Swal from 'sweetalert2';
import DommusBarraTitulo from '../../components/DommusBarraTitulo';
import ItemBarraTitulo from '../../components/DommusBarraTitulo/ItemBarraTitulo';
import DommusTabelaPaginadaControlada from '../../components/DommusTabelaPaginadaControlada';
import DommusToggle from '../../components/DommusToggle';
import MidiaSocialIcone from '../../components/MidiaSocialIcone';
import caAPI from '../../services/caAPI';
import { trackPromise } from "react-promise-tracker";
import DommusLoadingArea from '../../components/DommusLoading/DommusLoadingArea';
import {WebSocketContext} from '../../contexts/WebSocketContext';
import { infoToast } from '../../components/DommusToast';
import CabecalhoTabelaHistorico from '../../components/CabecalhoTabelaHistorico';
import LinhaTabelaHistorico from '../../components/LinhaTabelaHistorico';
import FiltroHistoricoAtendimento from './FiltroHistoricoAtendimento';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import ModalDommus from '../../components/Modal';

export default function QuadroHistoricoAtendimento() {
  const [tamanhoPagina, setTamanhoPagina] = useState(20);
  const [pagina, setPagina] = useState(1);
  const [totalHistoricos, setTotalHistoricos] = useState(null);
  const [historicos, setHistoricos] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const {channel} = useContext(WebSocketContext);
  const [filtrosFiltrarPor, setFiltrosFiltrarPor] = useState({filtros: {}, filtrarPor: 3});
  const [openModalFiltro, setOpenModalFiltro] = useState(false);

  const atualizarLista = () => {
    let filtrarAtual = true;
    let filtrarSnapshot = true;
    switch(filtrosFiltrarPor.filtrarPor){
      case 1:
        filtrarAtual = true;
        filtrarSnapshot = false;
        break;

      case 2:
        filtrarAtual = false;
        filtrarSnapshot = true;
        break;

      default:
        break;
    }
    trackPromise(caAPI.historicoAtendimento.index(pagina, tamanhoPagina, filtrosFiltrarPor.filtros, filtrarAtual, filtrarSnapshot).then(res => {
      const novaLista = res.data || [];
      setHistoricos(novaLista);
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os históricos  " + err,
        icon: 'error'
      });
    }), 'loadingQuadroHistoricoArea');
  };
  useEffect(() => {
    if(pagina && tamanhoPagina && (totalHistoricos > -1)){
      atualizarLista();
    }
  }, [pagina, tamanhoPagina])

  useEffect(() => {
    channel.unbind('novo-historico-atendimento');
    if(channel && pagina){
      channel.bind('novo-historico-atendimento', function(data){
        caAPI.historicoAtendimento.show(data.historico).then((response) => {
          if(pagina === 1){
            const novaLista = historicos.slice();
            novaLista.unshift(response.data);
            novaLista.pop();
            setHistoricos(novaLista);
          }
          infoToast.fire({text: `Nova interação no atendimento # ${response.data.id_atendimento} - ${response.data.tipo_historico_atendimento.descricao}`})
        })

      })
    }

    return () => {
      channel.unbind('novo-historico-atendimento');
    }
  }, [channel, pagina, historicos, caAPI])

  useEffect(() => {
    let filtrarAtual = true;
    let filtrarSnapshot = true;
    switch(filtrosFiltrarPor.filtrarPor){
      case 1:
        filtrarAtual = true;
        filtrarSnapshot = false;
        break;

      case 2:
        filtrarAtual = false;
        filtrarSnapshot = true;
        break;

      default:
        break;
    }
    trackPromise(caAPI.historicoAtendimento.total(filtrosFiltrarPor.filtros, filtrarAtual, filtrarSnapshot).then(res => {
      setTotalHistoricos(res.data.total);
      if(pagina !== 1){
        setPagina(1);
      }else{
        atualizarLista();
      }
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os históricos  " + err,
        icon: 'error'
      });
    }), 'loadingQuadroHistoricoArea');
  }, [filtrosFiltrarPor])

  useEffect(() => {
    const novasLinhas = historicos.map((historico) => {
        return (
          <React.Fragment  key={historico.id}>
            <LinhaTabelaHistorico historico={historico} />
          </React.Fragment>
        );
      });

      setLinhas(novasLinhas);
  }, [historicos])

  return (<>
    <DommusBarraTitulo>
      <ItemBarraTitulo>
        Acompanhamento de Atendimentos
      </ItemBarraTitulo>
      <ItemBarraTitulo classe="btn" onClick={() => setOpenModalFiltro(true)}>
        <OverlayTrigger
          key="buttonPesquisa"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-Pesquisa`}>
              Pesquisar
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faSearch} color="#265d9c" />
        </OverlayTrigger>
        {Object.keys(filtrosFiltrarPor.filtros).length ? <div className="indicativoFiltro3">{Object.keys(filtrosFiltrarPor.filtros).length}</div> : <></>}
      </ItemBarraTitulo>
    </DommusBarraTitulo>
    <DommusToggle title='Tempo Real' toggle={true} className="labels" bodyClass="dommus-toggle-height-fixed-atendimento">
      <div className="mt-3">
        <DommusLoadingArea area="loadingQuadroHistoricoArea" fundo={"#fff"} />
        {(totalHistoricos && linhas && linhas.length && <DommusTabelaPaginadaControlada linhasCabecalho={<CabecalhoTabelaHistorico />} optionsTabela={{}}
        linhasCorpo={linhas} pagina={pagina} setPagina={setPagina} tamanhoPagina={tamanhoPagina}
        setTamanhoPagina={setTamanhoPagina} tamanhoTotal={totalHistoricos} />) || "Nenhum histórico registrado."}
      </div>
    </DommusToggle>

    <ModalDommus
      className="modalFiltros"
      open={openModalFiltro}
      size={"lg"}
      close={() => {
        setOpenModalFiltro(false);
      }}
      titulo={"Filtrar Interações"}
    >
      <FiltroHistoricoAtendimento filtros={filtrosFiltrarPor.filtros}
      setOpenModalFiltro={setOpenModalFiltro} setFiltrosFiltrarPor={setFiltrosFiltrarPor}
      filtrarPorEntrada={filtrosFiltrarPor.filtrarPor}/>
    </ModalDommus>
  </>);
}
