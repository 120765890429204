import React, { createContext, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import DisparoMassaService from "../services/DisparoMassaService";
import Swal from "sweetalert2/src/sweetalert2";

export const DisparoMassaContext = createContext({});

export function DisparoMassaProvider({ children }) {
    const [baseDisparoSelecionada, setBaseDisparoSelecionada] = useState(null);
    const [agendamentoSelecionado, setAgendamentoSelecionado] = useState(null);
    const [modalCadastroBaseDisparoAberto, setModalCadastroBaseDisparoAberto] =
    useState(false);    
    const [listaBaseDisparo, setListaBaseDisparo] = useState([]);
    const [listaAgendamentos, setListaAgendamentos] = useState([]);

    function recarrecarListaBaseDisparo() {
        trackPromise(DisparoMassaService.buscarListaBasesDisparos())
            .then((response) => {
                setListaBaseDisparo(response.data);
            })
            .catch((error) => {
                Swal.fire({
                    titleText: "Erro",
                    text:
                        "Houve um problema ao carregar as bases de disparo! " +
                        error,
                    icon: "error",
                });
            });
    }

    function abrirFecharModalCadastro(baseDisparo = null, agendamento=null) {
        if (baseDisparo) {
            setBaseDisparoSelecionada(baseDisparo);
        } else if (baseDisparoSelecionada) {
            setBaseDisparoSelecionada(null);
        }
        if(agendamento){
            setAgendamentoSelecionado(agendamento);
        }else if(agendamentoSelecionado){
            setAgendamentoSelecionado(null);
        }
        setModalCadastroBaseDisparoAberto((estadoAtual) => !estadoAtual);
    }

    function adicionarBaseDisparo(newBase){
        setListaBaseDisparo((current)=>[newBase, ...current]);
    }

    function atualizarBaseDisparo(id, newBase){
        setListaBaseDisparo((current)=>{
            return current.map((item)=>{
                if(item.id === id){
                    return newBase;
                }
                return item;
            })
        });
    }

    function removerBaseDisparo(id){
        setListaBaseDisparo((current)=>{
            return current.filter((item)=>{
                if(item.id !== id){
                    return item;
                }
            })
        });
    }

    function adicionarAgendamento(newAgendamento){
        setListaAgendamentos((current)=>[newAgendamento, ...current]);
    }

    function atualizarAgendamentoBack(idAgendamento){
        trackPromise(DisparoMassaService.buscarAgendamento(idAgendamento)).then((response)=>{
            atualizarAgendamento(idAgendamento, response.data);
        }).catch((error)=>{
            Swal.fire({
                titleText: "Erro",
                text:
                    "Houve um problema ao buscar agendamento" +
                    error,
                icon: "error",
            });
        })
    }

    function atualizarAgendamento(id, newAgendamento){
        setListaAgendamentos((current)=>{
            return current.map((item)=>{
                if(item.id === id){
                    return newAgendamento;
                }
                return item;
            })
        });
    }

    function removerAgendamento(id){
        setListaAgendamentos((current)=>{
            return current.filter((item)=>{
                if(item.id !== id){
                    return item;
                }
            })
        });
    }

    function removerDisparoAgendamento(idAgendamento, idDisparo){
        var agendamentoTemp = null;
        setListaAgendamentos((current)=>{
            return current.map((agendamento)=>{
                if(agendamento.id === idAgendamento){
                    agendamento.execucao_disparos = agendamento.execucao_disparos.filter((disparo)=>{
                        return disparo.id !== idDisparo;
                    })
                    agendamentoTemp = agendamento;
                }
                return agendamento;
            })
        })

        setAgendamentoSelecionado(agendamentoTemp);
    }

    async function buscarAgendamentoPorId(idAgendamento) {
        return await trackPromise(DisparoMassaService.buscarAgendamento(idAgendamento))
    }

    return (
        <DisparoMassaContext.Provider
            value={{ 
                abrirFecharModalCadastro, 
                baseDisparoSelecionada,
                modalCadastroBaseDisparoAberto,
                listaBaseDisparo,
                adicionarBaseDisparo,
                atualizarBaseDisparo,
                removerBaseDisparo,
                setListaBaseDisparo,
                setBaseDisparoSelecionada,
                recarrecarListaBaseDisparo,
                listaAgendamentos,
                setListaAgendamentos,
                adicionarAgendamento,
                atualizarAgendamento,
                removerAgendamento,
                agendamentoSelecionado,
                removerDisparoAgendamento,
                atualizarAgendamentoBack,
                buscarAgendamentoPorId
            }}
        >
            {children}
        </DisparoMassaContext.Provider>
    );
}
