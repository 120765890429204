import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Card, Button, Image } from 'react-bootstrap';
import Swal from 'sweetalert2'
import './template.scss';
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import { trackPromise } from "react-promise-tracker";
import caAPI from "../../../../services/caAPI";
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import { templatesFilter } from '../../../../helpers/TemplateFilter';

export function Templates({ enviarMensagemTemplate }) {
    const [mensagemInicial, setMensagemInicial] = useState();
    const [listaTemplates, setListaTemplates] = useState([]);
    const [listaTemplatesEmail, setListaTemplatesEmail] = useState([])
    const {atendimentoAtivo} = useContext(AtendimentoContext);

    useEffect(() => {
        trackPromise(
            caAPI.meio_comunicacao.getTemplate(4)
            .then(response => setListaTemplatesEmail(response.data))
            .catch(err => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao buscar ao buscar os templates de mensagens " + err,
                    icon: 'error'
                });
                return;
            })
        )
    }, []);

    useEffect(() => setListaTemplates(templatesFilter(listaTemplatesEmail, atendimentoAtivo)), [atendimentoAtivo, listaTemplatesEmail])

    function selecionaMensagem(idTemplateSelecionado) {
        const mensagem = listaTemplates.find(template => template.id.toString() === idTemplateSelecionado).mensagem;
        setMensagemInicial({
            id_template: idTemplateSelecionado,
            mensagem : mensagem.replace(/<\/?[^>]+(>|$)/g, "")
        })
    }

    function tratarUrlImagemTemplate(template) {
        if(!template?.info) {
            return null
        }
        const infoTemplate = JSON.parse(template.info)

        return infoTemplate?.imagem?.url || null
    }

    function enviarTemplate() {
        if(!mensagemInicial) {
            Swal.fire({
                titleText: "Atenção",
                text: "Selecione uma mensagem para enviar",
                icon: 'warning'
            })
            return
        }
        enviarMensagemTemplate(mensagemInicial)  
    }

    return <>
        <div className="templatesContainer">
        <div className="cabecalhoTemplates">
            <div className="tituloTemplares">Selecione um modelo de mensagem para iniciar o atendimento</div>
            <p />
            <div className="selecioneTemplares">Clique sobre a mensagem para selecionar:</div>
        </div>
        <div className="selecaoTemplates">
            <Form>
                <Form.Group >
                    <Form.Control
                        as="radio"
                        custom
                        onChange={(event) => selecionaMensagem(event.target.id)}
                    >
                        {listaTemplates && listaTemplates.map((template) =>
                            <div key={template.id}>
                                <Row>
                                    <Card className="templateCard">
                                        <Card.Body className="templateCorpo">
                                            <Form.Check
                                                className="template"
                                                type="radio"
                                                id={template.id}
                                                value={template.id}
                                            >
                                                <Form.Check.Input type='radio' name="selecionatemplate" isValid />
                                                <Form.Label><strong style={{ fontSize: '1rem' }}>{template.nome}</strong></Form.Label>
                                                {(template.email_assunto && <Form.Label><strong style={{ fontSize: '1rem' }}>Assunto:</strong> {template.email_assunto}</Form.Label>) || 
                                                    <></>
                                                }
                                                {(template.emails_copia && <Form.Label><strong style={{ fontSize: '1rem' }}>CC:</strong> {template.emails_copia}</Form.Label>) ||
                                                    <></>
                                                }
                                                {(
                                                    template.emails_copia_oculta && 
                                                    <Form.Label><strong style={{ fontSize: '1rem' }}>CCO:</strong> {template.emails_copia_oculta}</Form.Label>
                                                ) || <></>}
                                                <Form.Label >
                                                    <div >
                                                        {template.mensagem.replace(/<\/?[^>]+(>|$)/g, "")}
                                                    </div>
                                                    {(tratarUrlImagemTemplate(template) && <Image src={tratarUrlImagemTemplate(template)} height={50}/>) || <></>}
                                                </Form.Label>
                                            </Form.Check>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </div>
                        )}
                        <Button className="btnEnviaPrimeiraMensagem" onClick={enviarTemplate}>
                            Enviar mensagem selecionada <Icon icon={sendOutlined} color="white" />
                        </Button>

                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
    </div>
    </>;
}
