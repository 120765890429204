import React, { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import DommusRequiredSpan from "../../../components/DommusRequiredSpan";
import { MeioComunicacaoContext } from "../../../contexts/MeioComunicacaoContext";
import { TipoHistoricoAtendimentoContext } from "../../../contexts/TipoHistoricoAtendimentoContext";
import { apenasNumeros, celularMask, celularMaskInput } from "../../../components/InputTextMask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import { ContatoNotificacaoHistoricoService } from "../../../services/ContatoNotificacaoHistoricoService";
import { successToast } from '../../../components/DommusToast';
import Swal from "sweetalert2";
import { ContatoNotificacaoHistoricoContext } from "../../../contexts/ContatoNotificacaoHistoricoContext";

export function ContatosNotificacaoHistoricoForm({ contato, closeModal }) {
    const { handleSubmit, register, watch, setValue } = useForm({
        defaultValues: contato,
    });
    const watchMeioComunicacao = watch("id_meio_comunicacao");
    const { meiosComunicacao } = useContext(MeioComunicacaoContext);
    const { tiposHistoricoAtendimento } = useContext(
        TipoHistoricoAtendimentoContext
    );
    const {adicionarContatoNotificacao, atualizarContatoNotificacao} = useContext(ContatoNotificacaoHistoricoContext);

    const handleOnSubmit = (data) => {
        let metodo = null;
        const meioComunicacao = meiosComunicacao.find((item) => Number(item.id) === Number(data.id_meio_comunicacao));

        if(meioComunicacao.slug !== "email"){
            data.contato = apenasNumeros(data.contato);
        }

        if(contato && contato?.id_contato_notificacao_historico){
            metodo = ContatoNotificacaoHistoricoService.update(contato.id_contato_notificacao_historico, data);
        }else{
            metodo = ContatoNotificacaoHistoricoService.store(data);
        }

        trackPromise(metodo).then((response)=>{
            if(contato && contato?.id_contato_notificacao_historico){
                atualizarContatoNotificacao(response.data);
                successToast.fire("Atendimento atualizado com sucesso!!");
            }else{
                adicionarContatoNotificacao(response.data);
                successToast.fire("Atendimento criado com sucesso!!");
            }
            closeModal();
        }).catch((error)=>{
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao salvar contato " + error,
                icon: 'error'
            });
        })
    };

    useEffect(()=>{
        if(contato){
            setValue('contato', celularMask(contato.contato));
        }
    },[contato])

    const campoContato = useMemo(() => {
        if (watchMeioComunicacao) {
            const meio = meiosComunicacao.find(
                (item) => Number(item.id) === Number(watchMeioComunicacao)
            );
            switch (meio?.slug) {
                case "email":
                    return (
                        <Form.Group>
                            <Form.Label className="inputLabel">
                                Contato:{" "}
                            </Form.Label>
                            <DommusRequiredSpan />
                            <Form.Control
                                type="text"
                                {...register("contato", {required: true})}
                            />
                        </Form.Group>
                    );
                default:
                    return (
                        <Form.Group>
                            <Form.Label className="inputLabel">
                                Contato:{" "}
                            </Form.Label>
                            <DommusRequiredSpan />
                            <Form.Control
                                type="text"
                                {...register("contato", {required: true})}
                                onChange={(e) => {
                                    setValue('contato', celularMaskInput(e.target.value));
                                }}
                            />
                        </Form.Group>
                    );
            }
        } else {
            return (
                <Form.Group>
                    <Form.Label className="inputLabel">Contato: </Form.Label>
                    <DommusRequiredSpan />
                    <Form.Control type="text" {...register("contato")} disabled={true}/>
                </Form.Group>
            );
        }
    }, [watchMeioComunicacao]);

    return (
        <Form
            className="container-agendamento-form"
            onSubmit={handleSubmit(handleOnSubmit)}
        >
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Descrição:{" "}
                        </Form.Label>
                        <Form.Control type="text" {...register("descricao")} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Meio de Comunicação:{" "}
                        </Form.Label>
                        <DommusRequiredSpan />
                        <Form.Control
                            as="select"
                            {...register("id_meio_comunicacao", {
                                required: true,
                            })}
                        >
                            <option selected={true} value="">
                                SELECIONE
                            </option>
                            {meiosComunicacao
                                .filter((item) => item.slug === "sms")
                                .map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.nome}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>{campoContato}</Col>
                <Col>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Tipo Histórico Atendimento:{" "}
                        </Form.Label>
                        <DommusRequiredSpan />
                        <Form.Control
                            as="select"
                            {...register("id_tipo_historico_atendimento", {
                                required: true,
                            })}
                        >
                            <option selected={true} value="">
                                SELECIONE
                            </option>
                            {tiposHistoricoAtendimento.map((item) => {
                                return (
                                    <option
                                        value={
                                            item.id_tipo_historico_atendimento
                                        }
                                    >
                                        {item?.descricao}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="px-3 justify-content-end pt-3 border-top">
                    <Button variant="success" type="submit">
                        <FontAwesomeIcon icon={faCloudUpload} /> Salvar
                    </Button>
                </Row>
        </Form>
    );
}
