import React from "react";
import AtendentesSection from "../AtendentesSection";
import AtendentesForm from "../AtendentesForm";
import AdicionarFila from "../AdicionarFila";
import { Tabs, Tab } from "react-bootstrap";

export default function AtendentesWrapper({
  setOpenPane,
  openPane,
  qualificacaoAtendentes,
  meiosComunicacao,
  atendentes,
  infosAtendentes,
  setRefresh,
  refresh,
  addOcorrenciaFila,
}) {
  // const [config, setConfig] = useState({});

  return (
    <AtendentesSection
      openSection={openPane}
      closePaneHandler={() => {
        setOpenPane(false);
      }}
    >
      <Tabs defaultActiveKey={"qualificacao"}>
        <Tab eventKey={"qualificacao"} title={"Meio de Comunicação"}>
          <AtendentesForm
            meiosComunicacao={meiosComunicacao}
            qualificacaoAtendentes={qualificacaoAtendentes}
            atendentes={atendentes}
            infosAtendentes={infosAtendentes}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Tab>
        <Tab eventKey={"fila"} title={"Adicionar Atendentes"}>
          <AdicionarFila
            atendentes={atendentes}
            setRefresh={setRefresh}
            refresh={refresh}
            addOcorrenciaFila={addOcorrenciaFila}
          />
        </Tab>
      </Tabs>
    </AtendentesSection>
  );
}
