import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import caAPI from "../services/caAPI";

export const TipoAtendimentoContext = createContext({});

export function TipoAtendimentoProvider({ children }) {
  const [tiposAtendimento, setTiposAtendimento] = useState([]);
  const [mapaTiposAtendimento, setMapaTiposAtendimento] = useState([]);
  
  useEffect(()=>{
    const novoMapa = new Map();
    tiposAtendimento.forEach((tipoAtendimento) => {
      novoMapa.set(Number(tipoAtendimento.id), tipoAtendimento);
    });
    setMapaTiposAtendimento(novoMapa);
  }, [tiposAtendimento]);

  const recarregarTiposAtendimento = ()=>{
    caAPI.tipo_atendimento.list().then(res => {
        setTiposAtendimento(res.data);
    }).catch(err => Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar os tipos de atendimento  " + err,
        icon: 'error'
    }))
  };

  const resolverNomeTipoAtendimento = (idTipoAtendimento, padrao = "Não Atribuído") => {
    let tipoAtendimento = mapaTiposAtendimento.get(Number(idTipoAtendimento));
    return (tipoAtendimento && tipoAtendimento.descricao) || padrao;
  };

  return (
    <TipoAtendimentoContext.Provider value={{
      tiposAtendimento,
      recarregarTiposAtendimento,
      resolverNomeTipoAtendimento
    }}>
      {children}
    </TipoAtendimentoContext.Provider>
  )
}
