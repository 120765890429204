import React, {useState} from 'react';
import '../MenuTopo/index.css';
import { Link } from "react-router-dom";
import logo from '../../assets/new-logo-w.svg';
import logoMobile from '../../assets/logo-mobile.svg';
import alarm from '../../assets/alarm.svg';
import DommusOnlineFlag from "../DommusOnlineFlag";
import Agenda from "./Agenda";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown} from "react-bootstrap";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../MenuLateral/index';
import caAPI from "../../services/caAPI";
import { Icon } from '@iconify/react';
import calendarOutlineBadged from '@iconify-icons/clarity/calendar-outline-badged';
import menu from '../../assets/navigation-menu.svg';
import close from '../../assets/close.png';
import NavegadorModulos from '../NavegadorModulos';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';

export default function MenuTopo({history}) {

    const [openAgenda, setOpenAgenda] = useState(false);

    function logout(e) {
        e.preventDefault();
        caAPI.logout();
        window.location.reload(false);
    }

    function abrirMenuMobile() {
      const sidebarMobile = document.getElementById('sidebarMobile');
      const asidebar = document.getElementById('asideMobile');
      const asidebarWrapper = document.getElementById('asideWrapperMobile');
      const barsMenuMobile = document.getElementById('barsMenuMobile');


      if (asidebar.classList == 'sidebar hide') {
        asidebar.classList.remove('hide');
        asidebarWrapper.classList.remove('is-closed');
        sidebarMobile.style.display = 'flex';
        barsMenuMobile.src = close;
      } else {
        asidebar.classList.add('hide');
        asidebarWrapper.classList.add('is-closed');
        sidebarMobile.style.display = 'none';
        barsMenuMobile.src = menu;
      }
    }

    // function openLateral(){
    //   const lateral = !caAPI.getLocalState('dommusMenuMobile');
    //   console.log(lateral);
    //   //localStorage.setItem('dommusMenuMobile', !lateral);

    // }

    return (
        <>
            <nav className="dommus-menu">
                <div className="logo">
                    <picture>
                        {isDesktop || isTablet ? 
                            <img src={logo} alt="Dommus Conecta"/>
                        :
                            <img src={logoMobile} alt="Dommus Conecta"/>                        
                        }
                    </picture>
                    <div className="modulo-name">
                        <span>|</span>     
                        <NavegadorModulos/>
                    </div>
                </div>
                <div className="right-nav">
                    <div className="info-nav">
                        <div className="online">
                        <Dropdown>
                            <Dropdown.Toggle className="perfil-dropdown">
                                    <DommusOnlineFlag/>
                                    <h1 className="user-name d-none d-md-flex">Olá, {caAPI.getLocalState('nomeUsuario')}</h1>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/*<Dropdown.Item eventKey="4" onClick={() => {}}>
                                    Perfil
                                </Dropdown.Item>
                                <Dropdown.Divider/>*/}
                                <Dropdown.Item >
                                <Link style={{color: "#000000"}} to="/usuario/gerenciar-assinatura">
                                Gerenciar Assinatura
                                </Link>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={(e) => logout(e)}>
                                    Sair
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        <picture className={"picture-topo"} onClick={() => {
                            setOpenAgenda(statusAtual => {
                                return !statusAtual
                            })
                        }}>
                            <Icon icon={calendarOutlineBadged} width="30" />
                        </picture>
                        <picture className={"picture-topo"}>
                            <img src={alarm} alt="Ícone de sino"/>
                        </picture>
                        <picture className="barsMenuMobile" onClick={() => {
                          abrirMenuMobile()
                        }} id="barsMenuMobile">
                          <FontAwesomeIcon icon={faBars} className="barsMenuMobile"/>
                        </picture>
                    </div>
                </div>
            </nav>
            <Agenda openAgenda={openAgenda} closeAgenda={() => {
                setOpenAgenda(false)
            }}/>
        </>

    )

}
