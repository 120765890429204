import React from 'react';
import { Card } from 'react-bootstrap';
import face_logo from '../../../assets/logos/facebook.png'
import FacebookLogin from "react-facebook-login"
import Swal from 'sweetalert2';
import IntegracoesService from '../../../services/IntegracoesService';

export default function Facebook() {
    const responseFacebook = (response) => {
        if(response.userID && response.accessToken) {
            IntegracoesService.subscribeFacebookPages({id_user: response.userID, user_access_token: response.accessToken})
                .then(res => {
                    Swal.fire({
                        titleText: "Sucesso!",
                        text: "Páginas integradas com sucesso!",
                        icon: 'success'
                    })
                }).catch(err => Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao integrar as páginas do usuário " + err,
                    icon: 'error'
                }))
        }
    }

    return <>
        <Card.Body>
            <Card.Title><Card.Img src={face_logo}/></Card.Title>
            <Card.Text>
                Rede Social
            </Card.Text>
        </Card.Body>
        <FacebookLogin
            appId="1009563269991608"
            autoLoad={false}
            callback={responseFacebook}
            scope={"pages_manage_metadata,pages_show_list,pages_messaging,instagram_basic,instagram_manage_messages"}
        />
    </>
}