import React, { useState, useContext, useEffect } from 'react';
import DommusToggle from "../../../../components/DommusToggle";
import { Accordion, Card, DropdownButton, Dropdown, AccordionContext, useAccordionToggle, Tab, Col, Nav, Button, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import './index.scss';
import Historico from './historico';
import InformacoesCadastrais from "./InformacoesCadastrais";
import Produto from "./produto";
import Documentacao from './documentacao';
import Contrato from './contrato';
import Financeiro from './financeiro';
import ModalDommus from "../../../../components/Modal";
import NovoChamado from '../DadosDetalhes/AbrirChamado';
import MenuDetalhes from '../MenuDetalhes';
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import Alertas from './alertas';
import { CardOutroAtendimento } from '../../../../components/CardOutroAtendimento';
import { trackPromise } from 'react-promise-tracker';
import caAPI from '../../../../services/caAPI';
import Swal from 'sweetalert2';
// import './style.scss'
// import CardAccordion from "./CardAccordion";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);  
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey ? '#265d9c' : 'rgba(0,0,0,.03)',
        color: isCurrentEventKey ? 'white' : 'black',
        padding: '.75rem 1.25rem',
        marginbottom: '0',
        borderbottom: '1px solid rgba(0,0,0,.125)'
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default function DadosDetalhes({ tipoDetalhes,cliente, titleToggle, handleSelecionaProcesso, handleSetClienteDadosProcesso, idProcesso, idAtendimento }) {

  const [dadosCliente, setDadosCliente] = useState(cliente);
  const [novoChamado, setOpenNovoChamado] = useState(false);
  const [outrosAtendimentos, setOutrosAtendimentos] = useState([]);
  const { atendimentoAtivo } = useContext(AtendimentoContext);


  useEffect(() => {
    setDadosCliente(cliente)
  }, [atendimentoAtivo, cliente, titleToggle])

  useEffect(()=>{
      trackPromise(caAPI.atendimento.buscarOutrosAtendimento(atendimentoAtivo.id)).then((response)=>{
        setOutrosAtendimentos(response.data);
      }).catch((erro)=>{
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao carregar outros atendimentos " + erro,
          icon: 'error'
      });
      })
  },[atendimentoAtivo])


  return (
    <>
      <DommusToggle
        title={<MenuDetalhes
          tipoDetalhes={tipoDetalhes}
          titleToggle={titleToggle}
          handleSetClienteDadosProcesso={handleSetClienteDadosProcesso}
          handleSelecionaProcesso={handleSelecionaProcesso}
          link={dadosCliente.link_processo}
        />}
        toggle={true}
        bodyClass="dommus-toggle-height-fixed-d">
        <div id='dadosDetalhes'>
          <Tab.Container id="left-tabs" defaultActiveKey="dados_cliente">
            <div className='conteudo'>
              <div className="menuClienteHistoricoTarefas">
                <Nav variant="pills" className="menu_direita">
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="dados_cliente">Cliente</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="historico">Histórico</Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item>
                      <Nav.Link eventKey="tarefas">Tarefas</Nav.Link>
                    </Nav.Item>
                  </Col>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="dados_cliente">
                  <Accordion className="accordion" defaultActiveKey="info">
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="info">
                          Informações Cadastrais
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="info">
                        <InformacoesCadastrais clientes={dadosCliente.proponentes} produto={dadosCliente.produto} />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="Produto">
                          Produto
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Produto">
                        <Produto produto={dadosCliente.produto} />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="Documentacao">
                          Documentação
                        </ContextAwareToggle>
                      </div>
                      {Array.isArray(dadosCliente.documentacao) && (
                        <Accordion.Collapse eventKey="Documentacao">
                          <Documentacao documentacao={dadosCliente.documentacao} />
                        </Accordion.Collapse>
                      )}
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="Contrato">
                          Contrato
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Contrato">
                        <Contrato contrato={dadosCliente.contrato} />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} eventKey="Financeiro">
                          Financeiro
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="Financeiro">
                        <Financeiro financeiro={dadosCliente.financeiro} />
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="alertas">
                          Alertas
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="alertas">
                        <Alertas alertas={dadosCliente.interacoes.alertas} />
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="historico">
                  {/* <Accordion defaultActiveKey="HistoricoCliente"> */}
                  <Accordion defaultActiveKey="HistoricoCliente">
                    <Card>
                      <div className="accordion_iten">
                        <ContextAwareToggle as={Card.Header} className="accordion_iten" eventKey="HistoricoCliente">
                          Histórico do Cliente
                        </ContextAwareToggle>
                      </div>
                      <Accordion.Collapse eventKey="HistoricoCliente">
                        <Card.Body>
                          <Historico />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {outrosAtendimentos?.chamados?.length ?
                      <Card>
                        <div className="accordion_iten">
                          <ContextAwareToggle as={Card.Header} eventKey="OutroAtendimentos">
                            Outros Atendimentos Pelo Chamado
                          </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="OutroAtendimentos">
                          <Card.Body style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                            {outrosAtendimentos?.chamados.length ?
                              outrosAtendimentos?.chamados.map((outroAtendimento)=>{
                                return (
                                  <CardOutroAtendimento atendimento={outroAtendimento}/>
                                )
                              })
                              :
                              <Alert variant='warning'>Não possui outros atendimentos!</Alert>
                            }

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :<></>
                    }                    
                    {outrosAtendimentos?.processo?.length ?
                      <Card>
                        <div className="accordion_iten">
                          <ContextAwareToggle as={Card.Header} eventKey="OutroAtendimentos">
                            Outros Atendimentos Pelo Processo
                          </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey="OutroAtendimentos">
                          <Card.Body style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                            {outrosAtendimentos?.processo?.length ?
                              outrosAtendimentos?.processo?.map((outroAtendimento)=>{
                                return (
                                  <CardOutroAtendimento atendimento={outroAtendimento}/>
                                )
                              })
                              :
                              <Alert variant='warning'>Não possui outros atendimentos!</Alert>
                            }

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      :<></>
                    }                    
                  </Accordion>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
        <div >
          <Button onClick={() => setOpenNovoChamado(true)} className="btnAcoes">Abrir chamado</Button>
        </div>
      </DommusToggle>
      <ModalDommus
        className="modalNovoChamado"
        open={novoChamado}
        size={"lg"}
        close={() => {
          setOpenNovoChamado(false);
        }}
        titulo={"Abertura de novo chamado"}
      >
        <NovoChamado
          corTop={"#265d9c"}
          corButton={"#4CAF50"}
          nomeProponente={atendimentoAtivo.nome}
          numeroOPVProcesso={atendimentoAtivo.processo}
          numeroAtendimento={atendimentoAtivo.id}
          idProcesso={idProcesso}
          idAtendimento={idAtendimento}
          dadosProcesso={cliente}
          meioComunicacao={atendimentoAtivo.meio_comunicacao.slug}
        />
      </ModalDommus>
    </>

  )
}
