import api from './api';

const TicketStatus = {
	index:()=>{
		return api.get('/ticket/status');
	},
	show:(id)=>{
		return api.get('/ticket/status/'+id);
	},
	updade: (dados, id) => {
        let uri = '/ticket/status/' + id;
        return api['put'].call(api, uri, {status: dados});
    },
	store: (dados) => {
        let uri = '/ticket/status';
        return api['post'].call(api, uri, {status: dados});
    },
};

export default TicketStatus;
