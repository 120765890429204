import React, {useState} from 'react';
import caAPI from '../../../../services/caAPI'
import {successToast} from '../../../../components/DommusToast'
import DommusRequiredSpan from '../../../../components/DommusRequiredSpan'
import ModalDommus from '../../../../components/Modal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import {useForm} from "react-hook-form";
import {Form, Col, Row, Container, Button} from "react-bootstrap";

export default function FormDisparoEmMassa({idTemplate}){
  const {handleSubmit, register, errors, reset} = useForm();
  const [openModal, setOpenModal] = useState(false);

  const disparar = (data) => {
    caAPI.atendimento.sms.dispararTemplate(idTemplate, data.contatos, data.separar);
    successToast.fire({text: "Disparo iniciado."});
    reset();
    setOpenModal(false);
  };

  const form =  <Form className={"formCadastro h-100"} onSubmit={handleSubmit(disparar)}>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Form.Group>
              <Form.Label>
                Contatos <DommusRequiredSpan/>
              </Form.Label>
              <Form.Control as={"textarea"} name="contatos" {...register("contatos", {required: true})} />
                { errors && errors.contatos && <small className="form-text text-danger">Informe os números de telefone.</small>}
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group>
              <Form.Label>
                Separador <DommusRequiredSpan/>
              </Form.Label>
              <Form.Control as={"input"} type="text" name="separador" {...register("separador", {required: true})} defaultValue=";" />
              { errors && errors.separador && <small className="form-text text-danger">Informe o separador que define os números ao lado.</small>}
            </Form.Group>
          </Col>
          <Col lg={10}>
            <div className="d-flex justify-content-end" style={{marginTop: '2rem'}}>
              <Button variant={"success"} type="submit">
                <FontAwesomeIcon icon={faPaperPlane}  style={{marginRight: '15px'}} color=""/>Disparar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  ;

  return (
    <>
      <FontAwesomeIcon icon={faPaperPlane} onClick={() => {
        setOpenModal(true)
      }} style={{cursor: 'pointer'}}/>
      <ModalDommus open={openModal} size={"lg"} close={() => {
        setOpenModal(false)
      }} content={form} titulo={"Disparar Template em Massa"}/>
    </>
  )
}
