import React, { useContext, useMemo } from "react";
import MidiaSocialIcone from "../MidiaSocialIcone";
import "./index.css";
import { celularMask } from "../InputTextMask";
import { AtendentesContext } from "../../contexts/AtendentesContext";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { FaHeadset } from "react-icons/fa";
import moment from "moment";
import { MdUpdate } from "react-icons/md";
import formatDommus from "../../helpers/format";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AtendimentoContext } from "../../contexts/AtendimentoContext";
import caAPI from "../../services/caAPI";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

export function CardOutroAtendimento({ atendimento }) {
    const { resolverNomeAtendente } = useContext(AtendentesContext);
    const { handleAdiconaLista, setAtendimentoAtivo } =
        useContext(AtendimentoContext);

    let nomeAtendente = useMemo(() => {
        return resolverNomeAtendente(atendimento.atendente);
    }, [atendimento]);

    let contatoOrigem = useMemo(() => {
        let contato = atendimento?.meio_comunicacao?.contatos_origem.find(
            (contato) => {
                return contato.valor == atendimento?.contato_origem;
            }
        );
        return contato ? contato?.descricao : "---";
    }, [atendimento]);

    const verificaContatoDestino = () => {
        switch (atendimento.meio_comunicacao.slug) {
            case "whatsapp":
            case "voip":
            case "sms":
                return celularMask(atendimento.contato_destino);
            case "email":
                return atendimento?.contato_destino;
            case "chatbot":
                return "Chatbot";
            default:
                return atendimento.contato_destino;
        }
    };

    return (
        <div
            className="card-outro-chamado"
            onClick={() => {
                trackPromise(caAPI.atendimento.find(atendimento.id)).then((response)=>{
                    setAtendimentoAtivo(response.data);
                    handleAdiconaLista(response.data);
                }).catch((erro)=>{
                    Swal.fire({
                        titleText: "Erro",
                        text: "Houve ao carregar atendimento " + erro,
                        icon: 'error'
                    });
                })
            }}
        >
            <div className="midia-outro-chamado">
                <MidiaSocialIcone
                    midia={atendimento?.meio_comunicacao?.slug || null}
                />
            </div>
            <Container fluid className="card-infos">
                <Row className="align-items-center">
                    <Col lg={7} className="info-atendimento">
                        <OverlayTrigger
                            key="nomeCliente"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-nome-atendente`}>
                                    {atendimento?.nome
                                        ? atendimento?.nome
                                        : "Nome não informado!"}
                                </Tooltip>
                            }
                        >
                            <div className="info-ellipse">
                                {atendimento?.nome}
                            </div>
                        </OverlayTrigger>
                    </Col>
                    <Col lg={5} className="dados-atendimento">
                        <div className="item-icone">
                            <MdUpdate className="icon-processo-info" />
                            <span>
                                {atendimento.ultima_movimentacao &&
                                    moment(
                                        atendimento.ultima_movimentacao
                                    ).format("DD/MM/YYYY HH:mm")}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={6} className="info-atendimento">
                        <span>{verificaContatoDestino()}</span>
                    </Col>
                    <Col lg={6} className="dados-atendimento">
                        <span>
                            Iniciado{" "}
                            {atendimento.criado_em &&
                                formatDommus.formatDateProcessInfo(
                                    atendimento.criado_em
                                )}
                        </span>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={6} className="info-atendimento">
                        {atendimento?.processo ? (
                            <div>
                                <span>Processo: </span>{" "}
                                <span>
                                    {atendimento?.processo
                                        ?.toString()
                                        .padStart(5, "0")}
                                </span>
                            </div>
                        ) : atendimento?.oportunidade ? (
                            <div>
                                <span>OPV: </span>{" "}
                                <span>
                                    {atendimento?.oportunidade
                                        ?.toString()
                                        .padStart(5, "0")}
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span>OPV/Processo</span>
                            </div>
                        )}
                    </Col>
                    <Col lg={6} className="dados-atendimento">
                        <span>{atendimento?.tipo_atendimento?.descricao}</span>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={6} className="info-atendimento">
                        <OverlayTrigger
                            key="nomeCliente"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-nome-atendente`}>
                                    {contatoOrigem}
                                </Tooltip>
                            }
                        >
                            <div className="contato-origem info-ellipse">
                                <BsFillTelephoneOutboundFill className="icon-registro-contato" />
                                <span>{contatoOrigem}</span>
                            </div>
                        </OverlayTrigger>
                    </Col>
                    <Col lg={6} className="dados-atendimento">
                        <OverlayTrigger
                                key="nomeCliente"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-nome-atendente`}>
                                        {nomeAtendente}
                                    </Tooltip>
                                }
                            >
                            <div className="item-icone info-ellipse">
                                <FaHeadset className="icon-processo-info" />
                                <span>{nomeAtendente}</span>
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={12} className="info-atendimento">
                        <div
                            className="situacao"
                            style={{
                                background:
                                    atendimento?.situacao_atendimento?.cor ??
                                    "#CA965C",
                            }}
                        >
                            {atendimento?.situacao_atendimento?.nome ?? "---"}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
