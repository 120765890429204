import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import {
    Row,
    Col,
    Form,
    InputGroup,
    OverlayTrigger,
    Button,
    Tooltip,
} from "react-bootstrap";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import { DisparoMassaContext } from "../../../../../contexts/DisparoMassaContext";
import { Icon } from "@iconify/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2/src/sweetalert2";
import { trackPromise } from "react-promise-tracker";
import DisparoMassaService from "../../../../../services/DisparoMassaService";
import { TemplateContext } from "../../../../../contexts/TemplateContext";

export function AgendamentoDisparoItem({ index, name, handleRemover }) {
    const {
        listaBaseDisparo,
        agendamentoSelecionado,
        removerDisparoAgendamento,
        atualizarAgendamentoBack,
    } = useContext(DisparoMassaContext);
    const { templates } = useContext(TemplateContext);
    const [habilitarRecorrencia, setHabilitarRecorrencia] = useState(false);
    const [disparoImediato, setDisparoImediato] = useState(false);
    const [tipoIntervalo, setTipoIntervalo] = useState(0);
    const [disparosStatus, setDisparosStatus] = useState({
        cancelado: 0,
        pendente: 0,
        concluido: 0,
        erro: 0,
        total: 0,
    });
    const [templateSelecionado, setTemplateSelecionado] = useState();
    const methods = useFormContext();
    const idTemplateWatch = methods.watch("id_template");
    const idDisparo = methods.watch(`${name}.id_disparo`);

    const resolverNomeTipoIntervalo = (intervaloTipo) => {
        switch (intervaloTipo) {
            case 1:
                return {
                    label: "Horas",
                    value: "H",
                };
            case 2:
                return {
                    label: "Dias",
                    value: "D",
                };
            case 0:
            default:
                return {
                    label: "Minutos",
                    value: "M",
                };
        }
    };
    const MODULO_CRM = useMemo(() => 1, []);
    const MODULO_VENDAS = useMemo(() => 2, []);
    const MODULO_CONECTA = useMemo(() => 6, []);
    const MODULO_PORTAL_CLIENTE = useMemo(() => 8, []);
    const MODULO_DESK = useMemo(() => 9, []);

    useEffect(() => {
        if (agendamentoSelecionado) {
            let temp = { ...disparosStatus };

            let disparo = agendamentoSelecionado.execucao_disparos.find(
                (execucao) => execucao.id === idDisparo
            );
            temp.total = disparo?.logs_disparos.length;
            disparo?.logs_disparos?.forEach((log) => {
                temp[log.status_execucao] += 1;
            });

            setDisparosStatus(temp);
        }
    }, [agendamentoSelecionado]);

    useEffect(()=>{
        let temp = templates.find((template)=>template.id === Number(idTemplateWatch));
        setTemplateSelecionado(temp);          
        methods.setValue(`${name}.id_base_disparo`, null);
    },[idTemplateWatch])

    const handleExcluirDisparo = () => {
        trackPromise(
            DisparoMassaService.removerDisparoPorId(
                agendamentoSelecionado?.id,
                idDisparo
            )
        )
            .then((response) => {
                removerDisparoAgendamento(
                    agendamentoSelecionado?.id,
                    idDisparo
                );
                handleRemover(index);
            })
            .catch((error) => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao remover disparos! " + error,
                    icon: "error",
                });
            });
    };

    const handleCancelarJobsPendentes = () => {
        trackPromise(DisparoMassaService.cancelarDisparosPendentes(idDisparo))
            .then((response) => {
                let temp = { ...disparosStatus };
                let pendentes = temp.pendente;
                temp.cancelado = pendentes;
                temp.pendente = 0;
                setDisparosStatus(temp);
                atualizarAgendamentoBack(agendamentoSelecionado.id);
            })
            .catch((error) => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao remover disparos! " + error,
                    icon: "error",
                });
            });
    };

    return (
        <div className="agendamento-disparo-item-section">
            <button
                type="button"
                className="btn-remover-agendamento"
                onClick={() => {
                    if (idDisparo) {
                        Swal.fire({
                            titleText: "Deseja excluir esse disparo?",
                            text: "Tem certeza que deseja excluir esse disparo? Os disparos pendentes serão excluídos e as informações dos disparos também serão excluídas",
                            icon: "question",
                            showCloseButton: false,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: "Sim, excluir!",
                            cancelButtonText: "Não, mantenha.",
                            customClass: {
                                confirmButton: "btn btn-primary mr-3",
                                cancelButton: "btn btn-danger",
                            },
                            buttonsStyling: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                handleExcluirDisparo();
                            }
                        });
                    } else {
                        handleRemover(index);
                    }
                }}
            >
                <FontAwesomeIcon icon={faTrash} color="white" />
            </button>
            <Row>
                <Col md={8}>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Base de Disparo:{" "}
                        </Form.Label>
                        <DommusRequiredSpan />
                        <Form.Control
                            as="select"
                            {...methods.register(`${name}.id_base_disparo`, {
                                required: true,
                            })}
                            disabled={idDisparo || !idTemplateWatch}
                        >
                            <option value="" hidden>
                                SELECIONE UMA BASE
                            </option>
                            {listaBaseDisparo.filter((base)=>{
                                if(templateSelecionado && templateSelecionado.id_modulo_tags){
                                    switch (Number(templateSelecionado.id_modulo_tags)) {
                                        case MODULO_CRM:
                                            return base.base_referencia === 'oportunidade';
                                        case MODULO_VENDAS:
                                        case MODULO_PORTAL_CLIENTE:
                                            return base.base_referencia === 'processo';                                    
                                        case MODULO_CONECTA:
                                        case MODULO_DESK:
                                            return false;
                                        default:
                                            return true;
                                    }
                                }else{
                                    return true;
                                }
                            }).map((baseDisparo, index) => (
                                <option key={index} value={baseDisparo.id}>
                                    {baseDisparo.nome}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Data e Hora:{" "}
                        </Form.Label>
                        <DommusRequiredSpan />
                        <Form.Control
                            type="datetime-local"
                            min="2000-01-01T00:00"
                            max="2050-12-31T00:00"
                            {...methods.register(`${name}.data_hora_disparo`, {
                                required: !disparoImediato,
                            })}
                            disabled={disparoImediato || idDisparo}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col
                    md={3}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Form.Group
                        controlId={`checkbox-imediato-${index}`}
                        className="checkbox-agendamento"
                    >
                        <Form.Check
                            type="checkbox"
                            label="Imediato"
                            {...methods.register(`${name}.disparo_imediato`)}
                            onChange={(event) => {
                                methods.setValue(
                                    `${name}.data_hora_disparo`,
                                    null
                                );
                                setDisparoImediato(event.target.checked);
                            }}
                            disabled={idDisparo}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId={`checkbox-recorrencia-${index}`}
                        className="checkbox-agendamento"
                    >
                        <Form.Check
                            type="checkbox"
                            label="Recorrência"
                            {...methods.register(
                                `${name}.recorrencia_habilitado`
                            )}
                            onChange={(event) => {
                                setHabilitarRecorrencia(event.target.checked);
                                if (event.target.checked) {
                                    methods.setValue(
                                        `${name}.tipo_intervalo_recorrencia`,
                                        "M"
                                    );
                                } else {
                                    methods.setValue(
                                        `${name}.tipo_intervalo_recorrencia`,
                                        null
                                    );
                                }
                            }}
                            disabled={idDisparo}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label className="inputLabel">
                            Recorrência:{" "}
                        </Form.Label>
                        <Form.Control
                            as="select"
                            {...methods.register(`${name}.recorrencia`, {
                                required: habilitarRecorrencia,
                            })}
                            disabled={
                                !habilitarRecorrencia || idDisparo
                            }
                        >
                            <option value="" hidden>
                                SELECIONE QUANTIDADE
                            </option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Label className="inputLabel">
                        Intervalo (
                        {resolverNomeTipoIntervalo(tipoIntervalo)?.label ??
                            "..."}
                        ):
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            placeholder=""
                            {...methods.register(
                                `${name}.intervalo_recorrencia`,
                                {
                                    required: habilitarRecorrencia,
                                }
                            )}
                            disabled={
                                !habilitarRecorrencia || idDisparo
                            }
                            min={0}
                        />
                        <InputGroup.Append>
                            <OverlayTrigger
                                key="buttonAlterarParaTelefone"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-editar`}>
                                        Alterar para{" "}
                                        {resolverNomeTipoIntervalo(
                                            tipoIntervalo + 1
                                        )?.label ?? "..."}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    disabled={
                                        !habilitarRecorrencia ||
                                        idDisparo
                                    }
                                    className="btnAlterar"
                                    onClick={() => {
                                        let newTipo =
                                            tipoIntervalo === 2
                                                ? 0
                                                : tipoIntervalo + 1;
                                        setTipoIntervalo(newTipo);
                                        methods.setValue(
                                            `${name}.tipo_intervalo_recorrencia`,
                                            resolverNomeTipoIntervalo(newTipo)
                                                .value
                                        );
                                    }}
                                    data-toggle={`Alterar para ${resolverNomeTipoIntervalo(
                                        tipoIntervalo + 1
                                    )}`}
                                    style={{ zIndex: 0 }}
                                >
                                    <Icon
                                        icon="akar-icons:arrow-right-left"
                                        color="white"
                                        inline={true}
                                    />
                                </Button>
                            </OverlayTrigger>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            {idDisparo ? (
                <Row>
                    <Col className="disparo-rodape-status">
                        <div className="status-disparo-section">
                            {Object.keys(disparosStatus).map((key, index) => {
                                return disparosStatus[key] ? (
                                    <div key={index} className="info-section">
                                        <span className={`nome ${key}`}>
                                            {key}
                                        </span>
                                        <span className="valor">
                                            {disparosStatus[key]}
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                );
                            })}
                        </div>
                        <div>
                            {disparosStatus.pendente ? (
                                <OverlayTrigger
                                    key="removerPendentes"
                                    placement="top"
                                    overlay={
                                        <Tooltip
                                            id={`tooltip-remover-pendente-${index}`}
                                        >
                                            Cancelar disparo
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        className="btn-cancelar-disparo"
                                        type="button"
                                        onClick={() => {
                                            Swal.fire({
                                                titleText:
                                                    "Deseja cancelar os disparos pendentes?",
                                                text: "Tem certeza que deseja cancelar os disparos pendentes? Essa ação irá cancelar todos os disparos ainda pendentes",
                                                icon: "question",
                                                showCloseButton: false,
                                                showCancelButton: true,
                                                focusConfirm: false,
                                                confirmButtonText:
                                                    "Sim, cancelar!",
                                                cancelButtonText:
                                                    "Não, mantenha.",
                                                customClass: {
                                                    confirmButton:
                                                        "btn btn-primary mr-3",
                                                    cancelButton:
                                                        "btn btn-danger",
                                                },
                                                buttonsStyling: false,
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    handleCancelarJobsPendentes();
                                                }
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faBan} />
                                        <span>Cancelar</span>
                                    </button>
                                </OverlayTrigger>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </div>
    );
}
