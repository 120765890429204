import React from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import './App.css';
import './assets/poppins/poppins.css';
// import Pusher from "pusher-js";

import 'bootstrap/dist/css/bootstrap.min.css';
import {AppContextProvider} from './contexts/AppContext'

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";

function App() {

	return (
		<BrowserRouter>
			<DommusPromiseLoading/>
			<AppContextProvider>
				<Routes/>
			</AppContextProvider>
		</BrowserRouter>

	);
}

export default App;
