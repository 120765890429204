import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router";
import caAPI from "../../services/caAPI";
import DommusLoading from "../../components/DommusLoading/DommusLoading";

export default function GuLogin() {

    const history = useHistory();
    let params = useParams();
    useEffect(() => {
        const token = params.token.split(':');
        caAPI.loginGU(token[0]).then(res => {       
            caAPI.setLoginInfo(res.data);
            let telas = [];
            let arrayTipoTelas = {
                portal_cliente: [1, 2, 3, 4, 13,7, 8, 9, 10, 25, 26],
                template: [1, 2, 3, 4,13, 7, 8, 9, 10, 25, 26],
                label: [1, 2, 3, 4, 13, 7, 8, 9, 10, 25, 26],
                arquivo: [1, 2, 3, 4, 13,7, 8, 9, 10, 25, 26],
                quadro_historico: [1, 2, 3, 4,13],
                integracoes: [1, 2, 3, 4,13],
                filtro_interacoes: [1, 2, 3, 4,13],
                filtro_notificacoes: [1, 2, 3, 4,13],
                relatorios_diversos: [1, 2, 3, 4,13],
                dashboard: [1, 2, 3, 4,13],
                disparoEmMassa: res?.data?.visualizacao_disparo_em_massa,
                contatos_notificacao_historico: [1, 2, 3, 4,13],
            };
            for (const [tela, tipos] of Object.entries(arrayTipoTelas)) {
                if (tipos.includes(Number(res.data.id_usuario_tipo))) {
                    telas.push(tela)
                }
            }
            caAPI.setLocalState('telas', telas);
            caAPI.setLocalState('guToken', token[1]);
            history.push(`/${token[1] ? `?idAtendimento=${token[1]}` : ''}`);
            window.location.reload();
        }).catch(err => alert(err));

    }, [history, params]);

    return <DommusLoading/>;
}
