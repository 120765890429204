import { Button, Toast } from "react-bootstrap";
import { BsExclamationSquareFill } from "react-icons/bs";
import './index.scss'

export function DommusToastWarmingOptions ({open, handleOpen, handleVisualizarSala, handleLembrete}){

	return(
            <Toast show={open} onClose={handleOpen} className="toast">
                <Toast.Header>
                    <BsExclamationSquareFill className="toast-icon"/>
                    <strong className="mr-auto">Sala de espera</strong>
                </Toast.Header>
                <Toast.Body className="toast-body">
                    <div className="toast-container">
                        <div>
                            Existem pessoas aguardando na sala de espera.
                        </div>
                        <div className="toast-buttons">
                            <Button variant="success" onClick={handleVisualizarSala}>
                                Visualizar
                            </Button>
                            <Button variant="secondary" onClick={handleOpen}>
                                Ignorar
                            </Button>
                            <Button variant="info" onClick={handleLembrete}>
                                Lembre-me em 5 minutos
                            </Button>
                        </div>
                    </div>
                </Toast.Body>
            </Toast>
	)
}
